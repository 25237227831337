import image1 from '../images/AlterarPerfil.jpg';
import image2 from '../images/GerencPlataforma.jpg';
import image3 from '../images/GerencUsuarios.jpg';
import ConstantInscricoes from "../constants/ConstantInscricoes";

export const Funcionalidades = (setFuncionalidade) => {

  const {
    isAdmin,
    permissoes,
    hasPermissionFuncionalidades
  } = ConstantInscricoes({ dontLoadAll: true });

  const functionalitiesData = [
    {
      id: 1,
      image: image1,
      subtitle: 'Gerenciamento de inscrições',
      description: ['Siga para o gerenciamento das inscrições. Liste, edite e aprove/reprove inscrições'],
      buttonText: 'Gerenciamento de inscrições',
      buttonVariant: 'contained',
      clique: () => setFuncionalidade(1),
      isVisible: isAdmin || hasPermissionFuncionalidades(permissoes, 'gerenciar-inscricoes')
    },
    {
      id: 2,
      image: image2,
      subtitle: 'Graficos e exportações',
      description: ['Explore visualizações gráficas detalhadas sobre as inscrições.'],
      buttonText: 'Gráficos e exportações',
      buttonVariant: 'contained',
      clique: () => setFuncionalidade(2),
      isVisible: isAdmin || hasPermissionFuncionalidades(permissoes, 'graficos')
    },
    {
      id: 3,
      image: image3,
      subtitle: 'Fluxo de caixa',
      description: ['Clique, baixe e/ou acompanhe os relatórios financeiros da marcha.'],
      buttonText: 'Fluxo de caixa',
      buttonVariant: 'outlined',
      clique: () => setFuncionalidade(3),
      isVisible: isAdmin || hasPermissionFuncionalidades(permissoes, 'fluxo-de-caixa')
    },
  ];

  return functionalitiesData.filter(func => func.isVisible);
};

