import React, { useEffect, useState } from "react";
import Navbar from '../pages/theme/Navbar';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import PrintIcon from '@mui/icons-material/Print';
import PrincipalImage from '../images/logo-cnm.png';
import { useNavigate } from 'react-router-dom';

import {
    Grid,
    Box,
    Container,
    Card,
    CardContent,
    Typography,
    Button,
    IconButton,
    FormControl,
    TextField,
    Modal
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import api from '../service/Api';
import LoadingOverlay from '../components/LoadingOverlay';

const Arenas = () => {
    const [arenas, setArenas] = useState([]);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [aprovado, setAprovado] = useState();
    const [cpfUser, setCpfUser] = useState();
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [userArenas, setUserArenas] = useState([]);
    const [currentDay, setCurrentDay] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [openModal, setOpenModal] = useState(false);
    const [desabilitarBotao, setDesabilitarBotao] = useState(false);

    const groupArenasByDay = (arenas) => {
        const groupedArenas = {};

        arenas.forEach((arena) => {
            const day = arena.dia;
            if (!groupedArenas[day]) {
                groupedArenas[day] = [];
            }
            groupedArenas[day].push(arena);
        });

        Object.keys(groupedArenas).forEach((day) => {
            groupedArenas[day].sort((a, b) => {
                const timeA = a.horario.split(' - ')[0];
                const timeB = b.horario.split(' - ')[0];
                return timeA.localeCompare(timeB);
            });
        });

        return groupedArenas;
    };


    const formatDayOfWeek = (dateString) => {
        const daysOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const date = new Date(dateString.split('/').reverse().join('/'));
        const dayOfWeek = daysOfWeek[date.getDay()];
        return dayOfWeek;
    };

    const pages = Object.values(groupArenasByDay(arenas));

    const HandleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const showAlert = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handlePrevPage = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 1));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleNextPage = () => {
        setPage((prevPage) => Math.min(prevPage + 1, pages.length));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const FormatCPF = (value) => {
        if (value) {
            value = value.replace(/\D/g, '');
            value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
            return value;
        }
        return '';
    };

    const calculateAvailabilityPercentage = (total, remaining) => {
        return (remaining / total) * 100;
    };

    const determineProgressBarColor = (percentage) => {
        if (percentage >= 75) {
            return 'success';
        } else if (percentage >= 50) {
            return 'warning';
        } else {
            return 'error';
        }
    };

    const fetchArenas = async () => {
        try {
            const response = await api.get('api/arenas');
            const sortedArenas = response?.data?.Arenas.sort((a, b) => {
                const dateA = new Date(`${a.dia} ${a.horario.split(' - ')[0]}`);
                const dateB = new Date(`${b.dia} ${b.horario.split(' - ')[0]}`);
                return dateA - dateB;
            });

            setArenas(sortedArenas);
            setLoading(false);
            fetchUserArenas();
            handleOpenModal();
        } catch (error) {
            console.error('Erro ao obter dados das arenas:', error);
        }
    };
    const apiCall = async (cpf) => {
        try {
            const userCpf = cpf.replace(/\D/g, '');
            const response = await api.get(`api/cadastroUser?cpf=${userCpf}`);
            return response;
        } catch (error) {
            console.error('Erro ao obter dados do usuário: ', error);
        }
    };

    const handleEnviarCpf = async () => {
        try {
            setDesabilitarBotao(true);
            if (cpfUser?.replace(/\D/g, '').length < 11) {
                setDesabilitarBotao(false);
                return showAlert("Informe o CPF corretamente para consulta", 'error');
            }
            const response = await apiCall(cpfUser);
            setAprovado(response?.data?.getCadastroUser[0]?.aprovado || response?.data?.getCadastroUser[0]?.cortesia === 1);
            if (response?.data?.getCadastroUser[0]?.aprovado|| response?.data?.getCadastroUser[0]?.cortesia === 1) {
                fetchArenas();
                setLoading(false);
                setDesabilitarBotao(false);
            } else if (response?.data?.getCadastroUser[0]?.aprovado === false || response?.data?.getCadastroUser[0]?.cortesia === 0) {
                setDesabilitarBotao(false);
                showAlert("Participante não homologado. Verifique se o pagamento foi realizado ou aguarde a homologação de sua inscrição", 'error');
            } else {
                setDesabilitarBotao(false);
                showAlert("Não foi possível localizar o cadastro. Por favor, verifique se o CPF está correto e tente novamente", 'error');
            }
        } catch (error) {
            setDesabilitarBotao(false);
            console.error('Erro ao obter dados do usuário: ', error);
        }
    };
    const fetchUserArenas = async () => {
        try {
            let userCpf = cpfUser;

            if (!userCpf) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                userCpf = urlSearchParams.get('cpf');
            }
            userCpf = userCpf.replace(/\D/g, '');
            const response = await api.get(`api/arenas/${userCpf}`);
            setUserArenas(response?.data?.Arenas);
        } catch (error) {
            console.error('Erro ao obter arenas do usuário:', error);
        }
    };

    const handleCadastroArena = async (arenaId) => {
        const arena = arenas.find((arena) => arena.id === arenaId);
        if (!arena || arena.lugares_restantes <= 0) {
            showAlert('Não há mais vagas disponíveis para esta arena.', 'error');
            return;
        }
        const cpfFormatado = cpfUser.replace(/[^\d]/g, '')
        try {
            const response = await api.post('api/arenas', { id_arena: arenaId, cpf: cpfFormatado });

            if (response?.status === 200) {
                const updatedArenas = arenas.map((arena) =>
                    arena.id === arenaId ? { ...arena, cadastrado: true, lugares_restantes: arena.lugares_restantes - 1 } : arena
                );
                setArenas(updatedArenas);
                showAlert('Cadastro realizado com sucesso', 'success');
                fetchUserArenas();
            } else {
                showAlert('Erro ao cadastrar na arena. Por favor, tente novamente mais tarde', 'error');
            }
        } catch (error) {
            console.error('Erro ao cadastrar na arena:', error);
            showAlert('Erro ao cadastrar na arena', 'error');
        }
    };

    const handleDescadastrarArena = async (arenaId, cadastroArenaId) => {
        try {
            const response = await api.delete(`api/arenas/${cadastroArenaId}`);

            if (response?.status === 200) {
                const updatedArenas = arenas.map((arena) =>
                    arena.id === arenaId ? { ...arena, cadastrado: false, lugares_restantes: arena.lugares_restantes + 1 } : arena
                );
                setArenas(updatedArenas);
                showAlert('Descadastramento realizado com sucesso!', 'success');
                fetchUserArenas();
            } else {
                showAlert('Erro ao descadastrar na arena. Por favor, tente novamente mais tarde', 'error');
            }
        } catch (error) {
            console.error('Erro ao descadastrar da arena:', error);
            showAlert('Erro ao descadastrar da arena', 'error');
        }
    };

    const handleImprimirArenasCadastradas = () => {
        const printWindow = window.open('', '_blank');
        if (!printWindow) {
            console.error('Erro ao abrir a janela de impressão');
            return;
        }

        const arenasPorDia = userArenas.reduce((groupedArenas, userArena) => {
            const arena = arenas.find((arena) => arena.id === userArena.arena_id);

            if (arena) {
                const dia = arena.dia;

                if (!groupedArenas[dia]) {
                    groupedArenas[dia] = [];
                }

                groupedArenas[dia].push(arena);
            }

            return groupedArenas;
        }, {});

        const diasOrdenados = Object.keys(arenasPorDia).sort();
        const arenasCadastradasHTML = diasOrdenados
            .map((dia) => {
                const arenasDoDia = arenasPorDia[dia];
                const arenasHTML = arenasDoDia
                    .map((arena) => `
                    <div>
                        <h2>${arena.area_tecnica}</h2>
                        <p>${arena.tema}</p>
                        <p>Data: ${arena.dia} de ${arena.horario}</p>
                        <p>Sala: ${arena.sala}</p>
                        <hr />
                    </div>
                `)
                    .join('');

                return `
                    <div>
                        <h1>${dia}</h1>
                        ${arenasHTML}
                    </div>
                `;
            })
            .join('');

        const toolbarHTML = `
            <div style="background-color: #005bab; color: #ffffff; padding: 10px; font-family: Arial, sans-serif;">
                <img src="${PrincipalImage}" alt="Logo CNM" style="height: 100px;" />
            </div>
        `;

        printWindow.document.write(`
            <html>
                <head>
                    <title>Arenas Cadastradas</title>
                    <style>
                        body {
                            font-family: 'Arial', sans-serif;
                        }
                        h2 {
                            color: blue;
                        }
                    </style>
                </head>
                <body>
                    ${toolbarHTML}
                    <h1>Arenas Cadastradas</h1>
                    ${arenasCadastradasHTML}
                </body>
            </html>
        `);

        printWindow.document.close();
        setTimeout(() => {
            printWindow.print();
        }, 200);
    };

    useEffect(() => {
        if (pages.length > 0) {
            setCurrentDay(pages[page - 1][0]?.dia);
        }
    }, [page, pages]);

    useEffect(() => {
        const fetchData = async () => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const urlCpf = urlSearchParams.get('cpf');
            setCpfUser(urlCpf);
            if (urlCpf && urlCpf.length === 11) {
                try {
                    const response = await apiCall(urlCpf);
                    setAprovado(response?.data?.getCadastroUser[0]?.aprovado || response?.data?.getCadastroUser[0]?.cortesia === 1);
                    if (response?.data?.getCadastroUser[0]?.aprovado|| response?.data?.getCadastroUser[0]?.cortesia === 1) {
                        fetchArenas();
                        setLoading(false);
                    } else if (response?.data?.getCadastroUser[0]?.aprovado === false || response?.data?.getCadastroUser[0]?.cortesia === 0) {
                        showAlert("Participante não homologado. Verifique se o pagamento foi realizado ou aguarde a homologação de sua inscrição", 'error');
                        setTimeout(() => {
                            navigate(`/arenas`);
                            setLoading(false);
                        }, 5000);
                    } else {
                        showAlert("Não foi possível localizar o cadastro. Por favor, verifique se o CPF está correto e tente novamente", 'error');
                        setTimeout(() => {
                            navigate(`/arenas`);
                            setLoading(false);
                        }, 5000);
                    }
                } catch (error) {
                    console.error('Erro ao obter dados do usuário: ', error);
                }
            } else {
                setAprovado(false);
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <React.Fragment>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={HandleSnackbarClose}
                    style={{ zIndex: 9999 }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={HandleSnackbarClose}
                        severity={snackbarSeverity}
                    >
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
                <Navbar />
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />

                {loading ? (<LoadingOverlay />) :

                    aprovado ? (
                        <Container disableGutters xs={12} sm={12} sx={{ pt: 5 }} style={{ userSelect: 'none' }}>
                            <Grid item xs={12} sm={12}>
                                <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                                    {pages.length > 0 && (
                                        <>
                                            <IconButton
                                                onClick={handlePrevPage}
                                                style={{
                                                    position: 'fixed',
                                                    left: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    zIndex: 1000,
                                                    display: page === 1 ? 'none' : 'block'
                                                }}
                                            >
                                                <ArrowBack />
                                                {!isMobile && <span style={{ marginLeft: '5px' }}>Voltar</span>}
                                            </IconButton>

                                            <Grid item xs={12} sm={12} style={{ marginBottom: '20px' }}>
                                                <Grid container justifyContent="space-between" alignItems="center" mt={4}>
                                                    <Typography variant="h4" style={{ color: '#005bab' }}>
                                                        ARENAS - {currentDay && `${currentDay} (${formatDayOfWeek(currentDay)})`}
                                                    </Typography>
                                                    {userArenas.length > 0 && (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleImprimirArenasCadastradas}
                                                            style={{ backgroundColor: 'green' }}
                                                        >
                                                            <PrintIcon style={{ marginRight: '5px' }} />
                                                            Finalizar e imprimir cadastro(s)
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                {pages[page - 1].map((arena) => (
                                                    <Grid item key={arena.id} xs={12} sm={6} md={4}>
                                                        <Card>
                                                            <CardContent>
                                                                <Typography variant="h6" component="div" style={{ height: '70px', overflow: 'hidden' }}>
                                                                    {arena?.area_tecnica}
                                                                </Typography>
                                                                <Typography variant="body2" style={{ height: '70px', overflow: 'hidden' }}>
                                                                    {arena?.tema}
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    Data: {arena.dia} de {arena.horario}
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    Arena: {arena.sala}
                                                                </Typography>
                                                                <Typography variant="body2">
                                                                    Vagas Restantes: {arena.lugares_restantes} / {arena.lugares}
                                                                </Typography>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={calculateAvailabilityPercentage(arena.lugares, arena.lugares_restantes)}
                                                                    color={determineProgressBarColor(calculateAvailabilityPercentage(arena.lugares, arena.lugares_restantes))}
                                                                    sx={{
                                                                        marginTop: '10px',
                                                                        height: '8px',
                                                                        marginBottom: '10px',
                                                                    }}
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{
                                                                        backgroundColor: userArenas.some((userArena) => userArena.arena_id === arena.id)
                                                                            ? '#d9534f'
                                                                            : '',
                                                                    }}
                                                                    onClick={() => {
                                                                        const cadastroArena = userArenas.find((userArena) => userArena.arena_id === arena.id);
                                                                        if (cadastroArena) {
                                                                            handleDescadastrarArena(arena.id, cadastroArena.id);
                                                                        } else {
                                                                            handleCadastroArena(arena.id);
                                                                        }
                                                                    }}
                                                                >
                                                                    {userArenas.some((userArena) => userArena.arena_id === arena.id) ? 'Descadastrar' : 'Cadastrar na Arena'}
                                                                </Button>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </>
                                    )}
                                    <IconButton
                                        onClick={handleNextPage}
                                        style={{
                                            position: 'fixed',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            zIndex: 1000,
                                            display: page === pages.length ? 'none' : 'block',
                                        }}
                                    >
                                        {!isMobile && <span style={{ marginRight: '5px' }}>Próximo</span>}
                                        <ArrowForward />
                                    </IconButton>
                                </Box>
                            </Grid>

                        </Container>
                    ) : (
                        <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
                            <Grid item xs={12} sm={4}>
                                <FormControl required fullWidth>
                                    <Typography variant="h6">
                                        Por favor, informe seu CPF para realizar os cadastros nas arenas
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="cpf"
                                        label="CPF"
                                        name="cpf"
                                        autoComplete="cpf"
                                        value={FormatCPF(cpfUser)}
                                        onChange={(e) => setCpfUser(e.target.value)}
                                        inputProps={{ maxLength: 14 }}
                                        style={{ marginTop: '20px' }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !desabilitarBotao) {
                                                handleEnviarCpf();
                                            }
                                        }}
                                    />
                                    <Button variant="contained" color="primary" disabled={desabilitarBotao} onClick={handleEnviarCpf}>
                                        Enviar
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Container>
                    )}

            </React.Fragment>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        borderRadius: '4px',
                    }}
                >
                    <Typography style={{ userSelect: 'none' }} variant="h6" component="h2">
                        Queremos que sua experiência nas nossas Arenas Temáticas seja incrível e personalizada. Siga estas simples etapas para se cadastrar:

                        <ol>
                            <li><strong>Escolha Seu(s) Tema(s):</strong>
                                <ul>
                                    <li>Selecione o(s) tema(s) de seu interesse para participar. Lembre-se, você pode escolher mais de um e em horários diferentes, permitindo uma participação flexível e diversificada.</li>
                                </ul>
                            </li>

                            <li><strong>Clique em "Cadastrar na Arena":</strong>
                                <ul>
                                    <li>Após escolher seu(s) tema(s), clique no botão "Cadastrar na Arena". Isso garantirá sua presença nas sessões escolhidas.</li>
                                </ul>
                            </li>

                            <li><strong>Imprima Sua Arena Cadastrada:</strong>
                                <ul>
                                    <li>É essencial imprimir ou salvar a confirmação do seu cadastro na Arena. Dessa forma, você terá todas as informações necessárias à mão e não perderá nenhum detalhe das atividades e discussões.</li>
                                </ul>
                            </li>
                        </ol>

                        <p>
                            <strong>Obs:</strong> Esse cadastro assegura sua participação durante um período de tolerância de 15 minutos, contados a partir do início da arena.
                        </p>
                    </Typography>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Fechar
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Arenas;
