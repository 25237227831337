import { useState, useEffect } from "react";
import api from '../service/Api';
import axios from 'axios';
import ValidateForm from '../components/ValidateForm';
import { useNavigate } from 'react-router-dom';

const useConstants = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [ufs, setUfs] = useState([]);
    const [naturezas, setNaturezas] = useState([]);
    const [tipoCargo, setTipoCargo] = useState([]);
    const [cargo, setCargo] = useState([]);
    const [parlamentar, setParlamentar] = useState([]);
    const [partidoPolitico, setPartidoPolitico] = useState([]);
    const [entidadeNacional, setEntidadeNacional] = useState([]);
    const [entidadeEstadual, setEntidadeEstadual] = useState([]);
    const [entidadeMicro, setEntidadeMicro] = useState([]);
    const [consorcio, setConsorcio] = useState([]);
    const [areaDeAtuacao, setAreaDeAtuacao] = useState([]);
    const [codigoUf, setCodigoUf] = useState(null);
    const [municipio, setMunicipio] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [redirectBoleto, setRedirectBoleto] = useState(false);
    const [open, setOpen] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [retornoApi, setRetornoApi] = useState({});
    const [fileInputKey, setFileInputKey] = useState(0);
    const navigate = useNavigate();
    const initialState = {
        tipo_inscricao: '',
        tipo_cargo: '',
        cargo: '',
        parlamentar: '',
        nome_parlamentar: '',
        entidade_estadual: '',
        entidade_micro: '',
        entidade_nacionais: '',
        consorcio: '',
        partido_politico: '',
        nome_cargo: '',
        pais: '',
        area_atuacao: '',
        empresa_instituicao: '',
        ministerio_orgao: '',
        nome: '',
        genero: '',
        PCD: {
            necessita_atendimento_especializado: '',
            tipo_deficiencias: [],
            outras_deficiencia: '',
            dificuldade_locomocao: '',
            acompanhamento_acessibilidade: '',
            descricao_acompanhamento_acessibilidade: ''
        },
        nome_cracha: '',
        cpf: '',
        passaporte: '',
        cnpj: '',
        uf: '',
        municipio: '',
        nome_municipio: '',
        celular: '',
        email: '',
        telefone: '',
        cep: '',
        endereco: '',
        bairro: '',
        razao_social: '',
        opc_pagamento: '2',
        status_contribuicao: '',
        politica_privacidade: '',
        tipo_pagamento: 'boleto',
        convidado: {
            cargo: '',
            convidado_de: ''
        },
        obs: '',
        aprovado: false,
        // file: '',
        cortesia: 0,
    };
    const [formValues, setFormValues] = useState(initialState);
    const HandleChangeForm = (atributo, valor, nome_municipio = null, nome_parlamentar = null) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [atributo]: valor,
            ...(nome_municipio && { nome_municipio }),
            ...(nome_parlamentar && { nome_parlamentar }),
        }));
    };

    const resetForm = () => {
        setFormValues({ ...initialState });
    };

    const handleFileChange = (e) => {
        HandleChangeForm('file', e.target.files[0]);
    };

    const handleRemoveFile = () => {
        setFileInputKey((prevKey) => prevKey + 1);
        HandleChangeForm('file', null);
    };

    const formatarData = (dataString) => {
        if (!dataString) return null;

        const [dia, mes, ano] = dataString.split('.');
        return `${dia}/${mes}/${ano}`;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const HandleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const showAlert = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const FormatCPF = (value) => {
        // Remove tudo o que não é dígito
        if (value) {
            value = value.replace(/\D/g, '');
            // Adiciona os pontos e o hífen no CPF
            value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
            return value;
        }

        return '';
    };

    const FormatCNPJ = (value) => {
        // Remove tudo o que não é dígito
        if (value) {
            value = value.replace(/\D/g, '');
            // Adiciona os pontos, a barra e o hífen no CNPJ
            value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
            return value;
        }

        return '';
    };

    const FormatCEP = (value) => {
        // Remove tudo o que não é dígito
        if (value) {
            value = value.replace(/\D/g, '');

            // Garante que o CEP tenha exatamente 8 dígitos
            if (value.length === 8) {
                // Adiciona o ponto e o hífen no CEP
                value = value.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3');
            }

            return value;
        }

        return '';
    };

    const FormatCelular = (value) => {
        const celular = value.replace(/\D/g, '');

        if (celular.length === 0) {
            return '';
        } else if (celular.length <= 2) {
            return `(${celular}`;
        } else if (celular.length <= 7) {
            return `(${celular.slice(0, 2)}) ${celular.slice(2)}`;
        } else {
            return `(${celular.slice(0, 2)}) ${celular.slice(2, 7)}-${celular.slice(7, 11)}`;
        }
    };

    const FormatTelefone = (value) => {
        const telefone = value.replace(/\D/g, '');

        if (telefone.length === 0) {
            return '';
        } else if (telefone.length <= 2) {
            return `(${telefone}`;
        } else if (telefone.length <= 6) {
            return `(${telefone.slice(0, 2)}) ${telefone.slice(2)}`;
        } else {
            return `(${telefone.slice(0, 2)}) ${telefone.slice(2, 6)}-${telefone.slice(6, 10)}`;
        }
    };

    const HandleChangeCheckbox = (tipoDeficiencia, isChecked) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            PCD: {
                ...prevFormValues.PCD,
                tipo_deficiencias: isChecked
                    ? [...prevFormValues.PCD.tipo_deficiencias, tipoDeficiencia]
                    : prevFormValues.PCD.tipo_deficiencias.filter((deficiencia) => deficiencia !== tipoDeficiencia),
            },
        }));
    };

    const ajustaNomes = (str) => {
        return str.replace(/(^|\s)\S/g, (match) => match.toLocaleUpperCase());
    };

    const handleSaveUser = async () => {
        let errors = {};
        const isValid = ValidateForm({
            formValues,
            setFormErrors,
            showAlert,
            errors,
        });
        if (isValid) {
            try {
                const maxSizeInBytes = 20480 * 1024;
                if (formValues?.file?.size > maxSizeInBytes) {
                    showAlert('Atenção: Tamanho da imagem excede 20MB permitidos. Favor, selecione uma imagem menor', 'error');
                    return;
                }
                const pcdJson = JSON.stringify(formValues.PCD);
                const convidadoJson = JSON.stringify(formValues.convidado);
                const cleanTelefone = formValues.telefone.replace(/[\s()-]/g, '');
                const cleanCelular = formValues.celular.replace(/[\s()-]/g, '');
                const cleanCpf = formValues.cpf.replace(/[^\d]/g, '');
                const cleanCnpj = formValues.cnpj.replace(/[^\d]/g, '');
                const cleanCep = formValues.cep.replace(/[^\d]/g, '');
                const updatedFormValues = {
                    ...formValues,
                    PCD: pcdJson,
                    convidado: convidadoJson,
                    telefone: cleanTelefone,
                    celular: cleanCelular,
                    cpf: cleanCpf,
                    cnpj: cleanCnpj,
                    cep: cleanCep,
                    nome: ajustaNomes(formValues.nome.toLowerCase()),
                    nome_cracha: ajustaNomes(formValues.nome_cracha.toLowerCase()),
                    email: formValues.email.toLowerCase(),
                };

                const formData = new FormData();

                for (const [key, value] of Object.entries(updatedFormValues)) {
                    if (key === 'PCD' || key === 'convidado') {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }

                const response = await api.post('api/cadastroUser', formData);
                setRetornoApi(response);
                if (response?.status === 200) {
                    if (response?.data?.pagamento) {
                        setRedirectBoleto(true);
                    } else if (formValues?.aprovado === true) {
                        showAlert('Cadastro realizado com sucesso! Favor, verifique seu e-mail.', 'success');
                        resetForm();
                        setTimeout(() => {
                            navigate(`/arenas?cpf=${formValues?.cpf}`);
                        }, 3500);
                    } else {
                        showAlert('Cadastro realizado com sucesso! Favor, verifique seu e-mail.', 'success');
                        resetForm();
                    }
                } else {
                    showAlert('Erro ao enviar o formulário. Tente novamente mais tarde.', 'error');
                }
            } catch (error) {
                const erroValidacao = error?.response?.data?.errors;
                const erroPrefeitoNaoCadastrado = error?.response.data?.mensage;
                if (erroValidacao) {
                    const errorMessage = Object.values(erroValidacao)
                        .flat()
                        .join(' ');
                    showAlert(errorMessage, 'error');
                } else if (erroPrefeitoNaoCadastrado) {
                    showAlert(erroPrefeitoNaoCadastrado, 'error');
                } else {
                    showAlert('Inscrição não realizada! Certifique-se de que o CPF ou CNPJ estão corretos. Persistindo o erro, entre em contato com a CNM.', 'error');
                }
            }
        }
    };

    const getEntidadeOpcoesPorTipoCargo = (tipoCargo) => {
        switch (tipoCargo) {
            case 138:
                return entidadeNacional || [];
            case 146:
                return entidadeEstadual || [];
            case 153:
                return entidadeMicro || [];
            default:
                return [];
        }
    };

    const getEntidadeValorPorTipoCargo = (formValues, tipoCargo) => {
        switch (tipoCargo) {
            case 138:
                return entidadeNacional?.find((e) => e.id === formValues?.entidade_nacionais);
            case 146:
                return entidadeEstadual?.find((e) => e.id === formValues?.entidade_estadual);
            case 153:
                return entidadeMicro?.find((e) => e.id === formValues?.entidade_micro);
            default:
                return null;
        }
    };

    const getEntidadeLabelPorTipoCargo = (tipoCargo) => {
        switch (tipoCargo) {
            case 138:
                return 'Entidade Nacional';
            case 146:
                return 'Entidade Estadual';
            case 153:
                return 'Entidade Microrregional';
            default:
                return '';
        }
    };

    const getEntidadeNomeFormulario = (tipoCargo) => {
        switch (tipoCargo) {
            case 138:
                return 'entidade_nacionais';
            case 146:
                return 'entidade_estadual';
            case 153:
                return 'entidade_micro';
            default:
                return '';
        }
    };

    useEffect(() => {
        if (!formValues.cep.trim()) {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                endereco: '',
                bairro: '',
            }));
        }
    }, [formValues.cep]);

    useEffect(() => {
        const handleSearchCep = async () => {
            const cep = formValues.cep;
            const cleanedCep = cep.replace(/\D/g, '');

            if (cleanedCep.length === 8) {
                // setIsLoading(true);
                try {
                    const response = await axios.get(`https://viacep.com.br/ws/${cleanedCep}/json/`);
                    const data = response.data;
                    setFormValues((prevFormValues) => ({
                        ...prevFormValues,
                        endereco: data.logradouro || '',
                        bairro: data.bairro || '',
                    }));
                    // setIsLoading(false);
                } catch (error) {
                    console.error('Erro ao buscar endereço: ', error);
                }
            }
        };

        handleSearchCep();
    }, [formValues.cep, setFormValues]);

    useEffect(() => {
        const fetchTipoCargo = async () => {
            try {
                // setIsLoading(true);
                const response = await api.get(`api/getTipoInscricao?id_tipo_inscricao=${formValues.tipo_inscricao}`);
                setTipoCargo(response.data.tipoInscricao);
                setIsLoading(false);
            } catch (error) {
                console.error('Erro ao buscar tipos de cargos:', error);
            }
        };

        if (formValues.tipo_inscricao) {
            fetchTipoCargo();
        }
    }, [formValues.tipo_inscricao]);

    useEffect(() => {
        const fetchAutoridades = async () => {
            try {
                const response = await api.get(`api/getAutoridade`);
                setParlamentar(response.data.autoridade)
            } catch (error) {
                console.error('Erro ao buscar as autoridades:', error);
            }
        };
        if (formValues?.tipo_inscricao === 26 || formValues?.tipo_inscricao === 68) {
            fetchAutoridades();
        }

    }, [formValues?.tipo_inscricao]);

    useEffect(() => {
        const fetchPartidoPolitico = async () => {
            try {
                const response = await api.get(`api/getPartidoPolitico`);
                setPartidoPolitico(response.data.partidoPolitico)
            } catch (error) {
                console.error('Erro ao buscar os partidos políticos:', error);
            }
        };
        if (formValues?.tipo_inscricao === 26 || formValues?.tipo_inscricao === 68) {
            fetchPartidoPolitico();
        }

    }, [formValues?.tipo_inscricao]);

    useEffect(() => {
        const fetchCargos = async () => {
            try {
                // setIsLoading(true);
                const response = await api.get(`api/getCargo?id_tipo_cargo=${formValues.tipo_cargo}`);
                setCargo(response.data.id_tipo_cargo);
                setIsLoading(false);
            } catch (error) {
                console.error('Erro ao buscar cargos:', error);
            }
        };

        if (formValues.tipo_cargo) {
            fetchCargos();
        }
    }, [formValues.tipo_cargo]);

    useEffect(() => {
        const fetchAreasAtuacao = async () => {
            try {
                // setIsLoading(true);
                const response = await api.get(`api/getAreaAtuacao`);
                setAreaDeAtuacao(response.data.AreaAtuacao);
                setIsLoading(false);
            } catch (error) {
                console.error('Erro ao buscar áreas de atuação:', error);
            }
        };

        if (formValues.tipo_cargo) {
            fetchAreasAtuacao();
        }
    }, [formValues.tipo_cargo]);

    useEffect(() => {
        const fetchUfs = async () => {
            try {
                // setIsLoading(true);
                const response = await api.get('api/getUf');

                let ufsData = response.data.ufs;

                if (formValues?.tipo_inscricao === 1) {
                    ufsData = ufsData.filter(uf => uf.SIGLA_UF !== 'DF');
                }

                setUfs(ufsData);
                setIsLoading(false);
            } catch (error) {
                console.error('Erro ao buscar UFs:', error);
            }
        };

        fetchUfs();
    }, [formValues?.tipo_inscricao]);

    useEffect(() => {
        const fetchMunicipios = async () => {
            try {
                // setIsLoading(true);
                if (codigoUf) {
                    const response = await api.get(`api/getMunicipio?uf_id=${codigoUf}`);
                    setMunicipio(response.data.municipios);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Erro ao buscar município:', error);
            }
        };

        fetchMunicipios();
    }, [codigoUf]);

    useEffect(() => {
        const fetchEntidade = async () => {
            try {
                let response;
                if (formValues.tipo_cargo === 15) {
                    response = await api.get(`api/getConsorcios`);
                    setConsorcio(response.data.consorcios)
                }
                else if (formValues.tipo_cargo === 138) {
                    response = await api.get(`api/getEntidadesNacionais`);
                    setEntidadeNacional(response.data.EntidadesNacionais);
                } else if (formValues.tipo_cargo === 146) {
                    response = await api.get(`api/getEntidadesEstaduais`);
                    setEntidadeEstadual(response.data.EntidadesEstaduais);
                } else if (formValues.tipo_cargo === 153) {
                    response = await api.get(`api/getEntidadesMicros`);
                    setEntidadeMicro(response.data.EntidadesMicros);
                } else {
                    return;
                }
            } catch (error) {
                console.error('Erro ao buscar entidade:', error);
            }
        };

        fetchEntidade();
    }, [formValues.tipo_cargo]);

    useEffect(() => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            consorcio: '',
            entidade_nacionais: '',
            entidade_estadual: '',
            entidade_micro: '',
        }));
    }, [formValues.tipo_inscricao]);

    useEffect(() => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            consorcio: '',
            entidade_nacionais: '',
            entidade_estadual: '',
            entidade_micro: '',
        }));
    }, [formValues.tipo_cargo]);

    useEffect(() => {
        const fetchContribuinte = async () => {
            try {
                if (formValues?.municipio) {
                    const apiContatos = axios.create({
                        baseURL: 'https://contatos.cnm.org.br/',
                    });
                    const response = await apiContatos.get(`api/list/municipio/${formValues?.municipio}`);
                    const isContribuinte = response?.data?.data?.situacao === "Em dia" ? true : false;
                    HandleChangeForm('status_contribuicao', isContribuinte);
                    if (!isContribuinte) {
                        setOpen(true);
                    }
                }
            } catch (error) {
                if (error?.response.status === 404) {
                    HandleChangeForm('status_contribuicao', false);
                } else {
                    console.error('Erro ao buscar município:', error);
                }
            }
        };

        fetchContribuinte();
    }, [formValues?.municipio]);

    useEffect(() => {
        if (formValues.tipo_cargo === 122) {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                cpf: '',
                uf: '',
                municipio: ''
            }));
        }
    }, [formValues.tipo_cargo]);

    useEffect(() => {
        if (formValues?.PCD?.necessita_atendimento_especializado === 'Nao') {
            const updatedPCD = {
                tipo_deficiencias: [],
                outras_deficiencia: '',
                dificuldade_locomocao: '',
                acompanhamento_acessibilidade: '',
                descricao_acompanhamento_acessibilidade: '',
            };
            if (
                formValues.PCD.tipo_deficiencias.length !== 0 ||
                formValues.PCD.outras_deficiencia ||
                formValues.PCD.dificuldade_locomocao ||
                formValues.PCD.acompanhamento_acessibilidade ||
                formValues.PCD.descricao_acompanhamento_acessibilidade
            ) {
                HandleChangeForm('PCD', { ...formValues.PCD, ...updatedPCD });
            }
        }
    }, [formValues?.PCD?.necessita_atendimento_especializado, formValues.PCD]);


    useEffect(() => {
        if (formValues?.PCD?.tipo_deficiencias && !formValues.PCD.tipo_deficiencias.includes('Outros')) {
            if (formValues.PCD.outras_deficiencia) {
                HandleChangeForm('PCD', { ...formValues.PCD, outras_deficiencia: '' });
            }
        }
    }, [formValues?.PCD?.tipo_deficiencias, formValues.PCD]);

    useEffect(() => {
        if (
            formValues?.PCD?.dificuldade_locomocao &&
            formValues.PCD.dificuldade_locomocao !== 'Sim' &&
            formValues.PCD.dificuldade_locomocao !== 'Cadeirante'
        ) {
            const updatedPCD = {
                acompanhamento_acessibilidade: '',
                descricao_acompanhamento_acessibilidade: '',
            };
            if (
                formValues.PCD.acompanhamento_acessibilidade !== updatedPCD.acompanhamento_acessibilidade ||
                formValues.PCD.descricao_acompanhamento_acessibilidade !== updatedPCD.descricao_acompanhamento_acessibilidade
            ) {
                HandleChangeForm('PCD', { ...formValues.PCD, ...updatedPCD });
            }
        } else if (formValues?.PCD?.acompanhamento_acessibilidade === 'Nao') {
            const updatedPCD = {
                descricao_acompanhamento_acessibilidade: '',
            };
            if (formValues.PCD.descricao_acompanhamento_acessibilidade !== updatedPCD.descricao_acompanhamento_acessibilidade) {
                HandleChangeForm('PCD', { ...formValues.PCD, ...updatedPCD });
            }
        }
    }, [formValues?.PCD?.dificuldade_locomocao, formValues.PCD]);

    useEffect(() => {
        if (formValues.opc_pagamento === '1') {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                cnpj: '',
                razao_social: '',
            }));
        }
    }, [formValues.opc_pagamento]);

    useEffect(() => {
        if ([3, 16].includes(formValues.cargo) && formValues.status_contribuicao === true) {
            HandleChangeForm('aprovado', true);
        } else {
            HandleChangeForm('aprovado', false);
        }
    }, [formValues.cargo, formValues.status_contribuicao]);

    return {
        handleClose,
        formatarData,
        HandleSnackbarClose,
        showAlert,
        FormatCPF,
        FormatCNPJ,
        FormatCEP,
        FormatCelular,
        FormatTelefone,
        HandleChangeForm,
        HandleChangeCheckbox,
        handleSaveUser,
        setFormValues,
        setCodigoUf,
        setMunicipio,
        setNaturezas,
        setIsLoading,
        resetForm,
        getEntidadeOpcoesPorTipoCargo,
        getEntidadeValorPorTipoCargo,
        getEntidadeNomeFormulario,
        getEntidadeLabelPorTipoCargo,
        setConsorcio,
        handleFileChange,
        handleRemoveFile,
        isLoading,
        ufs,
        naturezas,
        tipoCargo,
        cargo,
        consorcio,
        areaDeAtuacao,
        codigoUf,
        municipio,
        formErrors,
        retornoApi,
        initialState,
        formValues,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        redirectBoleto,
        parlamentar,
        partidoPolitico,
        fileInputKey,
        open
    };

}

export default useConstants;