import React from 'react';
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'
import linkedin from '../images/linkedin.png'
import twitter from '../images/x.png'
import youtube from '../images/youtube.png'
import logo from '../images/logo-cnm.png'

function Footer() {
    return (
        <>
            <footer style={{ userSelect: 'none' }}>
                <div className='contatos'>
                    <ul className='end'>
                        <li><p>SGAN 601 Módulo N</p></li>
                        <li><p>Brasília - DF | CEP: 70830-010</p></li>
                    </ul>
                    <ul>
                        <li><p>Telefone: (61) 2101-6655</p></li>
                        <li><p>E-mail: marcha@cnm.org.br</p></li>
                    </ul>
                    <ul className='redessociais'>
                        <li><a href="https://www.facebook.com/portalcnm" draggable={false} target="noreferrer"><img draggable={false} src={facebook} alt="facebook" target="noreferrer" /></a></li>
                        <li><a href="https://www.instagram.com/portalcnm/" draggable={false} target="noreferrer"><img draggable={false} src={instagram} alt="instagram" target="noreferrer" /></a></li>
                        <li><a href="https://www.linkedin.com/company/portalcnm/mycompany/" draggable={false} target="noreferrer"><img draggable={false} src={linkedin} alt="linkedin" target="noreferrer"/></a></li>
                        <li><a href="https://twitter.com/portalcnm" draggable={false} target="noreferrer"><img draggable={false} src={twitter} alt="twitter" target="noreferrer" /></a></li>
                        <li><a href="https://www.youtube.com/@TVportalCNM" draggable={false} target="noreferrer"><img draggable={false} src={youtube} alt="youtube" target="_blank" /></a></li>
                    </ul>
                    <img src={logo} draggable={false} alt="Logo da CNM" className='logoCNM'></img>
                </div>
                <div>
                    <p className='textcopy'>
                        © Copyright 2022 Confederação Nacional de Municípios. Todo conteúdo do site pode ser reproduzido, desde
                        que citada a fonte.
                    </p>
                </div>
            </footer>
        </>
    )
}

export default Footer;