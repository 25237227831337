import { useState, useEffect, useRef } from 'react';
import { Dialog, TextField, DialogTitle, DialogContent, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import api from '../service/Api';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import Etiqueta from './Etiqueta';

export default function InputsDialog({ open, onClose }) {
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [userData, setUserData] = useState(null);
    const [userDataDialogOpen, setUserDataDialogOpen] = useState(false);
    const etiquetaRef = useRef();

    const Print = useReactToPrint({
        content: () => etiquetaRef.current,
        onAfterPrint: () => {
            window.location.reload();
        }
    });

    useEffect(() => {
        if (userData) {
            setUserDataDialogOpen(true);
        }
    }, [userData]);

    const handleSendToken = async () => {
        try {
            const response = await api.post(`api/confirmaEmail?cpf=${cpf}&email=${email}`);

            if (response.data.getConfirmaEmail === "Usuário com e-mail confirmado") {
                const userDataResponse = await api.get(`api/cadastroUser?cpf=${cpf}`);
                setUserData(userDataResponse.data);

                const userId = userDataResponse.data.getCadastroUser[0].id;

                try {
                    const etiquetaImp = await api.get(`api/controleAcesso/${userId}`);
                    if (etiquetaImp.data.ControleAcesso != null) {
                        alert('Sua etiqueta já foi impressa! Compareça a um dos caixas da XXV Marcha.');
                        window.location.reload();
                    }
                } catch (error) {
                    console.error('Erro na consulta', error);
                }

                try {
                    await api.post(`api/controleAcesso?user_id=${userId}&tipo_registro=etiqueta`);
                } catch (error) {
                    console.error('Erro ao utilizar o controle de acessos: ', error.message);
                }
            }

            if (response.data.getConfirmaEmail === "Email enviado com sucesso!") {
                alert("E-mail enviado com sucesso! Por favor, verifique sua caixa de entrada e clique no link/token fornecido.");
            }

            if (response.data.getConfirmaEmail === "Usuário não encontrato") {
                alert("Usuário não encontrado!");
            }


        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
        }
    }

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <Button onClick={onClose} style={{ position: 'absolute', right: 5, color: 'red' }}>Fechar</Button>
                <DialogTitle style={{ marginTop: '25px' }}>
                    Preencha os seus dados e envie
                </DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <TextField variant='outlined' label="Digite o seu CPF" inputProps={{ maxLength: '11' }} value={cpf} onChange={(e) => setCpf(e.target.value)} />
                    <TextField variant='outlined' label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <Button variant='contained' style={{ backgroundColor: 'green', color: '#fff', fontWeight: 'bold' }} onClick={handleSendToken}>Enviar</Button>
                </DialogContent>
            </Dialog>

            <Dialog open={userDataDialogOpen} onClose={() => setUserDataDialogOpen(false)}>
                <Button onClick={onClose} style={{ position: 'absolute', right: 5, color: 'red' }}>Fechar</Button>
                <DialogTitle>Dados do Usuário</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Nome</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Cargo</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userData && userData.getCadastroUser.map(dados => (
                                    <TableRow key={dados.id}>
                                        <TableCell style={{ textAlign: 'center', fontWeight: 'bold', color: 'rgba(0, 90, 156, 0.8)' }}>
                                            {dados.nome.charAt(0).toUpperCase() + dados.nome.slice(1)}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center', fontWeight: 'bold', color: 'rgba(0, 90, 156, 0.8)' }}>{dados.cargo_descricao}</TableCell>
                                        <TableCell>
                                            {dados.status && dados.status.toLowerCase() === 'aberto' ? (
                                                <Button variant="contained" color="primary" disabled>
                                                    <>
                                                        Pagamento em aberto
                                                        <PrintIcon />
                                                    </>
                                                </Button>
                                            ) : (
                                                <Button variant="contained" color="primary" onClick={Print}>
                                                    <>
                                                        Imprimir
                                                        <PrintIcon />
                                                    </>
                                                </Button>
                                            )}
                                            <div style={{ display: 'none' }}>
                                                <Etiqueta
                                                    nome={dados.nome_cracha.toUpperCase()}
                                                    uf={dados.cargo === 77 || dados.cargo === 78 || dados.cargo === 79 || dados.cargo === 50 || dados.cargo === 51 || dados.cargo === 52 || dados.cargo === 56 || dados.cargo === 57 || dados.cargo === 58 || dados.cargo === 70 || dados.cargo === 71 || dados.cargo === 75 || dados.cargo === 99 || dados.cargo === 101 ? dados.NOME_UF : dados.uf}
                                                    uf_sigla={dados.cargo === 76 || dados.cargo === 53 || dados.cargo === 59 || dados.cargo === 98 || dados.cargo === 99 || dados.cargo === 100 ? ' / ' + dados.uf : ''}
                                                    cpf={dados.cpf}
                                                    cargo={dados.cargo === 14 || dados.cargo === 19 || dados.cargo === 25 || dados.cargo === 38 || dados.cargo === 43 || dados.cargo === 48 || dados.cargo === 54 || dados.cargo === 60 || dados.cargo === 67 || dados.cargo === 79 || dados.cargo === 85 || dados.cargo === 90 || dados.cargo === 96 || dados.cargo === 101 || dados.cargo === 107 || dados.cargo === 114 || dados.cargo === 121 || dados.cargo === 131 || dados.cargo === 136 || dados.cargo === 145 || dados.cargo === 152 || dados.cargo === 159 || dados.cargo === 174 ? '' : dados.cargo_descricao}
                                                    complemento={dados.cargo === 5 ? ' Prefeito(a)' : dados.cargo === 28 ? ' da República' : dados.cargo === 29 ? ' Presidente da República' : dados.cargo === 31 ? ' do Brasil' : dados.cargo === 51 ? ' do Senado Federal' : dados.cargo === 52 ? ' Presidente do Senado Federal' : dados.cargo === 56 ? ' da Câmara dos Deputados' : dados.cargo === 57 ? ' Presidente da Câmara dos Deputados' : dados.cargo === 71 ? ' Governador(a)' : dados.cargo === 99 ? ' da Assembleia Legislativa' : dados.cargo === 62 ? ' Integrante do Judiciário Federal' : dados.cargo === 63 ? ' Integrante do Judiciário Federal' : dados.cargo === 64 ? ' Integrante do Judiciário Federal' : dados.cargo === 65 ? ' Integrante do Judiciário Federal' : dados.cargo === 66 ? ' Integrante do Judiciário Federal' : ''}
                                                    campo_obs={dados.empresa_instituicao}
                                                    parlamentar={dados.cargo === 76 || dados.cargo === 100 || dados.cargo === 53 || dados.cargo === 59 ? dados.nome_autoridade + ' - ' : ''}
                                                    tipo_inscricao={dados.cargo === 72 || dados.cargo === 75 || dados.cargo === 33 || dados.cargo === 34 || dados.cargo === 35 || dados.cargo === 36 || dados.cargo === 37 || dados.cargo === 38 || dados.cargo === 76 || dados.cargo === 77 || dados.cargo === 78 || dados.cargo === 79 || dados.cargo === 90 || dados.cargo === 157 ? dados.tipo_inscricao_descricao : ''}
                                                    tipo_cargo={dados.cargo === 54 || dados.cargo === 60 || dados.cargo === 81 || dados.cargo === 83 || dados.cargo === 84 || dados.cargo === 85 || dados.cargo === 82 || dados.cargo === 87 || dados.cargo === 88 || dados.cargo === 89 || dados.cargo === 40 || dados.cargo === 41 || dados.cargo === 42 || dados.cargo === 43 || dados.cargo === 45 || dados.cargo === 46 || dados.cargo === 47 || dados.cargo === 48 || dados.cargo === 101 || dados.cargo === 161 || dados.cargo === 162 || dados.cargo === 163 || dados.cargo === 164 ? dados.tipo_cargo_descricao : ''}
                                                    partido={dados.cargo === 76 || dados.cargo === 98 || dados.cargo === 99 || dados.cargo === 100 || dados.cargo === 53 || dados.cargo === 59 ? dados.partido_sigla : ''}
                                                    municipio={dados.cargo === 3 || dados.cargo === 4 || dados.cargo === 5 || dados.cargo === 6 || dados.cargo === 7 || dados.cargo === 8 || dados.cargo === 9 || dados.cargo === 10 || dados.cargo === 11 || dados.cargo === 12 || dados.cargo === 13 || dados.cargo === 14 || dados.cargo === 24 ? dados.nome_municipio : ''}
                                                    statusContribuicao={dados.status_contribuicao === 'true' ? '●' : dados.status_contribuicao === 'false' ? '' : ''}
                                                    ref={etiquetaRef}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </>
    )
}
