import React, { useEffect, useState } from 'react';
import api from '../service/Api';
import {
    Container,
    TextField,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    FormControl,
    InputAdornment,
    Button,
    Typography
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ConstantInscricoes from "../constants/ConstantInscricoes";
import SaveIcon from '@material-ui/icons/Save';

const TelaVincularAssessores = ({ setTelaVincularAssessores, ufs, autoridade, naturezas, dadosAssessor, fetchAssessores, showAlert, setFuncionalidade, HandleSearch, showFieldParlamentar, resetForm, HandleSnackbarClose, FormatCelular, FormatCPF, snackbarOpen, snackbarMessage, snackbarSeverity }) => {
    const {
        setFormValues,
        HandleChangeForm,
        formValues,
        initialState,
        tipoCargo,
        cargo
    } = ConstantInscricoes();

    const [desabilitarBotao, setDesabilitarBotao] = useState(false);
    const [parlamentar, setParlamentar] = useState([]);

    const handleSaveAssessor = async () => {
        if (formValues?.nome.length <= 0) {
            return showAlert("Por favor, informe o nome", 'error');
        }
        if (showFieldParlamentar && !formValues?.parlamentar) {
            return showAlert("Por favor, informe o parlamentar", 'error');
        }
        try {
            const formData = new FormData();
            setDesabilitarBotao(true);
            for (const campo in formValues) {
                formData.append(campo, formValues[campo]);
            }
            formData.delete('file');
            formData.append('file', '');
            formData.delete('convidado');
            formData.append('convidado', '');
            formData.delete('tipo_cargo_descricao');
            formData.delete('tipo_inscricao_descricao');
            formData.delete('cargo_descricao');
            let response;
            if (dadosAssessor?.id) {
                response = await api.put(`api/assessor/${dadosAssessor.id}`, formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "X-Requested-With": "XMLHttpRequest"
                    }
                });
            } else {
                response = await api.post('api/assessor', formData);
            }

            if (response.status === 200) {
                resetForm();
                setDesabilitarBotao(false);
                if (showFieldParlamentar) {
                    setFuncionalidade('');
                    HandleSearch();
                } else {
                    setTelaVincularAssessores('');
                    fetchAssessores();
                }
                showAlert(!dadosAssessor ? "Assessor cadastrado com sucesso" : "Assessor editado com sucesso", 'success');
            }
        } catch (error) {
            showAlert("Ocorreu um erro no cadastro do assessor. Por favor, tente novamente mais tarde", 'error');
            setDesabilitarBotao(false);
            console.error('Erro ao cadastrar autoridade:', error);
        }
    };

    const handleVoltar = () => {
        resetForm();
        if (showFieldParlamentar) {
            setFuncionalidade('');
        } else {
            setTelaVincularAssessores('');
        }
    };

    const shouldRenderEmpresaInstituicaoField = () => {
        const tipoInscricao = formValues?.tipo_inscricao;
        const tipoCargo = formValues?.tipo_cargo;
        const cargo = formValues?.cargo;

        return (
            ([26].includes(tipoInscricao) && [27, 39, 44, 49, 55].includes(tipoCargo) && [32, 38, 43, 48, 54, 60].includes(cargo))
            || ([26].includes(tipoInscricao) && [61].includes(tipoCargo))
            || ([26].includes(tipoInscricao) && [27].includes(tipoCargo))
            || ([68].includes(tipoInscricao) && [69, 80, 91, 97].includes(tipoCargo) && [74, 79, 85, 92, 93, 94, 95, 96, 101].includes(cargo))
            || ([102].includes(tipoInscricao) && !['', 165].includes(tipoCargo))
        );
    };

    useEffect(() => {
        if (autoridade?.id) {
            if (tipoCargo.length) {
                setFormValues({
                    ...formValues,
                    tipo_cargo: tipoCargo.find(tipo => tipo.descricao === autoridade?.tipo_cargo_descricao)?.id
                });
            } else {
                setFormValues({
                    ...formValues,
                    parlamentar: autoridade?.id,
                    tipo_inscricao: naturezas.find(natureza => natureza.descricao === autoridade?.cargo_id)?.id
                });
            }
        }
        // eslint-disable-next-line
    }, [autoridade?.id, tipoCargo, cargo]);

    useEffect(() => {
        const fetchAutoridades = async () => {
            try {
                const response = await api.get(`api/getAutoridade`);
                setParlamentar(response.data.autoridade)
            } catch (error) {
                console.error('Erro ao buscar as autoridades:', error);
            }
        };
        fetchAutoridades();
    }, []);

    useEffect(() => {
        if (dadosAssessor) {
            const formValues = {
                ...initialState,
                ...dadosAssessor,
                cpf: FormatCPF(dadosAssessor?.cpf),
                celular: dadosAssessor?.celular ? FormatCelular(dadosAssessor?.celular) : '',
                cep: dadosAssessor?.cep ?? '',
                PCD: dadosAssessor?.PCD ?? '',
                aprovado: '',
                area_atuacao: dadosAssessor?.area_atuacao ?? '',
                bairro: dadosAssessor?.bairro ?? '',
                cargo: dadosAssessor?.cargo ?? '',
                cnpj: dadosAssessor?.cnpj ?? '',
                consorcio: dadosAssessor?.consorcio ?? '',
                convidado: dadosAssessor?.convidado ?? '',
                email: dadosAssessor?.email ?? '',
                empresa_instituicao: dadosAssessor?.empresa_instituicao ?? '',
                endereco: dadosAssessor?.endereco ?? '',
                entidade_estadual: dadosAssessor?.entidade_estadual ?? '',
                entidade_micro: dadosAssessor?.entidade_micro ?? '',
                entidade_nacionais: dadosAssessor?.entidade_nacionais ?? '',
                file: dadosAssessor?.file ?? '',
                genero: dadosAssessor?.genero ?? '',
                ministerio_orgao: dadosAssessor?.ministerio_orgao ?? '',
                municipio: dadosAssessor?.municipio ?? '',
                nome_cracha: dadosAssessor?.nome_cracha ?? '',
                obs: dadosAssessor?.obs ?? '',
                opc_pagamento: dadosAssessor?.opc_pagamento ?? '',
                pais: dadosAssessor?.pais ?? '',
                partido_politico: dadosAssessor?.partido_politico ?? '',
                razao_social: dadosAssessor?.razao_social ?? '',
                status_contribuicao: dadosAssessor?.status_contribuicao ?? '',
                telefone: dadosAssessor?.telefone ?? '',
                tipo_pagamento: dadosAssessor?.tipo_pagamento ?? '',
                tipo_cargo: dadosAssessor?.tipo_cargo ?? '',
                uf: dadosAssessor?.uf ?? '',
                tipo_inscricao: dadosAssessor?.tipo_inscricao ?? '',
            };
            setFormValues(formValues);
        }
        // eslint-disable-next-line
    }, [dadosAssessor]);

    // useEffect(() => {
    //     // console.log('formValuesCadastroAssessores -> ', formValues);
    // }, [formValues]);

    return (
        <Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <Button style={{ marginTop: '10px' }} variant="contained" onClick={handleVoltar}>Voltar</Button>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                    <Typography variant="h6" gutterBottom>
                        {!dadosAssessor ? 'Cadastro de Assessor' : 'Editar dados do Assessor'}
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }} >Tipo de inscrição</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.tipo_inscricao}
                            value={formValues?.tipo_inscricao}
                            onChange={(e) => {
                                const tipoInscricaoSelecionado = e.target.value;
                                setFormValues({
                                    ...formValues,
                                    tipo_inscricao: tipoInscricaoSelecionado,
                                    tipo_cargo: '',
                                    cargo: '',
                                });
                            }}
                            IconComponent={naturezas.length === 1 ? () => null : undefined}
                            disabled={naturezas.length === 1}
                        >
                            {naturezas?.map((natureza) => (
                                <MenuItem key={natureza.id} value={natureza.id}>
                                    {natureza.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }} >Tipo cargo</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.tipo_cargo}
                            disabled={!formValues?.tipo_inscricao}
                            value={formValues?.tipo_cargo}
                            onChange={(e) => {
                                const tipoCargoSelecionado = e.target.value;
                                setFormValues({
                                    ...formValues,
                                    tipo_cargo: tipoCargoSelecionado,
                                    cargo: '',
                                });
                            }}
                        >
                            {tipoCargo?.map((tipo) => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                    {tipo.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }}>Cargo</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.cargo}
                            value={formValues?.cargo}
                            disabled={!formValues?.tipo_cargo}
                            onChange={(e) => {
                                const cargoSelecionado = e.target.value;
                                const cargoSelecionadoDescricao = cargo.find((cargo) => cargo.id === cargoSelecionado)?.descricao || '';
                                setFormValues({
                                    ...formValues,
                                    cargo: cargoSelecionado,
                                    nome_cargo: cargoSelecionadoDescricao,
                                    area_atuacao: '',
                                });
                            }}
                        >
                            {cargo?.map((cargo) => (
                                <MenuItem key={cargo.id} value={cargo.id}>
                                    {cargo.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.nome}
                        onChange={(e) => HandleChangeForm('nome', e.target.value)}
                        value={formValues?.nome}
                        fullWidth
                        label="Nome"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="cpf"
                        label="CPF"
                        name="cpf"
                        autoComplete="off"
                        value={FormatCPF(formValues?.cpf)}
                        onChange={(e) => HandleChangeForm('cpf', e.target.value)}
                        inputProps={{ maxLength: 14 }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.email}
                        onChange={(e) => HandleChangeForm('email', e.target.value)}
                        value={formValues?.email}
                        fullWidth
                        label="E-mail"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.celular}
                        onChange={(e) => HandleChangeForm('celular', FormatCelular(e.target.value))}
                        value={FormatCelular(formValues?.celular)}
                        fullWidth
                        label="Celular"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <WhatsAppIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            options={ufs}
                            getOptionLabel={(uf) => uf.SIGLA_UF}
                            value={ufs.find((uf) => uf.SIGLA_UF === formValues?.uf) || null}
                            onChange={(e, selectedUfObject) => {
                                HandleChangeForm('uf', selectedUfObject?.SIGLA_UF || '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    // error={!!formErrors.uf}
                                    label="UF"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>

                {showFieldParlamentar && (
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={parlamentar || []}
                                getOptionLabel={(parlamentar) => parlamentar.nome || ''}
                                value={parlamentar?.find((p) => p.id === formValues?.parlamentar) || null}
                                onChange={(e, selectedParlamentar) => {
                                    HandleChangeForm('parlamentar', selectedParlamentar?.id);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Parlamentar"

                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                )}

                {shouldRenderEmpresaInstituicaoField() && (
                    <Grid item xs={12} sm={3}>
                        <TextField
                            variant="outlined"
                            onChange={(e) => HandleChangeForm('empresa_instituicao', e.target.value)}
                            value={formValues?.empresa_instituicao}
                            fullWidth
                            label="Empresa/Instituição"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                )}

            </Grid>

            <Divider style={{ margin: '10px' }} />

            <Grid item xs={12} sm={12}>
                <Grid container justifyContent="center" mt={4} style={{ marginTop: '20px' }}>
                    <Button type="submit" disabled={desabilitarBotao} variant="contained" style={{ backgroundColor: '#47944a', color: '#FFFFFF' }} onClick={handleSaveAssessor}>
                        <SaveIcon /> Salvar
                    </Button>
                </Grid>
            </Grid>

        </Container >
    );
}

export default TelaVincularAssessores;