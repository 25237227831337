import React, { useState } from 'react';
import {
  AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItemIcon,
  ListItemText, Divider, Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useNavigate, useLocation } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import EmailIcon from '@mui/icons-material/ForwardToInbox';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CheckIcon from '@mui/icons-material/Check';
import WorkIcon from '@mui/icons-material/Work';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PrincipalImage from '../images/logo-cnm.png';
import AuthHandler from '../service/AuthHandler';
import ConstantInscricoes from "../constants/ConstantInscricoes";
import { Dashboard, MeetingRoom } from '@mui/icons-material';

const AuthenticatedNavbar = ({ setFuncionalidade }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const login = AuthHandler();

  const {
    isAdmin,
    hasPermission
  } = ConstantInscricoes({ dontLoadAll: true });

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    login.handleLogout();
    localStorage.clear();
    navigate('/');
  };

  const defaultFunctionalities  = [
    {
      id: 1,
      icon: <EventNoteIcon />,
      text: 'Gerenciar Inscrições',
      onClick: () => { setFuncionalidade(1); handleToggleMenu(); },
      isVisible: hasPermission('gerenciar-inscricoes', 'escrita') || hasPermission('gerenciar-inscricoes', 'leitura'),
    },
    {
      id: 2,
      icon: <EqualizerIcon />,
      text: 'Gráficos',
      onClick: () => { setFuncionalidade(2); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('graficos', 'escrita') || hasPermission('graficos', 'leitura'),

    },
    {
      id: 3,
      icon: <LocalAtmIcon />,
      text: 'Fluxo de Caixa',
      onClick: () => { setFuncionalidade(3); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('fluxo-de-caixa', 'escrita') || hasPermission('fluxo-de-caixa', 'leitura'),
    },
    {
      id: 4,
      icon: <CheckIcon />,
      text: 'Gerenciar Convidados',
      onClick: () => { setFuncionalidade(4); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('gerenciar-convidados', 'escrita') || hasPermission('gerenciar-convidados', 'leitura'),
    },
    {
      id: 5,
      icon: <EmailIcon />,
      text: 'Envio de E-mails',
      onClick: () => { setFuncionalidade(5); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('e-mail', 'escrita') || hasPermission('e-mail', 'leitura'),
    },
    {
      id: 6,
      icon: <WorkIcon />,
      text: 'Cadastro de Autoridades',
      onClick: () => { setFuncionalidade(6); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('cadastro-autoridades', 'escrita') || hasPermission('cadastro-autoridades', 'leitura'),
    },
    {
      id: 7,
      icon: <MeetingRoom />,
      text: 'Entrada e Saída',
      onClick: () => { setFuncionalidade(7); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('entrada-e-saida', 'escrita') || hasPermission('entrada-e-saida', 'leitura'),
    },
    {
      id: 8,
      icon: <Dashboard />,
      text: 'Consultar Arenas',
      onClick: () => { setFuncionalidade(8); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('consultar-arenas', 'escrita') || hasPermission('consultar-arenas', 'leitura'),
    },
    {
      id: 9,
      icon: <PointOfSaleIcon />,
      text: 'Meu Caixa',
      onClick: () => { setFuncionalidade(9); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('caixas', 'escrita') || hasPermission('caixas', 'leitura'),
    },
    {
      id: 10,
      icon: <PersonAddAlt1Icon />,
      text: 'Credenciamento Caixa',
      onClick: () => { setFuncionalidade(10); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('caixas', 'escrita') || hasPermission('caixas', 'leitura'),
    }
  ];

  const eventFunctionalities = [
    {
      id: 1,
      icon: <EventNoteIcon />,
      text: 'Gerenciar Eventos',
      onClick: () => { setFuncionalidade(11); handleToggleMenu(); },
      isVisible: hasPermission('gerenciar-eventos', 'escrita') || hasPermission('gerenciar-eventos', 'leitura'),
    },
    {
      id: 2,
      icon: <EqualizerIcon />,
      text: 'Relatórios de Eventos',
      onClick: () => { setFuncionalidade(12); handleToggleMenu(); },
      isVisible: isAdmin || hasPermission('relatorios-eventos', 'escrita') || hasPermission('relatorios-eventos', 'leitura'),
    },
  ];

  const functionalitiesData = location.pathname.includes('/eventos') ? eventFunctionalities : defaultFunctionalities;

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: '#005bab' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleToggleMenu}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <a style={{ marginTop: '8px' }} href="/home">
              <img style={{ height: '50px' }} alt="foto" src={PrincipalImage} className="image-container" />
            </a>
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Sair
            <ExitToAppIcon style={{ marginLeft: 5 }} />
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={isMenuOpen} onClose={handleToggleMenu}>
        <List>
          {functionalitiesData.filter(func => func.isVisible).map(func => (
            <ListItemButton key={func.id} onClick={func.onClick}>
              <ListItemIcon>{func.icon}</ListItemIcon>
              <ListItemText primary={func.text} />
            </ListItemButton>
          ))}
        </List>
        <Divider />
      </Drawer>
    </>
  );
};

export default AuthenticatedNavbar;
