import React, { useState, useRef } from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Button,
    Select,
    MenuItem,
    FormControl,
    TextField,
    Grid,
    InputLabel,
    Autocomplete,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup,
    Checkbox,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Paper,
    TableSortLabel,
    InputAdornment,
    Menu
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@material-ui/icons/Search';
import CustomModal from './CustomModal';
import Etiqueta from './Etiqueta';
import api from '../service/Api';
import { useReactToPrint } from "react-to-print";

const Filtros = ({ filtrosAbertos, setFiltrosAbertos, formValues, setFormValues, naturezas, tipoCargo, cargo, parlamentar, HandleChangeForm, FormatCPF, OcultarCPF, ufs, setCodigoUf, setMunicipio, municipio, FormatCNPJ, HandleChangeCheckbox, HandleSearch, searchTerm, setSearchTerm, resetForm, orderBy, order, handleSort, sortedAndFilteredUsers, EditInscricao, anchorEl, isAdmin, hasPermission, createInscricao, handleMenuClose, handleMenuItemClick, handleOpenModalExcluirUsuario, handleCloseModalExcluirUsuario, handleDeletarInscricao, openModalExcluirCadastro, desabilitarBotao, handleMenuOpen }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedUsers = sortedAndFilteredUsers.slice(startIndex, endIndex);
    const etiquetaRef = useRef();
    const [selectedUser, setSelectedUser] = useState(null);


    const handlePrintClick = (user) => {
        setSelectedUser(user);
        setTimeout(() => {
            imprimirEtiqueta();
            setSelectedUser(null);
        }, 500);

        const user_id = user.id

        try {
            api.post(`api/controleAcesso?user_id=${user_id}&tipo_registro=etiqueta`);
        } catch (error) {
            console.error('Erro ao utilizar o controle de acessos: ', error)
        }
    };

    const imprimirEtiqueta = useReactToPrint({
        content: () => etiquetaRef.current
    });

    return (
        <Paper>
            <Accordion expanded={filtrosAbertos} onChange={() => setFiltrosAbertos(!filtrosAbertos)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>Filtros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel >Tipo de inscrição</InputLabel>
                                <Select
                                    variant="outlined"
                                    value={formValues?.tipo_inscricao}
                                    onChange={(e) => {
                                        const tipoInscricaoSelecionado = e.target.value;
                                        setFormValues({
                                            ...formValues,
                                            tipo_inscricao: tipoInscricaoSelecionado,
                                            tipo_cargo: '',
                                            cargo: '',
                                            area_atuacao: '',
                                            nome_cargo: '',
                                            convidado: {
                                                ...formValues.convidado,
                                                cargo: '',
                                                convidado_de: '',
                                            },
                                        });
                                    }}
                                    IconComponent={naturezas.length === 1 ? () => null : undefined}
                                    disabled={naturezas.length === 1}
                                >
                                    {naturezas?.map((natureza) => (
                                        <MenuItem key={natureza.id} value={natureza.id}>
                                            {natureza.descricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel >Tipo cargo</InputLabel>
                                <Select
                                    variant="outlined"
                                    disabled={!formValues.tipo_inscricao}
                                    value={formValues?.tipo_cargo}
                                    onChange={(e) => {
                                        const tipoCargoSelecionado = e.target.value;
                                        setFormValues({
                                            ...formValues,
                                            tipo_cargo: tipoCargoSelecionado,
                                            cargo: '',
                                            area_atuacao: '',
                                            nome_cargo: '',
                                            convidado: {
                                                ...formValues.convidado,
                                                cargo: '',
                                                convidado_de: '',
                                            },
                                        });
                                    }}
                                >
                                    {tipoCargo.map((tipo) => (
                                        <MenuItem key={tipo.id} value={tipo.id}>
                                            {tipo.descricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel >Cargo</InputLabel>
                                <Select
                                    variant="outlined"
                                    value={formValues?.cargo}
                                    disabled={!formValues.tipo_cargo}
                                    onChange={(e) => {
                                        const cargoSelecionado = e.target.value;
                                        const cargoSelecionadoDescricao = cargo.find((cargo) => cargo.id === cargoSelecionado)?.descricao || '';
                                        setFormValues({
                                            ...formValues,
                                            cargo: cargoSelecionado,
                                            nome_cargo: cargoSelecionadoDescricao,
                                            area_atuacao: '',
                                        });
                                    }}
                                >
                                    {cargo.map((cargo) => (
                                        <MenuItem key={cargo.id} value={cargo.id}>
                                            {cargo.descricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={parlamentar || []}
                                    getOptionLabel={(parlamentar) => parlamentar.nome || ''}
                                    value={parlamentar?.find((p) => p.id === formValues?.parlamentar) || null}
                                    onChange={(e, selectedParlamentar) => {
                                        HandleChangeForm('parlamentar', selectedParlamentar?.id || '', '', selectedParlamentar?.nome || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            // error={!!formErrors.parlamentar}
                                            label="Parlamentar"

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                onChange={(e) => HandleChangeForm('nome', e.target.value)}
                                value={formValues?.nome}
                                fullWidth
                                label="Nome"
                                inputProps={{ maxLength: 255 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="cpf"
                                label="CPF"
                                name="cpf"
                                autoComplete="cpf"
                                value={FormatCPF(formValues?.cpf)}
                                onChange={(e) => HandleChangeForm('cpf', e.target.value)}
                                inputProps={{ maxLength: 14 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="passaporte"
                                label="Passaporte"
                                name="passaporte"
                                autoComplete="passaporte"
                                value={formValues?.passaporte}
                                onChange={(e) => {
                                    const inputValue = e.target.value.toUpperCase();
                                    const formattedValue = inputValue.replace(/[^A-Z0-9]/g, '');
                                    const passaporteFormatted = formattedValue.substring(0, 8);
                                    if (/^[A-Z]{0,2}\d{0,6}$/.test(passaporteFormatted)) {
                                        HandleChangeForm('passaporte', passaporteFormatted);
                                    }
                                }}
                                inputProps={{
                                    maxLength: 8,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Gênero</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(e) => HandleChangeForm('genero', e.target.value)}
                                    value={formValues?.genero}
                                >
                                    <MenuItem value="masculino">Masculino</MenuItem>
                                    <MenuItem value="feminino">Feminino</MenuItem>
                                    <MenuItem value="outros">Outros</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={ufs}
                                    getOptionLabel={(uf) => uf.SIGLA_UF}
                                    value={ufs.find((uf) => uf.SIGLA_UF === formValues?.uf) || null}
                                    onChange={(e, selectedUfObject) => {
                                        setCodigoUf(selectedUfObject?.CD_UF || null);
                                        setMunicipio(selectedUfObject?.CD_MUNICIPIO || null);
                                        HandleChangeForm('uf', selectedUfObject?.SIGLA_UF || '');
                                        HandleChangeForm('status_contribuicao', '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="UF"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disabled={!formValues?.uf}
                                    options={municipio || []}
                                    getOptionLabel={(municipio) => municipio.NOME_MUNICIPIO || ''}
                                    value={municipio?.find((m) => m.CD_MUNICIPIO === formValues?.municipio) || null}
                                    onChange={(e, selectedMunicipio) => {
                                        HandleChangeForm('municipio', selectedMunicipio?.CD_MUNICIPIO || '', selectedMunicipio?.NOME_MUNICIPIO || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"

                                            label="Município"

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Tipo de pagamento</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(e) => HandleChangeForm('tipo_pagamento', e.target.value)}
                                    value={formValues?.tipo_pagamento || ''}
                                >
                                    <MenuItem value="boleto">Boleto</MenuItem>
                                    {/* <MenuItem value="pix">Pix</MenuItem> */}
                                    <MenuItem value="caixa">Caixa</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Contribuinte</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(e) => HandleChangeForm('status_contribuicao', e.target.value === 'true')}
                                    value={String(formValues?.status_contribuicao)}
                                >
                                    <MenuItem value="true">Sim</MenuItem>
                                    <MenuItem value="false">Não</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Dados pagamento</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(e) => HandleChangeForm('opc_pagamento', e.target.value)}
                                    value={formValues?.opc_pagamento || ''}
                                >
                                    <MenuItem value="1">Pessoa Física</MenuItem>
                                    <MenuItem value="2">Pessoa Jurídica</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="CNPJ"
                                    value={FormatCNPJ(formValues?.cnpj)}
                                    onChange={(e) => HandleChangeForm('cnpj', e.target.value)}
                                    inputProps={{ maxLength: 18 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Razão Social</InputLabel>
                                    <Select

                                        onChange={(e) => HandleChangeForm('razao_social', e.target.value)}
                                        value={formValues?.razao_social}
                                    >
                                        <MenuItem value="prefeitura_municipal">Prefeitura Municipal</MenuItem>
                                        <MenuItem value="camara_municipal">Câmara Municipal</MenuItem>
                                        <MenuItem value="consorcios_publicos">Consórcios Públicos</MenuItem>
                                        <MenuItem value="municipio">Município</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Homologado</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(e) => HandleChangeForm('aprovado', e.target.value)}
                                    value={formValues?.aprovado}
                                >
                                    <MenuItem value="1">Sim</MenuItem>
                                    <MenuItem value="0">Não</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                onChange={(e) => HandleChangeForm('email', e.target.value)}
                                value={formValues?.email}
                                fullWidth
                                label="E-mail"
                                inputProps={{ maxLength: 255 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel>Atendimento especializado</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, necessita_atendimento_especializado: e.target.value })}
                                    value={formValues?.PCD?.necessita_atendimento_especializado || ''}
                                >
                                    <MenuItem value="Sim">Sim</MenuItem>
                                    <MenuItem value="Não">Não</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {formValues?.PCD?.necessita_atendimento_especializado === 'Sim' && (
                            <>
                                <Grid item xs={12} sm={4} style={{ marginTop: '5px' }}>
                                    Possui deficiência?
                                    <FormControl fullWidth>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormGroup style={{ marginLeft: '10px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.PCD?.tipo_deficiencias.includes('Fisica') || false}
                                                                onChange={(e) => HandleChangeCheckbox('Fisica', e.target.checked)}
                                                            />
                                                        }
                                                        label="Física"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.PCD?.tipo_deficiencias.includes('Visual') || false}
                                                                onChange={(e) => HandleChangeCheckbox('Visual', e.target.checked)}
                                                            />
                                                        }
                                                        label="Visual"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.PCD?.tipo_deficiencias.includes('Auditiva') || false}
                                                                onChange={(e) => HandleChangeCheckbox('Auditiva', e.target.checked)}
                                                            />
                                                        }
                                                        label="Auditiva"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.PCD?.tipo_deficiencias.includes('Intelectual') || false}
                                                                onChange={(e) => HandleChangeCheckbox('Intelectual', e.target.checked)}
                                                            />
                                                        }
                                                        label="Intelectual"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.PCD?.tipo_deficiencias.includes('Mental') || false}
                                                                onChange={(e) => HandleChangeCheckbox('Mental', e.target.checked)}
                                                            />
                                                        }
                                                        label="Mental"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.PCD?.tipo_deficiencias.includes('Outros') || false}
                                                                onChange={(e) => HandleChangeCheckbox('Outros', e.target.checked)}
                                                            />
                                                        }
                                                        label="Outros"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        </Grid>

                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4} style={{ marginTop: '5px' }}>
                                    Possui dificuldade de locomoção
                                    <RadioGroup
                                        row
                                        aria-label="dificuldadeLocomocao"
                                        name="dificuldadeLocomocao"


                                        onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, dificuldade_locomocao: e.target.value })}
                                        value={formValues?.PCD?.dificuldade_locomocao || ''}
                                    >
                                        <Grid item xs={12} sm={3}>

                                            <FormControlLabel
                                                value="Nao"
                                                control={<Radio color="primary" />}
                                                label="Não"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>

                                            <FormControlLabel
                                                value="Sim"
                                                control={<Radio color="primary" />}
                                                label="Sim"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>

                                            <FormControlLabel
                                                value="Cadeirante"
                                                control={<Radio color="primary" />}
                                                label="É Cadeirante"
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </Grid>

                                {((formValues?.PCD?.dificuldade_locomocao === 'Sim') || (formValues?.PCD?.dificuldade_locomocao === 'Cadeirante')) && (
                                    <Grid item xs={12} sm={4} style={{ marginTop: '5px' }}>
                                        Precisa de acompanhamentos ou recurso de acessibilidade para participar do evento
                                        <RadioGroup
                                            row
                                            aria-label="acompanhamentoEvento"
                                            name="acompanhamentoEvento"


                                            onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, acompanhamento_acessibilidade: e.target.value })}
                                            value={formValues?.PCD?.acompanhamento_acessibilidade || ''}
                                        >
                                            <Grid item xs={12} sm={3}>

                                                <FormControlLabel
                                                    value="Nao"
                                                    control={<Radio color="primary" />}
                                                    label="Não"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>

                                                <FormControlLabel
                                                    value="Sim"
                                                    control={<Radio color="primary" />}
                                                    label="Sim"
                                                />
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                )}
                            </>
                        )}

                        <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
                            <Button variant="contained" color="inherit" onClick={resetForm}>
                                Limpar Campos
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
                            <Button variant="contained" color="primary" onClick={HandleSearch}>
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid container xs={12} sm={12}>
                {isAdmin || hasPermission('gerenciar-inscricoes', 'escrita') || hasPermission('caixas', 'escrita') ? (
                    <Grid item xs={12} sm={3}>
                        <Button variant="contained" color="primary" onClick={createInscricao} style={{ marginTop: '20px', marginLeft: '10px' }}>
                            Nova inscrição
                        </Button>
                    </Grid>
                ) :
                    <Grid item xs={12} sm={3}>
                        <Button variant="contained" color="primary" onClick={handleMenuOpen} style={{ marginTop: '20px', marginLeft: '10px' }}>
                            Nova inscrição
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => handleMenuItemClick('/autoridades-municipais')}>
                                Governo Municipal
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('/autoridades-federais-estaduais')}>
                                Governo Federal
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('/convidados')}>
                                Convidados
                            </MenuItem>
                        </Menu>
                    </Grid>
                }
                <Grid item xs={12} sm={6}>
                </Grid>
                <Grid item alignItems="center" justifyContent="space-between" xs={12} sm={3}>
                    <Grid item>
                        <TextField
                            label="Pesquisar"
                            variant="outlined"
                            value={searchTerm}
                            style={{ marginTop: '10px' }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <TableContainer style={{ marginTop: '10px' }} component={Paper} className="custom-table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'nome'}
                                    direction={orderBy === 'nome' ? order : 'asc'}
                                    onClick={() => handleSort('nome')}
                                >
                                    Nome
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ minWidth: '150px', textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'cpf'}
                                    direction={orderBy === 'cpf' ? order : 'asc'}
                                    onClick={() => handleSort('cpf')}
                                >
                                    CPF
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'cargo'}
                                    direction={orderBy === 'cargo' ? order : 'asc'}
                                    onClick={() => handleSort('cargo')}
                                >
                                    Cargo
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'municipioUf'}
                                    direction={orderBy === 'municipioUf' ? order : 'asc'}
                                    onClick={() => handleSort('municipioUf')}
                                >
                                    Municipio/UF
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'aprovado'}
                                    direction={orderBy === 'aprovado' ? order : 'asc'}
                                    onClick={() => handleSort('aprovado')}
                                >
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'status'}
                                    direction={orderBy === 'status' ? order : 'asc'}
                                    onClick={() => handleSort('status')}
                                >
                                    Situação
                                </TableSortLabel>
                            </TableCell>
                            {isAdmin || hasPermission('gerenciar-inscricoes', 'escrita') || hasPermission('caixas', 'escrita') ? (
                                <>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        Credenciado
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        Ações
                                    </TableCell>
                                </>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedUsers.map((inscricao) => (
                            <TableRow key={inscricao.id}>
                                <TableCell>{inscricao.nome}</TableCell>
                                <TableCell>{OcultarCPF(inscricao.cpf)}</TableCell>
                                <TableCell>{inscricao.cargo_descricao}</TableCell>
                                <TableCell>{inscricao.nome_municipio || inscricao.municipio} - {inscricao.uf}</TableCell>
                                <TableCell>{inscricao.aprovado}</TableCell>
                                <TableCell>{inscricao.situacao}</TableCell>
                                {isAdmin || hasPermission('gerenciar-inscricoes', 'escrita') || hasPermission('caixas', 'escrita') ? (
                                    <>
                                        <TableCell>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {inscricao.qtd_etiquetas !== 0 ? <ThumbUpIcon color='success' /> : <ThumbDownIcon color="error" /> }
                                                <Button disabled>{inscricao.qtd_etiquetas}</Button>
                                                {isAdmin ? (
                                                    <Button onClick={() => handlePrintClick(inscricao)} style={{ cursor: 'pointer', marginLeft: '4px' }}>
                                                        <PrintIcon style={{ color: '#007bff' }} />
                                                    </Button>
                                                ) : (
                                                    desabilitarBotao || inscricao.situacao.toLowerCase() === 'aberto' ? (
                                                        <Button disabled style={{ marginLeft: '4px' }}>
                                                            <PrintDisabledIcon style={{ color: '#6c757d' }} />
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={() => handlePrintClick(inscricao)} style={{ cursor: 'pointer', marginLeft: '4px' }}>
                                                            <PrintIcon style={{ color: '#007bff' }} />
                                                        </Button>
                                                    )
                                                )}
                                            </div>
                                        </TableCell>

                                        <TableCell>
                                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                <Button onClick={() => EditInscricao(inscricao.id)} style={{ color: '#007bff' }}>
                                                    <EditIcon />
                                                </Button>
                                                {isAdmin || hasPermission('gerenciar-inscricoes', 'escrita') ? (
                                                    <Button onClick={() => handleOpenModalExcluirUsuario(inscricao.id)} style={{ color: 'red' }}>
                                                        <DeleteIcon />
                                                    </Button>
                                                ) : null}
                                            </div>
                                        </TableCell>
                                    </>
                                ) : null}
                            </TableRow>
                        ))}
                        <div style={{ display: 'none' }}>
                            {selectedUser && (
                                <>
                                    <Etiqueta
                                        nome={selectedUser.nome_cracha.toUpperCase()}
                                        uf={selectedUser.cargo === 77 || selectedUser.cargo === 78 || selectedUser.cargo === 79 || selectedUser.cargo === 50 || selectedUser.cargo === 51 || selectedUser.cargo === 52 || selectedUser.cargo === 56 || selectedUser.cargo === 57 || selectedUser.cargo === 58 || selectedUser.cargo === 70 || selectedUser.cargo === 71 || selectedUser.cargo === 75 || selectedUser.cargo === 99 || selectedUser.cargo === 101 ? selectedUser.NOME_UF : selectedUser.cargo === 116 ? '' : selectedUser.uf}
                                        uf_sigla={selectedUser.cargo === 3 || selectedUser.cargo === 12 || selectedUser.cargo === 13 || selectedUser.cargo === 21 || selectedUser.cargo === 72 || selectedUser.cargo === 73 || selectedUser.cargo === 74 || selectedUser.cargo === 76 || selectedUser.cargo === 79 || selectedUser.cargo === 81 || selectedUser.cargo === 82 || selectedUser.cargo === 83 || selectedUser.cargo === 84 || selectedUser.cargo === 85 || selectedUser.cargo === 87 || selectedUser.cargo === 88 || selectedUser.cargo === 89 || selectedUser.cargo === 92 || selectedUser.cargo === 93 || selectedUser.cargo === 94 || selectedUser.cargo === 95 || selectedUser.cargo === 98 || selectedUser.cargo === 99 || selectedUser.cargo === 100 ? selectedUser.uf : ''}
                                        cpf={selectedUser.cpf}
                                        cargo={selectedUser.cargo === 14 || selectedUser.cargo === 19 || selectedUser.cargo === 25 || selectedUser.cargo === 38 || selectedUser.cargo === 43 || selectedUser.cargo === 48 || selectedUser.cargo === 54 || selectedUser.cargo === 60 || selectedUser.cargo === 67 || selectedUser.cargo === 79 || selectedUser.cargo === 85 || selectedUser.cargo === 90 || selectedUser.cargo === 96 || selectedUser.cargo === 101 || selectedUser.cargo === 107 || selectedUser.cargo === 114 || selectedUser.cargo === 121 || selectedUser.cargo === 131 || selectedUser.cargo === 136 || selectedUser.cargo === 145 || selectedUser.cargo === 152 || selectedUser.cargo === 159 || selectedUser.cargo === 174 ? '' : selectedUser.cargo_descricao}
                                        complemento={selectedUser.cargo === 5 ? ' Prefeito(a)' : selectedUser.cargo === 28 ? ' da República' : selectedUser.cargo === 29 ? ' Presidente da República' : selectedUser.cargo === 31 ? ' do Brasil' : selectedUser.cargo === 51 ? ' do Senado Federal' : selectedUser.cargo === 52 ? ' Presidente do Senado Federal' : selectedUser.cargo === 56 ? ' da Câmara dos Deputados' : selectedUser.cargo === 57 ? ' Presidente da Câmara dos Deputados' : selectedUser.cargo === 71 ? ' Governador(a)' : selectedUser.cargo === 99 ? ' da Assembleia Legislativa' : selectedUser.cargo === 62 ? ' Integrante do Judiciário Federal' : selectedUser.cargo === 63 ? ' Integrante do Judiciário Federal' : selectedUser.cargo === 64 ? ' Integrante do Judiciário Federal' : selectedUser.cargo === 65 ? ' Integrante do Judiciário Federal' : selectedUser.cargo === 66 ? ' Integrante do Judiciário Federal' : selectedUser.cargo === 147 ? ' de Entidade Municipalista Estadual' : ''}
                                        campo_obs={selectedUser.empresa_instituicao}
                                        parlamentar={selectedUser.cargo === 76 || selectedUser.cargo === 100 || selectedUser.cargo === 53 || selectedUser.cargo === 59 ? selectedUser.nome_autoridade + ' - ' : ''}
                                        tipo_inscricao={selectedUser.cargo === 72 || selectedUser.cargo === 75 || selectedUser.cargo === 33 || selectedUser.cargo === 34 || selectedUser.cargo === 35 || selectedUser.cargo === 36 || selectedUser.cargo === 37 || selectedUser.cargo === 38 || selectedUser.cargo === 76 || selectedUser.cargo === 77 || selectedUser.cargo === 78 || selectedUser.cargo === 79 || selectedUser.cargo === 90 || selectedUser.cargo === 157 ? selectedUser.tipo_inscricao_descricao : ''}
                                        tipo_cargo={selectedUser.cargo === 14 || selectedUser.cargo === 54 || selectedUser.cargo === 60 || selectedUser.cargo === 79 || selectedUser.cargo === 81 || selectedUser.cargo === 83 || selectedUser.cargo === 84 || selectedUser.cargo === 85 || selectedUser.cargo === 82 || selectedUser.cargo === 87 || selectedUser.cargo === 88 || selectedUser.cargo === 89 || selectedUser.cargo === 40 || selectedUser.cargo === 41 || selectedUser.cargo === 42 || selectedUser.cargo === 43 || selectedUser.cargo === 45 || selectedUser.cargo === 46 || selectedUser.cargo === 47 || selectedUser.cargo === 48 || selectedUser.cargo === 101 || selectedUser.cargo === 161 || selectedUser.cargo === 162 || selectedUser.cargo === 163 || selectedUser.cargo === 164 ? selectedUser.tipo_cargo_descricao : ''}
                                        partido={selectedUser.cargo === 76 || selectedUser.cargo === 98 || selectedUser.cargo === 99 || selectedUser.cargo === 100 || selectedUser.cargo === 53 || selectedUser.cargo === 59 ? selectedUser.partido_sigla + ' / ' : ''}
                                        // municipio={selectedUser.cargo === 3 || selectedUser.cargo === 4 || selectedUser.cargo === 5 || selectedUser.cargo === 6 || selectedUser.cargo === 7 || selectedUser.cargo === 8 || selectedUser.cargo === 9 || selectedUser.cargo === 10 || selectedUser.cargo === 11 || selectedUser.cargo === 12 || selectedUser.cargo === 13 || selectedUser.cargo === 14 || selectedUser.cargo === 21 || selectedUser.cargo === 23 || selectedUser.cargo === 24 ? selectedUser.nome_municipio : ''}
                                        municipio={selectedUser.cargo === 116 ? '' : selectedUser.nome_municipio}
                                        statusContribuicao={selectedUser.status_contribuicao === 'true' ? '●' : selectedUser.status_contribuicao === 'false' ? '' : ''}
                                        ref={etiquetaRef}
                                    />
                                </>
                            )}
                        </div>
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    count={sortedAndFilteredUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                    }}
                    labelRowsPerPage="Registros por página:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                />
            </TableContainer>
            <CustomModal
                open={openModalExcluirCadastro}
                onClose={handleCloseModalExcluirUsuario}
                title="Confirmar Operação"
                content="Tem certeza que deseja excluir este usuário? ESTA OPERAÇÃO É IRREVERSÍVEL"
                onCancel={handleCloseModalExcluirUsuario}
                onConfirm={handleDeletarInscricao}
                disabled={desabilitarBotao}
            />
        </Paper>
    );
}

export default Filtros;
