
const ValidateForm = ({ formValues, setFormErrors, showAlert, errors }) => {

    const validateForm = () => {
        let errors = {};

        if (!formValues.tipo_inscricao) {
            errors.tipo_inscricao = true;
            showAlert('O campo Tipo de Inscrição é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.tipo_cargo) {
            errors.tipo_cargo = true;
            showAlert('O campo Tipo de Cargo é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.cargo && formValues.tipo_cargo !== 132) {
            errors.cargo = true;
            showAlert('O campo Cargo é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.parlamentar && (([26, 68].includes(formValues?.tipo_inscricao) && [49, 55, 97].includes(formValues?.tipo_cargo)) && (formValues?.cargo && ![50, 51, 52, 56, 57, 58, 98, 99].includes(formValues?.cargo)))) {
            errors.parlamentar = true;
            showAlert('Por favor informe o parlamentar.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.partido_politico && (([26, 68].includes(formValues?.tipo_inscricao) && [49, 55, 97].includes(formValues?.tipo_cargo)) && (formValues?.cargo && [50, 51, 52, 56, 57, 58, 98, 99].includes(formValues?.cargo)))) {
            errors.partido_politico = true;
            showAlert('Por favor informe o partido político.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.convidado.cargo && formValues.tipo_cargo === 132) {
            errors.convidadoCargo = true;
            showAlert('O campo Cargo é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues?.tipo_inscricao === 26 && formValues?.tipo_cargo === 160 && !formValues.ministerio_orgao) {
            errors.ministerio_orgao = true;
            showAlert('O campo ministério é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!(formValues.entidade_estadual || formValues.entidade_nacionais || formValues.entidade_micro) && [138, 146, 153].includes(formValues?.tipo_cargo)) {
            errors.entidade = true;
            showAlert('O campo de Entidade é obrigatório', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.consorcio && formValues.tipo_cargo === 15) {
            errors.consorcio = true;
            showAlert('O campo Consórcio é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.convidado.convidado_de && formValues.tipo_cargo === 132) {
            errors.convidadoDe = true;
            showAlert('O campo Convidado de é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.nome_cargo) {
            errors.nome_cargo = true;
            showAlert('O campo Nome do Cargo é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.pais && formValues.tipo_cargo === 122) {
            errors.pais = true;
            showAlert('O campo Pais é obrigatório para cargos internacionais.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues.tipo_inscricao === 102 && !formValues.empresa_instituicao) {
            errors.empresa_instituicao = true;
            showAlert('O campo empresa/instituição é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.area_atuacao && formValues.cargo === 13) {
            errors.area_atuacao = true;
            showAlert('O campo Área de atuação é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.nome) {
            errors.nome = true;
            showAlert('O campo Nome é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.nome_cracha) {
            errors.nome_cracha = true;
            showAlert('O campo nome crachá é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues.tipo_cargo !== 122) {
            if (!formValues.cpf) {
                errors.cpf = true;
                showAlert('O campo CPF é obrigatório.', 'error');
                setFormErrors(errors);
                return false;
            } else {
                formValues.cpf = formValues.cpf.replace(/[^\d]/g, '');
                const cpfPattern = /^\d{11}$/;
                if (!cpfPattern.test(formValues.cpf)) {
                    errors.cpf = true;
                    showAlert('CPF inválido. O formato deve ser XXX.XXX.XXX-XX.', 'error');
                    setFormErrors(errors);
                    return false;
                }
            }
        }

        if (!formValues.passaporte && formValues.tipo_cargo === 122) {
            errors.passaporte = true;
            showAlert('O campo Passaporte é obrigatório para cargos internacionais.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues.passaporte && formValues.tipo_cargo === 122 && (formValues.passaporte.length !== 8 || !/^[A-Z]{2}\d{6}$/.test(formValues.passaporte))) {
            errors.passaporte = true;
            showAlert('Passaporte inválido. O formato deve ser XX123456', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.genero) {
            errors.genero = true;
            showAlert('O campo Gênero é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues.tipo_cargo !== 122) {
            if (!formValues.uf) {
                errors.uf = true;
                showAlert('O campo UF é obrigatório.', 'error');
                setFormErrors(errors);
                return false;
            }

            if (!formValues.municipio) {
                errors.municipio = true;
                showAlert('O campo Município é obrigatório.', 'error');
                setFormErrors(errors);
                return false;
            }
        }

        if (!formValues.celular) {
            errors.celular = true;
            showAlert('O campo Celular é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        }

        if (!formValues.email) {
            errors.email = true;
            showAlert('O campo Email é obrigatório.', 'error');
            setFormErrors(errors);
            return false;
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formValues.email)) {
                errors.email = true;
                showAlert('E-mail inválido. Por favor, certifique-se de que o endereço de e-mail inserido é válido (por exemplo, seuemail@email.com).', 'error');
                setFormErrors(errors);
                return false;
            }
        }

        if (!formValues.PCD.necessita_atendimento_especializado) {
            errors.necessita_atendimento_especializado = true;
            showAlert('Por favor, informe se precisa de atendimento especializado', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues.PCD.tipo_deficiencias.includes('Outros') && !formValues.PCD.outras_deficiencia.trim()) {
            errors.outras_deficiencia = true;
            showAlert('Por favor, especifique o campo Outros', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues.PCD.necessita_atendimento_especializado === "Sim" && !formValues.PCD.dificuldade_locomocao) {
            errors.dificuldade_locomocao = true;
            showAlert('Por favor, selecione se possui dificuldade de locomoção', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues.PCD.dificuldade_locomocao === "Sim" || formValues.PCD.dificuldade_locomocao === "Cadeirante") {
            if (!formValues.PCD.acompanhamento_acessibilidade) {
                errors.acompanhamento_acessibilidade = true;
                showAlert('Por favor, informe se precisa de acompanhamentos ou recursos de acessibilidade para participar do evento', 'error');
                setFormErrors(errors);
                return false;
            } else if (formValues.PCD.acompanhamento_acessibilidade === "Sim" && !formValues.PCD.descricao_acompanhamento_acessibilidade) {
                errors.descricao_acompanhamento_acessibilidade = true;
                showAlert('Por favor, informe de qual/quais recursos e/ou acompanhamentos serão necessários para a sua participação do evento', 'error');
                setFormErrors(errors);
                return false;
            }
        }

        if (!formValues?.opc_pagamento) {
            errors.opc_pagamento = true;
            showAlert('O campo Dados Pagamento é obrigatório', 'error');
            setFormErrors(errors);
            return false;
        }

        if (formValues.opc_pagamento === '2') {
            if (!formValues.cnpj) {
                errors.cnpj = true;
                showAlert('O campo CNPJ é obrigatório para Pessoa Jurídica.', 'error');
                setFormErrors(errors);
                return false;
            } else {
                formValues.cnpj = formValues.cnpj.replace(/[^\d]/g, '');
                const cnpjPattern = /^\d{14}$/;
                if (!cnpjPattern.test(formValues.cnpj)) {
                    errors.cnpj = true;
                    showAlert('CNPJ inválido. O formato deve ser XX.XXX.XXX/XXXX-XX.', 'error');
                    setFormErrors(errors);
                    return false;
                }
            }
        }

        if (!formValues.razao_social && formValues.opc_pagamento === '2') {
            errors.razao_social = true;
            showAlert('O campo Razão Social é obrigatório para Pessoa Jurídica.', 'error');
            setFormErrors(errors);
            return false;
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    return validateForm();
};

export default ValidateForm;
