import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Navbar from '../theme/Navbar';
import Footer from '../../components/Footer'
import LoadingOverlay from '../../components/LoadingOverlay';
import FormularioCadastro from '../../components/FormularioCadastro';
import Endereco from '../../components/Endereco';
import TermosDeUso from '../../components/TermosDeUso';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
  Grid,
  Button,
} from '@material-ui/core';
import api from '../../service/Api';
import { Navigate } from 'react-router-dom';
import Constants from '../../constants/Constants';

function Index() {
  const {
    HandleSnackbarClose,
    formatarData,
    HandleChangeForm,
    handleSaveUser,
    setFormValues,
    setMunicipio,
    setCodigoUf,
    FormatCelular,
    FormatCPF,
    FormatCEP,
    FormatTelefone,
    HandleChangeCheckbox,
    FormatCNPJ,
    setNaturezas,
    setIsLoading,
    handleFileChange,
    handleRemoveFile,
    isLoading,
    ufs,
    naturezas,
    tipoCargo,
    cargo,
    areaDeAtuacao,
    municipio,
    formErrors,
    retornoApi,
    formValues,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    redirectBoleto,
    parlamentar,
    partidoPolitico,
    fileInputKey,
  } = Constants();

  useEffect(() => {
    const fetchNatureza = async () => {
      try {
        // setIsLoading(true);
        const response = await api.get('api/getNatureza');
        setNaturezas(response?.data?.natureza?.isento_autoridade);
        // setIsLoading(false);
      } catch (error) {
        console.error('Erro ao buscar tipos de inscrições:', error);
      }
    };

    fetchNatureza();
  }, [setNaturezas, setIsLoading]);

  return (
    <React.Fragment>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={HandleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={HandleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <Navbar />
      {isLoading && <LoadingOverlay />}
      <Container disableGutters xs={12} sm={12} sx={{ pt: 5 }}>
        <FormularioCadastro
          formValues={formValues}
          naturezas={naturezas}
          tipoCargo={tipoCargo}
          cargo={cargo}
          parlamentar={parlamentar}
          partidoPolitico={partidoPolitico}
          areaDeAtuacao={areaDeAtuacao}
          ufs={ufs}
          municipio={municipio}
          setFormValues={setFormValues}
          setCodigoUf={setCodigoUf}
          isLoading={isLoading}
          HandleChangeForm={HandleChangeForm}
          setMunicipio={setMunicipio}
          formErrors={formErrors}
          handleSaveUser={handleSaveUser}
          FormatCPF={FormatCPF}
          FormatCelular={FormatCelular}
          FormatTelefone={FormatTelefone}
          nome_municipio={formValues.nome_municipio}
          HandleChangeCheckbox={HandleChangeCheckbox}
          handleFileChange={handleFileChange}
          handleRemoveFile={handleRemoveFile}
          fileInputKey={fileInputKey}
        />
      </Container>

      <Container disableGutters xs={12} sm={12}>
        <Endereco
          formValues={formValues}
          HandleChangeForm={HandleChangeForm}
          formErrors={formErrors}
          FormatCNPJ={FormatCNPJ}
          FormatCEP={FormatCEP}
        />
      </Container>

      <Container disableGutters xs={12} sm={12}>
        <Grid container spacing={3} component="main">
          <Grid item xs={12} sm={8}>
            <TermosDeUso
              formValues={formValues}
              HandleChangeForm={HandleChangeForm}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container justifyContent="center" mt={4} style={{ marginTop: '20px' }}>
              <Button type="submit" variant="contained" color="primary" onClick={handleSaveUser}>
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* Footer */}
      <Footer />

      {redirectBoleto && (
        <Navigate
          to="/boleto"
          state={{
            nome: formValues?.nome,
            cpf: formValues?.cpf,
            bairro: formValues?.bairro,
            endereco: formValues?.endereco,
            celular: formValues?.celular,
            opc_pagamento: formValues?.opc_pagamento,
            tipo_pagamento: formValues?.tipo_pagamento,
            // cidade: formValues?.bairro,
            municipio: formValues?.municipio,
            nome_municipio: formValues?.nome_municipio,
            uf: formValues?.uf,
            cep: formValues?.cep,
            // url: retornoApi?.data?.pagamento?.qrCode?.url,
            // txId: retornoApi?.data?.pagamento?.qrCode?.txId,
            emv: retornoApi?.data?.pagamento?.pagamento?.qrCode?.emv,
            numero: retornoApi?.data?.pagamento?.pagamento?.numero,
            dataVencimento: formatarData(retornoApi?.data?.pagamento?.dataFutura),
            dataEmissao: formatarData(retornoApi?.data?.pagamento?.dataEmissao),
            valor: retornoApi?.data?.pagamento?.valor + ',00',
            numeroTituloCliente: retornoApi?.data?.pagamento?.pagamento?.numero,
            razao_social: formValues?.razao_social ? formValues?.razao_social : null,
            cnpj: formValues?.cnpj ? formValues.cnpj : null,
            numeroCarteira: retornoApi?.data?.pagamento?.pagamento?.numeroCarteira,
            numeroVariacaoCarteira: retornoApi?.data?.pagamento?.pagamento?.numeroVariacaoCarteira,
            codigoCliente: retornoApi?.data?.pagamento?.pagamento?.codigoCliente,
            linhaDigitavel: retornoApi?.data?.pagamento?.pagamento?.linhaDigitavel,
            codigoBarraNumerico: retornoApi?.data?.pagamento?.pagamento?.codigoBarraNumerico,
            // numeroContratoCobranca: retornoApi?.data?.pagamento?.numeroContratoCobranca,
          }}
        />

      )}
    </React.Fragment >

  );
}

export default Index;
