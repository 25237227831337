import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Card, CardContent, Typography, Button, Tooltip, IconButton, FormControl, TextField } from '@mui/material';
import Barcode from 'react-barcode';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../pages/theme/Navbar';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LoadingOverlay from '../components/LoadingOverlay';
import api from '../service/Api';
import checkPagamento from '../images/check-pagamento.jpg';
import atualizarPagamento from '../images/atualizar-pagamento.jpg';
import CustomModal from './CustomModal';

const BoletoPix = () => {
    const location = useLocation();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [openModalAtualizarBoleto, setOpenModalAtualizarBoleto] = useState(false);
    const [openModalCriarBoleto, setOpenModalCriarBoleto] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [desabilitarBotao, setDesabilitarBotao] = useState(false);
    const [showCpfInput, setShowCpfInput] = useState(false);
    const initialState = {
        statusPagamento: '',
        cpf: '',
        nome: '',
        cargo: '',
        status_contribuicao: '',
        bairro: '',
        endereco: '',
        celular: '',
        opc_pagamento: '',
        tipo_pagamento: '',
        municipio: '',
        nome_municipio: '',
        uf: '',
        cep: '',
        dataVencimento: '',
        dataEmissao: '',
        valor: '',
        numeroTituloCliente: '',
        razao_social: '',
        cnpj: '',
        linhaDigitavel: '',
        emv: '',
        numero: '',
        codigoBarraNumerico: '',
    };
    const [formValues, setFormValues] = useState(initialState);

    const handleOpenModalAtualizarBoleto = () => {
        setOpenModalAtualizarBoleto(true);
    };
    const handleCloseModalAtualizarBoleto = () => {
        setOpenModalAtualizarBoleto(false);
    };

    const handleOpenModalCriarBoleto = () => {
        setOpenModalCriarBoleto(true);
    };
    const handleCloseModalCriarBoleto = () => {
        setOpenModalCriarBoleto(false);
    };

    const FormatCPF = (value) => {
        // Remove tudo o que não é dígito
        if (value) {
            value = value.replace(/\D/g, '');
            // Adiciona os pontos e o hífen no CPF
            value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
            return value;
        }

        return '';
    };

    const formatarData = (dataString) => {
        if (!dataString) return null;

        const [dia, mes, ano] = dataString.split('.');
        return `${dia}/${mes}/${ano}`;
    };

    const HandleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const showAlert = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleImprimirBoleto = () => {
        const params = {
            linhaDigitavel: encodeURIComponent(formValues.linhaDigitavel),
            dataVencimento: encodeURIComponent(formValues.dataVencimento),
            dataEmissao: encodeURIComponent(formValues.dataEmissao),
            numeroTituloCliente: encodeURIComponent(formValues.numeroTituloCliente),
            valor: encodeURIComponent(formValues.valor),
            endereco: encodeURIComponent(formValues.endereco),
            bairro: encodeURIComponent(formValues.bairro),
            municipio: encodeURIComponent(formValues.nome_municipio),
            nome_municipio: encodeURIComponent(formValues.nome_municipio),
            uf: encodeURIComponent(formValues.uf),
            cep: encodeURIComponent(formValues.cep),
            codigoBarraNumerico: encodeURIComponent(formValues.codigoBarraNumerico),
            emv: encodeURIComponent(formValues.emv),
        };
        if (formValues.cpf) {
            params.cpf = encodeURIComponent(formValues.cpf);
        }
        if (formValues.cnpj) {
            params.cnpj = encodeURIComponent(formValues.cnpj);
        }
        if (formValues.razao_social) {
            params.razao_social = encodeURIComponent(formValues.razao_social);
        }
        if (formValues.nome) {
            params.nome = encodeURIComponent(formValues.nome);
        }
        const imprimirBoletoUrl = `/imprimir-boleto?${Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')}`;
        window.open(imprimirBoletoUrl, '_blank');
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(formValues.linhaDigitavel);
        showAlert('Número do boleto copiado para a área de transferência!', 'info');
    };

    const fetchDataFromApi = useCallback(async () => {
        let response;
        let dadosInscricaoAPI;
        let dadosPagamento;
        const updateFormValues = (prevFormValues, dadosInscricaoAPI, dadosPagamento) => {
            return {
                ...prevFormValues,
                statusPagamento: dadosPagamento?.codigoEstadoTituloCobranca ?? 7,
                nome: dadosInscricaoAPI?.nome || '',
                cargo: dadosInscricaoAPI?.cargo || '',
                status_contribuicao: dadosInscricaoAPI?.status_contribuicao || '',
                cpf: FormatCPF(dadosInscricaoAPI?.cpf) || '',
                bairro: dadosInscricaoAPI?.bairro || '',
                endereco: dadosInscricaoAPI?.endereco || '',
                celular: dadosInscricaoAPI?.celular,
                opc_pagamento: dadosInscricaoAPI?.opc_pagamento,
                tipo_pagamento: dadosInscricaoAPI?.tipo_pagamento,
                municipio: dadosInscricaoAPI?.municipio || '',
                nome_municipio: dadosInscricaoAPI?.nome_municipio || '',
                uf: dadosInscricaoAPI?.uf || '',
                cep: dadosInscricaoAPI?.cep || '',
                emv: dadosInscricaoAPI?.emv || '',
                numero: dadosInscricaoAPI.numeroTituloCliente || '',
                dataVencimento: formatarData(dadosPagamento?.dataVencimentoTituloCobranca) || '',
                dataEmissao: formatarData(dadosPagamento?.dataEmissaoTituloCobranca) || '',
                valor: (dadosPagamento?.valorAtualTituloCobranca ?? dadosInscricaoAPI?.valor) + ',00' || '',
                numeroTituloCliente: dadosInscricaoAPI?.numeroTituloCliente || '',
                razao_social: dadosInscricaoAPI?.razao_social || '',
                cnpj: dadosInscricaoAPI?.cnpj || '',
                linhaDigitavel: dadosPagamento?.codigoLinhaDigitavel || '',
                codigoBarraNumerico: dadosPagamento?.textoCodigoBarrasTituloCobranca || '',
            };
        };
        try {
            const urlSearchParams = new URLSearchParams(window.location.search);
            let urlCpf = urlSearchParams.get('cpf');
            if (!urlCpf) {
                urlCpf = formValues?.cpf.replace(/\D/g, '');
                if (!urlCpf || urlCpf.length < 11) {
                    return showAlert("Informe o CPF corretamente para consulta", 'error');
                }
            }
            setDesabilitarBotao(true);
            response = await api.get(`api/cadastroUser?cpf=${urlCpf}&tipo_pagamento=boleto`);
            dadosInscricaoAPI = response.data.getCadastroUser[0];
            if (!dadosInscricaoAPI) {
                showAlert("Não foi localizado um cadastro com o CPF informado. Verifique o CPF e tente novamente.", 'error');
                setDesabilitarBotao(false);
                const urlSearchParams = new URLSearchParams(window.location.search);
                let cpfUrl = urlSearchParams.get('cpf');
                if (cpfUrl) {
                    return setTimeout(() => {
                        window.location.href = '/boleto';
                    }, 4000);
                }
                return;
            }
            const response2 = await api.get(`api/detalhaBoleto?numeroCliente=${dadosInscricaoAPI?.numeroTituloCliente}`);
            dadosPagamento = response2.data.mensageBoleto;
            setFormValues((prevFormValues) => updateFormValues(prevFormValues, dadosInscricaoAPI, dadosPagamento));
            setDesabilitarBotao(false);
            setIsLoading(false);
            setShowCpfInput(false);
        } catch (error) {
            if (response?.data?.getCadastroUser[0]?.numeroTituloCliente === null) {
                setDesabilitarBotao(false);
                return showAlert("Não foram localizados dados de pagamento para a sua inscrição. Em caso de dúvidas, favor, entre em contato com a CNM", 'error');
            } else {
                setShowCpfInput(false);
                setDesabilitarBotao(false);
                setFormValues((prevFormValues) => updateFormValues(prevFormValues, dadosInscricaoAPI, dadosPagamento));
                setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    statusPagamento: 7
                }));
                setIsLoading(false);
            }
        }
    }, [formValues?.cpf]);

    const AtualizarVencimentoBoleto = async () => {
        try {
            const formData = new FormData();
            formData.append('numeroCliente', formValues?.numeroTituloCliente);
            setDesabilitarBotao(true);
            const response = await api.post('api/boleto', formData);
            if (response?.data?.mensage?.dataAtualizacao) {
                showAlert("Boleto atualizado com sucesso!", 'success');
                handleCloseModalAtualizarBoleto();
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                showAlert("Não foi possível atualizar a data de vencimento do boleto. Por favor, tente novamente mais tarde.", 'error');
                setDesabilitarBotao(false);
                handleCloseModalAtualizarBoleto();
            }
            setIsLoading(false);
        } catch (error) {
            showAlert("Não foi possível completar a ação. Verifique se o boleto ainda está apto para pagamento ou tente novamente mais tarde", 'error');
            setDesabilitarBotao(false);
            handleCloseModalAtualizarBoleto();
        }
    };

    const CriarNovoBoleto = async () => {
        try {
            const { cpf, cnpj, celular, cep, endereco, bairro, opc_pagamento, tipo_pagamento, municipio, uf, numeroTituloCliente, nome } = formValues;
            const formattedCPF = cpf.replace(/\D/g, '');
            let valorSubstituicao;

            if ([3, 16].includes(formValues?.cargo) && (formValues?.status_contribuicao === "false" || formValues?.status_contribuicao === false)) {
                valorSubstituicao = process.env.REACT_APP_VALOR_PREFEITO_NAO_CONTRIBUINTE;
            } else if (formValues?.status_contribuicao === true || formValues?.status_contribuicao === "true") {
                valorSubstituicao = process.env.REACT_APP_VALOR_MUNICIPIO_CONTRIBUINTE;
            } else if (formValues?.uf === 'DF') {
                valorSubstituicao = process.env.REACT_APP_VALOR_DF;
            } else {
                valorSubstituicao = process.env.REACT_APP_VALOR_DEMAIS;
            }

            const formattedValor = parseFloat(valorSubstituicao.replace(/,00$/, ''));

            const requestBody = {
                cpf: formattedCPF,
                cnpj,
                celular,
                cep,
                endereco,
                bairro,
                opc_pagamento,
                tipo_pagamento,
                municipio,
                uf,
                valor: formattedValor,
                numeroCliente: numeroTituloCliente,
                nome,
            };
            setDesabilitarBotao(true);
            const response = await api.post('api/novoBoleto', requestBody);
            if (response?.data?.NovoBoleto) {
                showAlert("Novo boleto criado com sucesso!", 'success');
                handleCloseModalCriarBoleto();
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                showAlert("Não foi possível criar um novo boleto. Por favor, tente novamente mais tarde.", 'error');
                setDesabilitarBotao(false);
            }
            setIsLoading(false);
        } catch (error) {
            setDesabilitarBotao(false);
            showAlert("Não foi possível criar um novo boleto. Por favor, tente novamente mais tarde.", 'error');
        }
    };

    const isBotaoAtualizarHabilitado = () => {
        const [diaEmis, mesEmis, anoEmis] = formValues?.dataEmissao.split('/');
        const dataEmissao = new Date(anoEmis, mesEmis - 1, diaEmis);
        const dataAtual = new Date();
        dataAtual.setHours(0, 0, 0, 0);
        return dataEmissao.getTime() === dataAtual.getTime();
    };

    useEffect(() => {
        if (location?.state) {
            setIsLoading(false);
            showAlert('Inscrição realizada com sucesso. Realize o pagamento com os dados acima ou verifique seu e-mail para mais informações.', 'success');
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                ...location.state,
            }));
        }
    }, [location?.state, fetchDataFromApi, setIsLoading]);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlCpf = urlSearchParams.get('cpf');
        if (!location.state && !urlCpf) {
            setShowCpfInput(true);
            setIsLoading(false);
            return;
        } else {
            fetchDataFromApi();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={12000}
                onClose={HandleSnackbarClose}
                style={{ zIndex: 9999 }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Navbar />


            {/* {isLoading && <LoadingOverlay />} */}
            {isLoading ? (
                <>
                    <LoadingOverlay />
                </>
            ) : (
                <>
                    {showCpfInput ? (
                        <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
                            <Grid item xs={12} sm={4}>
                                <FormControl required fullWidth>
                                    <Typography variant="h6">
                                        Informe seu CPF para consultar o boleto de pagamento.
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="cpf"
                                        label="CPF"
                                        name="cpf"
                                        autoComplete="cpf"
                                        value={FormatCPF(formValues?.cpf)}
                                        onChange={(e) => setFormValues((prevFormValues) => ({ ...prevFormValues, cpf: FormatCPF(e.target.value) }))}
                                        inputProps={{ maxLength: 14 }}
                                        style={{ marginTop: '20px' }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !desabilitarBotao) {
                                                fetchDataFromApi();
                                            }
                                        }}
                                    />
                                    <Button variant="contained" color="primary" disabled={desabilitarBotao} onClick={fetchDataFromApi}>
                                        Enviar
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Container>
                    ) : (
                        <>
                            <Container disableGutters xs={12} sm={12} sx={{ pt: 5 }}>
                                <Card style={{ maxWidth: 1200, margin: 'auto' }}>
                                    <CardContent>
                                        {[6, 16].includes(formValues?.statusPagamento) ? (

                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <img alt="foto" draggable={false} src={checkPagamento} className="image-container" style={{ maxWidth: '100%', height: 'auto' }} />
                                            </div>

                                        ) : formValues?.statusPagamento === 7 ? (
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img alt="foto" draggable={false} src={atualizarPagamento} className="image-container" style={{ maxWidth: '100%', height: 'auto' }} />
                                                </div>
                                                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button variant="contained" onClick={handleOpenModalCriarBoleto} >
                                                        Atualizar Boleto
                                                    </Button>
                                                </Grid>
                                            </>
                                        ) :
                                            (
                                                <Grid container spacing={3} justifyContent="center">
                                                    <Grid item xs={12} sm={5} textAlign="center">
                                                        <Typography variant="h5" gutterBottom>
                                                            PIX do Boleto
                                                        </Typography>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <img
                                                                draggable={false}
                                                                src={`https://api.invertexto.com/v1/qrcode?token=7336|jJY34KH5a0H4dMpo6h4XQ7k6FMANMuC6&text=${formValues?.emv}&scale=4`}
                                                                alt="Código PIX"
                                                            />
                                                        </div>
                                                        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                                            <Grid item xs={12} sm={6}>
                                                                <Button variant="contained" onClick={handleImprimirBoleto}>
                                                                    Imprimir Boleto
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Button variant="contained" onClick={handleOpenModalAtualizarBoleto} disabled={isBotaoAtualizarHabilitado()}>
                                                                    Atualizar Boleto
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={7} textAlign="center">
                                                        <Typography variant="h5" gutterBottom>
                                                            Detalhes do Boleto
                                                        </Typography>
                                                        <Typography variant="body1" gutterBottom>
                                                            Número: {formValues?.numero}
                                                        </Typography>
                                                        <Typography variant="body1" gutterBottom>
                                                            Data de vencimento: {formValues?.dataVencimento}
                                                        </Typography>
                                                        {/* <Typography variant="body1" gutterBottom>
                                                    Conta Corrente: 15969-7
                                                </Typography> */}
                                                        <Typography variant="body1" gutterBottom>
                                                            Linha Digitável: {formValues?.linhaDigitavel}
                                                            <Tooltip title="Copiar o número" arrow>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={handleCopyToClipboard}
                                                                    style={{ marginLeft: '4px', color: 'grey' }}
                                                                >
                                                                    <FileCopyIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Typography>
                                                        <div style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}>
                                                            <Barcode value={formValues?.codigoBarraNumerico} format='CODE128' />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </CardContent>
                                </Card>
                            </Container>
                        </>
                    )}
                </>
            )
            }

            <CustomModal
                open={openModalAtualizarBoleto}
                onClose={handleCloseModalAtualizarBoleto}
                title="Confirmar Atualização de Boleto"
                content="Deseja realmente atualizar o vencimento?"
                onCancel={handleCloseModalAtualizarBoleto}
                onConfirm={AtualizarVencimentoBoleto}
                disabled={desabilitarBotao}
            />

            <CustomModal
                open={openModalCriarBoleto}
                onClose={handleCloseModalCriarBoleto}
                title="Confirmar Criação de Boleto"
                content="Deseja realmente criar um novo boleto?"
                onCancel={handleCloseModalCriarBoleto}
                onConfirm={CriarNovoBoleto}
                disabled={desabilitarBotao}
            />

        </React.Fragment >
    );
};

export default BoletoPix;
