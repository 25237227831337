import React, { useState } from 'react';
import {
    Container,
    TextField,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    FormControl,
    InputAdornment,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup,
    Checkbox,
    Button,
    Typography
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Countries } from '../constants/Nation';
import api from '../service/Api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ConstantInscricoes from "../constants/ConstantInscricoes";
import SaveIcon from '@material-ui/icons/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import ValidateFormInside from '../components/ValidateFormInside';
import Caixas from './Caixas';

const CadastroInterno = ({ setFuncionalidade, showAlert, HandleSearch }) => {
    const {
        HandleSnackbarClose,
        FormatCPF,
        FormatCelular,
        FormatTelefone,
        HandleChangeForm,
        HandleChangeCheckbox,
        setFormValues,
        setCodigoUf,
        setMunicipio,
        getEntidadeOpcoesPorTipoCargo,
        getEntidadeValorPorTipoCargo,
        getEntidadeNomeFormulario,
        getEntidadeLabelPorTipoCargo,
        ajustaNomes,
        CalculoDeValores,
        getStatusLabel,
        handlePagamentosChange,
        hasPermission,
        FormatCNPJ,
        fetchContribuicao,
        fetchRegularizacao,
        dadosMunicipio,
        isCaixa,
        isAdmin,
        ufs,
        areaDeAtuacao,
        naturezas,
        tipoCargo,
        cargo,
        consorcio,
        municipio,
        formValues,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        parlamentar,
        partidoPolitico
    } = ConstantInscricoes();

    const handleVoltar = () => {
        HandleSearch();
        setFuncionalidade('');
    };
    const [formErrors, setFormErrors] = useState({});

    const handleSaveUser = async () => {
        let errors = {};
        const isValid = ValidateFormInside({
            formValues,
            setFormErrors,
            showAlert,
            errors,
        });

        if (isValid) {
            try {
                const pcdJson = JSON.stringify(formValues.PCD);
                const convidadoJson = JSON.stringify(formValues.convidado);
                const cleanTelefone = formValues.telefone.replace(/[\s()-]/g, '');
                const cleanCelular = formValues.celular.replace(/[\s()-]/g, '');
                const cleanCpf = formValues.cpf.replace(/[^\d]/g, '');
                const cleanCnpj = formValues.cnpj.replace(/[^\d]/g, '');
                const cleanCep = formValues.cep.replace(/[^\d]/g, '');

                let pagamentosUnicos = {};

                if (formValues.pagamentos && formValues.pagamentos.length > 0) {
                    const groupedPagamentos = {};

                    formValues.pagamentos.forEach(async (pagamento) => {
                        const tipoPgto = pagamento.tipo_pgto.toLowerCase();
                        if (!groupedPagamentos[tipoPgto]) {
                            groupedPagamentos[tipoPgto] = 0;
                        }
                        groupedPagamentos[tipoPgto] += pagamento.valor;

                        if (tipoPgto === 'filiacao' && dadosMunicipio?.situacao === 'Não contribuinte') {
                            const fetchContribuinte = await fetchContribuicao();
                            if (fetchContribuinte === 200) {
                                formValues.status_contribuicao = 'true';
                                showAlert('Municipio filiado com sucesso', 'success');
                            } else {
                                return showAlert('Ocorreu um erro durante o processo de filiação', 'error');
                            }
                        } else if (tipoPgto === 'filiacao' && dadosMunicipio?.situacao === 'Inadimplente') {
                            const fetchContribuinte = await fetchRegularizacao();
                            if (fetchContribuinte === 200) {
                                formValues.status_contribuicao = 'true';
                                showAlert('Municipio regularizado com sucesso', 'success');
                            } else {
                                return showAlert('Ocorreu um erro durante o processo de filiação', 'error');
                            }
                        }
                    });

                    const perfil = formValues.pagamentos[0]?.perfil || '';

                    pagamentosUnicos = {
                        ...groupedPagamentos,
                        obs: '',
                        banco: null,
                        agencia: null,
                        conta: null,
                        titular: null,
                        perfil,
                    };

                    formValues.pagamentos.forEach((pagamento) => {
                        if (pagamento.obs) {
                            const obsComTipoPagamento = `${pagamento.tipo_pgto}: ${pagamento.obs}`;
                            if (pagamentosUnicos.obs) {
                                pagamentosUnicos.obs += `, ${obsComTipoPagamento}`;
                            } else {
                                pagamentosUnicos.obs = obsComTipoPagamento;
                            }
                        }

                        pagamentosUnicos.banco = pagamento.banco || pagamentosUnicos.banco;
                        pagamentosUnicos.agencia = pagamento.agencia || pagamentosUnicos.agencia;
                        pagamentosUnicos.conta = pagamento.conta || pagamentosUnicos.conta;
                        pagamentosUnicos.titular = pagamento.titular || pagamentosUnicos.titular;
                        pagamentosUnicos.perfil = pagamento.perfil || pagamentosUnicos.perfil;
                    });
                }

                if (Array.isArray(formValues.pagamentos) && formValues.pagamentos.length === 0) {
                    delete formValues.pagamentos;
                }

                const updatedFormValues = {
                    ...formValues,
                    PCD: pcdJson,
                    convidado: convidadoJson,
                    telefone: cleanTelefone,
                    celular: cleanCelular,
                    cpf: cleanCpf,
                    cnpj: cleanCnpj,
                    cep: cleanCep,
                    nome: ajustaNomes(formValues.nome.toLowerCase()),
                    nome_cracha: ajustaNomes(formValues.nome_cracha.toLowerCase()),
                    email: formValues.email.toLowerCase(),
                    opc_pagamento: '2',
                    tipo_pagamento: 'caixa',
                    cadastroUserInterno: 'true',
                    file: '',
                    cortesia: formValues?.tipo_inscricao !== 1 ? '1' : '0',
                    aprovado: ([3, 16].includes(formValues.cargo) && formValues.status_contribuicao === true) || (formValues.pagamentos && formValues.pagamentos.length > 0) ? '1' : '0',
                };

                if (Object.keys(pagamentosUnicos).length > 0) {
                    updatedFormValues.pagamentos = JSON.stringify(pagamentosUnicos);
                }

                const formData = new FormData();

                for (const [key, value] of Object.entries(updatedFormValues)) {
                    if (key === 'PCD' || key === 'convidado' || key === 'pagamentos') {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }

                const response = await api.post('api/cadastroUser', formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "X-Requested-With": "XMLHttpRequest"
                    }
                });

                if (response?.status === 200) {
                    setTimeout(() => {
                        showAlert('Cadastro realizado com sucesso!', 'success');
                    }, 1000);
                    handleVoltar();
                } else {
                    showAlert('Erro ao enviar o formulário. Tente novamente mais tarde.', 'error');
                }
            } catch (error) {
                const erroValidacao = error?.response?.data?.errors;
                const erroPrefeitoNaoCadastrado = error?.response?.data?.mensage;
                if (erroValidacao) {
                    const errorMessage = Object.values(erroValidacao).flat().join(' ');
                    showAlert(errorMessage, 'error');
                } else if (erroPrefeitoNaoCadastrado) {
                    showAlert(erroPrefeitoNaoCadastrado, 'error');
                } else {
                    showAlert('Ocorreu um erro durante o cadastro.', 'error');
                }
            }
        }
    };

    return (
        <Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <Button style={{ marginTop: '10px' }} variant="contained" onClick={handleVoltar}>Voltar</Button>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                    <Typography variant="h6" gutterBottom>
                        Dados de cadastro
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }} >Tipo de inscrição</InputLabel>
                        <Select
                            variant="outlined"
                            error={!!formErrors.tipo_inscricao}
                            value={formValues?.tipo_inscricao}
                            onChange={(e) => {
                                const tipoInscricaoSelecionado = e.target.value;
                                setFormValues({
                                    ...formValues,
                                    tipo_inscricao: tipoInscricaoSelecionado,
                                    tipo_cargo: '',
                                    cargo: '',
                                    area_atuacao: '',
                                    nome_cargo: '',
                                    convidado: {
                                        ...formValues.convidado,
                                        cargo: '',
                                        convidado_de: '',
                                    },
                                });
                            }}
                            IconComponent={naturezas.length === 1 ? () => null : undefined}
                            disabled={naturezas.length === 1}
                        >
                            {naturezas?.map((natureza) => (
                                <MenuItem key={natureza.id} value={natureza.id}>
                                    {natureza.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }} >Tipo cargo</InputLabel>
                        <Select
                            variant="outlined"
                            error={!!formErrors.tipo_cargo}
                            disabled={!formValues.tipo_inscricao}
                            value={formValues?.tipo_cargo}
                            onChange={(e) => {
                                const tipoCargoSelecionado = e.target.value;
                                setFormValues({
                                    ...formValues,
                                    tipo_cargo: tipoCargoSelecionado,
                                    cargo: '',
                                    area_atuacao: '',
                                    nome_cargo: '',
                                    convidado: {
                                        ...formValues.convidado,
                                        cargo: '',
                                        convidado_de: '',
                                    },
                                });
                            }}
                        >
                            {tipoCargo.map((tipo) => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                    {tipo.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        {formValues?.tipo_cargo === 132 ?
                            <>
                                <TextField
                                    variant="outlined"
                                    error={!!formErrors?.convidadoCargo}
                                    fullWidth
                                    id="convidadoCargo"
                                    label="Cargo"
                                    name="convidadoCargo"
                                    autoComplete="convidadoCargo"
                                    value={formValues?.convidado?.cargo || ''}
                                    onChange={(e) => {
                                        const novoCargo = e.target.value;
                                        HandleChangeForm('cargo', formValues?.tipo_cargo);
                                        HandleChangeForm('convidado', { ...formValues.convidado, cargo: novoCargo });
                                        HandleChangeForm('nome_cargo', novoCargo);
                                    }}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </>
                            :
                            <>
                                <InputLabel style={{ marginLeft: '13px' }}>Cargo</InputLabel>
                                <Select
                                    variant="outlined"
                                    error={!!formErrors.cargo}
                                    value={formValues?.cargo}
                                    disabled={!formValues.tipo_cargo}
                                    onChange={(e) => {
                                        const cargoSelecionado = e.target.value;
                                        const cargoSelecionadoDescricao = cargo.find((cargo) => cargo.id === cargoSelecionado)?.descricao || '';
                                        setFormValues({
                                            ...formValues,
                                            cargo: cargoSelecionado,
                                            nome_cargo: cargoSelecionadoDescricao,
                                            area_atuacao: '',
                                        });
                                    }}
                                >
                                    {cargo.map((cargo) => (
                                        <MenuItem key={cargo.id} value={cargo.id}>
                                            {cargo.descricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        }
                    </FormControl>
                </Grid>

                {formValues?.tipo_inscricao === 137 &&
                    <>
                        {[138, 146, 153].includes(formValues?.tipo_cargo) &&
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={getEntidadeOpcoesPorTipoCargo(formValues?.tipo_cargo) || []}
                                        getOptionLabel={(entidade) => entidade.NOME_ENTIDADE || ''}
                                        value={getEntidadeValorPorTipoCargo(formValues, formValues.tipo_cargo) || null}
                                        onChange={(e, selectedEntidade) => {
                                            HandleChangeForm(getEntidadeNomeFormulario(formValues?.tipo_cargo), selectedEntidade?.id);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                required
                                                error={!!formErrors.entidade}
                                                label={getEntidadeLabelPorTipoCargo(formValues?.tipo_cargo)}

                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        }

                        {formValues?.tipo_cargo === 15 &&
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={consorcio || []}
                                        getOptionLabel={(consorcio) => consorcio.NOME_ENTIDADE || ''}
                                        value={consorcio?.find((p) => p.id === formValues?.consorcio) || null}
                                        onChange={(e, selectedConsorcio) => {
                                            HandleChangeForm('consorcio', selectedConsorcio?.id);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                required
                                                error={!!formErrors.consorcio}
                                                label="Consorcio"

                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        }
                    </>
                }
                {(([26, 68].includes(formValues?.tipo_inscricao) && [49, 55, 97].includes(formValues?.tipo_cargo)) && (formValues?.cargo && ![50, 51, 52, 56, 57, 58, 98, 99].includes(formValues?.cargo))) &&
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={parlamentar || []}
                                getOptionLabel={(parlamentar) => parlamentar.nome || ''}
                                value={parlamentar?.find((p) => p.id === formValues?.parlamentar) || null}
                                onChange={(e, selectedParlamentar) => {
                                    HandleChangeForm('parlamentar', selectedParlamentar?.id || '', '', selectedParlamentar?.nome || '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        required
                                        error={!!formErrors.parlamentar}
                                        label="Parlamentar"

                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                }

                {(([26, 68].includes(formValues?.tipo_inscricao) && [49, 55, 97].includes(formValues?.tipo_cargo)) && (formValues?.cargo && [50, 51, 52, 56, 57, 58, 98, 99].includes(formValues?.cargo))) &&
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={partidoPolitico || []}
                                getOptionLabel={(partidoPolitico) => partidoPolitico.NOME + ' - ' + partidoPolitico.SIGLA || ''}
                                value={partidoPolitico?.find((p) => p.id === formValues?.partido_politico) || null}
                                onChange={(e, selectedPartidoPolitico) => {
                                    HandleChangeForm('partido_politico', selectedPartidoPolitico?.id);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        required
                                        error={!!formErrors.partido_politico}
                                        label="Partido Político"

                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                }
                {formValues?.tipo_cargo === 122 && (
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel style={{ marginLeft: '13px' }}>País</InputLabel>
                            <Select
                                variant="outlined"
                                required
                                error={!!formErrors.pais}
                                value={formValues?.pais}
                                onChange={(e) => HandleChangeForm('pais', e.target.value)}
                            >
                                {Countries?.map((countrie) => (
                                    <MenuItem key={countrie.value} value={countrie.value}>
                                        {countrie.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {formValues?.cargo === 13 && (
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel style={{ marginLeft: '13px' }}>Áreas de Atuação</InputLabel>
                            <Select
                                variant="outlined"
                                required
                                error={!!formErrors.area_atuacao}
                                value={formValues?.area_atuacao}
                                onChange={(e) => HandleChangeForm('area_atuacao', e.target.value)}
                            >
                                {areaDeAtuacao?.map((area) => (
                                    <MenuItem key={area.id} value={area.id}>
                                        {area.AREA_ATUACAO}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {formValues?.tipo_inscricao && formValues?.tipo_inscricao === 102 && (
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            error={!!formErrors.empresa_instituicao}
                            required
                            onChange={(e) => HandleChangeForm('empresa_instituicao', e.target.value)}
                            value={formValues?.empresa_instituicao}
                            fullWidth
                            label="Empresa/Instituição"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                )}

                {formValues?.tipo_inscricao === 26 && formValues?.tipo_cargo === 160 &&
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            required
                            error={!!formErrors.ministerio_orgao}
                            onChange={(e) => HandleChangeForm('ministerio_orgao', e.target.value)}
                            value={formValues?.ministerio_orgao}
                            fullWidth
                            label="Ministério"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                }

                {formValues?.tipo_cargo === 132 && (
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            required
                            error={!!formErrors?.convidadoDe}
                            value={formValues?.convidado?.convidado_de || ''}
                            onChange={(e) => {
                                const convidadoDe = e.target.value;
                                HandleChangeForm('convidado', { ...formValues.convidado, convidado_de: convidadoDe });
                            }}
                            fullWidth
                            label="Convidado de"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        required
                        error={!!formErrors.nome}
                        onBlur={() => {
                            if (formValues.nome && !formValues.nome_cracha) {
                                const [primeiroNome, ...outrosNomes] = formValues.nome.split(' ');
                                const ultimoNome = outrosNomes.pop() || '';
                                const nomeCracha = `${primeiroNome} ${ultimoNome}`;
                                HandleChangeForm('nome_cracha', nomeCracha);
                            }
                        }}
                        onChange={(e) => HandleChangeForm('nome', e.target.value)}
                        value={formValues?.nome}
                        fullWidth
                        label="Nome"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        required
                        error={!!formErrors.nome_cracha}
                        onChange={(e) => HandleChangeForm('nome_cracha', e.target.value)}
                        value={formValues.nome_cracha}
                        fullWidth
                        label="Nome Crachá"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>

                {formValues?.tipo_cargo !== 122 ?
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            required
                            error={!!formErrors.cpf}
                            fullWidth
                            id="cpf"
                            label="CPF"
                            name="cpf"
                            autoComplete="cpf"
                            value={FormatCPF(formValues?.cpf)}
                            onChange={(e) => HandleChangeForm('cpf', e.target.value)}
                            inputProps={{ maxLength: 14 }}
                        />
                    </Grid>
                    :
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            required
                            error={!!formErrors.passaporte}
                            fullWidth
                            id="passaporte"
                            label="Passaporte"
                            name="passaporte"
                            autoComplete="passaporte"
                            value={formValues?.passaporte}
                            onChange={(e) => {
                                const inputValue = e.target.value.toUpperCase();
                                const formattedValue = inputValue.replace(/[^A-Z0-9]/g, '');
                                const passaporteFormatted = formattedValue.substring(0, 8);
                                if (/^[A-Z]{0,2}\d{0,6}$/.test(passaporteFormatted)) {
                                    HandleChangeForm('passaporte', passaporteFormatted);
                                }
                            }}
                            inputProps={{
                                maxLength: 8,
                            }}
                        />

                    </Grid>
                }
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }}>Gênero</InputLabel>
                        <Select
                            variant="outlined"
                            error={!!formErrors.genero}
                            required
                            onChange={(e) => HandleChangeForm('genero', e.target.value)}
                            value={formValues?.genero}
                        >
                            <MenuItem value="masculino">Masculino</MenuItem>
                            <MenuItem value="feminino">Feminino</MenuItem>
                            <MenuItem value="outros">Outros</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {formValues?.tipo_cargo !== 122 && (
                    <>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={ufs}
                                    getOptionLabel={(uf) => uf.SIGLA_UF}
                                    value={ufs.find((uf) => uf.SIGLA_UF === formValues?.uf) || null}
                                    onChange={(e, selectedUfObject) => {
                                        setCodigoUf(selectedUfObject?.CD_UF || null);
                                        setMunicipio(selectedUfObject?.CD_MUNICIPIO || null);
                                        HandleChangeForm('uf', selectedUfObject?.SIGLA_UF || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={!!formErrors.uf}
                                            required
                                            label="UF"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disabled={!formValues?.uf}
                                    options={municipio || []}
                                    getOptionLabel={(municipio) => municipio.NOME_MUNICIPIO || ''}
                                    value={municipio?.find((m) => m.CD_MUNICIPIO === formValues?.municipio) || null}
                                    onChange={(e, selectedMunicipio) => {
                                        HandleChangeForm('municipio', selectedMunicipio?.CD_MUNICIPIO || '', selectedMunicipio?.NOME_MUNICIPIO || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required
                                            error={!!formErrors.municipio}
                                            label="Município"

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        error={!!formErrors.celular}
                        required
                        onChange={(e) => HandleChangeForm('celular', FormatCelular(e.target.value))}
                        value={FormatCelular(formValues?.celular)}
                        fullWidth
                        label="Celular"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <WhatsAppIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        onChange={(e) => HandleChangeForm('telefone', FormatTelefone(e.target.value))}
                        value={FormatTelefone(formValues?.telefone)}
                        fullWidth
                        label="Telefone" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        error={!!formErrors.email}
                        required
                        onChange={(e) => HandleChangeForm('email', e.target.value)}
                        value={formValues?.email}
                        fullWidth
                        label="E-mail"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>

                <Grid item xs={12} sm={4} style={{ marginBottom: '10px' }}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }}>Dados pagamento</InputLabel>
                        <Select
                            error={!!formErrors.opc_pagamento}
                            variant="outlined"
                            onChange={(e) => HandleChangeForm('opc_pagamento', e.target.value)}
                            value={formValues?.opc_pagamento || ''}
                        >
                            <MenuItem value="1">Pessoa Física</MenuItem>
                            <MenuItem value="2">Pessoa Jurídica</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {formValues?.opc_pagamento === "2" && (
                    <>
                        <Grid item xs={12} sm={4} style={{ marginBottom: '10px' }}>
                            <FormControl fullWidth>
                                <InputLabel style={{ marginLeft: '13px' }}>Razão Social</InputLabel>
                                <Select
                                    variant="outlined"
                                    error={!!formErrors.razao_social}
                                    onChange={(e) => HandleChangeForm('razao_social', e.target.value)}
                                    value={formValues?.razao_social}
                                >
                                    <MenuItem value="prefeitura_municipal">Prefeitura Municipal</MenuItem>
                                    <MenuItem value="camara_municipal">Câmara Municipal</MenuItem>
                                    <MenuItem value="consorcios_publicos">Consórcios Públicos</MenuItem>
                                    <MenuItem value="municipio">Município</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                error={!!formErrors.cnpj}
                                variant="outlined"
                                value={FormatCNPJ(formValues?.cnpj)}
                                onChange={(e) => HandleChangeForm('cnpj', e.target.value)}
                                fullWidth
                                label="CNPJ"
                                inputProps={{ maxLength: 18 }}
                            />
                        </Grid>
                    </>
                )}

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }}>Atendimento especializado</InputLabel>
                        <Select
                            variant="outlined"
                            onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, necessita_atendimento_especializado: e.target.value })}
                            value={formValues?.PCD?.necessita_atendimento_especializado}
                        >
                            <MenuItem value="Sim">Sim</MenuItem>
                            <MenuItem value="Nao">Não</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>

                {formValues?.PCD?.necessita_atendimento_especializado === 'Sim' && (
                    <>
                        <Grid item xs={12} sm={4} style={{ marginTop: '5px', userSelect: 'none' }}>
                            Possui deficiência? (Lei nº13.146/2015)
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormGroup style={{ marginLeft: '10px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Fisica') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Fisica', e.target.checked)}
                                                    />
                                                }
                                                label="Física"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Visual') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Visual', e.target.checked)}
                                                    />
                                                }
                                                label="Visual"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Auditiva') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Auditiva', e.target.checked)}
                                                    />
                                                }
                                                label="Auditiva"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Intelectual') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Intelectual', e.target.checked)}
                                                    />
                                                }
                                                label="Intelectual"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Mental') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Mental', e.target.checked)}
                                                    />
                                                }
                                                label="Mental"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Outros') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Outros', e.target.checked)}
                                                    />
                                                }
                                                label="Outros"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>

                            </FormControl>
                        </Grid>

                        {formValues?.PCD?.tipo_deficiencias?.includes('Outros') && (
                            <Grid item xs={12} sm={4} style={{ userSelect: 'none' }}>
                                Outros. Qual?
                                <TextField
                                    variant="outlined"
                                    error={!!formErrors.outras_deficiencia}
                                    onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, outras_deficiencia: e.target.value })}
                                    value={formValues?.PCD?.outras_deficiencia || ''}
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={4} style={{ marginTop: '5px', userSelect: 'none' }}>
                            Possui dificuldade de locomoção?
                            <RadioGroup
                                row
                                aria-label="dificuldadeLocomocao"
                                name="dificuldadeLocomocao"
                                error={!!formErrors.dificuldade_locomocao}
                                style={{ marginLeft: '13px' }}
                                onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, dificuldade_locomocao: e.target.value })}
                                value={formValues?.PCD?.dificuldade_locomocao || ''}
                            >
                                <Grid item xs={12} sm={3}>

                                    <FormControlLabel
                                        value="Nao"
                                        control={<Radio color="primary" />}
                                        label="Não"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>

                                    <FormControlLabel
                                        value="Sim"
                                        control={<Radio color="primary" />}
                                        label="Sim"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <FormControlLabel
                                        value="Cadeirante"
                                        control={<Radio color="primary" />}
                                        label="Sou Cadeirante"
                                    />
                                </Grid>
                            </RadioGroup>
                        </Grid>

                        {((formValues?.PCD?.dificuldade_locomocao === 'Sim') || (formValues?.PCD?.dificuldade_locomocao === 'Cadeirante')) && (
                            <Grid item xs={12} sm={4} style={{ marginTop: '5px', userSelect: 'none' }}>
                                Precisa de acompanhamentos ou recurso de acessibilidade para participar do evento?
                                <RadioGroup
                                    row
                                    aria-label="acompanhamentoEvento"
                                    name="acompanhamentoEvento"
                                    style={{ marginLeft: '13px' }}
                                    onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, acompanhamento_acessibilidade: e.target.value })}
                                    value={formValues?.PCD?.acompanhamento_acessibilidade || ''}
                                >
                                    <Grid item xs={12} sm={3}>

                                        <FormControlLabel
                                            value="Nao"
                                            control={<Radio color="primary" />}
                                            label="Não"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>

                                        <FormControlLabel
                                            value="Sim"
                                            control={<Radio color="primary" />}
                                            label="Sim"
                                        />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        )}
                        {formValues?.PCD?.acompanhamento_acessibilidade === "Sim" && (
                            <Grid item xs={12} sm={4} style={{ userSelect: 'none' }}>
                                Sim. Qual?
                                <TextField
                                    error={!!formErrors.descricao_acompanhamento_acessibilidade}
                                    variant="outlined"
                                    onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, descricao_acompanhamento_acessibilidade: e.target.value })}
                                    value={formValues?.PCD?.descricao_acompanhamento_acessibilidade || ''}
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>
                        )}
                    </>
                )}

            </Grid>
            <Divider />

            {(isCaixa || isAdmin || hasPermission('gerenciar-inscricoes', 'escrita')) && !(getStatusLabel() === 'Pago' || getStatusLabel() === 'Isento') && !(formValues?.cortesia === 1 || formValues?.aprovado === true) && (formValues?.tipo_inscricao === 1) ? (
                <Caixas
                    CalculoDeValores={CalculoDeValores(formValues?.cargo, formValues?.status_contribuicao, formValues?.uf)}
                    handlePagamentosChange={handlePagamentosChange}
                    handleSaveUser={handleSaveUser}
                    isEdition={false}
                    dontShowButtons={false}
                    // handleOpenModalObservacao={handleOpenModalObservacao}
                    isAdmin={isAdmin || hasPermission('gerenciar-inscricoes', 'escrita')}
                    isPrefeito={[3, 16].includes(formValues?.cargo)}
                    dadosMunicipio={dadosMunicipio}
                    FormatCNPJ={FormatCNPJ}
                    showAlert={showAlert}
                />
            ) :
                <Grid item xs={12} sm={12}>
                    <Grid container justifyContent="center" mt={4} style={{ marginTop: '20px' }}>
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#47944a', color: '#FFFFFF' }} onClick={handleSaveUser}>
                            <SaveIcon /> Salvar
                        </Button>
                        {(dadosMunicipio?.termo_filiacao) && ([3, 16].includes(formValues?.cargo)) && (
                            <Button variant="contained" color="primary" style={{ marginLeft: '10px', color: 'white', textDecoration: 'none' }} href={dadosMunicipio?.termo_filiacao} target="_blank" >
                                <DescriptionIcon /> Abrir Termo de Filiação
                            </Button>
                        )}
                    </Grid>
                </Grid>
            }

        </Container>
    );
}

export default CadastroInterno;