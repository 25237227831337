import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
    Container,
    Grid,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TabelaCortesias from "./TabelaCortesias";
import ConstantInscricoes from "../constants/ConstantInscricoes";

const InscricoesList = () => {

    const {
        HandleSnackbarClose,
        showAlert,
        OcultarCPF,
        FormatCelular,
        FormatTelefone,
        HandleCheckboxChange,
        setOrder,
        setOrderBy,
        setInscricoes,
        setSearchTerm,
        resetForm,
        ajustaNomes,
        TransformFormData,
        CreateFormData,
        removerAcentos,
        hasPermission,
        verificaSituacao,
        isAdmin,
        cargo,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        inscricoes,
        orderBy,
        order,
        searchTerm,
    } = ConstantInscricoes();

    const handleSort = (columnName) => {
        if (orderBy === columnName) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(columnName);
            setOrder('asc');
        }
    };

    const sortedAndFilteredUsers = inscricoes
        .filter((inscricao) => {
            const termoSemAcento = removerAcentos(searchTerm.toLowerCase());

            return (
                removerAcentos(inscricao?.nome).toLowerCase().includes(termoSemAcento) ||
                removerAcentos(inscricao?.cpf).toLowerCase().includes(termoSemAcento) ||
                removerAcentos(inscricao?.tipo_inscricao_descricao).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.tipo_cargo_descricao).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.cargo_descricao).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.aprovado).toLowerCase().includes(termoSemAcento) ||
                removerAcentos(inscricao?.status).toLowerCase().includes(termoSemAcento)
            );
        })
        .sort((a, b) => {
            const isAsc = order === 'asc';
            if (orderBy === 'nome') {
                return removerAcentos(a.nome).localeCompare(removerAcentos(b.nome)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'cpf') {
                return removerAcentos(a.cpf).localeCompare(removerAcentos(b.cpf)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'aprovado') {
                return removerAcentos(a.aprovado).localeCompare(removerAcentos(b.aprovado)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'status') {
                return removerAcentos(a.situacao).localeCompare(removerAcentos(b.situacao)) * (isAsc ? 1 : -1);
            } else {
                return 0;
            }
        });

    return (
        <React.Fragment>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Grid item xs={12} sm={12}>
                <Container style={{ marginTop: '20px' }}>
                    <TabelaCortesias
                        cargo={cargo}
                        searchTerm={searchTerm}
                        orderBy={orderBy}
                        order={order}
                        sortedAndFilteredUsers={sortedAndFilteredUsers}
                        inscricoes={inscricoes}
                        ajustaNomes={ajustaNomes}
                        showAlert={showAlert}
                        resetForm={resetForm}
                        HandleCheckboxChange={HandleCheckboxChange}
                        FormatCelular={FormatCelular}
                        FormatTelefone={FormatTelefone}
                        OcultarCPF={OcultarCPF}
                        setSearchTerm={setSearchTerm}
                        handleSort={handleSort}
                        TransformFormData={TransformFormData}
                        CreateFormData={CreateFormData}
                        setInscricoes={setInscricoes}
                        hasPermission={hasPermission}
                        verificaSituacao={verificaSituacao}
                        isAdmin={isAdmin}
                    />
                </Container>
            </Grid>
        </React.Fragment>
    );
};

export default InscricoesList;
