import React from 'react';
import {
    Grid,
    Typography,
    Box,
} from '@material-ui/core';
import barrasImage from '../images/barras.png';
import headerImage from '../images/header.png';

function Cracha({ formValues }) {
    return (
        <Grid item xs={12} sm={4} style={{userSelect: 'none'}}>
            <Box className="cracha">
                <Box className="buraco"></Box>
                <Box className="corpo">
                    <Box className="header mt-3">
                        <img src={headerImage} draggable={false} style={{ marginTop: '25px', width: '250px', height: '250px' }} className="d-inline-block align-top" alt="" />
                    </Box>
                    <Box className="footer">
                        <Typography variant="h6" id="nomeCard" className="nomeCard">
                            {formValues?.nome_cracha || 'Nome'}
                        </Typography>
                        {formValues?.tipo_cargo !== 122 && (
                            <>
                                <Typography variant="h6" id="municipioUfCard" className="municipioUfCard" >
                                    {formValues?.nome_municipio || 'Municipio'} / {formValues?.uf || 'UF'}
                                </Typography>
                            </>
                        )}
                        <Box>
                            <img src={barrasImage} draggable={false} width="150px" height="75px" className="d-inline-block align-top" alt="" />
                        </Box>
                    </Box>
                    <Typography style={{ marginTop: '25px' }} variant="h5" className="cargo" id="cargoCard">
                        {formValues?.nome_cargo || 'Cargo'}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
}

export default Cracha;