import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import Divider from '@mui/material/Divider';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import Pix from '@mui/icons-material/Pix';

function TipoPagamento({ formValues, formErrors, HandleChangeForm }) {
    return (
        <Grid container spacing={3} component="main" style={{ userSelect: 'none' }}>
            <Grid item xs={12} sm={8}>
                <Card>
                    <CardContent>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Tipo de pagamento
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant={formValues.tipo_pagamento === 'boleto' ? 'contained' : 'outlined'}
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        required
                                        error={!!formErrors.tipo_pagamento}
                                        style={{ borderRadius: '20px' }}
                                        startIcon={<ViewWeekIcon />}
                                        onClick={(e) => HandleChangeForm('tipo_pagamento', 'boleto')}
                                    >
                                        Boleto
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant={formValues.tipo_pagamento === 'pix' ? 'contained' : 'outlined'}
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        required
                                        disabled
                                        error={!!formErrors.tipo_pagamento}
                                        style={{ borderRadius: '20px' }}
                                        startIcon={<Pix />}
                                        // onClick={(e) => HandleChangeForm('tipo_pagamento', 'pix')}
                                    >
                                        Pix
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default TipoPagamento;