import React, { useState, useEffect } from "react";
import Navbar from "../../components/AuthenticatedNavbar";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import ResponsiveCards from '../../components/ResponsiveCards'
import GerenciamentoInscricoes from "../../components/GerenInscricoes";
import GerenciamentoCortesias from "../../components/GerenCortesias";
import EmailsEmLote from "../../components/EmailsEmLote";
import GerenciamentoAutoridades from "../../components/GerenAutoridades";
import Graficos from "../../components/Graficos";
import FluxoDeCaixa from "../../components/GerenFluxoDeCaixa";
// import Caixas from "../../components/Caixas";
import ConstantInscricoes from "../../constants/ConstantInscricoes";
import {
    Grid,
    Box,
    Container
} from '@material-ui/core';
import { Funcionalidades } from "../../constants/Funcionalidades";
import GerenciarEntradaSaida from "../../components/GerenciarEntradaSaida";
import ConsultarArenas from "../../components/ConsultarArenas";
import MeuCaixa from "../../components/MeuCaixa";
// import Footer from '../../components/Footer';

const DashBoard = () => {
    const [funcionalidade, setFuncionalidade] = useState('');

    const {
        token,
    } = ConstantInscricoes({ dontLoadAll: true });

    const tiers = Funcionalidades(setFuncionalidade);

    useEffect(() => {
        const userCred = JSON.parse(localStorage.getItem('user_credmarcha'));

        if (userCred && userCred.perfil) {
            const temPermissaoCaixas = userCred?.perfil?.permissoes?.includes('caixas:leitura') || userCred?.perfil?.permissoes?.includes('caixas:escrita');

            if (temPermissaoCaixas) {
                setFuncionalidade(1);
            }
        } else {
            console.log('Não foi possível encontrar as credenciais do usuário.');
        }
    }, []);

    return (
        <>
            {token && (
                <React.Fragment>
                    <Navbar setFuncionalidade={setFuncionalidade} />
                    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                    <CssBaseline />
                    {funcionalidade === '' &&
                        <Container disableGutters xs={12} sm={12} sx={{ pt: 5 }}>
                            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={12}>
                                        <ResponsiveCards props={tiers} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    }
                    {funcionalidade === 1 && <GerenciamentoInscricoes />}

                    {funcionalidade === 2 && <Graficos />}

                    {funcionalidade === 3 && <FluxoDeCaixa />}

                    {funcionalidade === 4 && <GerenciamentoCortesias />}

                    {funcionalidade === 5 && <EmailsEmLote />}

                    {funcionalidade === 6 && <GerenciamentoAutoridades />}

                    {funcionalidade === 7 && <GerenciarEntradaSaida />}

                    {funcionalidade === 8 && <ConsultarArenas />}

                    {funcionalidade === 9 && <MeuCaixa />}

                    {funcionalidade === 10 && <GerenciamentoInscricoes />}

                    {
                        [11].includes(funcionalidade) && (
                            <>
                                {alert('Em desenvolvimento')}
                                {setFuncionalidade('')}
                            </>
                        )
                    }

                </React.Fragment >
            )}
            {/* <Footer /> */}
        </>
    );
}

export default DashBoard;
