import React, { useState, useEffect } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import api from '../service/Api';
import {
    TextField,
    FormControl,
    MenuItem,
    InputAdornment,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    Checkbox,
    Container,
    Button
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ConstantInscricoes from "../constants/ConstantInscricoes";
import SearchIcon from '@material-ui/icons/Search';
import LoadingOverlay from '../components/LoadingOverlay';

const EmailsEmLote = () => {
    const [inscricoesHomologadas, setInscricoesHomologadas] = useState([]);
    const [inscricoesNaoHomologadas, setInscricoesNaoHomologadas] = useState([]);
    const [mostrarHomologados, setMostrarHomologados] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]);
    const [sendEmailDisabled, setSendEmailDisabled] = useState(true);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const [isLoading, setIsLoading] = useState(false);
    const {
        HandleSnackbarClose,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        searchTerm,
        orderBy,
        order,
        isAdmin,
        OcultarCPF,
        setOrder,
        setOrderBy,
        removerAcentos,
        setSearchTerm,
        showAlert,
        hasPermission
    } = ConstantInscricoes({ dontLoadAll: true });

    const handleSort = (columnName) => {
        if (orderBy === columnName) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(columnName);
            setOrder('asc');
        }
    };

    const handleCheckboxChange = (id) => {
        const newSelectedRows = [...selectedRows];
        const selectedIndex = newSelectedRows.indexOf(id);

        if (selectedIndex === -1) {
            newSelectedRows.push(id);
        } else {
            newSelectedRows.splice(selectedIndex, 1);
        }

        setSelectedRows(newSelectedRows);
        setSendEmailDisabled(newSelectedRows.length === 0);
    };

    const handleSelectAllClick = () => {
        const allIds = (mostrarHomologados ? inscricoesHomologadas : inscricoesNaoHomologadas).map((inscricao) => inscricao.id);
        const newSelectedRows = selectedRows.length === allIds.length ? [] : allIds;
        setSelectedRows(newSelectedRows);
        setSendEmailDisabled(newSelectedRows.length === 0);
    };

    const sortedAndFilteredUsers = (mostrarHomologados ? inscricoesHomologadas : inscricoesNaoHomologadas)
        .filter((inscricao) => {
            const termoSemAcento = removerAcentos(searchTerm.toLowerCase());

            return (
                removerAcentos(inscricao?.nome).toLowerCase().includes(termoSemAcento) ||
                removerAcentos(inscricao?.cpf).toLowerCase().includes(termoSemAcento) ||
                removerAcentos(inscricao?.cargo_descricao).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.aprovado).toLowerCase().includes(termoSemAcento) ||
                removerAcentos(inscricao?.status).toLowerCase().includes(termoSemAcento)
            );
        })
        .sort((a, b) => {
            const isAsc = order === 'asc';
            if (orderBy === 'nome') {
                return removerAcentos(a.nome).localeCompare(removerAcentos(b.nome)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'cpf') {
                return removerAcentos(a.cpf).localeCompare(removerAcentos(b.cpf)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'cargo') {
                return removerAcentos(a.cargo_descricao).localeCompare(removerAcentos(b.cargo_descricao)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'aprovado') {
                return removerAcentos(a.aprovado).localeCompare(removerAcentos(b.aprovado)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'status') {
                return removerAcentos(a.situacao).localeCompare(removerAcentos(b.situacao)) * (isAsc ? 1 : -1);
            } else {
                return 0;
            }
        });

    const displayedUsers = sortedAndFilteredUsers.slice(startIndex, endIndex);

    const handleSendEmail = async () => {
        const totalRows = mostrarHomologados ? inscricoesHomologadas.length : inscricoesNaoHomologadas.length;

        if (mostrarHomologados) {
            const payload =
                (selectedRows.length > 0 && selectedRows.length < totalRows)
                    ? { lembrete: { id: selectedRows, filtros: [] } }
                    : { lembrete: { id: [], filtros: { "aprovado": 1 } } };

            try {
                setIsLoading(true);
                const response = await api.post('api/reenviarEmailLotes', payload);
                showAlert(response.data.reenviarEmail.boleto[0], 'success');
            } catch (error) {
                setIsLoading(false);
                showAlert("Ocorreu um erro ao enviar o(s) e-mail(s).", 'error');
                console.error('Erro ao enviar e-mail:', error);
            }
        } else {
            const payload =
                (selectedRows.length > 0 && selectedRows.length < totalRows)
                    ? { boleto: { id: selectedRows, filtros: {} } }
                    : { boleto: { id: [], filtros: { "aprovado": 0 } } }

            try {
                setIsLoading(true);
                const response = await api.post('api/reenviarEmailLotes', payload);
                showAlert(response.data.reenviarEmail.boleto[0], 'success');
            } catch (error) {
                setIsLoading(false);
                showAlert("Ocorreu um erro ao enviar o(s) e-mail(s).", 'error');
                console.error('Erro ao enviar e-mail:', error);
            }
        }

        setSelectedRows([]);
        setSendEmailDisabled(true);
        setIsLoading(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('api/cadastroUser');
                const cadastros = response.data.getCadastroUser;

                const inscricoes = cadastros.map(inscricao => ({
                    ...inscricao,
                    aprovado: inscricao.aprovado === true ? 'Homologado' : 'Não homologado',
                }));

                const homologados = inscricoes.filter(item => item.aprovado === 'Homologado');
                const naoHomologados = inscricoes.filter(item => item.aprovado === 'Não homologado' && item.boleto_id);

                setInscricoesHomologadas(homologados);
                setInscricoesNaoHomologadas(naoHomologados);
            } catch (error) {
                console.log(error);
                showAlert("Ocorreu um erro ao buscar os inscritos. Por favor, tente novamente mais tarde", 'error');
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setPage(0);
        setSelectedRows([]);
        setSendEmailDisabled(true);
    }, [mostrarHomologados]);

    return (
        <React.Fragment>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            {isLoading && <LoadingOverlay />}
            <Container style={{ marginTop: '30px' }}>
                <Paper>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Tipo de Inscritos"
                                    variant="outlined"
                                    value={mostrarHomologados ? 'homologados' : 'naoHomologados'}
                                    style={{ marginTop: '10px', marginLeft: '10px' }}
                                    onChange={(e) => {
                                        setMostrarHomologados(e.target.value === 'homologados');
                                    }}
                                >
                                    <MenuItem value="homologados">Homologados</MenuItem>
                                    <MenuItem value="naoHomologados">Não Homologados</MenuItem>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {isAdmin || hasPermission('e-mail', 'escrita') ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSendEmail}
                                    disabled={sendEmailDisabled}
                                    style={{ marginTop: '20px' }}
                                >
                                    Enviar E-mail
                                </Button>
                            ) : null}
                        </Grid>
                        <Grid item alignItems="center" justifyContent="space-between" xs={12} sm={3}>
                            <Grid item>
                                <TextField
                                    label="Pesquisar"
                                    variant="outlined"
                                    value={searchTerm}
                                    style={{ marginTop: '10px' }}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {isAdmin || hasPermission('e-mail', 'escrita') ? (
                                        <TableCell>
                                            <Checkbox
                                                indeterminate={selectedRows.length > 0 && selectedRows.length < (mostrarHomologados ? inscricoesHomologadas : inscricoesNaoHomologadas).length}
                                                checked={(selectedRows.length === (mostrarHomologados ? inscricoesHomologadas : inscricoesNaoHomologadas).length)}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                    ) : null}
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'nome'}
                                            direction={orderBy === 'nome' ? order : 'asc'}
                                            onClick={() => handleSort('nome')}
                                        >
                                            Nome
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'cpf'}
                                            direction={orderBy === 'cpf' ? order : 'asc'}
                                            onClick={() => handleSort('cpf')}
                                        >
                                            CPF
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'cargo'}
                                            direction={orderBy === 'cargo' ? order : 'asc'}
                                            onClick={() => handleSort('cargo')}
                                        >
                                            Cargo
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'aprovado'}
                                            direction={orderBy === 'aprovado' ? order : 'asc'}
                                            onClick={() => handleSort('aprovado')}
                                        >
                                            Status
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayedUsers.map((inscricao) => (
                                    <TableRow key={inscricao.id}>
                                        {isAdmin || hasPermission('e-mail', 'escrita') ? (
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedRows.indexOf(inscricao.id) !== -1}
                                                    onChange={() => handleCheckboxChange(inscricao.id)}
                                                />
                                            </TableCell>
                                        ) : null}
                                        <TableCell>{inscricao.nome}</TableCell>
                                        <TableCell>{OcultarCPF(inscricao.cpf)}</TableCell>
                                        <TableCell>{inscricao.cargo_descricao}</TableCell>
                                        <TableCell>{inscricao.aprovado}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            count={mostrarHomologados ? inscricoesHomologadas.length : inscricoesNaoHomologadas.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                            labelRowsPerPage="Registros por página:"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count} (${selectedRows.length} selecionados)`}
                        />
                    </TableContainer>
                </Paper>
            </Container>
        </React.Fragment>
    );
};

export default EmailsEmLote;
