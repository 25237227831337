import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Grid,
    Divider,
    Button,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    TablePagination
} from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import CadastroAssessores from './CadastroAssessores';
import api from '../service/Api';
import CustomModal from './CustomModal';
import { useReactToPrint } from 'react-to-print';
import Etiqueta from './Etiqueta';

const GerenAssessores = ({ setFuncionalidade, ufs, HandleSearch, inscricaoSelecionada, HandleSnackbarClose, hasPermission, FormatCPF, removerAcentos, showAlert, setOrder, setOrderBy, setInscricaoSelecionadaId, order, orderBy, snackbarOpen, snackbarMessage, snackbarSeverity, isAdmin, inscricaoSelecionadaId, searchTerm, naturezas, FormatCelular, formValues, HandleChangeForm, setFormValues, resetForm, tipoCargo, cargo }) => {

    const handleVoltar = () => {
        HandleSearch();
        setFuncionalidade('')
        inscricaoSelecionada('');
    };

    const handleCriarAssessor = () => {
        setTelaVincularAssessores(1);
        setDadosAssessor('')
    };

    const [telaVincularAssessores, setTelaVincularAssessores] = useState('');
    const [desabilitarBotao, setDesabilitarBotao] = useState(false);
    const [openModalExcluirCadastro, setOpenModalExcluirCadastro] = useState(false);
    const [assessores, setAssessores] = useState([]);
    const [dadosAssessor, setDadosAssessor] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const etiquetaRef = useRef();
    const [selectedUser, setSelectedUser] = useState(null);

    const handlePrintClick = (user) => {
        setSelectedUser(user);
        setTimeout(() => {
            imprimirEtiqueta();
            setSelectedUser(null);
        }, 1000);

        const autoridadeId = user.id

        try {
            api.post(`api/controleAcesso?autoridade_id=${autoridadeId}&tipo_registro=etiqueta`);
        } catch (error) {
            console.error('Erro ao utilizar o controle de acessos: ', error)
        }

    };

    const imprimirEtiqueta = useReactToPrint({
        content: () => etiquetaRef.current
    });

    const handleSort = (columnName) => {
        if (orderBy === columnName) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(columnName);
            setOrder('asc');
        }
    };

    const handleOpenModalExcluirUsuario = (idExclusao) => {
        setInscricaoSelecionadaId(idExclusao)
        setOpenModalExcluirCadastro(true);
    };

    const handleCloseModalExcluirUsuario = () => {
        setOpenModalExcluirCadastro(false);
    };

    const sortedAndFilteredUsers = assessores
        .filter((inscricao) => {
            return (
                removerAcentos(inscricao?.nome).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.cpf).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.email).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.celular).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.cargo).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase()))
            );
        })
        .sort((a, b) => {
            const isAsc = order === 'asc';
            if (orderBy === 'nome') {
                return removerAcentos(a.nome).localeCompare(removerAcentos(b.nome)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'cpf') {
                return removerAcentos(a.cpf).localeCompare(removerAcentos(b.cpf)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'email') {
                return removerAcentos(a.email).localeCompare(removerAcentos(b.email)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'celular') {
                return removerAcentos(a.celular).localeCompare(removerAcentos(b.celular)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'cargo') {
                return removerAcentos(a.cargo).localeCompare(removerAcentos(b.cargo)) * (isAsc ? 1 : -1);
            } else {
                return 0;
            }
        });

    const displayedUsers = sortedAndFilteredUsers.slice(startIndex, endIndex);

    const handleEditarAssessor = (inscricao) => {
        setTelaVincularAssessores(1);
        setDadosAssessor(inscricao);
    };

    const handleDeleteAssessor = async () => {
        if (inscricaoSelecionadaId) {
            try {
                setDesabilitarBotao(true);
                const response = await api.delete(`api/assessor/${inscricaoSelecionadaId}`);
                if (response?.status === 200) {
                    handleCloseModalExcluirUsuario();
                    setDesabilitarBotao(false);
                    showAlert("Cadastro excluido com sucesso", 'success');
                }
                fetchAssessores();
            } catch (error) {
                setDesabilitarBotao(false);
                console.error('Ocorreu um erro ao realizar a exclusão. Por favor, tente novamente mais tarde', error);
            }
        }
    };

    const fetchAssessores = async () => {
        try {
            const response = await api.get(`api/getAutoridadeEspecifica?id=${inscricaoSelecionada?.id}`);
            setAssessores(response?.data?.assessores);
        } catch (error) {
            console.error('Erro ao obter os dados.', error);
        }
    };

    useEffect(() => {
        fetchAssessores();
        // eslint-disable-next-line
    }, [inscricaoSelecionada?.id]);

    return (
        <Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>

            {!telaVincularAssessores && (
                <>
                    <Button style={{ marginTop: '10px' }} variant="contained" onClick={handleVoltar}>Voltar</Button>
                    <Button style={{ marginTop: '10px', marginLeft: '10px' }} variant="contained" color="primary" onClick={handleCriarAssessor}>Cadastrar Assessor</Button>

                    {inscricaoSelecionada && (
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px', marginTop: '10px' }}>
                            <Typography variant="h5" gutterBottom>
                                Detalhes da Autoridade
                            </Typography>
                            <Divider style={{ marginBottom: '10px' }} />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="body1">
                                        <span style={{ fontWeight: 'bold' }}>Nome:</span> {inscricaoSelecionada?.nome ?? 'Não informado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="body1">
                                        <span style={{ fontWeight: 'bold' }}>CPF:</span> {inscricaoSelecionada?.cpf ? FormatCPF(inscricaoSelecionada?.cpf) : 'Não informado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="body1">
                                        <span style={{ fontWeight: 'bold' }}>E-mail:</span> {inscricaoSelecionada?.email ?? 'Não informado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="body1">
                                        <span style={{ fontWeight: 'bold' }}>Celular:</span> {inscricaoSelecionada?.celular ?? 'Não informado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="body1">
                                        <span style={{ fontWeight: 'bold' }}>UF:</span> {inscricaoSelecionada?.NOME_UF ?? 'Não informado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="body1">
                                        <span style={{ fontWeight: 'bold' }}>Partido:</span> {inscricaoSelecionada?.partido ?? 'Não informado'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="body1">
                                        <span style={{ fontWeight: 'bold' }}>Cargo:</span> {inscricaoSelecionada?.cargo_descricao ?? 'Não informado'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    <Typography variant="h5" gutterBottom>
                        Assessores
                    </Typography>
                    <TableContainer style={{ marginTop: '10px' }} component={Paper} className="custom-table">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <TableSortLabel
                                            active={orderBy === 'nome'}
                                            direction={orderBy === 'nome' ? order : 'asc'}
                                            onClick={() => handleSort('nome')}
                                        >
                                            Nome
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '150px', textAlign: 'center' }}>
                                        <TableSortLabel
                                            active={orderBy === 'cpf'}
                                            direction={orderBy === 'cpf' ? order : 'asc'}
                                            onClick={() => handleSort('cpf')}
                                        >
                                            CPF
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <TableSortLabel
                                            active={orderBy === 'municipioUf'}
                                            direction={orderBy === 'municipioUf' ? order : 'asc'}
                                            onClick={() => handleSort('municipioUf')}
                                        >
                                            E-mail
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <TableSortLabel
                                            active={orderBy === 'aprovado'}
                                            direction={orderBy === 'aprovado' ? order : 'asc'}
                                            onClick={() => handleSort('aprovado')}
                                        >
                                            Celular
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <TableSortLabel
                                            active={orderBy === 'status'}
                                            direction={orderBy === 'status' ? order : 'asc'}
                                            onClick={() => handleSort('status')}
                                        >
                                            Cargo
                                        </TableSortLabel>
                                    </TableCell>
                                    {isAdmin || hasPermission('gerenciar-inscricoes', 'escrita') ? (
                                        <>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                Ações
                                            </TableCell>
                                        </>
                                    ) : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {displayedUsers?.map((assessor) => (
                                    <TableRow key={assessor.id}>
                                        <TableCell style={{ textAlign: 'center' }}>{assessor.nome}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{assessor.cpf ? FormatCPF(assessor.cpf) : 'Não informado'}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{assessor.email ?? 'Não informado'}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{assessor.celular ?? 'Não informado'}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{assessor.cargo_descricao ?? 'Não informado'}</TableCell>
                                        <TableCell width={230}>
                                            <div>
                                                <Button style={{ color: '#007bff' }}  onClick={() => handlePrintClick(assessor)}>
                                                    <PrintIcon />
                                                </Button>
                                                <Button style={{ color: '#007bff' }} onClick={() => handleEditarAssessor(assessor)}>
                                                    <EditIcon />
                                                </Button>
                                                <Button style={{ color: 'red' }} onClick={() => handleOpenModalExcluirUsuario(assessor.id)}>
                                                    <DeleteIcon />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <div style={{ display: 'none' }}>
                                    {selectedUser && (
                                        <>
                                            <Etiqueta
                                                nome={selectedUser.nome.toUpperCase()}
                                                uf={inscricaoSelecionada?.NOME_UF}
                                                empresa_instituicao={selectedUser.cargo === '43' || selectedUser.cargo === '32' || selectedUser.cargo === '33' || selectedUser.cargo === '34' ||  selectedUser.cargo === '35' || selectedUser.cargo === '37' || selectedUser.cargo === '38' || selectedUser.cargo === '74' || selectedUser.cargo === '79' || selectedUser.cargo === '92' || selectedUser.cargo === '93' || selectedUser.cargo === '94' || selectedUser.cargo === '95' || selectedUser.cargo === '96' || selectedUser.cargo === '116' || selectedUser.cargo === '133'   ? selectedUser.ministerio_orgao : ''}
                                                cargo={selectedUser.cargo === '14' || selectedUser.cargo === '19' || selectedUser.cargo === '25' || selectedUser.cargo === '38' || selectedUser.cargo === '43' || selectedUser.cargo === '48' || selectedUser.cargo === '54' || selectedUser.cargo === '60' || selectedUser.cargo === '67' || selectedUser.cargo === '79' || selectedUser.cargo === '85' || selectedUser.cargo === '90' || selectedUser.cargo === '96' || selectedUser.cargo === '101' || selectedUser.cargo === '107' || selectedUser.cargo === '114' || selectedUser.cargo === '121' || selectedUser.cargo === '131' || selectedUser.cargo === '136' || selectedUser.cargo === '145' || selectedUser.cargo === '152' || selectedUser.cargo === '159' || selectedUser.cargo === '174' ? '' : selectedUser.cargo_descricao}
                                                complemento={selectedUser.cargo === '81' ? ' do TCE' : selectedUser.cargo === '28' ? ' da República' : selectedUser.cargo === '29' ? ' Presidente da República' : selectedUser.cargo === '31' ? ' do Brasil' : selectedUser.cargo === '51' ? ' do Senado Federal' : selectedUser.cargo === '52' ? ' Presidente do Senado Federal' : selectedUser.cargo === '56' ? ' da Câmara dos Deputados' : selectedUser.cargo === '57' ? ' Presidente da Câmara dos Deputados' : selectedUser.cargo === '71' ? ' Governador' : selectedUser.cargo === '62' ? ' Integrante do Judiciário Federal' : selectedUser.cargo === '63' ? ' Integrante do Judiciário Federal' : selectedUser.cargo === '64' ? ' Integrante do Judiciário Federal' : selectedUser.cargo === '65' ? ' Integrante do Judiciário Federal' : selectedUser.cargo === '66' ? ' Integrante do Judiciário Federal' : ''}
                                                partido={inscricaoSelecionada?.partido}
                                                tipo_cargo={selectedUser.cargo === '33' || selectedUser.cargo === '34' ||  selectedUser.cargo === '35' || selectedUser.cargo === '37' || selectedUser.cargo === '38' || selectedUser.cargo === '72' || selectedUser.cargo === '75' || selectedUser.cargo === '76' || selectedUser.cargo === '77' || selectedUser.cargo === '78' || selectedUser.cargo === '79' || selectedUser.cargo === '87' || selectedUser.cargo === '88' ? selectedUser.cargo_id : ''}
                                                ref={etiquetaRef}
                                                tipo_cargo_descricao={ selectedUser.cargo === '40' || selectedUser.cargo === '41' || selectedUser.cargo === '42' || selectedUser.cargo === '43' || selectedUser.cargo === '44' || selectedUser.cargo === '45' || selectedUser.cargo === '46' || selectedUser.cargo === '47' || selectedUser.cargo === '48' || selectedUser.cargo === '82' || selectedUser.cargo === '83' || selectedUser.cargo === '84' || selectedUser.cargo === '85' || selectedUser.cargo === '87' || selectedUser.cargo === '88' || selectedUser.cargo === '89' || selectedUser.cargo === '101' || selectedUser.cargo === '99' ? selectedUser.tipo_cargo_descricao : ''}
                                                parlamentar={inscricaoSelecionada?.nome}
                                            />
                                        </>
                                    )}
                                </div>
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            count={sortedAndFilteredUsers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                            labelRowsPerPage="Registros por página:"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                        />
                    </TableContainer>
                </>
            )}

            {telaVincularAssessores === 1 && (
                <CadastroAssessores
                    setTelaVincularAssessores={setTelaVincularAssessores}
                    fetchAssessores={fetchAssessores}
                    autoridade={inscricaoSelecionada}
                    dadosAssessor={dadosAssessor}
                    showAlert={showAlert}
                    FormatCPF={FormatCPF}
                    FormatCelular={FormatCelular}
                    HandleChangeForm={HandleChangeForm}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    resetForm={resetForm}
                    ufs={ufs}
                    naturezas={naturezas}
                />
            )}

            <CustomModal
                open={openModalExcluirCadastro}
                onClose={handleCloseModalExcluirUsuario}
                title="Confirmar Operação"
                content="Tem certeza que deseja excluir este assessor? ESTA OPERAÇÃO É IRREVERSÍVEL"
                onCancel={handleCloseModalExcluirUsuario}
                onConfirm={handleDeleteAssessor}
                disabled={desabilitarBotao}
            />
        </Container >
    );
}

export default GerenAssessores;