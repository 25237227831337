export default class AuthApiContatos {
    constructor({ systemId, serverUrl, loggedHome, tokenKey, userKey }) {
        if (!systemId) alert('System ID is required.');
        if (!serverUrl) alert('Server URL is required.');

        this.systemId = systemId;
        this.serverUrl = serverUrl;
        this.loggedHome = loggedHome || '/';
        this.tokenKey = tokenKey || 'tokenApiContatos';
        this.userKey = userKey || 'userApiContatos';

        this.origin = window.location.origin;
    }

    get token() {
        return window.localStorage[this.tokenKey] || null;
    }

    set token(value) {
        window.localStorage[this.tokenKey] = value;

        return this.token;
    }

    get user() {
        let user = window.localStorage[this.userKey];

        if (user) {
            return JSON.parse(user);
        }

        return null;
    }

    set user(value) {
        window.localStorage[this.userKey] = JSON.stringify(value);

        return this.user;
    }

    get check() {
        return !!(this.token && this.user);
    }

    get isAdmin() {
        return this.user.perfil.is_admin;
    }

    get permissions() {
        return this.user.perfil.permissions || [];
    }

    init() {
        let urlParams = new URLSearchParams(window.location.search);

        let redirect_to = urlParams.get('redirect_to') || '';
        let token_type = urlParams.get('token_type');
        let access_token = urlParams.get('access_token') || '';

        if (redirect_to === this.origin && access_token) {
            this.token = `${token_type || 'Bearer'} ${access_token}`;

            this._loadUser();
        } else if (this.token && !this.user) {
            this._loadUser();
        }
    }

    login() {
        if (!this.token) {
            window.location.href = `${this.serverUrl}/auth/login?systemId=${this.systemId}`;
            return;
        }

        if (!this.user) {
            this._loadUser();
            return;
        }

        window.location.href = this.loggedHome;
    }

    logout() {
        fetch(`${this.serverUrl}/api/auth/logout`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': this.token
            })
        }).then(() => this._revokeToken());
    }

    passwordReset(email) {
        fetch(`${this.serverUrl}/api/auth/password/create`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }),
            body: JSON.stringify({ email, codigo_sistema: this.systemId })
        }).then(response => {
            response.json()
                .then(json => {
                    alert(json.message);
                });
        });
    }

    hasPermission(permissions) {
        if (!this.isAdmin) {
            if (Array.isArray(permissions)) {
                return permissions.some(permission => this.permissions.includes(permission));
            }

            return this.permissions.includes(permissions);
        }

        return true;
    }

    _loadUser() {
        fetch(`${this.serverUrl}/api/auth/user?codigo_sistema=${this.systemId}`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': this.token
            })
        }).then(response => {
            response.json()
                .then(json => {
                    if (response.status === 200) {
                        this.user = json.data;
                        window.location.href = this.loggedHome;
                    } else {
                        alert(`${json.message} Tente novamente mais tarde ou logue novamente.`);
                    }
                });
        });
    }

    _revokeToken() {
        window.localStorage.removeItem(this.tokenKey);
        window.localStorage.removeItem(this.userKey);
    }
}