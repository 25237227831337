import React from 'react';
import {
    FormControl,
    TextField,
    Grid,
    Typography,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Countries } from '../constants/Nation';
import Divider from '@mui/material/Divider';


function DadosInscricao({ formValues, naturezas, tipoCargo, cargo, consorcio, getEntidadeOpcoesPorTipoCargo, getEntidadeValorPorTipoCargo, getEntidadeNomeFormulario, getEntidadeLabelPorTipoCargo, parlamentar, partidoPolitico, areaDeAtuacao, setFormValues, HandleChangeForm, formErrors }) {
    return (
        <Grid container spacing={2} style={{ userSelect: 'none' }}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Dados da Inscrição
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl required fullWidth>
                    <InputLabel style={{ marginLeft: '13px' }} >Tipo de inscrição</InputLabel>
                    <Select
                        variant="outlined"
                        required
                        error={!!formErrors.tipo_inscricao}
                        value={formValues?.tipo_inscricao}
                        onChange={(e) => {
                            const tipoInscricaoSelecionado = e.target.value;
                            setFormValues({
                                ...formValues,
                                tipo_inscricao: tipoInscricaoSelecionado,
                                tipo_cargo: '',
                                cargo: '',
                                area_atuacao: '',
                                nome_cargo: '',
                                convidado: {
                                    ...formValues.convidado,
                                    cargo: '',
                                    convidado_de: '',
                                },
                                parlamentar: ''
                            });
                        }}
                        IconComponent={naturezas.length === 1 ? () => null : undefined}
                        disabled={naturezas.length === 1}
                    >
                        {naturezas?.map((natureza) => (
                            <MenuItem key={natureza.id} value={natureza.id}>
                                {natureza.descricao}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
                <FormControl required fullWidth>
                    <InputLabel style={{ marginLeft: '13px' }} >Tipo cargo</InputLabel>
                    <Select
                        variant="outlined"
                        required
                        error={!!formErrors.tipo_cargo}
                        disabled={!formValues.tipo_inscricao}
                        value={formValues?.tipo_cargo}
                        onChange={(e) => {
                            const tipoCargoSelecionado = e.target.value;
                            setFormValues({
                                ...formValues,
                                tipo_cargo: tipoCargoSelecionado,
                                cargo: '',
                                area_atuacao: '',
                                nome_cargo: '',
                                convidado: {
                                    ...formValues.convidado,
                                    cargo: '',
                                    convidado_de: '',
                                },
                            });
                        }}
                    >
                        {tipoCargo.map((tipo) => (
                            <MenuItem key={tipo.id} value={tipo.id}>
                                {tipo.descricao}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl required fullWidth>
                    {formValues?.tipo_cargo === 132 ?
                        <>
                            <TextField
                                variant="outlined"
                                required
                                error={!!formErrors?.convidadoCargo}
                                fullWidth
                                id="convidadoCargo"
                                label="Cargo"
                                name="convidadoCargo"
                                autoComplete="convidadoCargo"
                                value={formValues?.convidado?.cargo || ''}
                                onChange={(e) => {
                                    const novoCargo = e.target.value;
                                    HandleChangeForm('cargo', formValues?.tipo_cargo);
                                    HandleChangeForm('convidado', { ...formValues.convidado, cargo: novoCargo });
                                    HandleChangeForm('nome_cargo', novoCargo);
                                }}
                                inputProps={{ maxLength: 255 }}
                            />
                        </>
                        :
                        <>
                            <InputLabel style={{ marginLeft: '13px' }}>Cargo</InputLabel>
                            <Select
                                variant="outlined"
                                required
                                error={!!formErrors.cargo}
                                value={formValues?.cargo}
                                disabled={!formValues.tipo_cargo}
                                onChange={(e) => {
                                    const cargoSelecionado = e.target.value;
                                    const cargoSelecionadoDescricao = cargo.find((cargo) => cargo.id === cargoSelecionado)?.descricao || '';
                                    setFormValues({
                                        ...formValues,
                                        cargo: cargoSelecionado,
                                        nome_cargo: cargoSelecionadoDescricao,
                                        area_atuacao: '',
                                    });
                                }}
                            >
                                {cargo.map((cargo) => (
                                    <MenuItem key={cargo.id} value={cargo.id}>
                                        {cargo.descricao}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    }
                </FormControl>
            </Grid>

            {formValues?.tipo_inscricao === 26 && formValues?.tipo_cargo === 160 &&
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        error={!!formErrors.ministerio_orgao}
                        required
                        onChange={(e) => HandleChangeForm('ministerio_orgao', e.target.value)}
                        value={formValues?.ministerio_orgao}
                        fullWidth
                        label="Ministério"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>
            }

            {formValues?.tipo_inscricao === 137 &&
                <>
                    {[138, 146, 153].includes(formValues?.tipo_cargo) &&
                        <Grid item xs={12} sm={4}>
                            <FormControl required fullWidth>
                                <Autocomplete
                                    options={getEntidadeOpcoesPorTipoCargo(formValues?.tipo_cargo) || []}
                                    getOptionLabel={(entidade) => entidade.SIGLA + ' - ' + entidade.NOME_ENTIDADE || ''}
                                    value={getEntidadeValorPorTipoCargo(formValues, formValues.tipo_cargo) || null}
                                    onChange={(e, selectedEntidade) => {
                                        HandleChangeForm(getEntidadeNomeFormulario(formValues?.tipo_cargo), selectedEntidade?.id);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required
                                            error={!!formErrors.entidade}
                                            label={getEntidadeLabelPorTipoCargo(formValues?.tipo_cargo)}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    }

                    {formValues?.tipo_cargo === 15 &&
                        <Grid item xs={12} sm={4}>
                            <FormControl required fullWidth>
                                <Autocomplete
                                    options={consorcio || []}
                                    getOptionLabel={(consorcio) => consorcio.SIGLA + ' - ' + consorcio.NOME_ENTIDADE || ''}
                                    value={consorcio?.find((p) => p.id === formValues?.consorcio) || null}
                                    onChange={(e, selectedConsorcio) => {
                                        HandleChangeForm('consorcio', selectedConsorcio?.id);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            required
                                            error={!!formErrors.consorcio}
                                            label="Consorcio"

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    }
                </>
            }

            {(([26, 68].includes(formValues?.tipo_inscricao) && [49, 55, 97].includes(formValues?.tipo_cargo)) && (formValues?.cargo && ![50, 51, 52, 56, 57, 58, 98, 99].includes(formValues?.cargo))) &&
                <Grid item xs={12} sm={4}>
                    <FormControl required fullWidth>
                        <Autocomplete
                            options={parlamentar || []}
                            getOptionLabel={(parlamentar) => parlamentar.nome || ''}
                            value={parlamentar?.find((p) => p.id === formValues?.parlamentar) || null}
                            onChange={(e, selectedParlamentar) => {
                                HandleChangeForm('parlamentar', selectedParlamentar?.id || '', '', selectedParlamentar?.nome || '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required
                                    error={!!formErrors.parlamentar}
                                    label="Parlamentar"

                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            }

            {(([26, 68].includes(formValues?.tipo_inscricao) && [49, 55, 97].includes(formValues?.tipo_cargo)) && (formValues?.cargo && [50, 51, 52, 56, 57, 58, 98, 99].includes(formValues?.cargo))) &&
                <Grid item xs={12} sm={4}>
                    <FormControl required fullWidth>
                        <Autocomplete
                            options={partidoPolitico || []}
                            getOptionLabel={(partidoPolitico) => partidoPolitico.NOME + ' - ' + partidoPolitico.SIGLA || ''}
                            value={partidoPolitico?.find((p) => p.id === formValues?.partido_politico) || null}
                            onChange={(e, selectedPartidoPolitico) => {
                                HandleChangeForm('partido_politico', selectedPartidoPolitico?.id);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required
                                    error={!!formErrors.partido_politico}
                                    label="Partido Político"

                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            }
            {formValues?.tipo_cargo === 122 && (
                <Grid item xs={12} sm={4}>
                    <FormControl required fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }}>País</InputLabel>
                        <Select
                            variant="outlined"
                            required
                            error={!!formErrors.pais}
                            value={formValues?.pais}
                            onChange={(e) => HandleChangeForm('pais', e.target.value)}
                        >
                            {Countries?.map((countrie) => (
                                <MenuItem key={countrie.value} value={countrie.value}>
                                    {countrie.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
            {formValues?.cargo === 13 && (
                <Grid item xs={12} sm={4}>
                    <FormControl required fullWidth>
                        <Autocomplete
                            options={areaDeAtuacao}
                            getOptionLabel={(option) => option.AREA_ATUACAO}
                            value={areaDeAtuacao.find((area) => area.id === formValues?.area_atuacao) || null}
                            onChange={(e, newValue) => HandleChangeForm('area_atuacao', newValue?.id || '')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required
                                    error={!!formErrors.area_atuacao}
                                    label="Área de Atuação"
                                />
                            )}

                        />
                    </FormControl>
                </Grid>
            )}
            {formValues?.tipo_inscricao && formValues?.tipo_inscricao === 102 && (
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        error={!!formErrors.empresa_instituicao}
                        required
                        onChange={(e) => HandleChangeForm('empresa_instituicao', e.target.value)}
                        value={formValues?.empresa_instituicao}
                        fullWidth label="Empresa/Instituição"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>
            )}
            {formValues?.tipo_cargo === 132 && (
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        required
                        error={!!formErrors?.convidadoDe}
                        value={formValues?.convidado?.convidado_de || ''}
                        onChange={(e) => {
                            const convidadoDe = e.target.value;
                            HandleChangeForm('convidado', { ...formValues.convidado, convidado_de: convidadoDe });
                        }}
                        fullWidth
                        label="Convidado de"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default DadosInscricao;