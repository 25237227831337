import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  Button,
  FormControl,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ConstantInscricoes from "../constants/ConstantInscricoes";
import { registraControleAcesso } from "../service/controle-acesso";
import LoadingOverlay from "../components/LoadingOverlay";

export default function GerenciarEntradaSaida() {
  const {
    HandleSnackbarClose,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    showAlert,
  } = ConstantInscricoes();

  const [cpfUser, setCpfUser] = useState('');
  const [tipoRegistro, setTipoRegistro] = useState("entrada");
  const [novoRegistro, setNovoRegistro] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tiposRegistro = {
    entrada: {
      label: "Entrada",
      color: "success",
      backgroundColor: "#2e7d32",
    },
    saida: {
      label: "Saída",
      color: "error",
      backgroundColor: "#d32f2f",
    },
  };

  const FormatCPF = (value) => {
    if (value) {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
      return value;
    }

    return "";
  };

  const focusCpf = () => document.getElementById("cpf").focus();

  const enviarRegistro = () => {
    if (!cpfUser || cpfUser.length < 11) {
      return;
    }

    setIsLoading(true);

    registraControleAcesso({
      cpf: cpfUser.replaceAll(".", "").replaceAll("-", ""),
      tipo_registro: tipoRegistro,
    })
      .then((response) => {
        showAlert(response?.data?.mensagem, "success");
        setNovoRegistro(
          response?.data?.dadosControleAcesso?.ControleAcesso || []
        );
        setCpfUser('');
      })
      .catch((error) => {
        showAlert(error?.response?.data?.mensagem, "error");
        setNovoRegistro([]);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    focusCpf();
    setNovoRegistro([]);
  }, [tipoRegistro]);

  return (
    <React.Fragment>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={HandleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={HandleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Grid
        container
        item
        xs={12}
        sm={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={tipoRegistro}
            onChange={(event, value) => setTipoRegistro(value)}
          >
            <Tab label="Registro de Entrada" value="entrada" />
            <Tab label="Registro de Saída" value="saida" />
          </Tabs>
        </Grid>

        <Grid xs={12} sm={4} item>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              enviarRegistro();
            }}
          >
            <FormControl required fullWidth>
              <Typography variant="h6">
                Informe o CPF para realizar o registro de{" "}
                {tiposRegistro[tipoRegistro].label.toLowerCase()}
              </Typography>

              <TextField
                variant="outlined"
                required
                fullWidth
                id="cpf"
                label="CPF"
                name="cpf"
                autoComplete="cpf"
                value={FormatCPF(cpfUser)}
                onChange={(e) => setCpfUser(e.target.value)}
                onBlur={focusCpf}
                inputProps={{ maxLength: 14 }}
                style={{ marginTop: 20 }}
              />

              <Button
                type="submit"
                style={{ marginTop: 10 }}
                variant="contained"
                color={tiposRegistro[tipoRegistro].color}
              >
                Registrar {tiposRegistro[tipoRegistro].label}
              </Button>
            </FormControl>
          </form>

          {isLoading ? <LoadingOverlay /> : ""}

          {novoRegistro.length ? (
            <>
              <Typography variant="h6" style={{ marginTop: 20 }}>
                NOME: {novoRegistro[0].nome}
              </Typography>

              <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data/Hora</TableCell>
                      <TableCell align="center">Tipo</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {novoRegistro.map((registro) => (
                      <TableRow key={registro.id}>
                        <TableCell component="th" scope="row">
                          {registro.created_at_formatted}
                        </TableCell>

                        <TableCell
                          style={{
                            width: 100,
                            backgroundColor:
                              tiposRegistro[registro.tipo_registro]
                                .backgroundColor,
                            color: "#ffffff",
                          }}
                          align="center"
                        >
                          {tiposRegistro[
                            registro.tipo_registro
                          ].label.toUpperCase()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
