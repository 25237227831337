import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';

const InstrucoesEtiqueta = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Button onClick={onClose} sx={{ position: 'absolute', right: 5, color: 'red' }}>
        Fechar
      </Button>
      <DialogTitle>Instruções para impressão de etiqueta</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seu código está em suas mãos?
          <br />
          1. Faça a leitura no leitor abaixo
          <br /> <br />
          2. Clique em <PrintIcon /> e retire a sua etiqueta
          <br /> <br />
          Seu código não está em mãos?
          <br /><br />
          1. Compareça ao guichê da marcha
          <br /><br />
          2. Verifique sua caixa de e-mail, você receberá o link de validação de inscrição
          <br /><br />
          3. Após a validação clique em <PrintIcon /> e retire a sua etiqueta
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default InstrucoesEtiqueta;
