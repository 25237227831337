import React, { useState, useRef } from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Button,
    Select,
    MenuItem,
    FormControl,
    TextField,
    Grid,
    InputLabel,
    Autocomplete,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Paper,
    TableSortLabel,
    InputAdornment,
    Menu
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import api from '../service/Api';
import { useReactToPrint } from 'react-to-print';
import Etiqueta from './Etiqueta';

const FiltrosAutoridades = ({ filtrosAbertos, setFiltrosAbertos, formValues, setFormValues, naturezas, tipoCargo, cargo, vinculateAutoridades, HandleChangeForm, ufs, HandleSearch, searchTerm, setSearchTerm, resetForm, orderBy, order, handleSort, sortedAndFilteredUsers, isAdmin, hasPermission, createAutoridades, createAssessor, createInscricao, anchorEl, handleMenuClose, handleMenuItemClick, handleMenuOpen }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedUsers = sortedAndFilteredUsers.slice(startIndex, endIndex);
    const etiquetaRef = useRef();
    const [selectedUser, setSelectedUser] = useState(null);

    const handlePrintClick = (user) => {
        setSelectedUser(user);
        setTimeout(() => {
            imprimirEtiqueta();
            setSelectedUser(null);
        }, 1000);

        const autoridadeId = user.id

        try {
            api.post(`api/controleAcesso?autoridade_id=${autoridadeId}&tipo_registro=etiqueta`);
        } catch (error) {
            console.error('Erro ao utilizar o controle de acessos: ', error)
        }
    };

    const imprimirEtiqueta = useReactToPrint({
        content: () => etiquetaRef.current
    });

    return (
        <Paper>
            <Accordion expanded={filtrosAbertos} onChange={() => setFiltrosAbertos(!filtrosAbertos)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>Filtros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel >Tipo de inscrição</InputLabel>
                                <Select
                                    variant="outlined"
                                    value={formValues?.tipo_inscricao}
                                    onChange={(e) => {
                                        const tipoInscricaoSelecionado = e.target.value;
                                        setFormValues({
                                            ...formValues,
                                            tipo_inscricao: tipoInscricaoSelecionado,
                                            tipo_cargo: '',
                                            cargo: '',
                                            area_atuacao: '',
                                            nome_cargo: '',
                                            convidado: {
                                                ...formValues.convidado,
                                                cargo: '',
                                                convidado_de: '',
                                            },
                                        });
                                    }}
                                    IconComponent={naturezas.length === 1 ? () => null : undefined}
                                    disabled={naturezas.length === 1}
                                >
                                    {naturezas?.map((natureza) => (
                                        <MenuItem key={natureza.id} value={natureza.id}>
                                            {natureza.descricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel >Tipo cargo</InputLabel>
                                <Select
                                    variant="outlined"
                                    disabled={!formValues.tipo_inscricao}
                                    value={formValues?.tipo_cargo}
                                    onChange={(e) => {
                                        const tipoCargoSelecionado = e.target.value;
                                        setFormValues({
                                            ...formValues,
                                            tipo_cargo: tipoCargoSelecionado,
                                            cargo: '',
                                            area_atuacao: '',
                                            nome_cargo: '',
                                            convidado: {
                                                ...formValues.convidado,
                                                cargo: '',
                                                convidado_de: '',
                                            },
                                        });
                                    }}
                                >
                                    {tipoCargo.map((tipo) => (
                                        <MenuItem key={tipo.id} value={tipo.id}>
                                            {tipo.descricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel >Cargo</InputLabel>
                                <Select
                                    variant="outlined"
                                    value={formValues?.cargo}
                                    disabled={!formValues.tipo_cargo}
                                    onChange={(e) => {
                                        const cargoSelecionado = e.target.value;
                                        const cargoSelecionadoDescricao = cargo.find((cargo) => cargo.id === cargoSelecionado)?.descricao || '';
                                        setFormValues({
                                            ...formValues,
                                            cargo: cargoSelecionado,
                                            nome_cargo: cargoSelecionadoDescricao,
                                            area_atuacao: '',
                                        });
                                    }}
                                >
                                    {cargo.map((cargo) => (
                                        <MenuItem key={cargo.id} value={cargo.id}>
                                            {cargo.descricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={ufs}
                                    getOptionLabel={(uf) => uf.SIGLA_UF}
                                    value={ufs.find((uf) => uf.SIGLA_UF === formValues?.uf) || null}
                                    onChange={(e, selectedUfObject) => {
                                        HandleChangeForm('uf', selectedUfObject?.SIGLA_UF || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="UF"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                variant="outlined"
                                onChange={(e) => HandleChangeForm('nome', e.target.value)}
                                value={formValues?.nome}
                                fullWidth
                                label="Nome"
                                autoComplete="off"
                                inputProps={{ maxLength: 255 }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        HandleSearch();
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
                            <Button variant="contained" color="inherit" onClick={resetForm}>
                                Limpar Campos
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
                            <Button variant="contained" color="primary" onClick={HandleSearch}>
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid container xs={12} sm={12}>
                <Grid item xs={12} sm={3}>
                    {isAdmin || hasPermission('cadastro-autoridades', 'escrita') ? (
                        <Button variant="contained" color="primary" onClick={createAutoridades} style={{ marginTop: '20px', marginLeft: '10px' }}>
                            Cadastrar Autoridade
                        </Button>
                    ) : null}
                </Grid>
                {isAdmin || hasPermission('cadastro-autoridades', 'escrita') || hasPermission('caixas', 'escrita') ? (
                    <Grid item xs={12} sm={3}>
                        <Button variant="contained" color="primary" onClick={createInscricao} style={{ marginTop: '20px', marginLeft: '10px' }}>
                            Nova inscrição
                        </Button>
                    </Grid>
                ) :
                    <Grid item xs={12} sm={3}>
                        <Button variant="contained" color="primary" onClick={handleMenuOpen} style={{ marginTop: '20px', marginLeft: '10px' }}>
                            Nova inscrição
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => handleMenuItemClick('/autoridades-municipais')}>
                                Governo Municipal
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('/autoridades-federais-estaduais')}>
                                Governo Federal
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('/convidados')}>
                                Convidados
                            </MenuItem>
                        </Menu>
                    </Grid>
                }
                <Grid item xs={12} sm={3}></Grid>
                <Grid item alignItems="center" justifyContent="space-between" xs={12} sm={3}>
                    <Grid item>
                        <TextField
                            label="Pesquisar"
                            variant="outlined"
                            value={searchTerm}
                            style={{ marginTop: '10px' }}
                            autoComplete="off"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    HandleSearch();
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <TableContainer style={{ marginTop: '10px' }} component={Paper} className="custom-table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'nome'}
                                    direction={orderBy === 'nome' ? order : 'asc'}
                                    onClick={() => handleSort('nome')}
                                >
                                    Nome
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'partidoPolitico'}
                                    direction={orderBy === 'partidoPolitico' ? order : 'asc'}
                                    onClick={() => handleSort('partidoPolitico')}
                                >
                                    Partido Político
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'Uf'}
                                    direction={orderBy === 'Uf' ? order : 'asc'}
                                    onClick={() => handleSort('Uf')}
                                >
                                    UF
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'cargo'}
                                    direction={orderBy === 'cargo' ? order : 'asc'}
                                    onClick={() => handleSort('cargo')}
                                >
                                    Cargo
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                                <TableSortLabel
                                    active={orderBy === 'parlamentar'}
                                    direction={orderBy === 'parlamentar' ? order : 'asc'}
                                    onClick={() => handleSort('parlamentar')}
                                >
                                    Parlamentar
                                </TableSortLabel>
                            </TableCell>
                            {isAdmin || hasPermission('cadastro-autoridades', 'escrita') ? (
                                <>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        Credenciado
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        Ações
                                    </TableCell>
                                </>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedUsers.map((inscricao) => (
                            <TableRow key={inscricao.id}>
                                <TableCell style={{ textAlign: 'center' }}>{inscricao.nome}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>{inscricao.partido}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>{inscricao.NOME_UF}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>{inscricao.cargo_descricao}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>{inscricao.parlamentar}</TableCell>
                                {isAdmin || hasPermission('cadastro-autoridades', 'escrita') ? (
                                    <>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {/* <ThumbDownIcon color="error" /> */}
                                                <PrintIcon onClick={() => handlePrintClick(inscricao)} style={{ color: '#007bff', cursor: 'pointer', marginLeft: '8px' }} />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {!inscricao.parlamentar && (
                                                    <PersonAddAltIcon onClick={() => vinculateAutoridades(inscricao)} style={{ color: 'green', cursor: 'pointer', marginLeft: '8px' }} />
                                                )}
                                                {/* <EditIcon onClick={() => alert('Em desenvolvimento')} style={{ color: '#007bff', cursor: 'pointer', marginLeft: '8px' }} />
                                                <DeleteIcon onClick={() => alert('Em desenvolvimento')} style={{ color: 'red', cursor: 'pointer', marginLeft: '8px' }} /> */}
                                            </div>
                                        </TableCell>
                                    </>
                                ) : null}
                            </TableRow>
                        ))}
                        <div style={{ display: 'none' }}>
                            {selectedUser && (
                                <>
                                    <Etiqueta
                                        nome={selectedUser.nome.toUpperCase()}
                                        uf={selectedUser.cargo === '51' || selectedUser.cargo === '52' || selectedUser.cargo === '88' || selectedUser.cargo === '89' || selectedUser.cargo === '101' || selectedUser.cargo === '70' || selectedUser.cargo === '73' || selectedUser.cargo === '71' || selectedUser.cargo === '75' || selectedUser.cargo === '77' || selectedUser.cargo === '78' || selectedUser.cargo === '79' || selectedUser.cargo === '81' || selectedUser.cargo === '82' || selectedUser.cargo === '83' || selectedUser.cargo === '84' || selectedUser.cargo === '85' || selectedUser.cargo === '87' || selectedUser.cargo === '76' || selectedUser.cargo === '100' || selectedUser.cargo === '53' || selectedUser.cargo === '59' || selectedUser.cargo === '100' ? selectedUser.NOME_UF : selectedUser.SIGLA_UF}
                                        empresa_instituicao={selectedUser.cargo === '43' || selectedUser.cargo === '32' || selectedUser.cargo === '33' || selectedUser.cargo === '34' ||  selectedUser.cargo === '35' || selectedUser.cargo === '37' || selectedUser.cargo === '38' || selectedUser.cargo === '74' || selectedUser.cargo === '79' || selectedUser.cargo === '92' || selectedUser.cargo === '93' || selectedUser.cargo === '94' || selectedUser.cargo === '95' || selectedUser.cargo === '96' || selectedUser.cargo === '116' || selectedUser.cargo === '133'   ? selectedUser.ministerio_orgao : ''}
                                        cargo={selectedUser.cargo === '14' || selectedUser.cargo === '19' || selectedUser.cargo === '25' || selectedUser.cargo === '38' || selectedUser.cargo === '43' || selectedUser.cargo === '48' || selectedUser.cargo === '54' || selectedUser.cargo === '60' || selectedUser.cargo === '67' || selectedUser.cargo === '79' || selectedUser.cargo === '85' || selectedUser.cargo === '90' || selectedUser.cargo === '96' || selectedUser.cargo === '101' || selectedUser.cargo === '107' || selectedUser.cargo === '114' || selectedUser.cargo === '121' || selectedUser.cargo === '131' || selectedUser.cargo === '136' || selectedUser.cargo === '145' || selectedUser.cargo === '152' || selectedUser.cargo === '159' || selectedUser.cargo === '174' ? '' : selectedUser.cargo_descricao}
                                        complemento={selectedUser.cargo === '81' ? ' do TCE' : selectedUser.cargo === '28' ? ' da República' : selectedUser.cargo === '29' ? ' Presidente da República' : selectedUser.cargo === '31' ? ' do Brasil' : selectedUser.cargo === '51' ? ' do Senado Federal' : selectedUser.cargo === '52' ? ' Presidente do Senado Federal' : selectedUser.cargo === '56' ? ' da Câmara dos Deputados' : selectedUser.cargo === '57' ? ' Presidente da Câmara dos Deputados' : selectedUser.cargo === '71' ? ' Governador' : selectedUser.cargo === '62' ? ' Integrante do Judiciário Federal' : selectedUser.cargo === '63' ? ' Integrante do Judiciário Federal' : selectedUser.cargo === '64' ? ' Integrante do Judiciário Federal' : selectedUser.cargo === '65' ? ' Integrante do Judiciário Federal' : selectedUser.cargo === '66' ? ' Integrante do Judiciário Federal' : ''}
                                        partido={selectedUser.cargo === '50' || selectedUser.cargo === '58' || selectedUser.cargo === '76' || selectedUser.cargo === '98' || selectedUser.cargo === '99' || selectedUser.cargo === '100' || selectedUser.cargo === '53' || selectedUser.cargo === '59' ? selectedUser.partido : ''}
                                        tipo_cargo={selectedUser.cargo === '33' || selectedUser.cargo === '34' ||  selectedUser.cargo === '35' || selectedUser.cargo === '37' || selectedUser.cargo === '38' || selectedUser.cargo === '72' || selectedUser.cargo === '75' || selectedUser.cargo === '76' || selectedUser.cargo === '77' || selectedUser.cargo === '78' || selectedUser.cargo === '79' || selectedUser.cargo === '87' || selectedUser.cargo === '88' ? selectedUser.cargo_id : ''}
                                        ref={etiquetaRef}
                                        tipo_cargo_descricao={ selectedUser.cargo === '40' || selectedUser.cargo === '41' || selectedUser.cargo === '42' || selectedUser.cargo === '43' || selectedUser.cargo === '44' || selectedUser.cargo === '45' || selectedUser.cargo === '46' || selectedUser.cargo === '47' || selectedUser.cargo === '48' || selectedUser.cargo === '82' || selectedUser.cargo === '83' || selectedUser.cargo === '84' || selectedUser.cargo === '85' || selectedUser.cargo === '87' || selectedUser.cargo === '88' || selectedUser.cargo === '89' || selectedUser.cargo === '101' || selectedUser.cargo === '99' ? selectedUser.tipo_cargo_descricao : ''}
                                        parlamentar={selectedUser.cargo === '76' ? ' Governador(a)' + selectedUser.parlamentar : selectedUser.cargo === '100' ? 'Deputado(a) ' + selectedUser.parlamentar : selectedUser.cargo === '59' ? 'Deputado(a) ' + selectedUser.parlamentar : selectedUser.cargo === '53' ? 'Senador(a) ' + selectedUser.parlamentar : ''}
                                    />
                                </>
                            )}
                        </div>
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    count={sortedAndFilteredUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                    }}
                    labelRowsPerPage="Registros por página:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                />
            </TableContainer>

        </Paper>
    );
}

export default FiltrosAutoridades;
