import React from 'react';
import { Modal, Typography, Button } from '@material-ui/core';

const CustomModal = ({ open, onClose, title, content, onCancel, onConfirm, disabled = false }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    backgroundColor: 'white',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    borderRadius: '4px',
                }}
            >
                <Typography variant="h6" component="h2">
                    {title}
                </Typography>
                <Typography variant="body1" component="p" style={{ marginTop: '20px' }}>
                    {content}
                </Typography>
                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" onClick={onCancel} style={{ marginRight: '10px' }}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={onConfirm} disabled={disabled}>
                        Confirmar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CustomModal;
