import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import InputsTotem from '../../components/InputsTotem';
import TabelaDados from '../../components/TabelaDados';
import InputsDialog from '../../components/InputsDialog';
import LoadingOverlay from '../../components/LoadingOverlay';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Grid, Typography, Button } from '@material-ui/core';
import InstrucoesEtiqueta from '../../components/InstrucoesEtiqueta';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Constants from '../../constants/Constants';
import CliqueAqui from '../../images/Hand2.gif';
import Logo from '../../images/xxv-marcha.png';
import Confederando from '../../images/Confederando.png';
import Confederando2 from '../../images/Confederando-R.png';

function Totem() {
  const [mostrarInstrucoes, setMostrarInstrucoes] = useState(false);
  const [mostrarInputs, setMostrarInputs] = useState(false);
  const [dadosFormulario, setDadosFormulario] = useState({
    codigo: '',
  });
  const [mostrarCampoCPF, setMostrarCampoCPF] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [inactive, setInactive] = useState(false);

  const handleInputChange = (campo, valor) => {
    setDadosFormulario((prevDados) => ({
      ...prevDados,
      [campo]: valor,
    }));
    setInactive(false);
  };

  const handleScreenClick = () => {
    setClicked(true);
    setInactive(false);
  };

  const returnPage = () => {
    window.location.reload();
  }

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setClicked(false);
        setInactive(true);
        window.location.reload();
      }, 50000);
    };

    resetTimer();

    const handleWheelEvent = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };

    const handleTouchEvent = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener('wheel', handleWheelEvent, { passive: false });
    document.addEventListener('touchstart', handleTouchEvent, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheelEvent);
      document.removeEventListener('touchstart', handleTouchEvent);
      clearTimeout(timeoutId);
    };

  }, [clicked, dadosFormulario]);

  const {
    HandleSnackbarClose,
    isLoading,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
  } = Constants();

  return (
    <Grid className='fundo-totem'>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={HandleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={HandleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      {!clicked && !inactive && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            cursor: 'pointer'
          }}
          onClick={handleScreenClick}
        >
          <Grid style={{ display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'center', alignItems: 'center' }}>
            <Typography style={{ padding: 10, fontFamily: 'impact', fontSize: '50px' }} className='textotem' >
              Toque na tela para iniciar
            </Typography>
            <img src={CliqueAqui} alt="Clique Aqui" className='clique-aqui' />
            <img src={Logo} alt="Logo CNM" className='logo-cnm' />
            <img src={Confederando} alt="Confederando" className='confederando-b' />
          </Grid>
        </div>
      )}

      {clicked && !mostrarCampoCPF && !inactive && (
        <Container style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography style={{ marginBottom: '5%', textAlign: 'center', color: 'rgba(0, 90, 156)', fontSize: '50px', padding: 10, fontFamily: 'impact' }}>
              Está com o código em mãos?
            </Typography>
            <Grid item container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  variant='contained'
                  style={{ backgroundColor: 'green', color: '#fff', width: '15rem', height: '5rem', fontSize: '2rem', fontWeight: 'bold' }}
                  onClick={() => setMostrarCampoCPF(true)}
                >
                  Sim
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => setMostrarInputs(true)}
                  style={{ backgroundColor: 'red', color: '#fff', width: '15rem', height: '5rem', fontSize: '2rem', fontWeight: 'bold' }}
                >
                  Não
                </Button>
                {mostrarInputs && <InputsDialog open={true} onClose={() => setMostrarInputs(false)} />}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}

      {mostrarCampoCPF && (
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '10%' }}>
              <Grid style={{ marginTop: '6%' }}>
                <Typography style={{ marginBottom: '2%', textAlign: 'center', color: 'rgba(0, 90, 156)', fontSize: '50px', padding: 10, fontFamily: 'impact' }}>
                  Faça a leitura do seu código de barras:
                </Typography>
                <InputsTotem
                  variant="outlined"
                  type="password"
                  onChange={(e) => handleInputChange('codigo', e.target.value)}
                  autoFocus
                />
                <img src={Logo} alt="Logo CNM" className='logo-cnm-a' />
                <Button
                  variant="contained"
                  size="large"
                  endIcon={<MenuBookIcon />}
                  className='botao-dicas'
                  onClick={() => setMostrarInstrucoes(true)}
                  style={{ backgroundColor: 'green', color: '#fff', fontWeight: 'bold', position: 'absolute', left: 50, top: 50 }}
                >
                  Dúvidas?
                </Button>
                <InstrucoesEtiqueta open={mostrarInstrucoes} onClose={() => setMostrarInstrucoes(false)} />
                <Button variant='contained' onClick={returnPage} style={{ position: 'absolute', bottom: 80, left: '38%', backgroundColor: 'red', color: '#fff', width: '25%', height: '60px' }}>
                  Finalizar
                </Button>
                <img src={Confederando2} alt="Confederando" className='confederando-a' />
              </Grid>
              {(dadosFormulario.codigo) && (
                <TabelaDados dadosFiltro={dadosFormulario} />
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {isLoading && <LoadingOverlay />}

    </Grid>
  )
}

export default Totem;
