import React from 'react';
import {
    Card,
    CardContent,
    TextField,
    Grid,
    Typography,
} from '@material-ui/core';
import Divider from '@mui/material/Divider';

function Endereco({ formValues, HandleChangeForm, FormatCEP, formErrors }) {
    return (
        <Grid container spacing={3} component="main" style={{userSelect: 'none'}}>
            <Grid item xs={12} sm={8}>
                <Card>
                    <CardContent>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Endereço
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        error={!!formErrors.cep}
                                        helperText={formErrors.cep}
                                        onChange={(e) => HandleChangeForm('cep', e.target.value)}
                                        value={FormatCEP(formValues?.cep)}
                                        fullWidth
                                        label="CEP"
                                        inputProps={{ maxLength: 10 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        error={!!formErrors.endereco}
                                        helperText={formErrors.endereco}
                                        disabled={!formValues?.cep}
                                        onChange={(e) => HandleChangeForm('endereco', e.target.value)}
                                        value={formValues?.endereco}
                                        fullWidth
                                        label="Endereço"
                                        inputProps={{ maxLength: 255 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        error={!!formErrors.bairro}
                                        helperText={formErrors.bairro}
                                        disabled={!formValues?.cep}
                                        onChange={(e) => HandleChangeForm('bairro', e.target.value)}
                                        value={formValues?.bairro}
                                        fullWidth
                                        label="Bairro"
                                        inputProps={{ maxLength: 255 }}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default Endereco;