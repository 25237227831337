import React, { useState, useEffect } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import api from '../service/Api';
import {
    Container,
    Grid,
    TextField,
    Button,
    Divider
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FiltrosTabelaFluxoCaixa from "./FiltrosTabelaFluxoCaixa";
import ConstantInscricoes from "../constants/ConstantInscricoes";
import InformacoesCaixa from "./InformacoesCaixa";

const FluxoDeCaixa = () => {

    const {
        HandleSnackbarClose,
        showAlert,
        setOrder,
        setOrderBy,
        setSearchTerm,
        removerAcentos,
        hasPermission,
        isAdmin,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        orderBy,
        order,
        searchTerm,
    } = ConstantInscricoes({ dontLoadAll: true });

    const [funcionalidade, setFuncionalidade] = useState('');
    const [valorViaSistema, setValorViaSistema] = useState('');
    const [dadosFluxoCaixa, setDadosFluxoCaixa] = useState([]);
    const [openModalFechamento, setOpenModalFechamento] = useState(false);
    const [caixas, setCaixas] = useState([]);
    const [dadosCaixa, setDadosCaixa] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleClearDates = () => {
        setStartDate(null);
        setEndDate(null);
    };

    const handleSort = (columnName) => {
        if (orderBy === columnName) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(columnName);
            setOrder('asc');
        }
    };

    const InfoCaixas = (dadosCaixa) => {
        setDadosCaixa(dadosCaixa);
        setFuncionalidade('1');
    };

    const sortedAndFilteredDadosCaixa = caixas
        .filter((caixas) => {
            return (
                removerAcentos(caixas?.perfil).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(caixas?.status).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase()))
            );
        })
        .sort((a, b) => {
            const isAsc = order === 'asc';
            if (orderBy === 'nome') {
                return removerAcentos(a.perfil).localeCompare(removerAcentos(b.perfil), 'pt', { numeric: true }) * (isAsc ? 1 : -1);
            } else if (orderBy === 'status') {
                return removerAcentos(a.status).localeCompare(removerAcentos(b.status)) * (isAsc ? 1 : -1);
            } else {
                return 0;
            }
        });

    useEffect(() => {
        HandleSearch();
        // eslint-disable-next-line
    }, []);

    const HandleSearch = async () => {
        try {
            const response = await api.get(`api/getTotalData?startDate=${startDate}&endDate=${endDate}`);
            setDadosFluxoCaixa(response?.data.getTotalData[0]);

            const response2 = await api.get(`api/getCaixas`);
            const response3 = await api.get(`api/getTotalBoletos`);
            const caixasData = response2?.data?.getCaixas;
            const fechamentosData = response2?.data?.getFechamento?.getFechamentosCaixa;
            setValorViaSistema(response3?.data?.Total);

            const fechamentosPorCaixa = {};
            fechamentosData.forEach(fechamento => {
                const perfilFluxo = fechamento.perfil_fluxo;
                if (!fechamentosPorCaixa[perfilFluxo]) {
                    fechamentosPorCaixa[perfilFluxo] = [];
                }
                fechamentosPorCaixa[perfilFluxo].push(fechamento);
            });

            const caixasComFechamento = caixasData.map(caixa => {
                const perfil = caixa.perfil;
                const fechamento = fechamentosPorCaixa[perfil] || [];
                return {
                    ...caixa,
                    fechamento: fechamento.length > 0 ? fechamento : null
                };
            });

            setCaixas(caixasComFechamento);
        } catch (error) {
            const errors = error?.response?.data?.errors;
            console.log(errors);
            showAlert('Ocorreu um erro ao realizar a consulta', 'error');
        }
    };

    const HandleRetirada = async (valoresModal) => {
        try {
            const { dinheiro, transferencia, cartao, pix, filiacao, cheque, furo, obs, perfil, fluxo_perfil } = valoresModal;

            const url = `api/fechamentoCaixa?dinheiro=${dinheiro}&transferencia=${transferencia}&cartao=${cartao}&pix=${pix}&filiacao=${filiacao}&cheque=${cheque}&furo=${furo}&obs=${obs}&perfil=${perfil}&fluxo_perfil=${fluxo_perfil}`;
            const response = await api.post(url);
            if (response?.status === 200) {
                showAlert('Fechamento realizado com sucesso', 'success');
                setOpenModalFechamento(false);
                HandleSearch();
                setFuncionalidade('');
            }

        } catch (error) {
            console.error('Ocorreu o seguinte erro ao fechar o caixa:', error);
        }
    };


    return (
        <React.Fragment>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            {funcionalidade === '1' && (
                <InformacoesCaixa
                    setFuncionalidade={setFuncionalidade}
                    HandleSnackbarClose={HandleSnackbarClose}
                    HandleRetirada={HandleRetirada}
                    dadosCaixa={dadosCaixa}
                    snackbarSeverity={snackbarSeverity}
                    snackbarMessage={snackbarMessage}
                    snackbarOpen={snackbarOpen}
                    openModalFechamento={openModalFechamento}
                    setOpenModalFechamento={setOpenModalFechamento}
                    HandleSearch={HandleSearch}
                />
            )}

            {funcionalidade === '' && (
                <Container spacing={2} style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={12} sm={3} style={{ marginRight: '10px' }}>
                            <TextField
                                label="Data Inicial"
                                type="date"
                                value={startDate === null ? '' : startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label="Data Final"
                                type="date"
                                value={endDate === null ? '' : endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={HandleSearch}
                            >
                                Buscar
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ textAlign: 'center' }}>
                            <Button variant="contained" color="inherit" onClick={handleClearDates}>
                                Limpar Filtros
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: '20px' }} />
                    <FiltrosTabelaFluxoCaixa
                        searchTerm={searchTerm}
                        orderBy={orderBy}
                        order={order}
                        sortedAndFilteredDadosCaixa={sortedAndFilteredDadosCaixa}
                        dadosFluxoCaixa={dadosFluxoCaixa}
                        valorViaSistema={valorViaSistema}
                        isAdmin={isAdmin}
                        hasPermission={hasPermission}
                        setSearchTerm={setSearchTerm}
                        handleSort={handleSort}
                        setFuncionalidade={setFuncionalidade}
                        InfoCaixas={InfoCaixas}
                    />
                </Container>
            )}
        </React.Fragment>
    );
};

export default FluxoDeCaixa;
