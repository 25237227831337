import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Barcode from 'react-barcode';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    height: '10cm',
    width: '5cm'
  },
});

const Etiqueta = React.forwardRef(({ nome, tipo_inscricao, tipo_cargo, tipo_cargo_descricao, uf, empresa_instituicao, cargo, complemento, campo_obs, cpf, partido, municipio, statusContribuicao, parlamentar, status }, ref) => {
  const checkNull = (value) => {
    return value !== null ? value : ' ';
  };

  return (
    <Document ref={ref} style={styles.page}>
      <Page size="A6" orientation="landscape" style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: '7%', lineHeight: '1.1em', marginLeft: '5%' }}>
        <View>
          {checkNull(nome)}
        </View>
        <View>
          <Text style={{ fontWeight: 'bold', fontSize: '1.2rem' }}> {checkNull(cargo)} {checkNull(complemento)}  </Text>
        </View>
        <View>
          <Text>{checkNull(campo_obs) ? (campo_obs) : checkNull(empresa_instituicao)}</Text>
        </View>
        <View>
          <Text> {checkNull(tipo_inscricao)} {checkNull(municipio) ? municipio + '/' + checkNull(uf) : ''} </Text>
        </View>
        <View>
          <Text>{checkNull(tipo_cargo_descricao)}</Text>
        </View>
        <View>
          <Text>{checkNull(tipo_cargo)}</Text>
        </View>
        <View>
          {(!checkNull(partido) && !checkNull(parlamentar) && !checkNull(municipio) ? checkNull(uf) : '')}
        </View>
        <View>
          <Text>
            {checkNull(partido) ? (!checkNull(parlamentar) ? checkNull(partido) + '/' + checkNull(uf) : checkNull(parlamentar) ? checkNull(parlamentar) + ' ' + checkNull(partido) + '/' + checkNull(uf): '') : ''}
          </Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ position: 'absolute', left: -1, top: 72, fontSize: '4rem' }}>{statusContribuicao}</Text>
          {cpf ? <Barcode value={cpf} displayValue={false} height={50} width={1.5} /> : ''}
        </View>
      </Page>
    </Document>
  );
});

export default Etiqueta;
