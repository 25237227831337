import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../service/Api';

const OAuthTokenHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const sendTokenRequest = async () => {
            try {
                const code = localStorage.getItem('code');
                if (!code) {
                    console.error('No oauthCode found in localStorage');
                    localStorage.clear();
                    navigate('/');
                    return;
                }

                const tokenRequestData = {
                    grant_type: 'authorization_code',
                    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
                    client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
                    redirect_url: `${process.env.REACT_APP_URL}callback`,
                    code: code,
                };

                const response = await api.post('oauth/token', tokenRequestData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                });

                localStorage.setItem('tokenApi', JSON.stringify(response.data));
                navigate('/home');
            } catch (error) {
                console.error('Error sending token request:', error);
            }
        };

        sendTokenRequest();
    }, [navigate]);

    return null;
};

export default OAuthTokenHandler;
