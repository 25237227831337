import React from 'react';
import {
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    FormControlLabel,
    Typography,
    RadioGroup,
    Radio
} from '@material-ui/core';
import Divider from '@mui/material/Divider';

function DadosPagamentos({ formValues, HandleChangeForm, formErrors, FormatCNPJ }) {
    return (
        <Grid container spacing={3} component="main" style={{ userSelect: 'none' }}>
            <Grid item xs={12} sm={8}>
                <Card>
                    <CardContent>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Dados do pagamento
                                    </Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <RadioGroup
                                        row
                                        aria-label="dadosPagamento"
                                        name="dadosPagamento"
                                        value={formValues?.opc_pagamento}
                                        style={{ marginLeft: '13px' }}
                                        onChange={(e) => HandleChangeForm('opc_pagamento', e.target.value)}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio color="primary" />}
                                            label="Pessoa Física"
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <RadioGroup
                                        row
                                        aria-label="dadosPagamento"
                                        name="dadosPagamento"
                                        value={formValues?.opc_pagamento}
                                        style={{ marginLeft: '13px' }}
                                        onChange={(e) => HandleChangeForm('opc_pagamento', e.target.value)}
                                    >
                                        <FormControlLabel
                                            value="2"
                                            control={<Radio color="primary" />}
                                            label="Pessoa Jurídica"
                                        />
                                    </RadioGroup>
                                </Grid>
                                {formValues?.opc_pagamento === '2' && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                error={!!formErrors.cnpj}
                                                fullWidth
                                                label="CNPJ"
                                                value={FormatCNPJ(formValues?.cnpj)}
                                                onChange={(e) => HandleChangeForm('cnpj', e.target.value)}
                                                inputProps={{ maxLength: 18 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>Razão Social</InputLabel>
                                                <Select

                                                    onChange={(e) => HandleChangeForm('razao_social', e.target.value)}
                                                    value={formValues?.razao_social}
                                                >
                                                    <MenuItem value="prefeitura_municipal">Prefeitura Municipal</MenuItem>
                                                    <MenuItem value="camara_municipal">Câmara Municipal</MenuItem>
                                                    <MenuItem value="consorcios_publicos">Consórcios Públicos</MenuItem>
                                                    <MenuItem value="municipio">Município</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default DadosPagamentos;