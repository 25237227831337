import React, { useState } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import api from '../service/Api';
import EditarInscricao from "./EditarInscricao";
import {
    Container,
    Grid,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FiltrosTabelaInscricoes from "./FiltrosTabelaInscricoes";
import ConstantInscricoes from "../constants/ConstantInscricoes";
import CadastroInterno from "./CadastroInterno";


const InscricoesList = () => {

    const {
        HandleSnackbarClose,
        showAlert,
        FormatCPF,
        OcultarCPF,
        FormatCNPJ,
        FormatCelular,
        FormatTelefone,
        HandleChangeForm,
        HandleChangeCheckbox,
        HandleCheckboxChange,
        setFormValues,
        setCodigoUf,
        setMunicipio,
        setOrder,
        setOrderBy,
        setInscricoes,
        setFiltrosAbertos,
        setInscricaoSelecionadaId,
        setSearchTerm,
        resetForm,
        handleMenuOpen,
        handleMenuClose,
        handleMenuItemClick,
        removerAcentos,
        hasPermission,
        deletarInscricao,
        verificaSituacao,
        isAdmin,
        permissoes,
        anchorEl,
        ufs,
        naturezas,
        tipoCargo,
        cargo,
        municipio,
        formValues,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        parlamentar,
        inscricoes,
        orderBy,
        order,
        searchTerm,
        filtrosAbertos,
        inscricaoSelecionadaId
    } = ConstantInscricoes();

    const [openModalExcluirCadastro, setOpenModalExcluirCadastro] = useState(false);
    const [desabilitarBotao, setDesabilitarBotao] = useState(false);
    const [funcionalidade, setFuncionalidade] = useState('');

    const handleSort = (columnName) => {
        if (orderBy === columnName) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(columnName);
            setOrder('asc');
        }
    };

    const sortedAndFilteredUsers = inscricoes
        .filter((inscricao) => {
            const searchTermLowerCase = removerAcentos(searchTerm.toLowerCase());

            const situacao = verificaSituacao(inscricao);

            if (searchTermLowerCase === 'isento' && situacao === 'Isento') {
                return true;
            }
            if (searchTermLowerCase === 'cortesia' && situacao === 'Cortesia') {
                return true;
            }
            if (searchTermLowerCase === 'pago' && situacao === 'Pago') {
                return true;
            }
            if (searchTermLowerCase === 'aberto' && situacao === 'Aberto') {
                return true;
            }

            return (
                removerAcentos(inscricao?.nome).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.cpf).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.cargo_descricao).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.nome_municipio).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.aprovado).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase()))
            );
        })
        .sort((a, b) => {
            const isAsc = order === 'asc';
            if (orderBy === 'nome') {
                return removerAcentos(a.nome).localeCompare(removerAcentos(b.nome)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'cpf') {
                return removerAcentos(a.cpf).localeCompare(removerAcentos(b.cpf)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'cargo') {
                return removerAcentos(a.cargo_descricao).localeCompare(removerAcentos(b.cargo_descricao)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'municipioUf') {
                return removerAcentos(a.nome_municipio).localeCompare(removerAcentos(b.nome_municipio)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'aprovado') {
                return removerAcentos(a.aprovado).localeCompare(removerAcentos(b.aprovado)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'status') {
                return removerAcentos(a.situacao).localeCompare(removerAcentos(b.situacao)) * (isAsc ? 1 : -1);
            } else {
                return 0;
            }
        });

    const HandleSearch = async () => {
        try {
            const cleanTelefone = formValues.telefone.replace(/[\s()-]/g, '');
            const cleanCelular = formValues.celular.replace(/[\s()-]/g, '');
            const cleanCpf = formValues.cpf.replace(/[^\d]/g, '');
            const cleanCnpj = formValues.cnpj.replace(/[^\d]/g, '');

            const nonEmptyValues = Object.fromEntries(
                Object.entries({
                    ...formValues,
                    telefone: cleanTelefone,
                    celular: cleanCelular,
                    cpf: cleanCpf,
                    cnpj: cleanCnpj,
                }).filter(([key, value]) => value !== '')
            );

            if (nonEmptyValues.PCD && nonEmptyValues.PCD.necessita_atendimento_especializado === 'Não') {
                nonEmptyValues.PCD = undefined;
            } else {
                if (nonEmptyValues.PCD) {
                    const nonEmptyPCD = Object.fromEntries(
                        Object.entries(nonEmptyValues.PCD).filter(([key, value]) => value !== '')
                    );
                    nonEmptyValues.PCD = Object.keys(nonEmptyPCD).length > 0 ? nonEmptyPCD : undefined;
                }
            }

            if (nonEmptyValues.reenviarEmail) {
                nonEmptyValues.reenviarEmail = undefined;
            } else {
                if (nonEmptyValues.reenviarEmail) {
                    const nonEmptyReenviarEmail = Object.fromEntries(
                        Object.entries(nonEmptyValues.reenviarEmail).filter(([key, value]) => value !== '')
                    );
                    nonEmptyValues.reenviarEmail = Object.keys(nonEmptyReenviarEmail).length > 0 ? nonEmptyReenviarEmail : undefined;
                }
            }

            const response = await api.get(`api/cadastroUser?limit=${process.env.REACT_APP_LIMIT_SEARCH}`, { params: nonEmptyValues });
            const inscricoesAtualizadas = response?.data?.getCadastroUser.map((inscricao) => {
                const situacao = verificaSituacao(inscricao);
                return {
                    ...inscricao,
                    aprovado: (inscricao.aprovado === true || inscricao?.cortesia === 1) ? 'Homologado' : 'Não homologado',
                    situacao: situacao,
                };

            });

            setInscricoes(inscricoesAtualizadas);
            setFiltrosAbertos(false);

        } catch (error) {
            const errors = error?.response?.data?.errors;
            console.log(errors);
            showAlert('Ocorreu um erro ao realizar a consulta', 'error');
        }
    };

    const handleOpenModalExcluirUsuario = (idExclusao) => {
        setInscricaoSelecionadaId(idExclusao)
        setOpenModalExcluirCadastro(true);
    };

    const handleCloseModalExcluirUsuario = () => {
        setOpenModalExcluirCadastro(false);
    };

    const createInscricao = () => {
        setFuncionalidade('1');
    };

    const EditInscricao = (id) => {
        setFuncionalidade('2');
        setInscricaoSelecionadaId(id);
    };

    const handleDeletarInscricao = async () => {
        if (inscricaoSelecionadaId) {
            try {
                setDesabilitarBotao(true);
                const responseData = await deletarInscricao();
                if (responseData?.status === 200) {
                    showAlert('Exclusão realizada com sucesso', 'success');
                    handleCloseModalExcluirUsuario();
                    HandleSearch();
                    setDesabilitarBotao(false);
                } else {
                    showAlert('Ocorreu um erro ao realizar a exclusão. Por favor, tente novamente mais tarde', 'error');
                    setDesabilitarBotao(false);
                }
            } catch (error) {
                showAlert('Ocorreu um erro ao realizar a exclusão. Por favor, tente novamente mais tarde', 'error');
                setDesabilitarBotao(false);
                console.error('Erro ao deletar inscrição:', error);
            }
        }
    };

    return (
        <React.Fragment>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            <Grid item xs={12} sm={12}>

                {funcionalidade === '1' && (
                    <CadastroInterno
                        setFuncionalidade={setFuncionalidade}
                        showAlert={showAlert}
                        HandleSearch={HandleSearch}
                    />
                )}

                {funcionalidade === '2' && (
                    <EditarInscricao
                        id={inscricaoSelecionadaId}
                        setFuncionalidade={setFuncionalidade}
                        HandleSearch={HandleSearch}
                    />
                )}

                {funcionalidade === '' && (
                    <Container style={{ marginTop: '20px' }}>
                        <FiltrosTabelaInscricoes
                            filtrosAbertos={filtrosAbertos}
                            formValues={formValues}
                            naturezas={naturezas}
                            tipoCargo={tipoCargo}
                            cargo={cargo}
                            parlamentar={parlamentar}
                            ufs={ufs}
                            municipio={municipio}
                            searchTerm={searchTerm}
                            orderBy={orderBy}
                            order={order}
                            sortedAndFilteredUsers={sortedAndFilteredUsers}
                            anchorEl={anchorEl}
                            isAdmin={isAdmin}
                            permissoes={permissoes}
                            openModalExcluirCadastro={openModalExcluirCadastro}
                            hasPermission={hasPermission}
                            resetForm={resetForm}
                            setFiltrosAbertos={setFiltrosAbertos}
                            setFormValues={setFormValues}
                            HandleChangeForm={HandleChangeForm}
                            HandleCheckboxChange={HandleCheckboxChange}
                            FormatCPF={FormatCPF}
                            FormatCelular={FormatCelular}
                            FormatTelefone={FormatTelefone}
                            OcultarCPF={OcultarCPF}
                            setCodigoUf={setCodigoUf}
                            setMunicipio={setMunicipio}
                            FormatCNPJ={FormatCNPJ}
                            HandleChangeCheckbox={HandleChangeCheckbox}
                            HandleSearch={HandleSearch}
                            setSearchTerm={setSearchTerm}
                            handleSort={handleSort}
                            EditInscricao={EditInscricao}
                            createInscricao={createInscricao}
                            handleMenuClose={handleMenuClose}
                            handleMenuItemClick={handleMenuItemClick}
                            handleOpenModalExcluirUsuario={handleOpenModalExcluirUsuario}
                            handleCloseModalExcluirUsuario={handleCloseModalExcluirUsuario}
                            handleDeletarInscricao={handleDeletarInscricao}
                            handleMenuOpen={handleMenuOpen}
                            desabilitarBotao={desabilitarBotao}
                        />
                    </Container>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default InscricoesList;
