import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    InputAdornment,
    Typography,
    // Button,
    // SvgIcon
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Divider from '@mui/material/Divider';
// import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import IconButton from '@mui/icons-material/Cancel';
// import CloseIcon from '@mui/icons-material/Close';


function DadosPessoais({ formValues, ufs, municipio, setCodigoUf, HandleChangeForm, setMunicipio, formErrors, FormatCPF, FormatCelular, FormatTelefone, fileInputKey, handleFileChange, handleRemoveFile }) {
    return (
        <Grid container spacing={2} style={{ marginTop: '10px', userSelect: 'none' }}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Dados Pessoais
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    required
                    error={!!formErrors.nome}
                    onBlur={() => {
                        if (formValues.nome && !formValues.nome_cracha) {
                            const [primeiroNome, ...outrosNomes] = formValues.nome.split(' ');
                            const ultimoNome = outrosNomes.pop() || '';
                            const nomeCracha = `${primeiroNome} ${ultimoNome}`;
                            HandleChangeForm('nome_cracha', nomeCracha);
                        }
                    }}
                    onChange={(e) => HandleChangeForm('nome', e.target.value)}
                    value={formValues?.nome}
                    fullWidth
                    label="Nome"
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    required
                    error={!!formErrors.nome_cracha}
                    onChange={(e) => HandleChangeForm('nome_cracha', e.target.value)}
                    value={formValues.nome_cracha}
                    fullWidth
                    label="Nome Crachá"
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>

            {formValues?.tipo_cargo !== 122 ?
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        required
                        error={!!formErrors.cpf}
                        fullWidth
                        id="cpf"
                        label="CPF"
                        name="cpf"
                        autoComplete="cpf"
                        value={FormatCPF(formValues?.cpf)}
                        onChange={(e) => HandleChangeForm('cpf', e.target.value)}
                        inputProps={{ maxLength: 14 }}
                    />
                </Grid>
                :
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        required
                        error={!!formErrors.passaporte}
                        fullWidth
                        id="passaporte"
                        label="Passaporte"
                        name="passaporte"
                        autoComplete="passaporte"
                        value={formValues?.passaporte}
                        onChange={(e) => {
                            const inputValue = e.target.value.toUpperCase();
                            const formattedValue = inputValue.replace(/[^A-Z0-9]/g, '');
                            const passaporteFormatted = formattedValue.substring(0, 8);
                            if (/^[A-Z]{0,2}\d{0,6}$/.test(passaporteFormatted)) {
                                HandleChangeForm('passaporte', passaporteFormatted);
                            }
                        }}
                        inputProps={{
                            maxLength: 8,
                        }}
                    />

                </Grid>
            }
            <Grid item xs={12} sm={4}>
                <FormControl required fullWidth>
                    <InputLabel style={{ marginLeft: '13px' }}>Gênero</InputLabel>
                    <Select
                        variant="outlined"
                        required
                        error={!!formErrors.genero}
                        onChange={(e) => HandleChangeForm('genero', e.target.value)}
                        value={formValues?.genero}
                    >
                        <MenuItem value="masculino">Masculino</MenuItem>
                        <MenuItem value="feminino">Feminino</MenuItem>
                        <MenuItem value="outros">Outros</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {formValues?.tipo_cargo !== 122 && (
                <>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={ufs}
                                getOptionLabel={(uf) => uf.SIGLA_UF}
                                value={ufs.find((uf) => uf.SIGLA_UF === formValues?.uf) || null}
                                onChange={(e, selectedUfObject) => {
                                    setCodigoUf(selectedUfObject?.CD_UF || null);
                                    setMunicipio(selectedUfObject?.CD_MUNICIPIO || null);
                                    HandleChangeForm('uf', selectedUfObject?.SIGLA_UF || '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        required
                                        error={!!formErrors.uf}
                                        label="UF"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disabled={!formValues?.uf}
                                options={municipio || []}
                                getOptionLabel={(municipio) => municipio.NOME_MUNICIPIO || ''}
                                value={municipio?.find((m) => m.CD_MUNICIPIO === formValues?.municipio) || null}
                                onChange={(e, selectedMunicipio) => {
                                    HandleChangeForm('municipio', selectedMunicipio?.CD_MUNICIPIO || '', selectedMunicipio?.NOME_MUNICIPIO || '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        required
                                        error={!!formErrors.municipio}
                                        label="Município"

                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    required
                    error={!!formErrors.celular}
                    onChange={(e) => HandleChangeForm('celular', FormatCelular(e.target.value))}
                    value={formValues?.celular}
                    fullWidth
                    label="Celular"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <WhatsAppIcon color="primary" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    onChange={(e) => HandleChangeForm('telefone', FormatTelefone(e.target.value))}
                    value={formValues?.telefone}
                    fullWidth
                    label="Telefone" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    variant="outlined"
                    required
                    error={!!formErrors.email}
                    onChange={(e) => HandleChangeForm('email', e.target.value)}
                    value={formValues?.email}
                    fullWidth
                    label="E-mail"
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
                <Button
                    component="label"
                    variant="outlined"
                    color="neutral"
                    startIcon={
                        <SvgIcon component={PhotoCameraIcon} />
                    }
                >
                    {formValues?.file ? "Foto Selecionada" : "Upload da Foto"}
                    <input
                        key={fileInputKey}
                        type="file"
                        required
                        error={!!formErrors.file}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept=".jpeg, .jpg, .png"
                    />
                    {formValues?.file && (
                        <CheckCircleIcon fontSize="small" style={{ marginLeft: '8px', color: '#2ECC71' }} />
                    )}
                    {!formValues?.file && (
                        <WarningAmberIcon fontSize="small" style={{ marginLeft: '8px', color: '#FFC107' }} />
                    )}
                </Button>
            </Grid>
            {formValues?.file && (
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2">
                        {formValues?.file?.name}
                        <IconButton
                            color="neutral"
                            aria-label="Remover"
                            onClick={handleRemoveFile}
                            style={{
                                color: '#FF0000',
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                </Grid>
            )} */}

        </Grid>
    );
}

export default DadosPessoais;