import { useNavigate } from 'react-router-dom';
import image1 from '../images/marcha.png';
import image2 from '../images/Eventos.png';
import ConstantInscricoes from "../constants/ConstantInscricoes";

export const HomeFuncionalidades = (setFuncionalidade) => {
  const navigate = useNavigate();

  const {
    isAdmin,
    permissoes,
    hasPermissionFuncionalidades
  } = ConstantInscricoes({ dontLoadAll: true });

  const functionalitiesData = [
    {
      id: 1,
      image: image1,
      subtitle: 'Gerenciamento de eventos',
      description: ['Siga para o gerenciamento dos eventos. Liste, edite e crie eventos para a CNM'],
      buttonText: 'Gerenciamento de eventos',
      buttonVariant: 'contained',
      clique: () => {
        navigate('/eventos/home');  // Redireciona para /marcha/home
      },
      isVisible: isAdmin || hasPermissionFuncionalidades(permissoes, 'gerenciar-inscricoes')
    },
    {
      id: 2,
      image: image2,
      subtitle: 'Credenciamento Marcha',
      description: ['Siga para o sistema de credenciamento para a marcha'],
      buttonText: 'CRED MARCHA',
      buttonVariant: 'contained',
      clique: () => {
        navigate('/marcha/home');  // Redireciona para /marcha/home
      },
      isVisible: isAdmin || hasPermissionFuncionalidades(permissoes, 'graficos')
    },
  ];

  return functionalitiesData.filter(func => func.isVisible);
};

