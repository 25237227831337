import React from 'react';
import {
    FormControl,
    FormControlLabel,
    TextField,
    Grid,
    Typography,
    RadioGroup,
    Radio,
    FormGroup,
    Checkbox
} from '@material-ui/core';
import Divider from '@mui/material/Divider';

function AtendimentoEspecializado({ formValues, HandleChangeForm, HandleChangeCheckbox, formErrors}) {
    return (
        <Grid container spacing={2} style={{ marginTop: '10px', userSelect: 'none' }}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Atendimento Especializados
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
                Necessita de Atendimento especializado?
                <RadioGroup
                    row
                    aria-label="atendimentoEspecializado"
                    name="atendimentoEspecializado"
                    required
                    style={{ marginLeft: '13px' }}
                    onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, necessita_atendimento_especializado: e.target.value })}
                    value={formValues?.PCD?.necessita_atendimento_especializado || ''}
                >
                    <Grid item xs={12} sm={6}>

                        <FormControlLabel
                            value="Sim"
                            control={<Radio color="primary" />}
                            label="Sim"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <FormControlLabel
                            value="Nao"
                            control={<Radio color="primary" />}
                            label="Não"
                        />
                    </Grid>
                </RadioGroup>
            </Grid>
            {formValues?.PCD?.necessita_atendimento_especializado === 'Sim' && (
                <>
                    <Grid item xs={12} sm={6} style={{ marginTop: '5px' }}>
                        Possui deficiência? (Lei nº13.146/2015)
                        <FormControl fullWidth>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormGroup style={{ marginLeft: '10px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={formValues?.PCD?.tipo_deficiencias.includes('Fisica') || false}
                                                    onChange={(e) => HandleChangeCheckbox('Fisica', e.target.checked)}
                                                />
                                            }
                                            label="Física"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={formValues?.PCD?.tipo_deficiencias.includes('Visual') || false}
                                                    onChange={(e) => HandleChangeCheckbox('Visual', e.target.checked)}
                                                />
                                            }
                                            label="Visual"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={formValues?.PCD?.tipo_deficiencias.includes('Auditiva') || false}
                                                    onChange={(e) => HandleChangeCheckbox('Auditiva', e.target.checked)}
                                                />
                                            }
                                            label="Auditiva"
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={formValues?.PCD?.tipo_deficiencias.includes('Intelectual') || false}
                                                    onChange={(e) => HandleChangeCheckbox('Intelectual', e.target.checked)}
                                                />
                                            }
                                            label="Intelectual"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={formValues?.PCD?.tipo_deficiencias.includes('Mental') || false}
                                                    onChange={(e) => HandleChangeCheckbox('Mental', e.target.checked)}
                                                />
                                            }
                                            label="Mental"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={formValues?.PCD?.tipo_deficiencias.includes('Outros') || false}
                                                    onChange={(e) => HandleChangeCheckbox('Outros', e.target.checked)}
                                                />
                                            }
                                            label="Outros"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>

                        </FormControl>
                    </Grid>

                    {formValues?.PCD?.tipo_deficiencias?.includes('Outros') && (
                        <Grid item xs={12} sm={6}>
                            Outros. Qual?
                            <TextField
                                variant="outlined"
                                error={!!formErrors.outras_deficiencia}
                                onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, outras_deficiencia: e.target.value })}
                                value={formValues?.PCD?.outras_deficiencia || ''}
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6} style={{ marginTop: '5px' }}>
                        Possui dificuldade de locomoção?
                        <RadioGroup
                            row
                            aria-label="dificuldadeLocomocao"
                            name="dificuldadeLocomocao"
                            required
                            error={!!formErrors.dificuldade_locomocao}
                            style={{ marginLeft: '13px' }}
                            onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, dificuldade_locomocao: e.target.value })}
                            value={formValues?.PCD?.dificuldade_locomocao || ''}
                        >
                            <Grid item xs={12} sm={3}>

                                <FormControlLabel
                                    value="Nao"
                                    control={<Radio color="primary" />}
                                    label="Não"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>

                                <FormControlLabel
                                    value="Sim"
                                    control={<Radio color="primary" />}
                                    label="Sim"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <FormControlLabel
                                    value="Cadeirante"
                                    control={<Radio color="primary" />}
                                    label="Sou Cadeirante"
                                />
                            </Grid>
                        </RadioGroup>
                    </Grid>

                    {((formValues?.PCD?.dificuldade_locomocao === 'Sim') || (formValues?.PCD?.dificuldade_locomocao === 'Cadeirante')) && (
                        <Grid item xs={12} sm={6} style={{ marginTop: '5px' }}>
                            Precisa de acompanhamentos ou recurso de acessibilidade para participar do evento?
                            <RadioGroup
                                row
                                aria-label="acompanhamentoEvento"
                                name="acompanhamentoEvento"
                                required
                                style={{ marginLeft: '13px' }}
                                onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, acompanhamento_acessibilidade: e.target.value })}
                                value={formValues?.PCD?.acompanhamento_acessibilidade || ''}
                            >
                                <Grid item xs={12} sm={3}>

                                    <FormControlLabel
                                        value="Nao"
                                        control={<Radio color="primary" />}
                                        label="Não"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>

                                    <FormControlLabel
                                        value="Sim"
                                        control={<Radio color="primary" />}
                                        label="Sim"
                                    />
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    )}
                    {formValues?.PCD?.acompanhamento_acessibilidade === "Sim" && (
                        <Grid item xs={12} sm={6}>
                            Sim. Qual?
                            <TextField
                                error={!!formErrors.descricao_acompanhamento_acessibilidade}
                                variant="outlined"
                                onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, descricao_acompanhamento_acessibilidade: e.target.value })}
                                value={formValues?.PCD?.descricao_acompanhamento_acessibilidade || ''}
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

export default AtendimentoEspecializado;
