import axios from 'axios';

const api = axios.create({
  // Local:
  // baseURL: process.env.REACT_APP_API_URL,
  // HMG:
  baseURL: process.env.REACT_APP_API_URL,
  // headers: {
  //   "Content-Type": "application/json",
  //   "Accept": "application/json",
  //   "X-Requested-With": "XMLHttpRequest"
  // }
});

api.interceptors.request.use(
  async (config) => {
    try {
      const { tokens } = await import('./TokensApi');
      if (tokens.token) {
        config.headers.Authorization = `Bearer ${tokens.token}`;
      }
    } catch (error) {
      console.error('Erro ao obter o token do arquivo:', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
