import React from 'react';
import { Grid } from '@mui/material';
import Assinatura from '../images/assinatura.jpg';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    backgroundImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: -1
    },
    inicio: {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    nome: {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        fontSize: '2rem',
        color: 'rgba(0, 90, 156)'
    },
    texto: {
        position: 'absolute',
        top: '43%',
        left: '50%',
        width: '80%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        fontSize: '1.2rem'
    },
    assinatura: {
        position: 'absolute',
        top: '61%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    imgassinatura: {
        width: '45%',
        display: 'block',
        margin: '0 auto'
    }
});

const Certificado = React.forwardRef(({ nome, cargo, municipio, uf }, ref) => {
    return (
        <>
            <style type="text/css">
                {`
                @media print {
                    @page {
                        size: landscape;
                    }
                    .certificado-to-print {
                        width: 100vw;
                        height: 100vh;
                    }
                    .certificado-to-print .MuiGrid-root {
                        width: 100%;
                        height: 100%;
                    }
                }
                `}
            </style>
            <Document ref={ref}>
                <Grid className='certificado' container>
                    <Page size="A6" orientation='landscape' style={styles.page}>
                        <View style={styles.inicio}>
                            A Confederação Nacional de Municípios (CNM) certifica que:
                        </View>
                        <View style={styles.nome}>
                            <Text>{nome}</Text>
                        </View>
                        <View style={styles.texto}>
                            <Text>{cargo} de {municipio}/{uf} participou da XXV Marcha a Brasília em Defesa dos Municípios, realizada entre os dias 20 e 23 de maio de 2024, no Centro Internacional de Convenções do Brasil (CICB), atingindo a carga horária de 70% de participação.</Text>
                        </View>
                        <View style={styles.assinatura}>
                            <img src={Assinatura} style={styles.imgassinatura} alt='Assinatura do Presidente' />
                        </View>
                    </Page>
                </Grid>
            </Document>
        </>
    )
});

export default Certificado;
