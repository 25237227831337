import React, { useState } from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Button,
    TextField,
    Grid,
    Paper,
    TableSortLabel,
    InputAdornment,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@material-ui/icons/Search';

const Filtros = ({ searchTerm, orderBy, order, sortedAndFilteredDadosCaixa, dadosFluxoCaixa, isAdmin, hasPermission, setSearchTerm, handleSort, InfoCaixas, valorViaSistema }) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const Caixas = sortedAndFilteredDadosCaixa.slice(startIndex, endIndex);

    return (
        <>
            <Paper>
                <Card style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: '#f0f0f0' }}>
                    <CardContent>
                        <Typography variant="h4" align="center">
                            Via sistema:
                            <span style={{ color: 'blue' }}> R$ {valorViaSistema?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                        </Typography>

                    </CardContent>
                </Card>
                <TableContainer style={{ marginTop: '10px' }} component={Paper} className="custom-table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        Dinheiro
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        Cartão
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        Transferência
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        Cheque
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        PIX
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        Filiação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        Total Fechamento Caixa
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        Total Fluxo Caixa
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel>
                                        Furo
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {dadosFluxoCaixa.map((dadosFluxoCaixa) => ( */}
                            <TableRow key={dadosFluxoCaixa.id}>
                                <TableCell style={{ textAlign: 'center' }}>R$ {dadosFluxoCaixa?.dinheiro?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>R$ {dadosFluxoCaixa?.cartao?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>R$ {dadosFluxoCaixa?.transferencia?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>R$ {dadosFluxoCaixa?.cheque?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>R$ {dadosFluxoCaixa?.pix?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>R$ {dadosFluxoCaixa?.filiacao?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>R$ {dadosFluxoCaixa?.total_fechamento_caixa?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>R$ {dadosFluxoCaixa?.total_fluxo_caixa?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center', color: dadosFluxoCaixa?.furo_de_caixa < 0 ? 'red' : 'inherit' }}>
                                    R$ {dadosFluxoCaixa?.furo_de_caixa?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                </TableCell>
                            </TableRow>
                            {/* ))} */}
                        </TableBody >
                    </Table >
                </TableContainer >
                <Card style={{ backgroundColor: '#f0f0f0' }}>
                    <CardContent>
                        <Typography variant="h4" align="center">
                            Total:
                            <span style={{ color: 'green' }}> R$ {((valorViaSistema || 0) + (dadosFluxoCaixa?.total_fechamento_caixa || 0)).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                        </Typography>

                    </CardContent>
                </Card>
            </Paper >

            <Paper style={{ marginTop: '20px' }}>
                <Grid container xs={12} sm={12}>
                    <Grid item xs={12} sm={9}>
                    </Grid>
                    <Grid item alignItems="center" justifyContent="space-between" xs={12} sm={3}>
                        <Grid item>
                            <TextField
                                label="Pesquisar"
                                variant="outlined"
                                value={searchTerm}
                                style={{ marginTop: '10px' }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer style={{ marginTop: '10px' }} component={Paper} className="custom-table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel
                                        active={orderBy === 'nome'}
                                        direction={orderBy === 'nome' ? order : 'desc'}
                                        onClick={() => handleSort('nome')}
                                    >
                                        Caixa
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                    <TableSortLabel
                                        active={orderBy === 'status'}
                                        direction={orderBy === 'status' ? order : 'asc'}
                                        onClick={() => handleSort('status')}
                                    >
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                {isAdmin || hasPermission('financeiro', 'escrita') ? (
                                    <>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            Ações
                                        </TableCell>
                                    </>
                                ) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Caixas.map((dadosCaixas) => (
                                <TableRow key={dadosCaixas.id}>
                                    <TableCell style={{ textAlign: 'center' }}>{dadosCaixas.perfil}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{dadosCaixas?.status}</TableCell>
                                    {isAdmin || hasPermission('financeiro', 'escrita') ? (
                                        <>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <div >
                                                    <Button onClick={() => InfoCaixas(dadosCaixas)} style={{ color: '#007bff' }}>
                                                        <InfoIcon />
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        </>
                                    ) : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={sortedAndFilteredDadosCaixa.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                        labelRowsPerPage="Registros por página:"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    />
                </TableContainer>

            </Paper >
        </>
    );
}

export default Filtros;