import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Button, Grid, Box, Container, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Navbar from '../pages/theme/Navbar';
import api from '../service/Api';

const FormularioEvento = () => {
    const { formId } = useParams();
    const [fields, setFields] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const fetchFormFields = async () => {
            try {
                const response = await api.get(`/api/eventos/cadastroEvento/${formId}`);
                const data = Array.isArray(response.data) ? response.data : [response.data];
                setFields(data);
                console.log(data);
            } catch (error) {
                console.error('Erro ao buscar os campos do formulário', error);
            }
        };

        fetchFormFields();
    }, [formId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form data submitted:', formData);
    };

    return (
        <React.Fragment>
            <Navbar />
            <Container style={{ marginTop: '20px' }}>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        {fields.map((field) => {
                            const fieldId = field.id;
                            return (
                                <React.Fragment key={fieldId}>
                                    {field.formulario.documento === "passaporte" && (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Documento (Passaporte)"
                                                name="documento"
                                                required={field.formulario.documento_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.nome_ativo === 1 && (
                                        <Grid item xs={12} key={`nome_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Nome"
                                                name="nome"
                                                required={field.formulario.nome_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.email_ativo === 1 && (
                                        <Grid item xs={6} key={`email_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                required={field.formulario.email_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.celular_ativo === 1 && (
                                        <Grid item xs={6} key={`celular_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Celular"
                                                name="celular"
                                                required={field.formulario.celular_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.telefone_fixo_ativo === 1 && (
                                        <Grid item xs={6} key={`telefone_fixo_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Telefone Fixo"
                                                name="telefone_fixo"
                                                required={field.formulario.telefone_fixo_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.cep_ativo === 1 && (
                                        <Grid item xs={6} key={`cep_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="CEP"
                                                name="cep"
                                                required={field.formulario.cep_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.logradouro_ativo === 1 && (
                                        <Grid item xs={6} key={`logradouro_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Logradouro"
                                                name="logradouro"
                                                required={field.formulario.logradouro_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.complemento_ativo === 1 && (
                                        <Grid item xs={6} key={`complemento_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Complemento"
                                                name="complemento"
                                                required={field.formulario.complemento_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.bairro_ativo === 1 && (
                                        <Grid item xs={6} key={`bairro_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Bairro"
                                                name="bairro"
                                                required={field.formulario.bairro_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.municipio_ativo === 1 && (
                                        <Grid item xs={6} key={`municipio_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Município"
                                                name="municipio"
                                                required={field.formulario.municipio_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.uf_ativo === 1 && (
                                        <Grid item xs={6} key={`uf_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="UF"
                                                name="uf"
                                                required={field.formulario.uf_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.pais_ativo === 1 && (
                                        <Grid item xs={6} key={`pais_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="País"
                                                name="pais"
                                                required={field.formulario.pais_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.data_nascimento_ativo === 1 && (
                                        <Grid item xs={6} key={`data_nascimento_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Data de Nascimento"
                                                name="data_nascimento"
                                                type="date"
                                                required={field.formulario.data_nascimento_obrigatorio === 1}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                    {field.formulario.sexo_ativo === 1 && (
                                        <Grid item xs={6} key={`sexo_${fieldId}`}>
                                            <FormControl fullWidth required={field.formulario.sexo_obrigatorio === 1}>
                                                <InputLabel id='sexo'>Sexo</InputLabel>
                                                <Select
                                                    labelId='sexo'
                                                    name="sexo"
                                                    onChange={handleChange}
                                                    label="Sexo"
                                                >
                                                    <MenuItem value="Feminino">Feminino</MenuItem>
                                                    <MenuItem value="Masculino">Masculino</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {field.formulario.outro_celular_ativo === 1 && (
                                        <Grid item xs={6} key={`outro_celular_${fieldId}`}>
                                            <TextField
                                                fullWidth
                                                label="Outro Celular"
                                                name="outro_celular"
                                                required={field.formulario.outro_celular_obrigatorio === 1}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
                        Enviar
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default FormularioEvento;
