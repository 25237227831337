import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ConstantInscricoes from "../constants/ConstantInscricoes";
import { getArenas, getArenasPorCpf } from "../service/arenas";
import LoadingOverlay from "../components/LoadingOverlay";

export default function ConsultarArenas() {
  const {
    HandleSnackbarClose,
    snackbarOpen,
    snackbarMessage,
    snackbarSeverity,
    showAlert,
  } = ConstantInscricoes();

  const [cpfUser, setCpfUser] = useState("");
  const [arenas, setArenas] = useState([]);
  const [arena, setArena] = useState(undefined);
  const [resultado, setResultado] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [hoje, setHoje] = useState("");

  const FormatCPF = (value) => {
    if (value) {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
      return value;
    }

    return "";
  };

  const focusCpf = () => document.getElementById("cpf").focus();

  const formatarDiaHoje = () => {
    const agora = new Date();
    const yyyy = agora.getFullYear();
    let mm = agora.getMonth() + 1;
    let dd = agora.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const agoraFormatado = dd + "/" + mm + "/" + yyyy;

    setHoje(agoraFormatado);
  };

  const carregarArenas = () => {
    setIsLoading(true);

    getArenas()
      .then((response) => {
        setArenas(
          (response?.data?.Arenas || [])
            // .filter((arena) => arena.dia === hoje)
            .map((arena) => arena.area_tecnica)
        );
      })
      .catch((error) => showAlert(error?.response?.data?.Arenas, "error"))
      .finally(() => setIsLoading(false));
  };

  const consultar = () => {
    if (!cpfUser || cpfUser.length < 11 || !arena) {
      return;
    }

    setIsLoading(true);

    getArenasPorCpf(cpfUser.replaceAll(".", "").replaceAll("-", ""))
      .then((response) =>
        setResultado(
          (response?.data?.Arenas || []).filter(
            (_arena) => _arena.area_tecnica === arena
          )
        )
      )
      .catch((error) => {
        showAlert(error?.response?.data?.Arenas, "error");
        setResultado(undefined);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    focusCpf();
    formatarDiaHoje();
  }, []);

  useEffect(() => {
    if (hoje) {
      carregarArenas();
    }
    // eslint-disable-next-line
  }, [hoje]);

  return (
    <React.Fragment>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={HandleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={HandleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Grid
        container
        item
        xs={12}
        sm={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
        }}
      >
        <Grid xs={12} sm={4} item>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              consultar();
            }}
          >
            <FormControl required fullWidth style={{ marginTop: 20 }}>
              <Typography variant="h6">Data: {hoje}</Typography>
              <Typography variant="h6" style={{ marginTop: 20 }}>
                Selecione uma arena para consulta:
              </Typography>

              <Autocomplete
                disablePortal
                id="combo-arenas"
                options={arenas}
                style={{ marginTop: 20 }}
                renderInput={(params) => (
                  <TextField {...params} label="Arenas" />
                )}
                onChange={(event, newValue) => setArena(newValue)}
              />

              <Typography variant="h6" style={{ marginTop: 30 }}>
                Informe o CPF para consultar inscrição na arena
              </Typography>

              <TextField
                variant="outlined"
                required
                fullWidth
                id="cpf"
                label="CPF"
                name="cpf"
                autoComplete="cpf"
                value={FormatCPF(cpfUser)}
                onChange={(e) => setCpfUser(e.target.value)}
                onBlur={focusCpf}
                inputProps={{ maxLength: 14 }}
                style={{ marginTop: 20 }}
              />

              <Button
                type="submit"
                style={{ marginTop: 10 }}
                variant="contained"
                color="success"
              >
                Consultar
              </Button>
            </FormControl>
          </form>

          {isLoading ? <LoadingOverlay /> : ""}

          {resultado !== undefined ? (
            <>
              <Typography
                variant="h6"
                align="center"
                style={{ marginTop: 20 }}
                color={!resultado.length ? "error" : "success"}
              >
                PARTICIPANTE {!resultado.length ? "NÃO" : ""} INSCRITO
              </Typography>

              {resultado.length ? (
                <TableContainer component={Paper} style={{ marginTop: 20 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" component="th">
                          Área Técnica
                        </TableCell>
                        <TableCell align="center" component="th">
                          Dia
                        </TableCell>
                        <TableCell align="center" component="th">
                          Horário
                        </TableCell>
                        <TableCell align="center" component="th">
                          Sala
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {resultado.map((arena) => (
                        <TableRow key={arena.id}>
                          <TableCell scope="row">
                            {arena.area_tecnica}
                          </TableCell>
                          <TableCell
                            scope="row"
                            align="center"
                            style={{ width: 100 }}
                          >
                            {arena.dia}
                          </TableCell>
                          <TableCell
                            scope="row"
                            align="center"
                            style={{ width: 130 }}
                          >
                            {arena.horario}
                          </TableCell>
                          <TableCell
                            scope="row"
                            align="center"
                            style={{ width: 80 }}
                          >
                            {arena.sala}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
