import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    page: {
        flexDirection: 'column',
        height: '10cm',
        width: '5cm'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.6rem',
        lineHeight: '1.2em',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(0.2),
    },
    rowenv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '1rem',
        marginTop: '2%',
    },
    labelenv: {
        fontWeight: 'bold',
    },
    perfil: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontWeight: 'bold',
        fontSize: '0.6rem'
    },
    label: {
        marginRight: theme.spacing(0.5), // Reduzindo a margem à direita dos rótulos
    },
}));

const EtiquetaCaixa = React.forwardRef(({ perfil, data, dinheiro, cheque, transferencia, cartao, pix, filiacao, furos }, ref) => {
    const checkUndefined = (value) => {
        return value !== undefined ? value : '0';
    };

    const envelopeSum = parseFloat(checkUndefined(dinheiro)) + parseFloat(checkUndefined(cheque)) + parseFloat(checkUndefined(transferencia)) + parseFloat(checkUndefined(cartao)) + parseFloat(checkUndefined(pix)) + parseFloat(checkUndefined(filiacao));
    const classes = useStyles();

    return (
        <Document ref={ref} style={{ height: '10cm', width: '5cm' }}>
            <Page size="A6" orientation="landscape">
                <View className={classes.root}>
                    <View className={classes.perfil}>
                        <Text>
                            {checkUndefined(perfil) ? checkUndefined(perfil) : ''} - Nº _____
                        </Text>
                    </View>
                    <View className={classes.row}>
                        <Text className={classes.label}>Retirada em: </Text>
                        <Text>{'' + checkUndefined(data)}</Text>
                    </View>
                    <View className={classes.row}>
                        <Text className={classes.label}>Dinheiro:</Text>
                        <Text>{'R$' + checkUndefined(dinheiro)}</Text>
                    </View>
                    <View className={classes.row}>
                        <Text className={classes.label}>Cheque:</Text>
                        <Text>{'R$' + checkUndefined(cheque)}</Text>
                    </View>
                    <View className={classes.row}>
                        <Text className={classes.label}>Transferência:</Text>
                        <Text>{'R$' + checkUndefined(transferencia)}</Text>
                    </View>
                    <View className={classes.row}>
                        <Text className={classes.label}>Cartão:</Text>
                        <Text>{'R$' + checkUndefined(cartao)}</Text>
                    </View>
                    <View className={classes.row}>
                        <Text className={classes.label}>Pix:</Text>
                        <Text>{'R$' + checkUndefined(pix)}</Text>
                    </View>
                    <View className={classes.row}>
                        <Text className={classes.label}>Filiação:</Text>
                        <Text>{'R$' + checkUndefined(filiacao)}</Text>
                    </View>
                    <View className={classes.row}>
                        <Text className={classes.label}>Furos:</Text>
                        <Text>{'R$' + checkUndefined(furos)}</Text>
                    </View>
                    <View className={classes.rowenv}>
                        <Text className={classes.labelenv}>Envelope:</Text>
                        <Text>{'R$' + envelopeSum}</Text>
                    </View>
                    <View style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        <Text>XXV Marcha à Brasilia</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
});

export default EtiquetaCaixa;



