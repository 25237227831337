import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';

function InputsTotem({ label, placeholder, value, onChange, type, variant = 'outlined', width = '100%', autoFocus }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <TextField
      inputRef={inputRef}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      variant={variant}
      type={type}
      inputProps={{ maxLength: 11 }}
      style={{ width, marginBottom: '16px' }}
    />
  );
}

export default InputsTotem;
