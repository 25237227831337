import React from 'react';

import {
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    Link,
  } from '@material-ui/core';

function TermosDeUso({formValues, HandleChangeForm}) {
    return (
        <Grid item xs={12} sm={12} style={{userSelect: 'none'}}>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={formValues?.politica_privacidade}
                        onChange={(e) => HandleChangeForm('politica_privacidade', e.target.checked)}
                    />
                }
                label={
                    <Typography>
                        Ao me inscrever na Marcha/2024, autorizo a Confederação Nacional de Municípios (CNM) a utilizar os meus
                        dados pessoais e a compartilhá-los com os patrocinadores desse evento, com a finalidade de me informar sobre
                        futuros eventos, ações e atividades. Observação: a autorização acima pode ser revogada a qualquer tempo,
                        conforme orientações em nossa{' '}
                        <Link target="_blank" href="https://www.cnm.org.br/informe/exibe/politica-de-privacidade">
                            Política de Privacidade
                        </Link>
                        .
                    </Typography>
                }
            />
        </Grid>
    );
}

export default TermosDeUso;