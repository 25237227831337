import React, { useEffect, useState } from 'react';
import {
    Container,
    TextField,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    FormControl,
    InputAdornment,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup,
    Checkbox,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Modal,
    TextareaAutosize,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import { Countries } from '../constants/Nation';
import api from '../service/Api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ConstantInscricoes from "../constants/ConstantInscricoes";
import UpdateIcon from '@material-ui/icons/Update';
import FreeIcon from '@mui/icons-material/Handshake';
import SaveIcon from '@material-ui/icons/Save';
import CustomModal from './CustomModal';
import CancelIcon from '@mui/icons-material/Cancel';
import Caixas from './Caixas';

const EditarInscricao = ({ id, setFuncionalidade, HandleSearch }) => {
    const {
        HandleSnackbarClose,
        showAlert,
        FormatCPF,
        FormatCNPJ,
        FormatCEP,
        FormatCelular,
        FormatTelefone,
        HandleChangeForm,
        HandleChangeCheckbox,
        HandleCheckboxChange,
        setFormValues,
        setCodigoUf,
        setMunicipio,
        getEntidadeOpcoesPorTipoCargo,
        getEntidadeValorPorTipoCargo,
        getEntidadeNomeFormulario,
        getEntidadeLabelPorTipoCargo,
        TransformFormData,
        CreateFormData,
        CalculoDeValores,
        getStatusLabel,
        handlePagamentosChange,
        SalvarPagamentos,
        hasPermission,
        dadosMunicipio,
        isCaixa,
        isAdmin,
        ufs,
        areaDeAtuacao,
        naturezas,
        tipoCargo,
        cargo,
        consorcio,
        municipio,
        formValues,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        parlamentar,
        partidoPolitico
    } = ConstantInscricoes();
    const [abrirReenviarEmail, setAbrirReenviarEmail] = useState(false);
    const [abrirDadosPagamento, setAbrirDadosPagamento] = useState(false);
    const [openModalAtualizarBoleto, setOpenModalAtualizarBoleto] = useState(false);
    const [openModalCriarBoleto, setOpenModalCriarBoleto] = useState(false);
    const [openModalGerarCortesia, setOpenModalGerarCortesia] = useState(false);
    const [openModalObservacao, setOpenModalObservacao] = useState(false);
    const [openModalCancelarBoleto, setOpenModalCancelarBoleto] = useState(false);
    const [desabilitarBotao, setDesabilitarBotao] = useState(false);
    const [reload, setReload] = useState(false);

    const handleOpenModalAtualizarBoleto = () => {
        setOpenModalAtualizarBoleto(true);
    };
    const handleCloseModalAtualizarBoleto = () => {
        setOpenModalAtualizarBoleto(false);
    };

    const handleOpenModalCriarBoleto = () => {
        setOpenModalCriarBoleto(true);
    };
    const handleCloseModalCriarBoleto = () => {
        setOpenModalCriarBoleto(false);
    };

    const handleOpenModalCancelarBoleto = () => {
        setOpenModalCancelarBoleto(true);
    };
    const handleCloseModalCancelarBoleto = () => {
        setOpenModalCancelarBoleto(false);
    };

    const handleOpenModalGerarCortesia = () => {
        setOpenModalGerarCortesia(true);
    };
    const handleCloseModalGerarCortesia = () => {
        setOpenModalGerarCortesia(false);
    };

    const handleOpenModalObservacao = () => {
        setOpenModalObservacao(true);
    };
    const handleCloseModalObservacao = () => {
        setOpenModalObservacao(false);
    };

    const handleChangeAbrirEmail = () => {
        setAbrirReenviarEmail((prevExpanded) => !prevExpanded);
    };
    const handleChangeAbrirDadosPagamento = () => {
        setAbrirDadosPagamento((prevExpanded) => !prevExpanded);
    };
    const isDisabled = !Object.keys(formValues.reenviarEmail).some((key) => key !== 'id' && formValues.reenviarEmail[key]);

    useEffect(() => {
        if (!id) {
            handleVoltar();
        }
    });

    const handleVoltar = () => {
        HandleSearch();
        setFuncionalidade('');
    };

    const AtualizarVencimentoBoleto = async () => {
        try {
            const formData = new FormData();
            formData.append('numeroCliente', formValues?.numeroTituloCliente);
            setDesabilitarBotao(true);
            const response = await api.post('api/boleto', formData);
            if (response?.data?.mensage?.dataAtualizacao) {
                showAlert("Boleto atualizado com sucesso!", 'success');
                handleCloseModalAtualizarBoleto();
                setDesabilitarBotao(false);
            } else {
                showAlert("Não foi possível atualizar a data de vencimento do boleto. Por favor, tente novamente mais tarde", 'error');
                setDesabilitarBotao(false);
                handleCloseModalAtualizarBoleto();
            }
            setTimeout(() => {
                setReload(true);
            }, 1000);
        } catch (error) {
            showAlert("Não foi possível completar a ação. Verifique se o boleto ainda está apto para pagamento ou tente novamente mais tarde", 'error');
            setDesabilitarBotao(false);
            handleCloseModalAtualizarBoleto();
        }
    };

    const CancelarBoleto = async () => {
        try {
            const updatedFormValues = TransformFormData({
                ...formValues,
                aprovado: "false",
                cortesia: '0',
            });
            const formData = CreateFormData(updatedFormValues);

            const responseAprovacao = await api.put(`api/cadastroUser/${id}`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest"
                }
            });

            if (responseAprovacao?.data?.status === 200) {
                const { numeroTituloCliente } = formValues;
                setDesabilitarBotao(true);
                const response = await api.post('api/baixaBoleto', { numeroCliente: numeroTituloCliente });
                if (response.status === 200) {
                    showAlert("Boleto cancelado com sucesso!", 'success');
                }
                handleCloseModalCancelarBoleto();
                setDesabilitarBotao(false);

                setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    statusBoleto: "",
                    boletoBaixado: true,
                }));

                setTimeout(() => {
                    setReload(true);
                }, 1000);
            } else {
                showAlert('Erro ao atualizar status para aprovado. Por favor, tente novamente mais tarde', 'error');
            }
        } catch (error) {
            showAlert("Não foi possível cancelar o boleto. Por favor, tente novamente mais tarde", 'error');
            handleCloseModalCancelarBoleto();
            setDesabilitarBotao(false);
        }
    };

    const CriarNovoBoleto = async () => {
        try {
            const { cpf, cnpj, celular, cep, endereco, bairro, opc_pagamento, tipo_pagamento, municipio, uf, numeroTituloCliente, nome, valorBoleto } = formValues;
            const formattedCPF = cpf.replace(/\D/g, '');

            const valorSubstituicao = CalculoDeValores(formValues?.cargo, formValues?.status_contribuicao, formValues?.uf);

            const valoresMarcha = parseFloat(valorSubstituicao.replace(/,00$/, ''));
            const valorFormulario = parseFloat(valorBoleto.replace(/,00$/, ''));

            setDesabilitarBotao(true);
            const requestBody = {
                cpf: formattedCPF,
                cnpj,
                celular,
                cep,
                endereco,
                bairro,
                opc_pagamento,
                tipo_pagamento,
                municipio,
                uf,
                valor: valoresMarcha === valorFormulario ? valoresMarcha : valorFormulario,
                numeroCliente: numeroTituloCliente,
                nome,
            };

            const response = await api.post('api/novoBoleto', requestBody);

            if (response?.data?.NovoBoleto) {
                const updatedFormValues = TransformFormData({ ...formValues, aprovado: "false", obs: "", cortesia: "0" });
                const formData = CreateFormData(updatedFormValues);

                const responseAprovacao = await api.put(`api/cadastroUser/${id}`, formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "X-Requested-With": "XMLHttpRequest"
                    }
                });

                if (responseAprovacao?.data?.status === 200) {
                    showAlert("Novo boleto criado com sucesso!", 'success');
                    handleCloseModalCriarBoleto();
                    setDesabilitarBotao(false);
                } else {
                    showAlert("Não foi possível criar um novo pagamento. Por favor, tente novamente mais tarde", 'error');
                    setDesabilitarBotao(false);
                }
                setTimeout(() => {
                    setReload(true);
                }, 1000);
            } else {
                showAlert("Não foi possível criar um novo pagamento. Por favor, tente novamente mais tarde", 'error');
                setDesabilitarBotao(false);
            }
        } catch (error) {
            showAlert("Não foi possível criar um novo pagamento. Por favor, tente novamente mais tarde", 'error');
            setDesabilitarBotao(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setReload(false);
                const response = await api.get(`api/cadastroUser?id=${id}`);
                const dadosInscricaoAPI = response?.data?.getCadastroUser[0];
                if (dadosInscricaoAPI) {
                    const PCD = JSON.parse(dadosInscricaoAPI.PCD || '{}');
                    const convidado = JSON.parse(dadosInscricaoAPI.convidado || '{}');

                    setFormValues((prevFormValues) => ({
                        ...prevFormValues,
                        tipo_inscricao: dadosInscricaoAPI.tipo_inscricao || '',
                        tipo_cargo: dadosInscricaoAPI.tipo_cargo || '',
                        cargo: dadosInscricaoAPI.cargo || '',
                        entidade_estadual: dadosInscricaoAPI.entidade_estadual || '',
                        entidade_micro: dadosInscricaoAPI.entidade_micro || '',
                        entidade_nacionais: dadosInscricaoAPI.entidade_nacionais || '',
                        consorcio: dadosInscricaoAPI.consorcio || '',
                        partido_politico: dadosInscricaoAPI.partido_politico || '',
                        parlamentar: dadosInscricaoAPI.parlamentar || '',
                        pais: dadosInscricaoAPI.pais || '',
                        area_atuacao: dadosInscricaoAPI.area_atuacao || '',
                        empresa_instituicao: dadosInscricaoAPI.empresa_instituicao || '',
                        ministerio_orgao: dadosInscricaoAPI.ministerio_orgao || '',
                        nome: dadosInscricaoAPI.nome || '',
                        genero: dadosInscricaoAPI.genero || '',
                        PCD: {
                            ...prevFormValues.PCD,
                            necessita_atendimento_especializado: PCD?.necessita_atendimento_especializado || '',
                            tipo_deficiencias: PCD?.tipo_deficiencias || [],
                            outras_deficiencia: PCD?.outras_deficiencia || '',
                            dificuldade_locomocao: PCD?.dificuldade_locomocao || '',
                            acompanhamento_acessibilidade: PCD?.acompanhamento_acessibilidade || '',
                            descricao_acompanhamento_acessibilidade: PCD?.descricao_acompanhamento_acessibilidade || '',
                        },
                        nome_cracha: dadosInscricaoAPI.nome_cracha || '',
                        cpf: dadosInscricaoAPI.cpf || '',
                        passaporte: dadosInscricaoAPI.passaporte || '',
                        cnpj: dadosInscricaoAPI.cnpj || '',
                        uf: dadosInscricaoAPI.uf || '',
                        municipio: dadosInscricaoAPI.municipio || '',
                        celular: dadosInscricaoAPI.celular || '',
                        email: dadosInscricaoAPI.email || '',
                        telefone: dadosInscricaoAPI.telefone || '',
                        cep: dadosInscricaoAPI.cep || '',
                        endereco: dadosInscricaoAPI.endereco || '',
                        bairro: dadosInscricaoAPI.bairro || '',
                        razao_social: dadosInscricaoAPI.razao_social || '',
                        opc_pagamento: dadosInscricaoAPI.opc_pagamento || '',
                        status_contribuicao: dadosInscricaoAPI.status_contribuicao || '',
                        politica_privacidade: dadosInscricaoAPI.politica_privacidade || '',
                        tipo_pagamento: dadosInscricaoAPI.tipo_pagamento || '',
                        obs: dadosInscricaoAPI.obs || '',
                        valorBoleto: dadosInscricaoAPI.valor || '',
                        statusBoleto: dadosInscricaoAPI.status || '',
                        numeroTituloCliente: dadosInscricaoAPI.numeroTituloCliente || '',
                        cortesia: dadosInscricaoAPI?.cortesia,
                        convidado: {
                            ...prevFormValues.convidado,
                            cargo: convidado?.cargo || '',
                            convidado_de: convidado?.convidado_de || '',
                        },
                        reenviarEmail: {
                            ...prevFormValues.reenviarEmail,
                            id: dadosInscricaoAPI.id
                        }
                    }));
                    HandleChangeForm('aprovado', dadosInscricaoAPI.aprovado);
                }
            } catch (error) {
                console.error('Erro ao obter os dados da inscrição', error);
            }
        };
        fetchData();
    }, [id, reload, setFormValues, HandleChangeForm]);

    useEffect(() => {
        const fetchDadosBoleto = async () => {
            try {
                const response = await api.get(`api/detalhaBoleto?numeroCliente=${formValues?.numeroTituloCliente}`);
                const dataRecebimentoTitulo = response?.data?.mensageBoleto?.dataRecebimentoTitulo;
                const dataVencimentoBoleto = response?.data?.mensageBoleto?.dataVencimentoTituloCobranca;
                const verificaBaixado = response?.data?.mensageBoleto?.codigoEstadoTituloCobranca === 7 ? true : false;
                HandleChangeForm('boletoBaixado', verificaBaixado);
                if (verificaBaixado === true) {
                    HandleChangeForm('statusBoleto', 'Baixado');
                }
                if (dataRecebimentoTitulo && dataRecebimentoTitulo !== '01.01.0001') {
                    const dataFormatada = dataRecebimentoTitulo.replaceAll('.', '/');
                    HandleChangeForm('dataPagamentoBoleto', dataFormatada);
                } else if (dataVencimentoBoleto) {
                    const dataFormatada = dataVencimentoBoleto.replaceAll('.', '/');
                    HandleChangeForm('dataVencimentoTituloCobranca', dataFormatada);
                }
            } catch (error) {
                console.error('Ocorreu um erro ao buscar as informações de boleto', error);
            }
        };

        if (formValues?.numeroTituloCliente) {
            fetchDadosBoleto();
        }
    }, [formValues?.numeroTituloCliente, HandleChangeForm]);

    useEffect(() => {
        if (!formValues?.valorBoleto) {
            if ([3, 16].includes(formValues?.cargo) && (formValues?.status_contribuicao === "false" || formValues?.status_contribuicao === false)) {
                HandleChangeForm('valorBoleto', process.env.REACT_APP_VALOR_PREFEITO_NAO_CONTRIBUINTE);
            } else if (formValues?.status_contribuicao === true || formValues?.status_contribuicao === "true") {
                HandleChangeForm('valorBoleto', process.env.REACT_APP_VALOR_MUNICIPIO_CONTRIBUINTE);
            } else if (formValues?.uf === 'DF') {
                HandleChangeForm('valorBoleto', process.env.REACT_APP_VALOR_DF);
            } else {
                HandleChangeForm('valorBoleto', process.env.REACT_APP_VALOR_DEMAIS);
            }
        }
    }, [formValues?.valorBoleto, formValues?.cargo, formValues?.status_contribuicao, formValues?.uf, HandleChangeForm, reload]);

    const GerarCortesia = async () => {
        try {
            if (!formValues?.obs) {
                return showAlert('Por favor, informe o motivo da aprovação da cortesia', 'error');
            }
            setDesabilitarBotao(true);
            const responseCortesia = await api.post(`api/baixaBoleto?numeroCliente=${formValues?.numeroTituloCliente}`);

            if (responseCortesia?.status === 200) {
                const updatedFormValues = TransformFormData({
                    ...formValues,
                    aprovado: "false",
                    cortesia: '1',
                    obs: 'Aprovado por: ' + JSON.parse(localStorage.getItem('user_credmarcha')).name + '. Considerações: ' + formValues?.obs
                });
                const formData = CreateFormData(updatedFormValues);

                const responseAprovacao = await api.put(`api/cadastroUser/${id}`, formData, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "X-Requested-With": "XMLHttpRequest"
                    }
                });

                if (responseAprovacao?.data?.status === 200) {
                    showAlert('Cortesia gerada com sucesso!', 'success');
                    setDesabilitarBotao(false);
                } else {
                    setDesabilitarBotao(false);
                    showAlert('Erro ao atualizar status para aprovado. Por favor, tente novamente mais tarde', 'error');
                }
                setTimeout(() => {
                    setReload(true);
                }, 1000);
                handleCloseModalGerarCortesia();
                handleCloseModalObservacao();
            }
        } catch (error) {
            setDesabilitarBotao(false);
            showAlert("Não foi possível gerar a cortesia. Por favor, tente novamente mais tarde", 'error');
            console.error(error);
        }
    };

    const handleEditUser = async (apenasEdicao) => {
        try {
            const updatedFormValues = TransformFormData(formValues);
            if (!apenasEdicao) {
                if (updatedFormValues?.pagamentos && updatedFormValues.pagamentos.length > 0) {
                    const salvarPagamentos = await SalvarPagamentos(id, updatedFormValues);
                    if (salvarPagamentos === 200) {
                        updatedFormValues.aprovado = 1;
                        updatedFormValues.tipo_pagamento = 'caixa';
                        showAlert('Pagamento inserido com sucesso', 'success');
                    } else {
                        return showAlert('Ocorreu um erro ao salvar o pagamento', 'error');
                    }
                }
            }
            const formData = CreateFormData(updatedFormValues);

            const response = await api.put(`api/cadastroUser/${id}`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest"
                }
            });

            if (response?.data?.status === 200) {
                setTimeout(() => {
                    showAlert('Cadastro atualizado com sucesso!', 'success');
                }, 1000);
            }
            setTimeout(() => {
                setReload(true);
            }, 1000);
        } catch (error) {
            const errors = error?.response?.data?.errors;
            console.log(errors);
            if (errors) {
                const errorMessage = Object.values(errors)
                    .flat()
                    .join(' ');
                showAlert(errorMessage, 'error');
            } else {
                showAlert('Ocorreu um erro ao realizar a inscrição. Por favor, tente novamente. Caso persista, favor, entre em contato com a CNM', 'error');
            }
        }
    };

    const handleEnviarEMail = async () => {
        try {
            const response = await api.post(`api/reenviarEmail`, formValues.reenviarEmail);

            if (response?.data?.reenviarEmail) {
                const emailResults = response.data.reenviarEmail;

                for (const key in emailResults) {
                    const emails = emailResults[key];
                    const statusKey = `status_${key}`;
                    const status = emailResults[statusKey] && emailResults[statusKey][0];

                    if (emails && emails.length > 0) {
                        const messageType = status === 200 ? 'success' : 'error';
                        const messageToShow = emails.join(', ');
                        if (!(messageToShow === '404' || messageToShow === '200')) {
                            showAlert(messageToShow, messageType);
                            await new Promise(resolve => setTimeout(resolve, 2000));
                        }
                    }
                }
            }
        } catch (error) {
            const status = error?.response?.status;
            const errors = error?.response?.data?.errors;

            if (status === 404 && errors && Object.keys(errors).length > 0) {
                const errorMessage = Object.values(errors)
                    .flat()
                    .join(' ');
                showAlert(errorMessage, 'warning');
            } else {
                showAlert('Ocorreu um erro ao realizar o envio de emails. Por favor, tente novamente.', 'error');
            }
        }
    };

    return (
        <Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <Button style={{ marginTop: '10px' }} variant="contained" onClick={handleVoltar}>Voltar</Button>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* <Typography variant="h6" gutterBottom>
                        Dados da Inscrição
                    </Typography> */}
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }} >Tipo de inscrição</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.tipo_inscricao}
                            value={formValues?.tipo_inscricao}
                            onChange={(e) => {
                                const tipoInscricaoSelecionado = e.target.value;
                                setFormValues({
                                    ...formValues,
                                    tipo_inscricao: tipoInscricaoSelecionado,
                                    tipo_cargo: '',
                                    cargo: '',
                                    area_atuacao: '',
                                    nome_cargo: '',
                                    convidado: {
                                        ...formValues.convidado,
                                        cargo: '',
                                        convidado_de: '',
                                    },
                                });
                            }}
                            IconComponent={naturezas.length === 1 ? () => null : undefined}
                            disabled={naturezas.length === 1}
                        >
                            {naturezas?.map((natureza) => (
                                <MenuItem key={natureza.id} value={natureza.id}>
                                    {natureza.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }} >Tipo cargo</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.tipo_cargo}
                            disabled={!formValues.tipo_inscricao}
                            value={formValues?.tipo_cargo}
                            onChange={(e) => {
                                const tipoCargoSelecionado = e.target.value;
                                setFormValues({
                                    ...formValues,
                                    tipo_cargo: tipoCargoSelecionado,
                                    cargo: '',
                                    area_atuacao: '',
                                    nome_cargo: '',
                                    convidado: {
                                        ...formValues.convidado,
                                        cargo: '',
                                        convidado_de: '',
                                    },
                                });
                            }}
                        >
                            {tipoCargo.map((tipo) => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                    {tipo.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        {formValues?.tipo_cargo === 132 ?
                            <>
                                <TextField
                                    variant="outlined"
                                    // error={!!formErrors?.convidadoCargo}
                                    fullWidth
                                    id="convidadoCargo"
                                    label="Cargo"
                                    name="convidadoCargo"
                                    autoComplete="convidadoCargo"
                                    value={formValues?.convidado?.cargo || ''}
                                    onChange={(e) => {
                                        const novoCargo = e.target.value;
                                        HandleChangeForm('cargo', formValues?.tipo_cargo);
                                        HandleChangeForm('convidado', { ...formValues.convidado, cargo: novoCargo });
                                        HandleChangeForm('nome_cargo', novoCargo);
                                    }}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </>
                            :
                            <>
                                <InputLabel style={{ marginLeft: '13px' }}>Cargo</InputLabel>
                                <Select
                                    variant="outlined"
                                    // error={!!formErrors.cargo}
                                    value={formValues?.cargo}
                                    disabled={!formValues.tipo_cargo}
                                    onChange={(e) => {
                                        const cargoSelecionado = e.target.value;
                                        const cargoSelecionadoDescricao = cargo.find((cargo) => cargo.id === cargoSelecionado)?.descricao || '';
                                        setFormValues({
                                            ...formValues,
                                            cargo: cargoSelecionado,
                                            nome_cargo: cargoSelecionadoDescricao,
                                            area_atuacao: '',
                                        });
                                    }}
                                >
                                    {cargo.map((cargo) => (
                                        <MenuItem key={cargo.id} value={cargo.id}>
                                            {cargo.descricao}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        }
                    </FormControl>
                </Grid>

                {formValues?.tipo_inscricao === 137 &&
                    <>
                        {[138, 146, 153].includes(formValues?.tipo_cargo) &&
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={getEntidadeOpcoesPorTipoCargo(formValues?.tipo_cargo) || []}
                                        getOptionLabel={(entidade) => entidade.NOME_ENTIDADE || ''}
                                        value={getEntidadeValorPorTipoCargo(formValues, formValues.tipo_cargo) || null}
                                        onChange={(e, selectedEntidade) => {
                                            HandleChangeForm(getEntidadeNomeFormulario(formValues?.tipo_cargo), selectedEntidade?.id);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={getEntidadeLabelPorTipoCargo(formValues?.tipo_cargo)}

                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        }

                        {formValues?.tipo_cargo === 15 &&
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={consorcio || []}
                                        getOptionLabel={(consorcio) => consorcio.NOME_ENTIDADE || ''}
                                        value={consorcio?.find((p) => p.id === formValues?.consorcio) || null}
                                        onChange={(e, selectedConsorcio) => {
                                            HandleChangeForm('consorcio', selectedConsorcio?.id);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Consorcio"

                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        }
                    </>
                }
                {(([26, 68].includes(formValues?.tipo_inscricao) && [49, 55, 97].includes(formValues?.tipo_cargo)) && (formValues?.cargo && ![50, 51, 52, 56, 57, 58, 98, 99].includes(formValues?.cargo))) &&
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={parlamentar || []}
                                getOptionLabel={(parlamentar) => parlamentar.nome || ''}
                                value={parlamentar?.find((p) => p.id === formValues?.parlamentar) || null}
                                onChange={(e, selectedParlamentar) => {
                                    HandleChangeForm('parlamentar', selectedParlamentar?.id || '', '', selectedParlamentar?.nome || '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"

                                        // error={!!formErrors.parlamentar}
                                        label="Parlamentar"

                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                }

                {(([26, 68].includes(formValues?.tipo_inscricao) && [49, 55, 97].includes(formValues?.tipo_cargo)) && (formValues?.cargo && [50, 51, 52, 56, 57, 58, 98, 99].includes(formValues?.cargo))) &&
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={partidoPolitico || []}
                                getOptionLabel={(partidoPolitico) => partidoPolitico.NOME + ' - ' + partidoPolitico.SIGLA || ''}
                                value={partidoPolitico?.find((p) => p.id === formValues?.partido_politico) || null}
                                onChange={(e, selectedPartidoPolitico) => {
                                    HandleChangeForm('partido_politico', selectedPartidoPolitico?.id);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"

                                        // error={!!formErrors.partido_politico}
                                        label="Partido Político"

                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                }
                {formValues?.tipo_cargo === 122 && (
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel style={{ marginLeft: '13px' }}>País</InputLabel>
                            <Select
                                variant="outlined"
                                // error={!!formErrors.pais}
                                value={formValues?.pais}
                                onChange={(e) => HandleChangeForm('pais', e.target.value)}
                            >
                                {Countries?.map((countrie) => (
                                    <MenuItem key={countrie.value} value={countrie.value}>
                                        {countrie.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {formValues?.cargo === 13 && (
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel style={{ marginLeft: '13px' }}>Áreas de Atuação</InputLabel>
                            <Select
                                variant="outlined"
                                // error={!!formErrors.area_atuacao}
                                value={formValues?.area_atuacao}
                                onChange={(e) => HandleChangeForm('area_atuacao', e.target.value)}
                            >
                                {areaDeAtuacao?.map((area) => (
                                    <MenuItem key={area.id} value={area.id}>
                                        {area.AREA_ATUACAO}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {formValues?.tipo_inscricao && formValues?.tipo_inscricao === 102 && (
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            // error={!!formErrors.empresa_instituicao}
                            onChange={(e) => HandleChangeForm('empresa_instituicao', e.target.value)}
                            value={formValues?.empresa_instituicao}
                            fullWidth
                            label="Empresa/Instituição"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                )}

                {formValues?.tipo_inscricao === 26 && formValues?.tipo_cargo === 160 &&
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            // error={!!formErrors.ministerio_orgao}
                            onChange={(e) => HandleChangeForm('ministerio_orgao', e.target.value)}
                            value={formValues?.ministerio_orgao}
                            fullWidth
                            label="Ministério"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                }

                {formValues?.tipo_cargo === 132 && (
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            // error={!!formErrors?.convidadoDe}
                            value={formValues?.convidado?.convidado_de || ''}
                            onChange={(e) => {
                                const convidadoDe = e.target.value;
                                HandleChangeForm('convidado', { ...formValues.convidado, convidado_de: convidadoDe });
                            }}
                            fullWidth
                            label="Convidado de"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.nome}
                        onBlur={() => {
                            if (formValues.nome && !formValues.nome_cracha) {
                                const [primeiroNome, ...outrosNomes] = formValues.nome.split(' ');
                                const ultimoNome = outrosNomes.pop() || '';
                                const nomeCracha = `${primeiroNome} ${ultimoNome}`;
                                HandleChangeForm('nome_cracha', nomeCracha);
                            }
                        }}
                        onChange={(e) => HandleChangeForm('nome', e.target.value)}
                        value={formValues?.nome}
                        fullWidth
                        label="Nome"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.nome_cracha}
                        onChange={(e) => HandleChangeForm('nome_cracha', e.target.value)}
                        value={formValues.nome_cracha}
                        fullWidth
                        label="Nome Crachá"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>

                {formValues?.tipo_cargo !== 122 ?
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            // error={!!formErrors.cpf}
                            fullWidth
                            id="cpf"
                            label="CPF"
                            name="cpf"
                            autoComplete="cpf"
                            value={FormatCPF(formValues?.cpf)}
                            onChange={(e) => HandleChangeForm('cpf', e.target.value)}
                            inputProps={{ maxLength: 14 }}
                        />
                    </Grid>
                    :
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="outlined"
                            // error={!!formErrors.passaporte}
                            fullWidth
                            id="passaporte"
                            label="Passaporte"
                            name="passaporte"
                            autoComplete="passaporte"
                            value={formValues?.passaporte}
                            onChange={(e) => {
                                const inputValue = e.target.value.toUpperCase();
                                const formattedValue = inputValue.replace(/[^A-Z0-9]/g, '');
                                const passaporteFormatted = formattedValue.substring(0, 8);
                                if (/^[A-Z]{0,2}\d{0,6}$/.test(passaporteFormatted)) {
                                    HandleChangeForm('passaporte', passaporteFormatted);
                                }
                            }}
                            inputProps={{
                                maxLength: 8,
                            }}
                        />

                    </Grid>
                }
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }}>Gênero</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.genero}
                            onChange={(e) => HandleChangeForm('genero', e.target.value)}
                            value={formValues?.genero}
                        >
                            <MenuItem value="masculino">Masculino</MenuItem>
                            <MenuItem value="feminino">Feminino</MenuItem>
                            <MenuItem value="outros">Outros</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {formValues?.tipo_cargo !== 122 && (
                    <>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={ufs}
                                    getOptionLabel={(uf) => uf.SIGLA_UF}
                                    value={ufs.find((uf) => uf.SIGLA_UF === formValues?.uf) || null}
                                    onChange={(e, selectedUfObject) => {
                                        setCodigoUf(selectedUfObject?.CD_UF || null);
                                        setMunicipio(selectedUfObject?.CD_MUNICIPIO || null);
                                        HandleChangeForm('uf', selectedUfObject?.SIGLA_UF || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            // error={!!formErrors.uf}
                                            label="UF"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disabled={!formValues?.uf}
                                    options={municipio || []}
                                    getOptionLabel={(municipio) => municipio.NOME_MUNICIPIO || ''}
                                    value={municipio?.find((m) => m.CD_MUNICIPIO === formValues?.municipio) || null}
                                    onChange={(e, selectedMunicipio) => {
                                        HandleChangeForm('municipio', selectedMunicipio?.CD_MUNICIPIO || '', selectedMunicipio?.NOME_MUNICIPIO || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            // error={!!formErrors.municipio}
                                            label="Município"

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.celular}
                        onChange={(e) => HandleChangeForm('celular', FormatCelular(e.target.value))}
                        value={FormatCelular(formValues?.celular)}
                        fullWidth
                        label="Celular"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <WhatsAppIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        onChange={(e) => HandleChangeForm('telefone', FormatTelefone(e.target.value))}
                        value={FormatTelefone(formValues?.telefone)}
                        fullWidth
                        label="Telefone" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.email}
                        onChange={(e) => HandleChangeForm('email', e.target.value)}
                        value={formValues?.email}
                        fullWidth
                        label="E-mail"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.cep}
                        // helperText={formErrors.cep}
                        onChange={(e) => HandleChangeForm('cep', e.target.value)}
                        value={FormatCEP(formValues?.cep)}
                        fullWidth
                        label="CEP"
                        inputProps={{ maxLength: 8 }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.endereco}
                        // helperText={formErrors.endereco}
                        disabled={!formValues?.cep}
                        onChange={(e) => HandleChangeForm('endereco', e.target.value)}
                        value={formValues?.endereco}
                        fullWidth
                        label="Endereço"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.bairro}
                        // helperText={formErrors.bairro}
                        disabled={!formValues?.cep}
                        onChange={(e) => HandleChangeForm('bairro', e.target.value)}
                        value={formValues?.bairro}
                        fullWidth
                        label="Bairro"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }}>Atendimento especializado</InputLabel>
                        <Select
                            variant="outlined"
                            onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, necessita_atendimento_especializado: e.target.value })}
                            value={formValues?.PCD?.necessita_atendimento_especializado || 'Nao'}
                        >
                            <MenuItem value="Sim">Sim</MenuItem>
                            <MenuItem value="Nao">Não</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>

                {formValues?.PCD?.necessita_atendimento_especializado === 'Sim' && (
                    <>
                        <Grid item xs={12} sm={4} style={{ marginTop: '5px', userSelect: 'none' }}>
                            Possui deficiência? (Lei nº13.146/2015)
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormGroup style={{ marginLeft: '10px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Fisica') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Fisica', e.target.checked)}
                                                    />
                                                }
                                                label="Física"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Visual') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Visual', e.target.checked)}
                                                    />
                                                }
                                                label="Visual"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Auditiva') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Auditiva', e.target.checked)}
                                                    />
                                                }
                                                label="Auditiva"
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Intelectual') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Intelectual', e.target.checked)}
                                                    />
                                                }
                                                label="Intelectual"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Mental') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Mental', e.target.checked)}
                                                    />
                                                }
                                                label="Mental"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={formValues?.PCD?.tipo_deficiencias.includes('Outros') || false}
                                                        onChange={(e) => HandleChangeCheckbox('Outros', e.target.checked)}
                                                    />
                                                }
                                                label="Outros"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>

                            </FormControl>
                        </Grid>

                        {formValues?.PCD?.tipo_deficiencias?.includes('Outros') && (
                            <Grid item xs={12} sm={4} style={{ userSelect: 'none' }}>
                                Outros. Qual?
                                <TextField
                                    variant="outlined"
                                    // error={!!formErrors.outras_deficiencia}
                                    onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, outras_deficiencia: e.target.value })}
                                    value={formValues?.PCD?.outras_deficiencia || ''}
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={4} style={{ marginTop: '5px', userSelect: 'none' }}>
                            Possui dificuldade de locomoção?
                            <RadioGroup
                                row
                                aria-label="dificuldadeLocomocao"
                                name="dificuldadeLocomocao"
                                // error={!!formErrors.dificuldade_locomocao}
                                style={{ marginLeft: '13px' }}
                                onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, dificuldade_locomocao: e.target.value })}
                                value={formValues?.PCD?.dificuldade_locomocao || ''}
                            >
                                <Grid item xs={12} sm={3}>

                                    <FormControlLabel
                                        value="Nao"
                                        control={<Radio color="primary" />}
                                        label="Não"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>

                                    <FormControlLabel
                                        value="Sim"
                                        control={<Radio color="primary" />}
                                        label="Sim"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <FormControlLabel
                                        value="Cadeirante"
                                        control={<Radio color="primary" />}
                                        label="Sou Cadeirante"
                                    />
                                </Grid>
                            </RadioGroup>
                        </Grid>

                        {((formValues?.PCD?.dificuldade_locomocao === 'Sim') || (formValues?.PCD?.dificuldade_locomocao === 'Cadeirante')) && (
                            <Grid item xs={12} sm={4} style={{ marginTop: '5px', userSelect: 'none' }}>
                                Precisa de acompanhamentos ou recurso de acessibilidade para participar do evento?
                                <RadioGroup
                                    row
                                    aria-label="acompanhamentoEvento"
                                    name="acompanhamentoEvento"
                                    style={{ marginLeft: '13px' }}
                                    onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, acompanhamento_acessibilidade: e.target.value })}
                                    value={formValues?.PCD?.acompanhamento_acessibilidade || ''}
                                >
                                    <Grid item xs={12} sm={3}>

                                        <FormControlLabel
                                            value="Nao"
                                            control={<Radio color="primary" />}
                                            label="Não"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>

                                        <FormControlLabel
                                            value="Sim"
                                            control={<Radio color="primary" />}
                                            label="Sim"
                                        />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        )}
                        {formValues?.PCD?.acompanhamento_acessibilidade === "Sim" && (
                            <Grid item xs={12} sm={4} style={{ userSelect: 'none' }}>
                                Sim. Qual?
                                <TextField
                                    // error={!!formErrors.descricao_acompanhamento_acessibilidade}
                                    variant="outlined"
                                    onChange={(e) => HandleChangeForm('PCD', { ...formValues.PCD, descricao_acompanhamento_acessibilidade: e.target.value })}
                                    value={formValues?.PCD?.descricao_acompanhamento_acessibilidade || ''}
                                    fullWidth
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>
                        )}
                    </>
                )}

                {(getStatusLabel() === 'Cortesia' && formValues?.cortesia === 1) && (
                    <Grid item xs={12}>
                        Cortesia Homologada
                        <TextField
                            fullWidth
                            label="Considerações"
                            variant="outlined"
                            multiline
                            minRows={4}
                            disabled={!isAdmin}
                            value={formValues?.obs}
                            style={{ marginTop: '10px' }}
                            onChange={(e) => {
                                if (isAdmin) {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 500) {
                                        HandleChangeForm('obs', inputValue);
                                    }
                                }
                            }}
                        />
                    </Grid>
                )}

            </Grid>
            <Divider style={{ margin: '10px' }} />

            {!isCaixa && (
                <>
                    <Grid container xs={12} sm={12} style={{ marginTop: '15px' }}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Accordion expanded={abrirReenviarEmail} onChange={handleChangeAbrirEmail}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="reenviar-email-content"
                                        id="reenviar-email-header"
                                    >
                                        <Typography>Reenviar E-mails</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12} sm={6}>
                                                <FormGroup style={{ marginLeft: '10px', userSelect: 'none' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.reenviarEmail?.recibo || false}
                                                                onChange={(e) => HandleCheckboxChange('recibo', e.target.checked)}
                                                            />
                                                        }
                                                        label="Recibo"
                                                        style={{ userSelect: 'none' }}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.reenviarEmail?.confirmacao_participacao || false}
                                                                onChange={(e) => HandleCheckboxChange('confirmacao_participacao', e.target.checked)}
                                                            />
                                                        }
                                                        label="Confirmação de participação"
                                                        style={{ userSelect: 'none' }}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.reenviarEmail?.confirmacao_pagamento || false}
                                                                onChange={(e) => HandleCheckboxChange('confirmacao_pagamento', e.target.checked)}
                                                            />
                                                        }
                                                        label="Confirmação de pagamento"
                                                        style={{ userSelect: 'none' }}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.reenviarEmail?.convidados_isentos || false}
                                                                onChange={(e) => HandleCheckboxChange('convidados_isentos', e.target.checked)}
                                                            />
                                                        }
                                                        label="Confirmação de pre-inscrição"
                                                        style={{ userSelect: 'none' }}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormGroup style={{ marginLeft: '10px', userSelect: 'none' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.reenviarEmail?.pix || false}
                                                                onChange={(e) => HandleCheckboxChange('pix', e.target.checked)}
                                                            />
                                                        }
                                                        label="Pix"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.reenviarEmail?.boleto || false}
                                                                onChange={(e) => HandleCheckboxChange('boleto', e.target.checked)}
                                                            />
                                                        }
                                                        label="Boleto"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.reenviarEmail?.prefeito_isento || false}
                                                                onChange={(e) => HandleCheckboxChange('prefeito_isento', e.target.checked)}
                                                            />
                                                        }
                                                        label="Reenviar Homologação - Prefeito(a)"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={formValues?.reenviarEmail?.empenho || false}
                                                                onChange={(e) => HandleCheckboxChange('empenho', e.target.checked)}
                                                            />
                                                        }
                                                        label="Empenho"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid container xs={12} sm={12} justifyContent="center" mt={4} style={{ marginTop: '20px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<EmailIcon />}
                                                    disabled={isDisabled}
                                                    onClick={handleEnviarEMail}
                                                >
                                                    Enviar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Accordion expanded={abrirDadosPagamento} onChange={handleChangeAbrirDadosPagamento}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="reenviar-email-content"
                                        id="reenviar-email-header"
                                    >
                                        <Typography>Dados de pagamento</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            {getStatusLabel() !== "Isento" && (
                                                <>
                                                    <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel style={{ marginLeft: '13px' }}>Tipo de pagamento</InputLabel>
                                                            <Select
                                                                variant="outlined"
                                                                onChange={(e) => HandleChangeForm('tipo_pagamento', e.target.value)}
                                                                value={formValues?.tipo_pagamento || ''}
                                                                disabled={!isAdmin ? true : false}
                                                            >
                                                                <MenuItem value="boleto">Boleto</MenuItem>
                                                                <MenuItem value="caixa">Caixa</MenuItem>
                                                                {/* <MenuItem value="pix">Pix</MenuItem>
                                                <MenuItem value="debito">Debito</MenuItem>
                                                <MenuItem value="dinheiro">Dinheiro</MenuItem>
                                                <MenuItem value="cheque">Cheque</MenuItem> */}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel style={{ marginLeft: '13px' }}>Dados pagamento</InputLabel>
                                                            <Select
                                                                variant="outlined"
                                                                onChange={(e) => HandleChangeForm('opc_pagamento', e.target.value)}
                                                                value={formValues?.opc_pagamento || ''}
                                                            >
                                                                <MenuItem value="1">Pessoa Física</MenuItem>
                                                                <MenuItem value="2">Pessoa Jurídica</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    {formValues?.opc_pagamento !== "1" && (
                                                        <>
                                                            <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel style={{ marginLeft: '13px' }}>Razão Social</InputLabel>
                                                                    <Select
                                                                        variant="outlined"
                                                                        onChange={(e) => HandleChangeForm('razao_social', e.target.value)}
                                                                        value={formValues?.razao_social}
                                                                    >
                                                                        <MenuItem value="prefeitura_municipal">Prefeitura Municipal</MenuItem>
                                                                        <MenuItem value="camara_municipal">Câmara Municipal</MenuItem>
                                                                        <MenuItem value="consorcios_publicos">Consórcios Públicos</MenuItem>
                                                                        <MenuItem value="municipio">Município</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={6}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    value={FormatCNPJ(formValues?.cnpj)}
                                                                    onChange={(e) => HandleChangeForm('cnpj', e.target.value)}
                                                                    fullWidth
                                                                    label="CNPJ"
                                                                    inputProps={{ maxLength: 18 }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {formValues?.statusBoleto && (
                                                <>
                                                    <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                                                        <TextField
                                                            variant="outlined"
                                                            value={'R$ ' + formValues?.valorBoleto}
                                                            // onChange={(e) => HandleChangeForm('valorBoleto', e.target.value)}
                                                            fullWidth
                                                            disabled
                                                            label="Valor do boleto"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                                                        <TextField
                                                            variant="outlined"
                                                            value={formValues?.dataPagamentoBoleto || formValues?.dataVencimentoTituloCobranca || 'Não disponível'}
                                                            fullWidth
                                                            disabled
                                                            label={formValues?.dataPagamentoBoleto ? 'Data de pagamento' : 'Data de vencimento'}
                                                        />
                                                    </Grid>

                                                </>
                                            )}

                                            {(!formValues?.statusBoleto && (getStatusLabel() !== "Isento")) && (
                                                <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={formValues?.valorBoleto}
                                                        onChange={(e) => HandleChangeForm('valorBoleto', e.target.value)}
                                                        fullWidth
                                                        label="Valor do boleto"
                                                        inputProps={{ maxLength: 4 }}
                                                    />
                                                </Grid>

                                            )}

                                            <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                                                <TextField
                                                    variant="outlined"
                                                    value={getStatusLabel()}
                                                    // onChange={(e) => HandleChangeForm('statusBoleto', e.target.value)}
                                                    fullWidth
                                                    disabled
                                                    label="Situação"
                                                />
                                            </Grid>

                                            {(getStatusLabel() !== "Isento" && getStatusLabel() !== "Pago") && (
                                                <>
                                                    <Grid item xs={12} sm={6} style={{ marginTop: '10px', marginLeft: '40px' }}>
                                                        <Button variant="contained" color="primary" onClick={formValues?.boletoBaixado === false ? handleOpenModalAtualizarBoleto : handleOpenModalCriarBoleto} >
                                                            <UpdateIcon /> Atualizar Boleto
                                                        </Button>
                                                    </Grid>
                                                    {(!["Cortesia", "Não há boleto registrado"].includes(getStatusLabel())) && (
                                                        <>
                                                            <Grid item xs={12} sm={4} style={{ marginTop: '10px' }}>
                                                                <Button variant="contained" style={{ backgroundColor: '#FF9800', color: '#fff' }} onClick={handleOpenModalObservacao}>
                                                                    <FreeIcon /> Gerar Cortesia
                                                                </Button>
                                                            </Grid>

                                                            <Grid item xs={12} sm={6} style={{ marginTop: '20px', marginLeft: '40px' }}>
                                                                <Button variant="contained" style={{ backgroundColor: 'red', color: '#fff' }} onClick={handleOpenModalCancelarBoleto}>
                                                                    <CancelIcon /> Cancelar boleto
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </>
                                            )}

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid item xs={12} sm={12}>
                <Grid container justifyContent="center" mt={4} style={{ marginTop: '20px' }}>
                    <Button type="submit" variant="contained" style={{ backgroundColor: '#47944a', color: '#FFFFFF' }} onClick={() => handleEditUser(true)}>
                        <SaveIcon /> Salvar Informações
                    </Button>
                </Grid>
            </Grid>
            {(isCaixa || isAdmin || hasPermission('gerenciar-inscricoes', 'escrita')) && !(getStatusLabel() === 'Pago' || getStatusLabel() === 'Isento') && !(formValues?.cortesia === 1) && (
                <Caixas
                    CalculoDeValores={CalculoDeValores(formValues?.cargo, formValues?.status_contribuicao, formValues?.uf)}
                    handlePagamentosChange={handlePagamentosChange}
                    handleSaveUser={handleEditUser}
                    isEdition={id}
                    dontShowButtons={(formValues?.aprovado === true || formValues?.aprovado === 'true') ? true : false}
                    handleOpenModalObservacao={handleOpenModalObservacao}
                    isAdmin={isAdmin || hasPermission('gerenciar-inscricoes', 'escrita')}
                    isPrefeito={[3, 16].includes(formValues?.cargo)}
                    dadosMunicipio={dadosMunicipio}
                    FormatCNPJ={FormatCNPJ}
                />
            )}

            <CustomModal
                open={openModalAtualizarBoleto}
                onClose={handleCloseModalAtualizarBoleto}
                title="Confirmar Operação"
                content="Deseja realmente atualizar o vencimento?"
                onCancel={handleCloseModalAtualizarBoleto}
                onConfirm={AtualizarVencimentoBoleto}
                disabled={desabilitarBotao}
            />

            <CustomModal
                open={openModalCriarBoleto}
                onClose={handleCloseModalCriarBoleto}
                title="Confirmar Operação"
                content="Deseja realmente criar um novo boleto?"
                onCancel={handleCloseModalCriarBoleto}
                onConfirm={CriarNovoBoleto}
                disabled={desabilitarBotao}
            />

            <CustomModal
                open={openModalCancelarBoleto}
                onClose={handleCloseModalCancelarBoleto}
                title="Confirmar Operação"
                content="Deseja realmente cancelar o boleto?"
                onCancel={handleCloseModalCancelarBoleto}
                onConfirm={CancelarBoleto}
                disabled={desabilitarBotao}
            />

            <CustomModal
                open={openModalGerarCortesia}
                onClose={handleCloseModalGerarCortesia}
                title="Confirmar Operação"
                content="Deseja realmente gerar esta cortesia? Se sim, o boleto atual será cancelado."
                onCancel={handleCloseModalGerarCortesia}
                onConfirm={GerarCortesia}
            />

            <Modal
                open={openModalObservacao}
                onClose={handleCloseModalObservacao}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        borderRadius: '4px',
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Gerar Cortesia - Observação
                    </Typography>
                    <Typography variant="body1" component="p" style={{ marginTop: '20px' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    aria-label="Considerações"
                                    placeholder="Considerações"
                                    minRows={4}
                                    value={formValues?.obs}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 500) {
                                            HandleChangeForm('obs', inputValue);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        fontFamily: 'Arial',
                                        fontSize: '16px',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        padding: '8px',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Typography>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={handleCloseModalObservacao} style={{ marginRight: '10px' }}>
                            Cancelar
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleOpenModalGerarCortesia} disabled={desabilitarBotao}>
                            Confirmar
                        </Button>
                    </div>
                </div>
            </Modal>

        </Container>
    );
}

export default EditarInscricao;