import React from "react";
import Navbar from "../../../components/AuthenticatedNavbar";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { HomeFuncionalidades } from "../../../constants/HomeFuncionalidades";
import ConstantInscricoes from "../../../constants/ConstantInscricoes";
import {
    Grid,
    Box,
    Container
} from '@material-ui/core';

import ResponsiveCards from '../../../components/ResponsiveCards';

// import Footer from '../../components/Footer';

const DashBoard = () => {
    const {
        token,
    } = ConstantInscricoes({ dontLoadAll: true });

    const tiers = HomeFuncionalidades();

    // useEffect(() => {
    //     const userCred = JSON.parse(localStorage.getItem('user_credmarcha'));

    //     if (userCred && userCred.perfil) {
    //         const temPermissaoCaixas = userCred?.perfil?.permissoes?.includes('caixas:leitura') || userCred?.perfil?.permissoes?.includes('caixas:escrita');

    //         if (temPermissaoCaixas) {ConstantInscricoes
    //             setFuncionalidade(1);
    //         }
    //     } else {
    //         console.log('Não foi possível encontrar as credenciais do usuário.');
    //     }
    // }, []);

    return (
        <>
            {token && (
                <React.Fragment>
                    <Navbar />
                    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                    <CssBaseline />

                    <Container disableGutters xs={12} sm={12} sx={{ pt: 5 }}>
                            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 0 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={12}>
                                        <ResponsiveCards props={tiers} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    
                </React.Fragment >
            )}
            {/* <Footer /> */}
        </>
    );
}

export default DashBoard;
