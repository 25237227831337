import * as React from 'react';
import { Grid } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PrincipalImage from '../../images/xxv-marcha.png';
import Button from '@mui/material/Button';
import AuthHandler from '../../service/AuthHandler';

function Navbar() {
    const { handleLogin } = AuthHandler();

    return (
        <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
            <Toolbar style={{ backgroundColor: '#005bab', userSelect: 'none' }} sx={{ flexWrap: 'wrap' }}>
                <a style={{ marginTop: '8px' }} href="/" draggable={false}>
                    <img draggable={false} style={{ height: '80px' }} alt="foto" src={PrincipalImage} className="image-container" />
                </a>
                <Typography
                    variant="h6"
                    style={{ marginLeft: '5px' }}
                    noWrap sx={{ flexGrow: 1 }}
                >
                    <Link
                        variant="h6"
                        style={{ color: '#FFFFFF', textDecoration: 'none' }}
                        href="/"
                        sx={{ mt: 2, mx: 1.5 }}
                    >
                    </Link>
                </Typography>
                <nav>
                    <Link
                        variant="button"
                        // color="text.primary"
                        style={{ color: '#FFFFFF', textDecoration: 'none' }}
                        href="https://marcha.cnm.org.br"
                        draggable={false}
                        target="_blank"
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        SOBRE A MARCHA
                    </Link>
                </nav>
                {/* <nav>
                    <Link
                        variant="button"
                        // color="text.primary"
                        style={{ color: '#FFFFFF', textDecoration: 'none' }}
                        href="/arenas"
                        draggable={false}
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        Arenas
                    </Link>
                </nav> */}
                {/* <nav>
                    <Link
                        variant="button"
                        // color="text.primary"
                        style={{ color: '#FFFFFF', textDecoration: 'none' }}
                        href="/boleto"
                        draggable={false}
                        sx={{ my: 1, mx: 1.5 }}
                    >
                        Pagamento
                    </Link>
                </nav> */}
                <Button onClick={handleLogin} style={{ color: '#FFFFFF' }} variant="contained" sx={{ my: 1, mx: 1.5 }}>
                    Area restrita
                </Button>
            </Toolbar>
            <Grid style={{ borderBottomWidth: '5px', borderBottomStyle: 'solid', borderBottomColor: 'rgb(255, 202, 8)', }} />
            <Grid style={{ borderBottomWidth: '5px', borderBottomStyle: 'solid', borderBottomColor: 'rgb(14, 167, 82)', }} />
        </AppBar>
    )
}

export default Navbar;