import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import NotFound from './pages/theme/NotFound';
import DashBoard from './pages/dashboard/Index';
import AuthHandler from './service/AuthHandler';
import TelaDeManutencao from './components/TelaDeManutencao';
import IndexPosEvento from './components/IndexPosEvento';
import PesquisaSatisfacao from './components/PesquisaSatisfacao';
import GovMunicipal from './pages/govMunicipal/Index';
import GovFederal from './pages/govFederal/Index';
import EntMunicipalista from './pages/entMunicipalista/Index';
import BoletoPix from './components/BoletoPix';
import ImpressaoBoleto from './components/ImpressaoBoleto';
import Totem from './pages/totem/Totem';
import OAuthCode from './service/OAuthCode';
import OAuthToken from './service/OAuthToken';
import Email from './pages/email/Email';
import Arenas from './components/Arenas';
import EventoDashboard from './pages/eventos/dashboard/index';
import Eventos from './pages/eventos/Index';
import Criacao from './pages/eventos/Criacao';
import FormularioEvento from './components/FormularioEvento';
import EdicaoEvento from './pages/eventos/Edicao';

function RoutesApp() {
    const login = AuthHandler();
    const location = useLocation();
    const navigate = useNavigate();
    const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

    useEffect(() => {
        if (maintenanceMode && location.pathname !== '/home' && location.pathname !== '/login') {
            navigate('/');
        }
    }, [maintenanceMode, location, navigate]);

    if (maintenanceMode && location.pathname !== '/home' && location.pathname !== '/login') {
        return <TelaDeManutencao />;
    }

    return (
        <Routes>
            {/* Rotas pós evento: */}
            <Route path="/" element={<IndexPosEvento />} />
            <Route path="/pesquisa" element={<PesquisaSatisfacao />} />
            <Route path="/login" element={<login.handleLogin />} />
            <Route path="/marcha/home" element={<DashBoard />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/home" element={<EventoDashboard />} />
            <Route path="/eventos/home" element={<Eventos />} />
            <Route path="/eventos/criacao" element={<Criacao />} />
            <Route path="/eventos/editar/:id" element={<EdicaoEvento />} />
            {/* Rota dinâmica para o formulário */}
            <Route path="/eventos/credenciamento/:formId" element={<FormularioEvento />} />

            {/* Rotas para o projeto em funcionamento: */}
            <Route path="/autoridades-municipais" element={<GovMunicipal />} />
            <Route path="/autoridades-federais-estaduais" element={<GovFederal />} />
            <Route path="/convidados" element={<EntMunicipalista />} />
            <Route path="/callback" element={<OAuthCode />} />
            <Route path="/oAuthToken" element={<OAuthToken />} />
            <Route path="/boleto" element={<BoletoPix />} />
            <Route path="/imprimir-boleto" element={<ImpressaoBoleto />} />
            <Route path="/arenas" element={<Arenas />} />
            <Route path="/totem" element={<Totem />} />
            <Route path="/confirmed" element={<Email />} />
        </Routes>
    );
}

export default RoutesApp;
