import React from 'react';
import {
    Card,
    CardContent,
    Grid,
} from '@material-ui/core';
import AtendimentoEspecializado from './AtendimentoEspecializado';
import DadosInscricao from './DadosInscricao';
import DadosPessoais from './DadosPessoais';
import Cracha from './Cracha';

function FormularioCadastro({ formValues, naturezas, tipoCargo, cargo, entidadeNacional, entidadeEstadual, entidadeMicro, consorcio, getEntidadeOpcoesPorTipoCargo, getEntidadeValorPorTipoCargo, getEntidadeNomeFormulario, getEntidadeLabelPorTipoCargo, parlamentar, partidoPolitico, areaDeAtuacao, ufs, municipio, setFormValues, setCodigoUf, HandleChangeForm, setMunicipio, formErrors, FormatCPF, FormatCelular, FormatTelefone, HandleChangeCheckbox, handleFileChange, handleRemoveFile, fileInputKey }) {
    return (
        <Grid container spacing={3} component="main">
            <Grid item xs={12} sm={8}>
                <Card>
                    <CardContent>
                        <form>
                            <DadosInscricao
                                formValues={formValues}
                                naturezas={naturezas}
                                tipoCargo={tipoCargo}
                                cargo={cargo}
                                entidadeNacional={entidadeNacional}
                                entidadeEstadual={entidadeEstadual}
                                entidadeMicro={entidadeMicro}
                                consorcio={consorcio}
                                getEntidadeOpcoesPorTipoCargo={getEntidadeOpcoesPorTipoCargo}
                                getEntidadeValorPorTipoCargo={getEntidadeValorPorTipoCargo}
                                getEntidadeNomeFormulario={getEntidadeNomeFormulario}
                                getEntidadeLabelPorTipoCargo={getEntidadeLabelPorTipoCargo}
                                parlamentar={parlamentar}
                                partidoPolitico={partidoPolitico}
                                areaDeAtuacao={areaDeAtuacao}
                                setFormValues={setFormValues}
                                HandleChangeForm={HandleChangeForm}
                                formErrors={formErrors}
                            />

                            <DadosPessoais
                                formValues={formValues}
                                ufs={ufs}
                                municipio={municipio}
                                setCodigoUf={setCodigoUf}
                                HandleChangeForm={HandleChangeForm}
                                setMunicipio={setMunicipio}
                                formErrors={formErrors}
                                FormatCPF={FormatCPF}
                                FormatCelular={FormatCelular}
                                FormatTelefone={FormatTelefone}
                                handleFileChange={handleFileChange}
                                handleRemoveFile={handleRemoveFile}
                                fileInputKey={fileInputKey}
                            />

                            <AtendimentoEspecializado
                                formValues={formValues}
                                HandleChangeForm={HandleChangeForm}
                                HandleChangeCheckbox={HandleChangeCheckbox}
                                formErrors={formErrors}
                            />

                        </form>
                    </CardContent>
                </Card>
            </Grid>
            <Cracha
                formValues={formValues}
            />
        </Grid >
    );
}

export default FormularioCadastro;
