import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const checkTokenAndRedirect = (navigate) => {
  const tokenApi = localStorage.getItem('tokenApi');
  if (tokenApi) {
    navigate('/home');
  }
};

const processOAuthCode = (navigate) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (!code) {
      throw new Error('Código não encontrado na URL.');
    }
    localStorage.setItem('code', code);
    navigate('/oAuthToken');
  } catch (error) {
    console.error('Erro ao processar código OAuth:', error);
    window.history.back();
  }
};

const OAuthCode = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkTokenAndRedirect(navigate);
  }, [navigate]);

  useEffect(() => {
    const tokenApi = localStorage.getItem('tokenApi');
    if (!tokenApi) {
      processOAuthCode(navigate);
    }
  }, [navigate]);

  return <div>Processando...</div>;
};

export default OAuthCode;
