import React, { useState, useEffect } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography,
    TextField,
    Modal,
    MenuItem,
    Card,
    CardContent,
} from "@mui/material";
import SaveIcon from '@material-ui/icons/Save';
import FreeIcon from '@mui/icons-material/Handshake';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../service/Api';

const Caixas = ({ CalculoDeValores, handlePagamentosChange, handleSaveUser, isEdition, dontShowButtons, handleOpenModalObservacao, isAdmin, isPrefeito, dadosMunicipio, FormatCNPJ, showAlert }) => {
    const [openModalPagamento, setOpenModalPagamento] = useState(false);
    const [openModalPendencias, setOpenModalPendencias] = useState(false);
    const [pagamentos, setPagamentos] = useState([]);
    const [tipoPagamento, setTipoPagamento] = useState('');
    const [dataPagamento, setDataPagamento] = useState(formatarDataAtual());
    const [valorPagamento, setValorPagamento] = useState('');
    const [observacoes, setObservacoes] = useState('');
    const [erroFormulario, setErroFormulario] = useState('');
    const [banco, setBanco] = useState('');
    const [agencia, setAgencia] = useState('');
    const [conta, setConta] = useState('');
    const [titular, setTitular] = useState('');
    const [totalPago, setTotalPago] = useState(0);
    const totalPagamentos = pagamentos?.reduce((total, pagamento) => total + parseFloat(pagamento.valor), 0);
    const valorRestante = CalculoDeValores - totalPagamentos;

    const getObservacao = () => {
        const valorFilacaoTotal = dadosMunicipio?.contribuicao * 6;
        const totalPagamentos = pagamentos?.reduce((total, pagamento) => total + pagamento.valor, 0);
        const valorTotalPendente = dadosMunicipio?.apagar?.reduce((total, pendencia) => total + parseFloat(pendencia.valor), 0);

        if ((dadosMunicipio?.situacao !== 'Inadimplente') && (pagamentos.some(pagamento => pagamento.tipo_pgto === 'Filiacao' && pagamento.valor >= valorFilacaoTotal))) {
            return "Filiação paga";
        } else if (totalPagamentos === 0) {
            return "Pendente";
        } else if (dadosMunicipio?.situacao === 'Inadimplente' && pagamentos.some(pagamento => pagamento.tipo_pgto === 'Filiacao' && pagamento.valor === valorTotalPendente)) {
            return "Regularização paga";
        } else if (totalPagamentos >= CalculoDeValores) {
            return "Pago";
        } else {
            return "Pendente";
        }
    };

    const verificaSeHaDebitos = () => {
        const temFiliacao = pagamentos.some(pagamento => pagamento.tipo_pgto === 'Filiacao');

        if (pagamentos.length > 0 && !temFiliacao && totalPago < CalculoDeValores) {
            showAlert('Há divergência nos valores de pagamento. Por favor, verifique e tente novamente', 'error');
        } else {
            handleSaveUser(false);
        }
    };

    const handleDeletePagamento = (index) => {
        const novosPagamentos = pagamentos.filter((_, i) => i !== index);
        setPagamentos(novosPagamentos);
        handlePagamentosChange(novosPagamentos);
    };

    const handleOpenModalPagamento = () => {
        setOpenModalPagamento(true);
    };

    const handleCloseModalPagamento = () => {
        setOpenModalPagamento(false);
        setTipoPagamento('');
        setDataPagamento(formatarDataAtual());
        setValorPagamento('');
        setObservacoes('');
        setBanco('');
        setAgencia('');
        setConta('');
        setTitular('');
    };

    const handleOpenModalPendencias = () => {
        setOpenModalPendencias(true);
    };

    const handleCloseModalPendencias = () => {
        setOpenModalPendencias(false);
    };

    const handleTipoPagamentoChange = (e) => {
        const novoTipoPagamento = e.target.value;
        setTipoPagamento(novoTipoPagamento);
        if (novoTipoPagamento !== 'Cheque') {
            setBanco('');
            setAgencia('');
            setConta('');
            setTitular('');
        }
    };

    const handleSubmitPagamento = () => {

        if (!tipoPagamento || !dataPagamento || !valorPagamento) {
            setErroFormulario('Preencha todos os campos obrigatórios.');
            return;
        }

        if (tipoPagamento === 'Cheque') {
            if (!banco || !agencia || !conta || !titular) {
                setErroFormulario('Preencha todos os campos obrigatórios para o pagamento com cheque.');
                return;
            }
        }

        const userCredMarcha = localStorage.getItem("user_credmarcha");
        const userData = JSON.parse(userCredMarcha);
        const userName = userData?.pessoa?.nome_razao || 'Desconhecido';

        const novoPagamento = {
            tipo_pgto: tipoPagamento,
            // data: dataPagamento,
            valor: parseFloat(valorPagamento),
            obs: observacoes,
            banco: tipoPagamento === 'Cheque' ? banco : null,
            agencia: tipoPagamento === 'Cheque' ? agencia : null,
            conta: tipoPagamento === 'Cheque' ? conta : null,
            titular: tipoPagamento === 'Cheque' ? titular : null,
            perfil: userName,
        };

        setPagamentos((prevPagamentos) => [...prevPagamentos, novoPagamento]);
        handlePagamentosChange([...pagamentos, novoPagamento]);

        handleCloseModalPagamento();
        setErroFormulario('');
    };

    function formatarDataAtual() {
        const dataAtual = new Date();
        const dia = dataAtual.getDate().toString().padStart(2, '0');
        const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
        const ano = dataAtual.getFullYear();
        return `${ano}-${mes}-${dia}`;
    }

    useEffect(() => {
        const total = pagamentos?.reduce((total, pagamento) => total + parseFloat(pagamento.valor), 0);
        setTotalPago(total);
    }, [pagamentos, setTotalPago]);

    useEffect(() => {
        const fetchData = async () => {
            if (isEdition) {
                try {
                    const response = await api.get(`api/fluxoCaixa/${isEdition}`);
                    const data = response.data.fluxoCaixa;

                    const pagamentosAtualizados = [];

                    const processarObservacoes = (obs) => {
                        if (!obs) {
                            return {};
                        }

                        const observacoesSeparadas = {};
                        const partes = obs.split(', ');

                        partes.forEach(parte => {
                            const [chave, ...valor] = parte.split(': ');
                            const chaveLower = chave.toLowerCase().trim();
                            const valorCompleto = valor.join(': ').trim();
                            observacoesSeparadas[chaveLower] = valorCompleto;
                        });

                        return observacoesSeparadas;
                    };

                    const observacoes = processarObservacoes(data.obs);

                    if (data.dinheiro) {
                        pagamentosAtualizados.push({
                            tipo_pgto: 'Dinheiro',
                            valor: parseFloat(data.dinheiro),
                            obs: observacoes.dinheiro || '',
                            data: data.created_at
                        });
                    }

                    if (data.transferencia) {
                        pagamentosAtualizados.push({
                            tipo_pgto: 'Transferencia',
                            valor: parseFloat(data.transferencia),
                            obs: observacoes.transferencia || '',
                            data: data.created_at
                        });
                    }

                    if (data.cartao) {
                        pagamentosAtualizados.push({
                            tipo_pgto: 'Cartao',
                            valor: parseFloat(data.cartao),
                            obs: observacoes.cartao || '',
                            data: data.created_at
                        });
                    }

                    if (data.pix) {
                        pagamentosAtualizados.push({
                            tipo_pgto: 'Pix',
                            valor: parseFloat(data.pix),
                            obs: observacoes.pix || '',
                            data: data.created_at
                        });
                    }

                    if (data.filiacao) {
                        pagamentosAtualizados.push({
                            tipo_pgto: 'Filiacao',
                            valor: parseFloat(data.filiacao),
                            obs: observacoes.filiacao || '',
                            data: data.created_at
                        });
                    }

                    if (data.cheque) {
                        pagamentosAtualizados.push({
                            tipo_pgto: 'Cheque',
                            valor: parseFloat(data.cheque),
                            obs: observacoes.cheque || '',
                            banco: data.banco,
                            agencia: data.agencia,
                            conta: data.conta,
                            titular: data.titular,
                            data: data.created_at
                        });
                    }

                    setPagamentos(pagamentosAtualizados);
                } catch (error) {
                    console.error('Ocorreu um erro ao buscar as informações de pagamento', error);
                }
            }
        };

        fetchData();
    }, [isEdition, setPagamentos]);

    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Grid item xs={12} sm={12}>
                <Container style={{ marginTop: '20px' }}>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={12} sm={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenModalPagamento}
                                disabled={
                                    totalPago >= CalculoDeValores ||
                                    pagamentos.some(
                                        (pagamento) => pagamento.tipo_pgto === 'Filiacao' && pagamento.valor === dadosMunicipio?.contribuicao * 6
                                    )
                                }
                                style={{ marginBottom: '10px' }}
                            >
                                Adicionar Pagamento
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6" component="h2">
                                <span
                                    style={{
                                        backgroundColor: dadosMunicipio?.situacao === 'Em dia' ? 'green' : dadosMunicipio?.situacao === 'Inadimplente' ? 'orange' : 'red',
                                        color: 'white',
                                        padding: '2px 4px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    {dadosMunicipio?.situacao ?? 'Não Contribuinte'}
                                </span>
                            </Typography>
                        </Grid>
                        {dadosMunicipio?.situacao === 'Inadimplente' && (
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenModalPendencias}
                                    style={{ marginBottom: '10px' }}
                                >
                                    Informações de inadimplências
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tipo de Pagamento</TableCell>
                                    <TableCell>Data de Pagamento</TableCell>
                                    <TableCell>Valor (R$)</TableCell>
                                    <TableCell>Observações</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pagamentos?.map((pagamento, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{pagamento.tipo_pgto}</TableCell>
                                        <TableCell>
                                            {pagamento.data ? new Date(pagamento.data).toLocaleDateString('pt-BR') : new Date().toLocaleDateString('pt-BR')}
                                        </TableCell>
                                        {/* <TableCell>{new Date().toLocaleDateString('pt-BR')}</TableCell> */}
                                        <TableCell>R$ {pagamento.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                                        <TableCell>{pagamento.obs}</TableCell>
                                        <TableCell>
                                            {!dontShowButtons && (
                                                <Button
                                                    onClick={() => handleDeletePagamento(index)}
                                                    style={{ color: 'red' }}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Card style={{ marginTop: '20px', backgroundColor: '#f0f0f0' }}>
                        <CardContent>
                            <Typography variant="h6">Informações de Pagamento</Typography>
                            <Typography variant="body1">
                                Total Geral: R$ {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos?.reduce((total, pagamento) => total + parseFloat(pagamento.valor), 0))}
                            </Typography>
                            <Typography variant="body1">
                                Observação: {getObservacao()}
                            </Typography>

                            {valorRestante > 0 && getObservacao() !== "Filiação paga" && getObservacao() !== "Regularização paga" && (
                                <Typography variant="body1">
                                    Valor Restante a Pagar: R$ {valorRestante.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                </Typography>
                            )}

                            {(isPrefeito && (!['Em dia', 'Inadimplente'].includes(dadosMunicipio?.situacao))) && (
                                <Typography variant="body1">
                                    Valor total para filiação: {dadosMunicipio?.contribuicao !== undefined && dadosMunicipio.contribuicao !== null && dadosMunicipio.contribuicao !== 0 ? 'R$ ' + new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosMunicipio.contribuicao * 6) : (dadosMunicipio?.contribuicao === 0 ? 'R$ 0,00' : "Não disponível")}
                                </Typography>
                            )}

                            {dadosMunicipio?.situacao === 'Inadimplente' && (
                                <Typography variant="body1">
                                    Valor para regularização: R${dadosMunicipio?.apagar?.reduce((total, pendencia) => total + parseFloat(pendencia.valor), 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                </Typography>
                            )}

                        </CardContent>
                    </Card>
                </Container>
            </Grid>

            {!dontShowButtons && (
                <Grid item xs={12} sm={12}>
                    <Grid container justifyContent="center" mt={4} style={{ marginTop: '20px' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: isEdition
                                    ? pagamentos.some(pagamento => pagamento.tipo_pgto === 'Filiacao') || totalPago >= CalculoDeValores
                                        ? '#47944a'
                                        : '#ADAAAB'
                                    : '#47944a',
                                color: '#FFFFFF'
                            }}
                            onClick={verificaSeHaDebitos}
                            disabled={
                                isEdition &&
                                !pagamentos.some(pagamento => pagamento.tipo_pgto === 'Filiacao') &&
                                totalPago < CalculoDeValores
                            }
                        >
                            <SaveIcon /> {isEdition ? 'Salvar Pagamentos' : 'Salvar'}
                        </Button>
                        {(isAdmin) && (isEdition) &&
                            <Button variant="contained" style={{ backgroundColor: '#FF9800', color: '#fff', marginLeft: '10px' }} onClick={handleOpenModalObservacao}>
                                <FreeIcon /> Gerar Cortesia
                            </Button>
                        }
                        {(dadosMunicipio?.termo_filiacao) && (isPrefeito) && (
                            <Button variant="contained" color="primary" style={{ marginLeft: '10px', color: 'white', textDecoration: 'none' }} href={dadosMunicipio?.termo_filiacao} target="_blank" >
                                <DescriptionIcon /> Termo de Filiação
                            </Button>
                        )}
                    </Grid>
                </Grid>
            )}

            <Modal
                open={openModalPendencias}
                onClose={handleCloseModalPendencias}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        borderRadius: '4px',
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Informações de faturas pendentes
                    </Typography>
                    <Typography variant="body1" component="p" style={{ marginTop: '20px', maxHeight: '600px', overflowY: 'auto' }}>

                        <Grid container>
                            {dadosMunicipio?.apagar?.map((pendencia, index) => (
                                <Grid item xs={12} key={pendencia.id} style={{ marginBottom: '20px', backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white', padding: '10px', borderRadius: '4px' }}>
                                    <div style={{ marginBottom: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Referência:</span> {pendencia.referencia}
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Vencimento:</span> {pendencia.vencimento}
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Forma de Pagamento:</span> {pendencia.formaDePgto}
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Situação:</span> {pendencia.situacao}
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Valor:</span> R${pendencia.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>CNPJ:</span> {FormatCNPJ(pendencia.cnpj)}
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Typography>

                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" onClick={handleCloseModalPendencias}>
                            Fechar
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={openModalPagamento}
                onClose={handleCloseModalPagamento}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        borderRadius: '4px',
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Informar pagamentos
                    </Typography>
                    <Typography variant="body1" component="p" style={{ marginTop: '20px' }}>
                        <Grid container>
                            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                <TextField
                                    select
                                    label="Tipo de Pagamento"
                                    value={tipoPagamento}
                                    onChange={handleTipoPagamentoChange}
                                    fullWidth
                                    required
                                >
                                    {['Dinheiro', 'Cartao', 'Cheque', 'Transferencia', 'Pix', ...(isPrefeito && isAdmin ? ['Filiacao'] : [])].map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}

                                </TextField>
                            </Grid>
                            {tipoPagamento === 'Cheque' && (
                                <React.Fragment>
                                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                        <TextField
                                            label="Banco"
                                            value={banco}
                                            onChange={(e) => setBanco(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                        <TextField
                                            label="Agência"
                                            value={agencia}
                                            onChange={(e) => setAgencia(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                        <TextField
                                            label="Conta"
                                            value={conta}
                                            onChange={(e) => setConta(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                        <TextField
                                            label="Titular"
                                            value={titular}
                                            onChange={(e) => setTitular(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                <TextField
                                    label="Data de Pagamento"
                                    type="date"
                                    value={dataPagamento}
                                    onChange={(e) => setDataPagamento(e.target.value)}
                                    fullWidth
                                    required
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                <TextField
                                    label="Valor (R$)"
                                    type="number"
                                    value={valorPagamento}
                                    onChange={(e) => setValorPagamento(e.target.value < 0 ? '' : e.target.value)}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                <TextField
                                    label="Observações"
                                    multiline
                                    rows={4}
                                    value={observacoes}
                                    onChange={(e) => setObservacoes(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        {erroFormulario && <Typography variant="body2" style={{ color: 'red' }}>{erroFormulario}</Typography>}
                    </Typography>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={handleCloseModalPagamento} style={{ marginRight: '10px' }}>
                            Cancelar
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSubmitPagamento}>
                            Confirmar
                        </Button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default Caixas;
