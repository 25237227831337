import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Button,
  Container,
  Modal,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EtiquetaCaixa from './EtiquetaCaixa';
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";

const InformacoesCaixa = ({
  dadosCaixa,
  setFuncionalidade,
  snackbarOpen,
  HandleSnackbarClose,
  snackbarSeverity,
  snackbarMessage,
  HandleRetirada,
  setOpenModalFechamento,
  openModalFechamento,
  HandleSearch,
}) => {
  const userCred = JSON.parse(localStorage.getItem("user_credmarcha"));
  const etiquetaRef = useRef();
  const componentRef = useRef();

  const handleVoltar = () => {
    setFuncionalidade("");
    HandleSearch();
  };
  const [valoresModal, setValoresModal] = useState({
    dinheiro: dadosCaixa?.dinheiro || 0,
    cheque: dadosCaixa?.cheque || 0,
    transferencia: dadosCaixa?.transferencia || 0,
    cartao: dadosCaixa?.cartao || 0,
    filiacao: dadosCaixa?.filiacao || 0,
    pix: dadosCaixa?.pix || 0,
    furo: 0,
  });

  const calcularTotalRetiradas = () => {
    if (!dadosCaixa || !dadosCaixa.fechamento) return 0;

    let total = 0;
    dadosCaixa.fechamento.forEach((caixa) => {
      total += parseFloat(caixa.dinheiro) || 0;
      total += parseFloat(caixa.cheque) || 0;
      total += parseFloat(caixa.transferencia) || 0;
      total += parseFloat(caixa.cartao) || 0;
      total += parseFloat(caixa.pix) || 0;
      total += parseFloat(caixa.filiacao) || 0;
    });
    return total.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  };

  const calcularValorEmCaixa = () => {
    if (!dadosCaixa) return "R$ 0,00";

    let total = 0;
    total += parseFloat(dadosCaixa.dinheiro) || 0;
    total += parseFloat(dadosCaixa.cheque) || 0;
    total += parseFloat(dadosCaixa.transferencia) || 0;
    total += parseFloat(dadosCaixa.cartao) || 0;
    total += parseFloat(dadosCaixa.pix) || 0;
    total += parseFloat(dadosCaixa.filiacao) || 0;
    total += parseFloat(dadosCaixa.furo) || 0;

    return total.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  };

  const calcularTotalRetiradasPorTipo = (tipo) => {
    if (!dadosCaixa || !dadosCaixa.fechamento) return 0;

    let total = 0;
    dadosCaixa.fechamento.forEach((caixa) => {
      total += parseFloat(caixa[tipo]) || 0;
    });
    return total.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  };

  const handleOpenModalFechamento = () => {
    setValoresModal({
      dinheiro: dadosCaixa?.dinheiro || 0,
      cheque: dadosCaixa?.cheque || 0,
      transferencia: dadosCaixa?.transferencia || 0,
      cartao: dadosCaixa?.cartao || 0,
      filiacao: dadosCaixa?.filiacao || 0,
      pix: dadosCaixa?.pix || 0,
      furo: 0,
      perfil: userCred.name,
      fluxo_perfil: dadosCaixa?.perfil,
      obs: "",
    });
    setOpenModalFechamento(true);
  };

  const handleCloseModalFechamento = () => {
    setOpenModalFechamento(false);
  };

  const Print = useReactToPrint({
    content: () => etiquetaRef.current
  });

  function formatarData(data) {
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    const horas = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    const segundos = data.getSeconds().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    let furo = Object.entries(valoresModal).reduce((acc, [key, value]) => {
      if (
        [
          "dinheiro",
          "cheque",
          "transferencia",
          "cartao",
          "filiacao",
          "pix",
        ].includes(key)
      )
        acc += (value - dadosCaixa[key]);
      return acc;
    }, 0);

    setValoresModal({
      ...valoresModal,
      furo,
    });
    // eslint-disable-next-line
  }, [
    valoresModal.dinheiro,
    valoresModal.cheque,
    valoresModal.transferencia,
    valoresModal.cartao,
    valoresModal.filiacao,
    valoresModal.pix,
  ]);

  return (
    <Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={HandleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={HandleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
        <Button variant="contained" onClick={handleVoltar}>
          Voltar
        </Button>
      </div>
      <Grid>
        <Typography style={{ textAlign: "center" }} variant="h6" gutterBottom>
          Dados do Perfil: {dadosCaixa.perfil}
        </Typography>
      </Grid>
      <Paper>
        <TableContainer
          style={{ marginTop: "10px" }}
          component={Paper}
          className="custom-table"
        >
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Nº</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Data</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Dinheiro</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Cheque</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Transferência</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Cartão</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>PIX</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Filiação</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Furos</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Envelope</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel>Ação</TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dadosCaixa?.fechamento?.map((caixa, index) => (
                  <TableRow key={caixa.id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {new Date(caixa?.created_at).toLocaleDateString("pt-BR")}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      R${" "}
                      {parseFloat(caixa?.dinheiro || 0).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      R${" "}
                      {parseFloat(caixa?.cheque || 0).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      R${" "}
                      {parseFloat(caixa?.transferencia || 0).toLocaleString(
                        "pt-BR",
                        { minimumFractionDigits: 2 }
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      R${" "}
                      {parseFloat(caixa?.cartao || 0).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      R${" "}
                      {parseFloat(caixa?.pix || 0).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      R${" "}
                      {parseFloat(caixa?.filiacao || 0).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ textAlign: "center", color: caixa?.furo < 0 ? "red" : "inherit" }}>
                      R${" "}
                      {parseFloat(caixa?.furo || 0).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      R${" "}
                      {(
                        parseFloat(caixa?.dinheiro || 0) +
                        parseFloat(caixa?.cheque || 0) +
                        parseFloat(caixa?.transferencia || 0) +
                        parseFloat(caixa?.cartao || 0) +
                        parseFloat(caixa?.pix || 0) +
                        parseFloat(caixa?.filiacao || 0)
                      ).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Button onClick={Print}>
                        <PrintIcon
                          style={{
                            color: "#007bff",
                            cursor: "pointer",
                            marginLeft: "8px",
                          }}
                        />
                      </Button>
                    </TableCell>
                    <div style={{ display: 'none' }}>
                      <EtiquetaCaixa
                        perfil={caixa.perfil_fluxo}
                        data={formatarData(new Date())}
                        dinheiro={caixa.dinheiro}
                        cheque={caixa.cheque}
                        transferencia={caixa.transferencia}
                        cartao={caixa.cartao}
                        pix={caixa.pix}
                        filiacao={caixa.filiacao}
                        furos={caixa.furo}
                        envelope={caixa.envelope}
                        ref={etiquetaRef}
                      />
                    </div>
                  </TableRow>
                ))}
              </TableBody>
              <tfoot>
                <TableRow
                  style={{
                    background: "#DCE2DC",
                    color: "#4955DA",
                    fontWeight: "bold",
                  }}
                >
                  <TableCell></TableCell>
                  <TableCell>Total Retiradas:</TableCell>
                  <TableCell>
                    R$ {calcularTotalRetiradasPorTipo("dinheiro")}
                  </TableCell>
                  <TableCell>
                    R$ {calcularTotalRetiradasPorTipo("cheque")}
                  </TableCell>
                  <TableCell>
                    R$ {calcularTotalRetiradasPorTipo("transferencia")}
                  </TableCell>
                  <TableCell>
                    R$ {calcularTotalRetiradasPorTipo("cartao")}
                  </TableCell>
                  <TableCell>R$ {calcularTotalRetiradasPorTipo("pix")}</TableCell>
                  <TableCell>
                    R$ {calcularTotalRetiradasPorTipo("filiacao")}
                  </TableCell>
                  <TableCell style={{ color: parseFloat(calcularTotalRetiradasPorTipo("furo")) < 0 ? "red" : "inherit" }}>
                    R$ {calcularTotalRetiradasPorTipo("furo")}
                  </TableCell>

                  <TableCell>R$ {calcularTotalRetiradas()}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow
                  style={{
                    background: "#DCE2DC",
                    color: "#4955DA",
                    fontWeight: "bold",
                  }}
                >
                  <TableCell></TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    Valor em caixa:
                  </TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    R${" "}
                    {parseFloat(dadosCaixa.dinheiro).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    R${" "}
                    {parseFloat(dadosCaixa.cheque).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    R${" "}
                    {parseFloat(dadosCaixa.transferencia).toLocaleString(
                      "pt-BR",
                      { minimumFractionDigits: 2 }
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    R${" "}
                    {parseFloat(dadosCaixa.cartao).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    R${" "}
                    {parseFloat(dadosCaixa.pix).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    R${" "}
                    {parseFloat(dadosCaixa.filiacao).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </TableCell>
                  <TableCell
                    style={{
                      background: "#DCE2DC",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    R$ {calcularValorEmCaixa()}
                  </TableCell>
                  <TableCell>
                    <Button onClick={handlePrint} variant="contained" color="primary">
                      <PrintIcon style={{ marginRight: '5px' }} />
                    </Button>
                  </TableCell>
                  <div style={{ display: 'none' }}>
                    <EtiquetaCaixa
                      perfil={dadosCaixa.perfil}
                      data={formatarData(new Date())}
                      dinheiro={dadosCaixa.dinheiro}
                      cheque={dadosCaixa.cheque}
                      transferencia={dadosCaixa.transferencia}
                      cartao={dadosCaixa.cartao}
                      pix={dadosCaixa.pix}
                      filiacao={dadosCaixa.filiacao}
                      furos={dadosCaixa.furo}
                      envelope={dadosCaixa.envelope}
                      ref={componentRef}
                    />
                  </div>
                </TableRow>
              </tfoot>
            </Table>
          </div>
        </TableContainer>
      </Paper>
      <Grid Container>
        {dadosCaixa?.status === "Aberto" && (
          <Grid item xs={12} sm={3} style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              color="grey"
              onClick={handleOpenModalFechamento}
            >
              Cadastrar Fechamento
            </Button>
          </Grid>
        )}
      </Grid>

      <Modal
        open={openModalFechamento}
        onClose={handleCloseModalFechamento}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          <Typography variant="h6" component="h2">
            Informar retirada
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{ marginTop: "20px" }}
          >
            <TextField
              id="dinheiro"
              label="Dinheiro (R$)"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              fullWidth
              style={{ marginBottom: "10px" }}
              value={valoresModal.dinheiro}
              onChange={(e) =>
                setValoresModal({ ...valoresModal, dinheiro: e.target.value })
              }
            />
            <TextField
              id="cheque"
              label="Cheque (R$)"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              fullWidth
              style={{ marginBottom: "10px" }}
              value={valoresModal.cheque}
              onChange={(e) =>
                setValoresModal({ ...valoresModal, cheque: e.target.value })
              }
            />
            <TextField
              id="transferencia"
              label="Transferência (R$)"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              fullWidth
              style={{ marginBottom: "10px" }}
              value={valoresModal.transferencia}
              onChange={(e) =>
                setValoresModal({
                  ...valoresModal,
                  transferencia: e.target.value,
                })
              }
            />
            <TextField
              id="cartao"
              label="Cartão (R$)"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              fullWidth
              style={{ marginBottom: "10px" }}
              value={valoresModal.cartao}
              onChange={(e) =>
                setValoresModal({ ...valoresModal, cartao: e.target.value })
              }
            />
            <TextField
              id="pix"
              label="PIX (R$)"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              fullWidth
              style={{ marginBottom: "10px" }}
              value={valoresModal.pix}
              onChange={(e) =>
                setValoresModal({ ...valoresModal, pix: e.target.value })
              }
            />
            <TextField
              id="filiacao"
              label="Filiação (R$)"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              fullWidth
              style={{ marginBottom: "10px" }}
              value={valoresModal.filiacao}
              onChange={(e) =>
                setValoresModal({ ...valoresModal, filiacao: e.target.value })
              }
            />
            <TextField
              id="furo"
              label="Furo (R$)"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
              fullWidth
              disabled
              value={valoresModal.furo}
              onChange={(e) =>
                setValoresModal({ ...valoresModal, furo: e.target.value })
              }
            />
          </Typography>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={handleCloseModalFechamento}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                HandleRetirada(valoresModal);
              }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default InformacoesCaixa;
