import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import QrCodeImg from '../images/QRCode2.png';
import api from '../service/Api';
import Etiqueta from './Etiqueta';
import { useReactToPrint } from 'react-to-print';

const TabelaDados = ({ dadosFiltro }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dadosFiltrados, setDadosFiltrados] = useState([]);
  const [loading, setLoading] = useState(true);
  const etiquetaRef = useRef();

  const Print = useReactToPrint({
    content: () => etiquetaRef.current,
    onAfterPrint: async () => {
      if (dadosFiltrados.length > 0) {
        const userId = dadosFiltrados[0].id;

        try {
          await api.post(`api/controleAcesso?user_id=${userId}&tipo_registro=etiqueta`);
        } catch (error) {
          console.error('Erro ao utilizar o controle de acessos: ', error.message);
        }
      }

      window.location.reload();
    }
  });

  useEffect(() => {
    const userFiltrado = async (codigo) => {
      try {
        if (codigo.length >= 8) {
          const response = await api.get(`api/cadastroUser?cpf=${codigo}`);

          const dadosAPI = response.data.getCadastroUser;
          setDadosFiltrados(dadosAPI);
          setLoading(false);

          const userId = dadosAPI[0].id;

          try {
            const etiquetaImp = await api.get(`api/controleAcesso/${userId}`);
            if (etiquetaImp.data.ControleAcesso != null) {
              alert('Sua etiqueta já foi impressa! Compareça a um dos caixas da XXV Marcha.');
              window.location.reload();
            }
          } catch (error) {
            console.error('Erro na consulta', error);
          }

        }
      } catch (error) {
        console.error('Erro ao enviar dados:', error);
      }
    };

    if (dadosFiltro.codigo) {
      userFiltrado(dadosFiltro.codigo);
    }
  }, [dadosFiltro.codigo]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const codigoCompleto = dadosFiltro.codigo.length >= 8;

  if (!codigoCompleto) {
    return <p>...</p>;
  }

  if (loading) {
    return <p>...</p>;
  }

  return (
    <>
      {dadosFiltrados.length === 0 ? (
        <div className='texto-dados'>
          <p >Dados não encontrados...</p>
          <Button variant='contained' color='secondary' onClick={handleOpenDialog}> Clique aqui para fazer sua inscrição</Button>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Fechar
              </Button>
            </DialogActions>
            <DialogTitle style={{ textAlign: 'center' }}>
              Leia o QR Code com o seu celular
            </DialogTitle>
            <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={QrCodeImg} alt="QR Code Inscrição da Marcha" width="85%" />
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <>
          <TableContainer component={Paper} style={{ marginTop: '10px', backgroundColor: 'rgba(250, 250, 250)', color: 'white' }}>
            <Table>
              <TableBody>
                {dadosFiltrados.map((dados, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'rgba(0, 90, 156, 0.8)' }}>
                      {dados.nome.charAt(0).toUpperCase() + dados.nome.slice(1)}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'rgba(0, 90, 156, 0.8)' }}>{dados.cargo_descricao}</TableCell>
                    {/* <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', color: 'rgba(0, 90, 156, 0.8)' }}>{dados.uf}</TableCell> */}
                    <TableCell sx={{ textAlign: 'center' }}>
                      {dadosFiltrados.length > 0 &&
                        dadosFiltrados[0] &&
                        dadosFiltrados[0].status &&
                        dadosFiltrados[0].status.toLowerCase() === 'aberto' ? (
                        <Button variant="contained" disabled>
                          Pagamento em aberto. Compareça a um caixa da XXV Marcha!
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={Print}>
                          <>
                            Imprimir
                            <PrintIcon />
                          </>
                        </Button>
                      )}
                    </TableCell>
                    <div style={{ display: 'none' }}>
                      <Etiqueta
                        nome={dados.nome_cracha.toUpperCase()}
                        uf={dados.cargo === 77 || dados.cargo === 78 || dados.cargo === 79 || dados.cargo === 50 || dados.cargo === 51 || dados.cargo === 52 || dados.cargo === 56 || dados.cargo === 57 || dados.cargo === 58 || dados.cargo === 70 || dados.cargo === 71 || dados.cargo === 75 || dados.cargo === 99 || dados.cargo === 101 ? dados.NOME_UF : dados.uf}
                        uf_sigla={dados.cargo === 76 || dados.cargo === 53 || dados.cargo === 59 || dados.cargo === 98 || dados.cargo === 99 || dados.cargo === 100 ? ' / ' + dados.uf : ''}
                        cpf={dados.cpf}
                        cargo={dados.cargo === 14 || dados.cargo === 19 || dados.cargo === 25 || dados.cargo === 38 || dados.cargo === 43 || dados.cargo === 48 || dados.cargo === 54 || dados.cargo === 60 || dados.cargo === 67 || dados.cargo === 79 || dados.cargo === 85 || dados.cargo === 90 || dados.cargo === 96 || dados.cargo === 101 || dados.cargo === 107 || dados.cargo === 114 || dados.cargo === 121 || dados.cargo === 131 || dados.cargo === 136 || dados.cargo === 145 || dados.cargo === 152 || dados.cargo === 159 || dados.cargo === 174 ? '' : dados.cargo_descricao}
                        complemento={dados.cargo === 5 ? ' Prefeito(a)' : dados.cargo === 28 ? ' da República' : dados.cargo === 29 ? ' Presidente da República' : dados.cargo === 31 ? ' do Brasil' : dados.cargo === 51 ? ' do Senado Federal' : dados.cargo === 52 ? ' Presidente do Senado Federal' : dados.cargo === 56 ? ' da Câmara dos Deputados' : dados.cargo === 57 ? ' Presidente da Câmara dos Deputados' : dados.cargo === 71 ? ' Governador(a)' : dados.cargo === 99 ? ' da Assembleia Legislativa' : dados.cargo === 62 ? ' Integrante do Judiciário Federal' : dados.cargo === 63 ? ' Integrante do Judiciário Federal' : dados.cargo === 64 ? ' Integrante do Judiciário Federal' : dados.cargo === 65 ? ' Integrante do Judiciário Federal' : dados.cargo === 66 ? ' Integrante do Judiciário Federal' : ''}
                        campo_obs={dados.empresa_instituicao}
                        parlamentar={dados.cargo === 76 || dados.cargo === 100 || dados.cargo === 53 || dados.cargo === 59 ? dados.nome_autoridade + ' - ' : ''}
                        tipo_inscricao={dados.cargo === 72 || dados.cargo === 75 || dados.cargo === 33 || dados.cargo === 34 || dados.cargo === 35 || dados.cargo === 36 || dados.cargo === 37 || dados.cargo === 38 || dados.cargo === 76 || dados.cargo === 77 || dados.cargo === 78 || dados.cargo === 79 || dados.cargo === 90 || dados.cargo === 157 ? dados.tipo_inscricao_descricao : ''}
                        tipo_cargo={dados.cargo === 54 || dados.cargo === 60 || dados.cargo === 81 || dados.cargo === 83 || dados.cargo === 84 || dados.cargo === 85 || dados.cargo === 82 || dados.cargo === 87 || dados.cargo === 88 || dados.cargo === 89 || dados.cargo === 40 || dados.cargo === 41 || dados.cargo === 42 || dados.cargo === 43 || dados.cargo === 45 || dados.cargo === 46 || dados.cargo === 47 || dados.cargo === 48 || dados.cargo === 101 || dados.cargo === 161 || dados.cargo === 162 || dados.cargo === 163 || dados.cargo === 164 ? dados.tipo_cargo_descricao : ''}
                        partido={dados.cargo === 76 || dados.cargo === 98 || dados.cargo === 99 || dados.cargo === 100 || dados.cargo === 53 || dados.cargo === 59 ? dados.partido_sigla : ''}
                        municipio={dados.cargo === 3 || dados.cargo === 4 || dados.cargo === 5 || dados.cargo === 6 || dados.cargo === 7 || dados.cargo === 8 || dados.cargo === 9 || dados.cargo === 10 || dados.cargo === 11 || dados.cargo === 12 || dados.cargo === 13 || dados.cargo === 14 || dados.cargo === 24 ? dados.nome_municipio : ''}
                        statusContribuicao={dados.status_contribuicao === 'true' ? '●' : dados.status_contribuicao === 'false' ? '' : ''}
                        ref={etiquetaRef}
                      />
                    </div>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default TabelaDados;
