import React, { useState, useRef } from 'react';
import Certificado from './Certificado';
import { Container, Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, TextField, Button, Box, Snackbar, Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import Navbar from '../pages/theme/Navbar';
import Footer from './Footer';
import api from '../service/Api';
import { useReactToPrint } from 'react-to-print';

const PesquisaSatisfacao = ({ user }) => {
    const [formValues, setFormValues] = useState({
        cpf: user?.cpf,
        comunicacao: {
            "Disponibilização das informações no site": '',
            "Mensagens (e-mail, SMS e Whatsapp)": '',
            "Redes Sociais (Facebook, Twitter e Instagram)": '',
            "Sinalização e informativos no local do evento": '',
            "Atendimento e suporte da Equipe CNM": ''
        },
        espaco: {
            "Localização do evento": '',
            "Credenciamento": '',
            "Instalações Físicas": '',
            "Limpeza e manutenção do local": '',
            "Qualidade e opções de alimentação": ''
        },
        programacao: {
            "Programação do Evento": '',
            "Domínio do conteúdo pelos palestrantes": '',
            "Tempo destinado às atividades": '',
            "Qualidade dos materiais disponibilizados": '',
            "Relevância dos temas abordados para a gestão municipal": ''
        },
        grau_satisfacao: '',
        mensagem: ''
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const certificadoRef = useRef();
    const [certificadoData, setCertificadoData] = useState(null);
    const Print = useReactToPrint({
        content: () => certificadoRef.current,
        onAfterPrint: () => {
            window.location.reload();
        }
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        const [category, index] = name.split('_');

        const labels = {
            comunicacao: [
                "Disponibilização das informações no site",
                "Mensagens (e-mail, SMS e Whatsapp)",
                "Redes Sociais (Facebook, Twitter e Instagram)",
                "Sinalização e informativos no local do evento",
                "Atendimento e suporte da Equipe CNM"
            ],
            espaco: [
                "Localização do evento",
                "Credenciamento",
                "Instalações Físicas",
                "Limpeza e manutenção do local",
                "Qualidade e opções de alimentação"
            ],
            programacao: [
                "Programação do Evento",
                "Domínio do conteúdo pelos palestrantes",
                "Tempo destinado às atividades",
                "Qualidade dos materiais disponibilizados",
                "Relevância dos temas abordados para a gestão municipal"
            ]
        };

        if (labels[category]) {
            const updatedCategory = labels[category][index];
            setFormValues((prevValues) => ({
                ...prevValues,
                [category]: {
                    ...prevValues[category],
                    [updatedCategory]: value
                }
            }));
        } else {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const validateCategories = ['comunicacao', 'espaco', 'programacao'];
        for (const category of validateCategories) {
            if (Object.values(formValues[category]).some(value => value === '')) {
                setSnackbarMessage(`Selecione pelo menos uma opção em cada campo de ${category}.`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setIsSubmitting(false);
                return;
            }
        }
        if (!formValues.grau_satisfacao) {
            setSnackbarMessage('Selecione uma opção de avaliação de satisfação.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setIsSubmitting(false);
            return;
        }

        try {
            const dataToSend = {
                cpf: formValues.cpf,
                comunicacao: JSON.stringify(formValues.comunicacao),
                espaco: JSON.stringify(formValues.espaco),
                programacao: JSON.stringify(formValues.programacao),
                grau_satisfacao: formValues.grau_satisfacao,
                mensagem: formValues.mensagem
            };

            const response = await api.post('/api/avaliacao', dataToSend);

            if (response?.status === 200) {
                setSnackbarMessage('Avaliação enviada com sucesso!');
                setSnackbarSeverity('success');
                setCertificadoData(user);
                setSnackbarOpen(true);
                setTimeout(() => {
                    Print();
                }, 200);
            } else {
                setSnackbarMessage('Erro ao cadastrar a avaliação. Por favor, tente novamente mais tarde.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setIsSubmitting(false);
            }
        } catch (error) {
            console.error('Erro ao cadastrar a avaliação:', error);
            setSnackbarMessage('Erro ao cadastrar a avaliação. Por favor, tente novamente mais tarde.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setIsSubmitting(false);
        }
    };

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <Navbar />
            <Container component="main" maxWidth="md" sx={{ mt: 8, mb: 2, flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom align="center">
                    AVALIAÇÃO DO EVENTO
                </Typography>
                <Typography variant="body1" gutterBottom align="center">
                    Preencha o formulário abaixo e ao final do preenchimento imprima o seu certificado
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center">Avalie os seguintes aspectos do evento:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={6} align="center">
                                                    <Typography variant="h6">COMUNICAÇÃO E INFORMAÇÕES</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="center">N/A</TableCell>
                                                <TableCell align="center">Ruim</TableCell>
                                                <TableCell align="center">Regular</TableCell>
                                                <TableCell align="center">Bom</TableCell>
                                                <TableCell align="center">Excelente</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {[
                                                'Disponibilização das informações no site',
                                                'Mensagens (e-mail, SMS e Whatsapp)',
                                                'Redes Sociais (Facebook, Twitter e Instagram)',
                                                'Sinalização e informativos no local do evento',
                                                'Atendimento e suporte da Equipe CNM'
                                            ].map((label, index) => (
                                                <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
                                                    <TableCell>{label}</TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`comunicacao_${index}`} value={formValues.comunicacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="N/A" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`comunicacao_${index}`} value={formValues.comunicacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Ruim" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`comunicacao_${index}`} value={formValues.comunicacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Regular" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`comunicacao_${index}`} value={formValues.comunicacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Bom" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`comunicacao_${index}`} value={formValues.comunicacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Excelente" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={6} align="center">
                                                    <Typography variant="h6">ESTRUTURA E LOCALIZAÇÃO</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="center">N/A</TableCell>
                                                <TableCell align="center">Ruim</TableCell>
                                                <TableCell align="center">Regular</TableCell>
                                                <TableCell align="center">Bom</TableCell>
                                                <TableCell align="center">Excelente</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {[
                                                'Localização do evento',
                                                'Credenciamento',
                                                'Instalações Físicas',
                                                'Limpeza e manutenção do local',
                                                'Qualidade e opções de alimentação'
                                            ].map((label, index) => (
                                                <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
                                                    <TableCell>{label}</TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`espaco_${index}`} value={formValues.espaco[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="N/A" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`espaco_${index}`} value={formValues.espaco[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Ruim" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`espaco_${index}`} value={formValues.espaco[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Regular" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`espaco_${index}`} value={formValues.espaco[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Bom" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`espaco_${index}`} value={formValues.espaco[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Excelente" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={6} align="center">
                                                    <Typography variant="h6">APRESENTAÇÕES E CONTEÚDO</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell align="center">N/A</TableCell>
                                                <TableCell align="center">Ruim</TableCell>
                                                <TableCell align="center">Regular</TableCell>
                                                <TableCell align="center">Bom</TableCell>
                                                <TableCell align="center">Excelente</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {[
                                                'Programação do Evento',
                                                'Domínio do conteúdo pelos palestrantes',
                                                'Tempo destinado às atividades',
                                                'Qualidade dos materiais disponibilizados',
                                                'Relevância dos temas abordados para a gestão municipal'
                                            ].map((label, index) => (
                                                <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f9f9f9' }}>
                                                    <TableCell>{label}</TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`programacao_${index}`} value={formValues.programacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="N/A" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`programacao_${index}`} value={formValues.programacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Ruim" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`programacao_${index}`} value={formValues.programacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Regular" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`programacao_${index}`} value={formValues.programacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Bom" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <RadioGroup name={`programacao_${index}`} value={formValues.programacao[label] || ''} onChange={handleChange} row>
                                                            <FormControlLabel value="Excelente" control={<Radio />} label=" " />
                                                        </RadioGroup>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <FormControl component="fieldset">
                                    <Typography component="legend" sx={{ fontSize: '1.25rem' }} align="center">De 0 a 10, como você avalia o evento como um todo?</Typography>
                                    <RadioGroup row name="grau_satisfacao" value={formValues.grau_satisfacao} onChange={handleChange} style={{ justifyContent: 'center' }}>
                                        {[...Array(11).keys()].map((value) => (
                                            <FormControlLabel key={value} value={value.toString()} control={<Radio />} label={value.toString()} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h6" align="center">Deixe aqui elogios, sugestões e/ou críticas</Typography>
                                <TextField
                                    name="mensagem"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={formValues.mensagem}
                                    onChange={handleChange}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={isSubmitting}
                            >
                                Enviar
                            </Button>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'none' }}>
                        {certificadoData && (
                            <Certificado
                                ref={certificadoRef}
                                nome={certificadoData.nome}
                                cargo={certificadoData.cargo}
                                municipio={certificadoData.municipio}
                                uf={certificadoData.uf}
                            />
                        )}
                    </div>
                </form>
            </Container>
            <Box mt="auto">
                <Footer />
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PesquisaSatisfacao;
