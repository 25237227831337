import React, { useState, useEffect, useCallback } from 'react';
import api from '../service/Api';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Paper,
    TableSortLabel,
    Grid,
    TextField,
    InputAdornment,
    Box,
    Typography,
    Button,
    Modal,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextareaAutosize
} from "@mui/material";
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import PaymentsIcon from '@mui/icons-material/Payments';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CustomModal from './CustomModal';

const TabelaCortesias = ({ OcultarCPF, setSearchTerm, showAlert, orderBy, order, handleSort, sortedAndFilteredUsers, searchTerm, verificaSituacao, CreateFormData, setInscricoes, hasPermission, isAdmin }) => {
    const [page, setPage] = useState(0);
    const [entidadeNacional, setEntidadeNacional] = useState([]);
    const [entidadeEstadual, setEntidadeEstadual] = useState([]);
    const [entidadeMicro, setEntidadeMicro] = useState([]);
    const [consorcio, setConsorcio] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedUsers = sortedAndFilteredUsers.slice(startIndex, endIndex);
    const [selectedPerson, setSelectedPerson] = useState({ id: null, data: null });
    const [open, setOpen] = useState(null);
    const [reload, setReload] = useState(false);
    const [openModalGerarCortesia, setOpenModalGerarCortesia] = useState(false);
    const [openModalGerarPagamento, setOpenModalGerarPagamento] = useState(false);
    const [openModalObservacao, setOpenModalObservacao] = useState(false);
    const [openModalGerarPagamentoConfirmacao, setOpenModalGerarPagamentoConfirmacao] = useState(false);
    const [desabilitarBotao, setDesabilitarBotao] = useState(false);
    const handleExpandRow = (rowId, rowData) => {
        setOpen(open === rowId ? null : rowId);
        setSelectedPerson({ id: rowId, data: rowData });
    };

    const handleOpenModalGerarCortesia = () => {
        setOpenModalGerarCortesia(true);
    };
    const handleCloseModalGerarCortesia = () => {
        setOpenModalGerarCortesia(false);
    };

    const handleOpenModalGerarPagamento = () => {
        setOpenModalGerarPagamento(true);
    };
    const handleCloseModalGerarPagamento = () => {
        setOpenModalGerarPagamento(false);
    };

    const handleOpenModalObservacao = () => {
        setOpenModalObservacao(true);
    };
    const handleCloseModalObservacao = () => {
        setOpenModalObservacao(false);
    };

    const handleOpenModalGerarPagamentoConfirmacao = () => {
        setOpenModalGerarPagamentoConfirmacao(true);
    };
    const handleCloseModalGerarPagamentoConfirmacao = () => {
        setOpenModalGerarPagamentoConfirmacao(false);
    };

    const HandleChangeForm = useCallback((atributo, valor) => {
        setSelectedPerson((prevFormValues) => ({
            ...prevFormValues,
            data: {
                ...prevFormValues.data,
                [atributo]: valor,
            },
        }));

    }, [setSelectedPerson]);

    const GerarCortesia = async () => {
        try {
            if (!selectedPerson?.data?.obs) {
                return showAlert('Por favor, informe o motivo da aprovação da cortesia', 'error');
            }
            const updatedData = {
                ...selectedPerson.data,
                aprovado: "false",
                cortesia: "1",
                obs: 'Aprovado por: ' + JSON.parse(localStorage.getItem('user_credmarcha')).name + '. Considerações: ' + selectedPerson?.data?.obs
            };

            Object.keys(updatedData).forEach((key) => {
                if (updatedData[key] === null) {
                    updatedData[key] = "";
                }
            });
            delete updatedData?.file;

            const formData = CreateFormData(updatedData);

            const responseAprovacao = await api.put(`api/cadastroUser/${selectedPerson.id}`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest"
                }
            });

            if (responseAprovacao?.data?.status === 200) {
                handleEnviarEMail(true);
                setReload(true);
                handleCloseModalGerarCortesia();
                handleCloseModalObservacao();
            } else {
                showAlert('Erro ao atualizar status para aprovado. Por favor, tente novamente mais tarde', 'error');
            }
        } catch (error) {
            showAlert("Não foi possível gerar a cortesia. Por favor, tente novamente mais tarde", 'error');
            console.error(error);
        }
    };

    const CriarNovoBoleto = async () => {
        try {

            const updatedData = {
                ...selectedPerson.data,
                aprovado: "false",
                cortesia: "0",
            };

            Object.keys(updatedData).forEach((key) => {
                if (updatedData[key] === null) {
                    updatedData[key] = "";
                }
            });
            delete updatedData?.file;

            const formData = CreateFormData(updatedData);

            const responseAprovacao = await api.put(`api/cadastroUser/${selectedPerson.id}`, formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest"
                }
            });

            if (responseAprovacao?.data?.status === 200) {
                const { cpf, cnpj, celular, cep, endereco, bairro, opc_pagamento, tipo_pagamento, municipio, uf, valorBoleto, numeroTituloCliente, nome } = selectedPerson.data;

                if (!valorBoleto) {
                    return showAlert("Por favor, informe o valor do boleto", 'error');
                }
                const formattedCPF = cpf.replace(/\D/g, '');
                const formattedValor = parseFloat(valorBoleto.replace(/,00$/, ''));
                setDesabilitarBotao(true);

                const requestBody = {
                    cpf: formattedCPF,
                    cnpj,
                    celular,
                    cep,
                    endereco,
                    bairro,
                    opc_pagamento,
                    tipo_pagamento,
                    municipio,
                    uf,
                    valor: formattedValor,
                    numeroCliente: numeroTituloCliente,
                    nome,
                };

                const response = await api.post('api/novoBoleto', requestBody);

                if (response?.data?.NovoBoleto) {
                    handleEnviarEMail(false);

                } else {
                    showAlert("Não foi possível criar um novo pagamento. Por favor, tente novamente mais tarde", 'error');
                    setDesabilitarBotao(false);
                }
            } else {
                showAlert('Erro ao atualizar status para aprovado. Por favor, tente novamente mais tarde', 'error');
            }
        } catch (error) {
            showAlert("Não foi possível criar um novo pagamento. Por favor, tente novamente mais tarde", 'error');
            setDesabilitarBotao(false);
        }
    };

    const validarValor = (valor) => {
        const valorNumerico = valor.replace(/[^0-9]/g, '');
        const valorValidado = Math.min(Math.max(parseInt(valorNumerico, 10) || 0, 0), 9999);
        return valorValidado.toString();
    };

    const handleEnviarEMail = async (cortesiaAprovada) => {
        try {
            const emailType = cortesiaAprovada ? 'confirmacao_participacao' : 'boleto';

            const response = await api.post(`api/reenviarEmail`, { id: selectedPerson.id, [emailType]: true });

            if (response?.data?.reenviarEmail) {
                const successMessage = cortesiaAprovada
                    ? 'Cortesia gerada com sucesso. E-mail de confirmação de participação enviado com sucesso!'
                    : 'Pagamento gerado com sucesso. O boleto foi enviado ao e-mail do participante.';

                showAlert(successMessage, 'success');
                setReload(true);
                setDesabilitarBotao(false);
                handleCloseModalGerarPagamento();
                handleCloseModalGerarPagamentoConfirmacao();
            }
        } catch (error) {
            const status = error?.response?.status;
            const errors = error?.response?.data?.errors;

            if (status === 404 && errors && Object.keys(errors).length > 0) {
                const errorMessage = Object.values(errors)
                    .flat()
                    .join(' ');

                showAlert(errorMessage, 'warning');
            } else {
                const errorMessage = cortesiaAprovada
                    ? 'Ocorreu um erro ao enviar o e-mail de confirmação de participação. Por favor, tente novamente.'
                    : 'Ocorreu um erro ao enviar o e-mail de boleto. Por favor, tente novamente.';

                showAlert(errorMessage, 'error');
            }
        }
    };

    useEffect(() => {
        if (selectedPerson?.data?.opc_pagamento === '2') {
            HandleChangeForm('opc_pagamento', '1');
        }
    }, [selectedPerson?.data?.opc_pagamento, HandleChangeForm]);

    useEffect(() => {
        if ([3, 16].includes(selectedPerson?.data?.cargo) && (selectedPerson?.data?.status_contribuicao === "false" || selectedPerson?.data?.status_contribuicao === false)) {
            HandleChangeForm('valorBoleto', process.env.REACT_APP_VALOR_PREFEITO_NAO_CONTRIBUINTE);
        } else if (selectedPerson?.data?.status_contribuicao === true || selectedPerson?.data?.status_contribuicao === "true") {
            HandleChangeForm('valorBoleto', process.env.REACT_APP_VALOR_MUNICIPIO_CONTRIBUINTE);
        } else if (selectedPerson?.data?.uf === 'DF') {
            HandleChangeForm('valorBoleto', process.env.REACT_APP_VALOR_DF);
        } else {
            HandleChangeForm('valorBoleto', process.env.REACT_APP_VALOR_DEMAIS);
        }
        // eslint-disable-next-line
    }, [selectedPerson.id, HandleChangeForm]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setReload(false);
                const response = await api.get('api/cadastroUser?aprovado=false&cortesia=0&obs=""');
                const inscricoesAtualizadas = response?.data?.getCadastroUser
                    .filter((inscricao) => inscricao.tipo_inscricao !== 1 && !inscricao.status)
                    .map((inscricao) => ({
                        ...inscricao,
                        aprovado: 'Não homologado',
                        situacao: 'Cortesia',
                    }));

                const cortesias = inscricoesAtualizadas.filter((inscricao) => inscricao.situacao === 'Cortesia');

                setInscricoes((prevInscricoes) => {
                    if (JSON.stringify(prevInscricoes) !== JSON.stringify(cortesias)) {
                        return cortesias;
                    }
                    return prevInscricoes;
                });

            } catch (error) {
                const errors = error?.response?.data?.errors;
                console.log(errors);
                showAlert('Ocorreu um erro ao realizar a consulta', 'error');
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [reload]);

    useEffect(() => {
        const fetchEntidade = async () => {
            try {
                const response1 = await api.get(`api/getConsorcios`);
                setConsorcio(response1.data.consorcios)
                const response2 = await api.get(`api/getEntidadesNacionais`);
                setEntidadeNacional(response2.data.EntidadesNacionais);
                const response3 = await api.get(`api/getEntidadesEstaduais`);
                setEntidadeEstadual(response3.data.EntidadesEstaduais);
                const response4 = await api.get(`api/getEntidadesMicros`);
                setEntidadeMicro(response4.data.EntidadesMicros);

            } catch (error) {
                console.error('Erro ao buscar entidade:', error);
            }
        };

        fetchEntidade();
    }, []);

    return (
        <Paper>
            <Grid container xs={12} sm={12}>
                <Grid item xs={12} sm={3}>

                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>
                <Grid item alignItems="center" justifyContent="space-between" xs={12} sm={3}>
                    <Grid item>
                        <TextField
                            label="Pesquisar"
                            variant="outlined"
                            value={searchTerm}
                            style={{ marginTop: '10px' }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <TableContainer style={{ marginTop: '10px' }} component={Paper} className="custom-table">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'nome'}
                                    direction={orderBy === 'nome' ? order : 'asc'}
                                    onClick={() => handleSort('nome')}
                                >
                                    Nome
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'cpf'}
                                    direction={orderBy === 'cpf' ? order : 'asc'}
                                    onClick={() => handleSort('cpf')}
                                >
                                    CPF
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'aprovado'}
                                    direction={orderBy === 'aprovado' ? order : 'asc'}
                                    onClick={() => handleSort('aprovado')}
                                >
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'status'}
                                    direction={orderBy === 'status' ? order : 'asc'}
                                    onClick={() => handleSort('status')}
                                >
                                    Situação
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedUsers.map((inscricao) => (
                            <React.Fragment key={inscricao.id}>
                                <TableRow key={inscricao.id}>
                                    <TableCell>{inscricao.nome}</TableCell>
                                    <TableCell>{OcultarCPF(inscricao.cpf)}</TableCell>
                                    <TableCell>{inscricao.aprovado}</TableCell>
                                    <TableCell>{inscricao.situacao}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleExpandRow(inscricao.id, inscricao)}
                                        >
                                            {open === inscricao.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={open === inscricao?.id} timeout="auto" unmountOnExit>
                                        <Box sx={{ margin: 1 }}>
                                            {/* <Typography variant="h6" gutterBottom component="div">
                                                Detalhes de {inscricao?.nome}
                                            </Typography> */}
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography>
                                                        {inscricao?.nome && (
                                                            <>
                                                                <span style={{ fontWeight: 'bold' }}>Nome:</span> {inscricao?.nome}
                                                            </>
                                                        )}
                                                    </Typography>

                                                    <Typography>
                                                        {inscricao?.celular && (
                                                            <>
                                                                <span style={{ fontWeight: 'bold' }}>Celular:</span> {inscricao?.celular}
                                                            </>
                                                        )}
                                                    </Typography>

                                                    <Typography>
                                                        {inscricao?.email && (
                                                            <>
                                                                <span style={{ fontWeight: 'bold' }}>E-mail:</span> {inscricao?.email}
                                                            </>
                                                        )}
                                                    </Typography>

                                                    {inscricao?.ministerio_orgao && (
                                                        <>
                                                            <Typography>
                                                                <span style={{ fontWeight: 'bold' }}>Ministério:</span> {inscricao?.ministerio_orgao}
                                                            </Typography>
                                                        </>
                                                    )}

                                                    {inscricao?.convidado && (() => {
                                                        try {
                                                            const convidadoObj = JSON.parse(inscricao?.convidado);
                                                            const convidadoDe = convidadoObj?.convidado_de;
                                                            if (convidadoDe && convidadoDe.trim() !== "") {
                                                                return (
                                                                    <Typography>
                                                                        <span style={{ fontWeight: 'bold' }}>Convidado de:</span> {convidadoDe}
                                                                    </Typography>
                                                                );
                                                            }
                                                        } catch (error) {
                                                            console.error('Erro ao fazer parsing do JSON:', error);
                                                        }

                                                        return null;
                                                    })()}

                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography>
                                                        {inscricao?.tipo_inscricao_descricao && (
                                                            <>
                                                                <span style={{ fontWeight: 'bold' }}>Tipo de Inscrição:</span> {inscricao?.tipo_inscricao_descricao}
                                                            </>
                                                        )}
                                                    </Typography>

                                                    <Typography>
                                                        {inscricao?.tipo_cargo_descricao && (
                                                            <>
                                                                <span style={{ fontWeight: 'bold' }}>Tipo de Cargo:</span> {inscricao?.tipo_cargo_descricao}
                                                            </>
                                                        )}
                                                    </Typography>

                                                    <Typography>
                                                        {inscricao?.cargo_descricao && (
                                                            <>
                                                                <span style={{ fontWeight: 'bold' }}>Cargo:</span> {inscricao?.cargo_descricao}
                                                            </>
                                                        )}
                                                    </Typography>

                                                    {inscricao?.empresa_instituicao && (
                                                        <Typography>
                                                            <span style={{ fontWeight: 'bold' }}>Empresa/Instituição:</span> {inscricao?.empresa_instituicao}
                                                        </Typography>
                                                    )}

                                                    {inscricao?.consorcio && (
                                                        <Typography>
                                                            <span style={{ fontWeight: 'bold' }}>Consórcio:</span> {consorcio.find((c) => c.id === inscricao?.consorcio)?.NOME_ENTIDADE || 'Desconhecido'}
                                                        </Typography>
                                                    )}

                                                    {inscricao?.entidade_nacionais && (
                                                        <Typography>
                                                            <span style={{ fontWeight: 'bold' }}>Entidade Nacional:</span> {entidadeNacional.find((en) => en.id === inscricao?.entidade_nacionais)?.NOME_ENTIDADE || 'Desconhecido'}
                                                        </Typography>
                                                    )}

                                                    {inscricao?.entidade_estadual && (
                                                        <Typography>
                                                            <span style={{ fontWeight: 'bold' }}>Entidade Estadual:</span> {entidadeEstadual.find((es) => es.id === inscricao?.entidade_estadual)?.NOME_ENTIDADE || 'Desconhecido'}
                                                        </Typography>
                                                    )}

                                                    {inscricao?.entidade_micro && (
                                                        <Typography>
                                                            <span style={{ fontWeight: 'bold' }}>Entidade Microrregional:</span> {entidadeMicro.find((em) => em.id === inscricao?.entidade_micro)?.NOME_ENTIDADE || 'Desconhecido'}
                                                        </Typography>
                                                    )}
                                                </Grid>

                                            </Grid>

                                        </Box>
                                        {isAdmin || hasPermission('gerenciar-convidados', 'escrita') ? (
                                            <>
                                                <Grid container spacing={2} alignItems="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                    <Grid item xs={12} sm={3} >
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} >
                                                        <Button variant="contained" color="primary" onClick={handleOpenModalGerarPagamento}>
                                                            <PaymentsIcon style={{ marginRight: '10px' }} /> Gerar Pagamento
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} >
                                                        <Button variant="contained" style={{ backgroundColor: '#FF9800', color: '#fff' }} onClick={handleOpenModalObservacao}>
                                                            <ThumbUpAltIcon style={{ marginRight: '10px' }} /> Aprovar Cortesia
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : null}

                                    </Collapse>

                                </TableCell>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    count={sortedAndFilteredUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                    }}
                    labelRowsPerPage="Registros por página:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                />
            </TableContainer>

            <CustomModal
                open={openModalGerarCortesia}
                onClose={handleCloseModalGerarCortesia}
                title="Confirmar Operação"
                content="Deseja realmente gerar esta cortesia para este convidado?"
                onCancel={handleCloseModalGerarCortesia}
                onConfirm={GerarCortesia}
            />
            <CustomModal
                open={openModalGerarPagamentoConfirmacao}
                onClose={handleCloseModalGerarPagamentoConfirmacao}
                title="Confirmar Operação"
                content="Deseja realmente criar um pagamento para este convidado?"
                onCancel={handleCloseModalGerarPagamentoConfirmacao}
                onConfirm={CriarNovoBoleto}
                disabled={desabilitarBotao}
            />

            <Modal
                open={openModalGerarPagamento}
                onClose={handleCloseModalGerarPagamento}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        borderRadius: '4px',
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Gerar Pagamento
                    </Typography>
                    <Typography variant="body1" component="p" style={{ marginTop: '20px' }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel >Tipo de pagamento</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={(e) => HandleChangeForm('tipo_pagamento', e.target.value)}
                                        value={selectedPerson?.data?.tipo_pagamento || ''}
                                    >
                                        <MenuItem value="boleto">Boleto</MenuItem>
                                        {/* <MenuItem value="pix">Pix</MenuItem>
                                                <MenuItem value="debito">Debito</MenuItem>
                                                <MenuItem value="dinheiro">Dinheiro</MenuItem>
                                                <MenuItem value="cheque">Cheque</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '10px' }}>
                                <FormControl fullWidth>
                                    <InputLabel>Dados pagamento</InputLabel>
                                    <Select
                                        variant="outlined"
                                        onChange={(e) => HandleChangeForm('opc_pagamento', e.target.value)}
                                        value={selectedPerson?.data?.opc_pagamento || ''}
                                    >
                                        <MenuItem value="1">Pessoa Física</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '10px' }}>
                                <TextField
                                    variant="outlined"
                                    value={selectedPerson?.data?.valorBoleto}
                                    onChange={(e) => HandleChangeForm('valorBoleto', validarValor(e.target.value))}
                                    fullWidth
                                    label="Valor do boleto"
                                    inputProps={{ maxLength: 4 }}
                                />
                            </Grid>

                        </Grid>
                    </Typography>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={handleCloseModalGerarPagamento} style={{ marginRight: '10px' }}>
                            Cancelar
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleOpenModalGerarPagamentoConfirmacao}>
                            Confirmar
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={openModalObservacao}
                onClose={handleCloseModalObservacao}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        borderRadius: '4px',
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Gerar Cortesia - Observação
                    </Typography>
                    <Typography variant="body1" component="p" style={{ marginTop: '20px' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    aria-label="Considerações"
                                    placeholder="Considerações"
                                    minRows={4}
                                    value={selectedPerson?.data?.obs}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= 500) {
                                            HandleChangeForm('obs', inputValue);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        fontFamily: 'Arial',
                                        fontSize: '16px',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        padding: '8px',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Typography>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outlined" onClick={handleCloseModalObservacao} style={{ marginRight: '10px' }}>
                            Cancelar
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleOpenModalGerarCortesia}>
                            Confirmar
                        </Button>
                    </div>
                </div>
            </Modal>

        </Paper>
    );
}

export default TabelaCortesias;
