import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Email = () => {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#F5F5F5' }}>
      <Container maxWidth="sm">
        <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#FFFFFF', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Typography variant="h5" style={{ color: '#1E88E5', fontWeight: 'bold', textShadow: '1px 1px 1px rgba(0,0,0,0.1)' }}>
              Confirmação de E-mail
            </Typography>
          </div>
          <Typography variant="h4" align="center" style={{ color: '#4CAF50', marginBottom: '20px' }}>
            <strong>Sucesso!</strong>
          </Typography>
          <div style={{ marginBottom: '20px' }}>
            <Typography variant="body1" align="center" style={{ color: '#757575', textShadow: '1px 1px 1px rgba(0,0,0,0.1)', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', fontSize: '1.5rem', letterSpacing: '0.05em' }}>
              E-mail validado com sucesso!
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" style={{ marginRight: '5px', fontSize: '16px', color: '#757575', textShadow: '1px 1px 1px rgba(0,0,0,0.1)', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', letterSpacing: '0.05em', }}>
              Retorne ao totem e aperte em enviar.
            </Typography>
            <CheckCircleOutlineIcon style={{ color: '#4CAF50', fontSize: '55px' }} />
          </div>
        </Paper>
      </Container>
    </div>
  );
};

export default Email;



