import React, { useState, useEffect } from "react";
import Navbar from "../../components/AuthenticatedNavbar";
import * as Yup from 'yup';
import CssBaseline from '@mui/material/CssBaseline';
// import { HomeFuncionalidades } from "../../constants/HomeFuncionalidades";
import ConstantInscricoes from "../../constants/ConstantInscricoes";
import {
    Paper, Container, Grid, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography, IconButton, InputLabel, Select, MenuItem, Checkbox,
    Switch, Snackbar, Alert
} from "@mui/material";
import TocIcon from '@mui/icons-material/Toc';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import DateRangeIcon from '@mui/icons-material/DateRange';
import BusinessIcon from '@mui/icons-material/Business';
import ReorderIcon from '@mui/icons-material/Reorder';
import { formatCEP } from '@brazilian-utils/brazilian-utils';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { red } from '@mui/material/colors';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import axios from 'axios';
import api from '../../service/Api';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Criacao = () => {
    const [checkedNome, setCheckedNome] = useState(true);

    const [checkedUF, setCheckedUF] = useState(true);
    const [switchUF, setSwitchUF] = useState(true);

    const [checkedMunicipio, setCheckedMunicipio] = useState(true);
    const [switchMunicipio, setSwitchMunicipio] = useState(true);

    const [checkedCelular, setCheckedCelular] = useState(true);
    const [switchCelular, setSwitchCelular] = useState(true);

    const [checkedEmail, setCheckedEmail] = useState(true);
    const [switchEmail, setSwitchEmail] = useState(true);

    const [checkedSexo, setCheckedSexo] = useState(true);
    const [switchSexo, setSwitchSexo] = useState(true);

    const [checkedPais, setCheckedPais] = useState(true);
    const [switchPais, setSwitchPais] = useState(true);

    const [checkedCep, setCheckedCep] = useState(true);
    const [switchCep, setSwitchCep] = useState(true);

    const [checkedLogradouro, setCheckedLogradouro] = useState(true);
    const [switchLogradouro, setSwitchLogradouro] = useState(true);

    const [checkedBairro, setCheckedBairro] = useState(true);
    const [switchBairro, setSwitchBairro] = useState(true);

    const [checkedComplemento, setCheckedComplemento] = useState(true);
    const [switchComplemento, setSwitchComplemento] = useState(true);

    const [checkedDtNascimento, setCheckedDtNascimento] = useState(true);
    const [switchDtNascimento, setSwitchDtNascimento] = useState(true);

    const [checkedOutroCelular, setCheckedOutroCelular] = useState(true);
    const [switchOutroCelular, setSwitchOutroCelular] = useState(true);

    const [checkedTelefoneFixo, setCheckedTelefoneFixo] = useState(true);
    const [switchTelefoneFixo, setSwitchTelefoneFixo] = useState(true);

    const [nomeEvento, setNomeEvento] = useState('');
    const [linkPaginaInscricao, setLinkPaginaInscricao] = useState('');
    const [descricaoEvento, setDescricaoEvento] = useState('');
    const [edicaoEvento, setEdicaoEvento] = useState('');
    const [documento, setDocumento] = useState('cpf');
    const [copiaHomolog, setCopiaHomolog] = useState('');
    const [confirmaHomolog, setConfirmaHomolog] = useState('');
    const [linkConvite, setLinkConvite] = useState('');
    const [cep, setCEP] = useState('');
    const [entidadeParceira, setEntidadeParceira] = useState('');
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 10,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [timeInicio, setTimeInicio] = useState('');
    const [timeFim, setTimeFim] = useState('');
    const [numberVagas, setNumberVagas] = useState('');
    const [numberSessoes, setNumberSessoes] = useState('');
    const [numberPresenca, setNumberPresenca] = useState('');
    const [valueBox, setValueBox] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [endereco, setEndereco] = useState('');

    const [eventoPago, setEventoPago] = useState(false);
    const [hospedagem, setHospedagem] = useState(false);
    const [acompanhante, setAcompanhante] = useState(false);
    const [quarto, setQuarto] = useState(false);
    const [passagemAerea, setPassagemAerea] = useState(false);
    const [terrestre, setTerrestre] = useState(false);
    const [emitirBoletos, setEmitirBoletos] = useState(false);

    const [open, setOpen] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleEventoPagoChange = (e) => {
        setEventoPago(e.target.value === 'sim');
    };

    const handleHospedagemChange = (e) => {
        setHospedagem(e.target.value === 'sim');
    };

    const handleAcompanhanteChange = (e) => {
        setAcompanhante(e.target.value === 'sim');
    };

    const handleQuartoChange = (e) => {
        setQuarto(e.target.value === 'sim');
    };

    const handlePassagemAereaChange = (e) => {
        setPassagemAerea(e.target.value === 'sim');
    };

    const handleTerrestreChange = (e) => {
        setTerrestre(e.target.value === 'sim');
    };

    const handleEmitirBoletosChange = (e) => {
        setEmitirBoletos(e.target.value === 'sim');
    };

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleChangeBox = (event, newValue) => {
        setValueBox(newValue);
    };

    const {
        token,
    } = ConstantInscricoes({ dontLoadAll: true });

    const eventSchema = Yup.object().shape({
        evento: Yup.string().required('Nome do evento é obrigatório'),
        descricao_evento: Yup.string().required('Descrição do evento é obrigatória'),
        edicao_evento: Yup.string().required('Edição do evento é obrigatória'),
        entidade_parceria: Yup.string().required('Entidade parceira é obrigatória'),
        data_inicio: Yup.date().required('Data de início é obrigatória'),
        hora_inicio: Yup.string().required('Hora de início é obrigatória'),
        data_fim: Yup.date().required('Data de fim é obrigatória'),
        hora_fim: Yup.string().required('Hora de fim é obrigatória'),
        vagas: Yup.number().required('Número de vagas é obrigatório').min(1, 'Deve ter pelo menos uma vaga'),
        pago: Yup.boolean().required('Indicação se o evento é pago é obrigatória'),
        hotel: Yup.string(),
        permite_acompanhante: Yup.boolean(),
        dividir_quarto: Yup.boolean(),
        disponibiliza_passagem_aerea: Yup.boolean(),
        endereco: Yup.string().required('Endereço é obrigatório'),
        uf: Yup.string().required('UF é obrigatória'),
        municipio: Yup.string().required('Município é obrigatório'),
        cep: Yup.string().required('CEP é obrigatório'),
        link_do_convite: Yup.string().url('Link do convite deve ser uma URL válida').required('Link do convite é obrigatório'),
        email_para_copia_da_homologacao: Yup.string().email('Deve ser um email válido').required('Email para cópia da homologação é obrigatório'),
        email_para_confirmacao_da_homologacao: Yup.string().email('Deve ser um email válido').required('Email para confirmação da homologação é obrigatório'),
        link_para_pagina_de_inscricao: Yup.string().url('Link para página de inscrição deve ser uma URL válida').required('Link para página de inscrição é obrigatório'),
        total_de_sessoes: Yup.number().required('Total de sessões é obrigatório').min(1, 'Deve ter pelo menos uma sessão'),
        percentual_de_presenca_obrigatorio: Yup.number().required('Percentual de presença obrigatório é obrigatório').min(0, 'Deve ser maior que um').max(100),
        uf_com_tratamento_diferenciado: Yup.string().required('UF com tratamento diferenciado é obrigatório'),
        desconto_para_uf_diferenciada: Yup.number().required('Desconto para UF diferenciada é obrigatório').min(0),
        formulario: Yup.object().shape({
            documento: Yup.string().required('Documento é obrigatório'),
            uf: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            municipio: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            celular: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            email: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            sexo: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            pais: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            cep: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            logradouro: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            bairro: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            complemento: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            dtNascimento: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            outroCelular: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            }),
            telefoneFixo: Yup.object().shape({
                Obrigatorio: Yup.boolean(),
                Ativo: Yup.boolean()
            })
        })
    });

    useEffect(() => {
        const fetchAddress = async () => {
            const cleanedCep = cep.replace(/\D/g, '');
            if (cleanedCep.length === 8) { // Certifique-se de que o CEP tem 8 dígitos
                try {
                    const response = await axios.get(`https://viacep.com.br/ws/${cleanedCep}/json/`);
                    const { logradouro, bairro, localidade, uf } = response.data;
                    // Correção do erro: definição de setEndereco
                    setEndereco(`${logradouro}, ${bairro}, ${localidade} - ${uf}`);
                } catch (error) {
                    console.error('Erro ao buscar endereço:', error);
                }
            }
        };

        fetchAddress();
    }, [cep]);

    useEffect(() => {
        const fetchDocumento = async () => {
            if (documento === 'passaporte') {

                setCheckedUF(false);
                setSwitchUF(false);
                setCheckedMunicipio(false);
                setSwitchMunicipio(false);
                setCheckedCelular(false);
                setSwitchCelular(false);
                setCheckedCep(false);
                setSwitchCep(false);
                setCheckedLogradouro(false);
                setSwitchLogradouro(false);
                setCheckedBairro(false);
                setSwitchBairro(false);
                setCheckedComplemento(false);
                setSwitchComplemento(false);
                setCheckedDtNascimento(false);
                setSwitchDtNascimento(false);
                setCheckedOutroCelular(false);
                setSwitchOutroCelular(false);
                setCheckedTelefoneFixo(false);
                setSwitchTelefoneFixo(false);
            } else {
                setCheckedUF(true);
                setSwitchUF(true);
                setCheckedMunicipio(true);
                setSwitchMunicipio(true);
                setCheckedCelular(true);
                setSwitchCelular(true);
                setCheckedCep(true);
                setSwitchCep(true);
                setCheckedLogradouro(true);
                setSwitchLogradouro(true);
                setCheckedBairro(true);
                setSwitchBairro(true);
                setCheckedComplemento(true);
                setSwitchComplemento(true);
                setCheckedDtNascimento(true);
                setSwitchDtNascimento(true);
                setCheckedOutroCelular(true);
                setSwitchOutroCelular(true);
                setCheckedTelefoneFixo(true);
                setSwitchTelefoneFixo(true);
            }
        };

        fetchDocumento();
    }, [documento]);

    useEffect(() => {
        const formattedNomeEvento = nomeEvento
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s+/g, '_')
            .toLowerCase();

        const fetchNome = async () => {
            setLinkPaginaInscricao('https://credmarcha.cnm.org.br/' + formattedNomeEvento)
        }

        fetchNome();
    }, [nomeEvento]);

    const handleSubmit = async () => {
        const data = {
            evento: nomeEvento,
            descricao_evento: descricaoEvento,
            edicao_evento: edicaoEvento,
            entidade_parceria: entidadeParceira,
            data_inicio: startDate,
            hora_inicio: timeInicio,
            data_fim: endDate,
            hora_fim: timeFim,
            vagas: numberVagas,
            pago: eventoPago,
            hotel: hospedagem,
            permite_acompanhante: acompanhante,
            dividir_quarto: quarto,
            disponibiliza_passagem_aerea: passagemAerea,
            permite_terrestre: terrestre,
            emitir_boletos: emitirBoletos,
            endereco: endereco,
            uf: 'DF',
            municipio: 'Brasília',
            cep: cep,
            link_do_convite: linkConvite,
            email_para_copia_da_homologacao: copiaHomolog,
            email_para_confirmacao_da_homologacao: confirmaHomolog,
            link_para_pagina_de_inscricao: linkPaginaInscricao,
            total_de_sessoes: numberSessoes,
            percentual_de_presenca_obrigatorio: numberPresenca,
            uf_com_tratamento_diferenciado: 'DF',
            desconto_para_uf_diferenciada: 5.00,
            formulario: {
                documento: documento,
                nome: {
                    Obrigatorio: true,
                    Ativo: true
                },
                uf: {
                    Obrigatorio: checkedUF,
                    Ativo: switchUF
                },
                municipio: {
                    Obrigatorio: checkedMunicipio,
                    Ativo: switchMunicipio
                },
                celular: {
                    Obrigatorio: checkedCelular,
                    Ativo: switchCelular
                },
                email: {
                    Obrigatorio: checkedEmail,
                    Ativo: switchEmail
                },
                sexo: {
                    Obrigatorio: checkedSexo,
                    Ativo: switchSexo
                },
                pais: {
                    Obrigatorio: checkedPais,
                    Ativo: switchPais
                },
                cep: {
                    Obrigatorio: checkedCep,
                    Ativo: switchCep
                },
                logradouro: {
                    Obrigatorio: checkedLogradouro,
                    Ativo: switchLogradouro
                },
                bairro: {
                    Obrigatorio: checkedBairro,
                    Ativo: switchBairro
                },
                complemento: {
                    Obrigatorio: checkedComplemento,
                    Ativo: switchComplemento
                },
                data_nascimento: {
                    Obrigatorio: checkedDtNascimento,
                    Ativo: switchDtNascimento
                },
                outro_celular: {
                    Obrigatorio: checkedOutroCelular,
                    Ativo: switchOutroCelular
                },
                telefone_fixo: {
                    Obrigatorio: checkedTelefoneFixo,
                    Ativo: switchTelefoneFixo
                }
            }
        };

        try {
            await eventSchema.validate(data, { abortEarly: false });
            await api.post('api/eventos/cadastroEvento', data);

            setAlertType('success');
            setAlertMessage('Evento cadastrado com sucesso!');
            setOpen(true);

            setTimeout(() => {
                window.location.href = '/eventos/home';
            }, 2000);
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const newErrors = {};
                err.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                    setAlertType('error');
                    setAlertMessage('Erro ao cadastrar evento: ' + newErrors[error.path]);
                    setOpen(true);

                    setTimeout(() => {
                        setOpen(false);
                    }, 5000);
                });
            } else {
                console.error('An unexpected error occurred:', err);

                setAlertType('error');
                setAlertMessage('Ocorreu um erro inesperado.');
                setOpen(true);

                setTimeout(() => {
                    setOpen(false);
                }, 5000);
            }
        }
    };

    return (
        <>
            {token && (
                <React.Fragment>
                    <Navbar />
                    <CssBaseline />
                    <Container style={{ marginTop: '20px' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={valueBox} onChange={handleChangeBox} aria-label="basic tabs example">
                                    <Tab label="Configuração evento" {...a11yProps(0)} />
                                    <Tab label="Configuração Formulário" {...a11yProps(1)} />
                                    <Tab label="Configuração página evento" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={valueBox} index={0}>
                                {/* Dados evento */}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <ReorderIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Dados evento</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} mt={2} style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Nome evento"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={nomeEvento}
                                                        onChange={(e) => setNomeEvento(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Descricao evento"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={descricaoEvento}
                                                        onChange={(e) => setDescricaoEvento(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Edição do evento"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={edicaoEvento}
                                                        onChange={(e) => setEdicaoEvento(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Entidade Parceira"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={entidadeParceira}
                                                        onChange={(e) => setEntidadeParceira(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Link do convite"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={linkConvite}
                                                        onChange={(e) => setLinkConvite(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        disabled
                                                        label="Link para página de incrição"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={linkPaginaInscricao}
                                                        onChange={(e) => setLinkPaginaInscricao(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                {/* Data e hora*/}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <DateRangeIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Data e hora</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} mt={2} style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Data Inicial"
                                                        type="date"
                                                        value={startDate === null ? '' : startDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Data Final"
                                                        type="date"
                                                        value={endDate === null ? '' : endDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Hora Inicial"
                                                        type="time"
                                                        value={timeInicio === null ? '' : timeInicio}
                                                        onChange={(e) => setTimeInicio(e.target.value)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Hora Final"
                                                        type="time"
                                                        value={timeFim === null ? '' : timeFim}
                                                        onChange={(e) => setTimeFim(e.target.value)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                {/* Endereço */}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <BusinessIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Endereço do evento</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="CEP"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={formatCEP(cep)}
                                                        onChange={(e) => setCEP(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Endereço"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={endereco}
                                                        onChange={(e) => setEndereco(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">UF</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectedOption}
                                                            label="Opção"
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Opção 1</MenuItem>
                                                            <MenuItem value={20}>Opção 2</MenuItem>
                                                            <MenuItem value={30}>Opção 3</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Municipio</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectedOption}
                                                            label="Opção"
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Opção 1</MenuItem>
                                                            <MenuItem value={20}>Opção 2</MenuItem>
                                                            <MenuItem value={30}>Opção 3</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                {/* Conf E-mail */}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <AlternateEmailIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Configuração e-mails</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Email para cópia da homologação"
                                                        variant="outlined"
                                                        type="email"
                                                        fullWidth
                                                        value={copiaHomolog}
                                                        onChange={(e) => setCopiaHomolog(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Email para confirmação da homologação"
                                                        variant="outlined"
                                                        type="email"
                                                        fullWidth
                                                        value={confirmaHomolog}
                                                        onChange={(e) => setConfirmaHomolog(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                {/* Conf. Evento */}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <DateRangeIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Configuração evento</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Número de vagas"
                                                        type="number"
                                                        value={numberVagas === null ? '' : numberVagas}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value >= 0) {
                                                                setNumberVagas(value);
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Total de sessões"
                                                        type="number"
                                                        value={numberSessoes === null ? '' : numberSessoes}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value >= 0) {
                                                                setNumberSessoes(value);
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="% de presença obrigatória"
                                                        type="number"
                                                        value={numberPresenca === null ? '' : numberPresenca}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value >= 0) {
                                                                setNumberPresenca(value);
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                {/* Conf. Inscrição */}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <SettingsIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Configuração inscrição</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Evento pago?</FormLabel>
                                                        <RadioGroup row onChange={handleEventoPagoChange}>
                                                            <FormControlLabel
                                                                value="sim"
                                                                control={<Radio checked={eventoPago} />}
                                                                label="Sim"
                                                            />
                                                            <FormControlLabel
                                                                value="nao"
                                                                control={<Radio checked={!eventoPago} />}
                                                                label="Não"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Disponibilizar hospedagem?</FormLabel>
                                                        <RadioGroup row onChange={handleHospedagemChange}>
                                                            <FormControlLabel
                                                                value="sim"
                                                                control={<Radio checked={hospedagem} />}
                                                                label="Sim"
                                                            />
                                                            <FormControlLabel
                                                                value="nao"
                                                                control={<Radio checked={!hospedagem} />}
                                                                label="Não"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Permite acompanhante?</FormLabel>
                                                        <RadioGroup row onChange={handleAcompanhanteChange}>
                                                            <FormControlLabel
                                                                value="sim"
                                                                control={<Radio checked={acompanhante} />}
                                                                label="Sim"
                                                            />
                                                            <FormControlLabel
                                                                value="nao"
                                                                control={<Radio checked={!acompanhante} />}
                                                                label="Não"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Dividir quarto?</FormLabel>
                                                        <RadioGroup row onChange={handleQuartoChange}>
                                                            <FormControlLabel
                                                                value="sim"
                                                                control={<Radio checked={quarto} />}
                                                                label="Sim"
                                                            />
                                                            <FormControlLabel
                                                                value="nao"
                                                                control={<Radio checked={!quarto} />}
                                                                label="Não"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Disponibiliza Passagem Aérea?</FormLabel>
                                                        <RadioGroup row onChange={handlePassagemAereaChange}>
                                                            <FormControlLabel
                                                                value="sim"
                                                                control={<Radio checked={passagemAerea} />}
                                                                label="Sim"
                                                            />
                                                            <FormControlLabel
                                                                value="nao"
                                                                control={<Radio checked={!passagemAerea} />}
                                                                label="Não"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Permite Terrestre?</FormLabel>
                                                        <RadioGroup row onChange={handleTerrestreChange}>
                                                            <FormControlLabel
                                                                value="sim"
                                                                control={<Radio checked={terrestre} />}
                                                                label="Sim"
                                                            />
                                                            <FormControlLabel
                                                                value="nao"
                                                                control={<Radio checked={!terrestre} />}
                                                                label="Não"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Emitir Boletos?</FormLabel>
                                                        <RadioGroup row onChange={handleEmitirBoletosChange}>
                                                            <FormControlLabel
                                                                value="sim"
                                                                control={<Radio checked={emitirBoletos} />}
                                                                label="Sim"
                                                            />
                                                            <FormControlLabel
                                                                value="nao"
                                                                control={<Radio checked={!emitirBoletos} />}
                                                                label="Não"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                {/* Upload */}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <DateRangeIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Upload</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} mt={2} style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Button
                                                        component="label"
                                                        role={undefined}
                                                        fullWidth
                                                        variant="contained"
                                                        tabIndex={-1}
                                                        startIcon={<FileUploadIcon />}
                                                    >
                                                        Banner
                                                        <VisuallyHiddenInput type="file" />
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button
                                                        component="label"
                                                        role={undefined}
                                                        fullWidth
                                                        variant="contained"
                                                        tabIndex={-1}
                                                        startIcon={<FileUploadIcon />}
                                                    >
                                                        Convite
                                                        <VisuallyHiddenInput type="file" />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel value={valueBox} index={1}>
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <AssignmentIndIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Documento</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container mt={2} style={{ padding: 14 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} sm={6}>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup row value={documento} onChange={(e) => setDocumento(e.target.value)}>
                                                                <FormControlLabel value="cpf" control={<Radio />} label="CPF" />
                                                                <FormControlLabel value="rg" control={<Radio />} label="RG" />
                                                                <FormControlLabel value="passaporte" control={<Radio />} label="Passaporte" />
                                                                <FormControlLabel value="cnpj" control={<Radio />} label="CNPJ" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} sm={6}>
                                                        <Typography variant="overline" display="block">Obrigatório em todos os eventos</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                {/* Conf. Input form */}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <TocIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Formulário</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center">
                                                <Grid item xs={12} sm={4}>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="h6">OBRIGATÓRIO</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="h6">ATIVADO/DESATIVADO</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Nome:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedNome}
                                                        onChange={(e) => setCheckedNome(e.target.checked)}
                                                        disabled
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Typography variant="overline" display="block">Obrigatório em todos os eventos</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">UF:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedUF}
                                                        onChange={(e) => setCheckedUF(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchUF}
                                                        onChange={(e) => setSwitchUF(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Município:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedMunicipio}
                                                        onChange={(e) => setCheckedMunicipio(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchMunicipio}
                                                        onChange={(e) => setSwitchMunicipio(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Celular:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedCelular}
                                                        onChange={(e) => setCheckedCelular(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchCelular}
                                                        onChange={(e) => setSwitchCelular(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">E-mail:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedEmail}
                                                        onChange={(e) => setCheckedEmail(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchEmail}
                                                        onChange={(e) => setSwitchEmail(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Sexo:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedSexo}
                                                        onChange={(e) => setCheckedSexo(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchSexo}
                                                        onChange={(e) => setSwitchSexo(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">País:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedPais}
                                                        onChange={(e) => setCheckedPais(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchPais}
                                                        onChange={(e) => setSwitchPais(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">CEP:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedCep}
                                                        onChange={(e) => setCheckedCep(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchCep}
                                                        onChange={(e) => setSwitchCep(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Logradouro:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedLogradouro}
                                                        onChange={(e) => setCheckedLogradouro(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchLogradouro}
                                                        onChange={(e) => setSwitchLogradouro(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Bairro:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedBairro}
                                                        onChange={(e) => setCheckedBairro(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchBairro}
                                                        onChange={(e) => setSwitchBairro(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Complemento:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedComplemento}
                                                        onChange={(e) => setCheckedComplemento(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchComplemento}
                                                        onChange={(e) => setSwitchComplemento(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Data de nascimento:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedDtNascimento}
                                                        onChange={(e) => setCheckedDtNascimento(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchDtNascimento}
                                                        onChange={(e) => setSwitchDtNascimento(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Outro celular:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedOutroCelular}
                                                        onChange={(e) => setCheckedOutroCelular(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchOutroCelular}
                                                        onChange={(e) => setSwitchOutroCelular(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} alignItems="center" style={{ padding: 14 }}>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="h6">Telefone fixo:</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Checkbox
                                                        checked={checkedTelefoneFixo}
                                                        onChange={(e) => setCheckedTelefoneFixo(e.target.checked)}
                                                        sx={{
                                                            color: red[900],
                                                            '&.Mui-checked': {
                                                                color: 'green',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }} sm={4}>
                                                    <Switch checked={switchTelefoneFixo}
                                                        onChange={(e) => setSwitchTelefoneFixo(e.target.checked)} />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel value={valueBox} index={2}>
                                {/* Exemplo */}
                                <Grid container spacing={3} mt={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={3} style={{ padding: '16px' }}>
                                            <Grid container alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <IconButton size="small">
                                                        <DateRangeIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">Exemplo</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={2} style={{ padding: 14 }}>
                                                <Button onClick={handleSubmit}>Cadastrar Evento</Button>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                        </Box>
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                            <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                                {alertMessage}
                            </Alert>
                        </Snackbar>
                    </Container>
                </React.Fragment >
            )}
        </>
    );
}

export default Criacao;
