import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © Developed by: '}
            <Link color="inherit" href="https://cnm.org.br">
                CNM
            </Link>
        </Typography>
    );
}

export default Copyright;