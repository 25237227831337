import React, { useState, useCallback } from 'react';
import api from '../service/Api';
import {
    Container,
    TextField,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    FormControl,
    InputAdornment,
    Button,
    Typography
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@material-ui/icons/Save';

const CadastroAutoridades = ({ setFuncionalidade, HandleSearch, HandleSnackbarClose, FormatCelular, setFormValues, setCodigoUf, setMunicipio, FormatCPF, showAlert, ufs, naturezas, tipoCargo, cargo, formValues, snackbarOpen, snackbarMessage, snackbarSeverity, partidoPolitico, vinculateAutoridades }) => {

    const initialStateInside = {
        nome: '',
        cpf: '',
        email: '',
        celular: '',
        uf: '',
        partido: '',
        ministerio_orgao: '',
        tipo_cargo: '',
        cargo_id: '',
        cargo: '',
        autoridade_id: '',
        credenciado: '',
        credenciado_em: '',
        etiquetas: ''
    };

    const [formValuesInside, setFormValuesInside] = useState(initialStateInside);
    const [desabilitarBotao, setDesabilitarBotao] = useState(false);
    const resetForm = () => {
        setFormValuesInside({ ...initialStateInside });
        setFormValues({
            ...formValues,
            tipo_inscricao: '',
            tipo_cargo: '',
        });
    };

    const HandleChangeFormInside = useCallback((atributo, valor) => {
        setFormValuesInside((prevFormValues) => ({
            ...prevFormValues,
            [atributo]: valor,
        }));
    }, [setFormValuesInside]);

    const handleSaveAutoridade = async () => {

        if (formValuesInside?.nome.length <= 0) {
            return showAlert("Por favor, informe o nome", 'error');
        }
        try {
            const formData = new FormData();
            setDesabilitarBotao(true);
            for (const campo in formValuesInside) {
                formData.append(campo, formValuesInside[campo]);
            }
            formData.append('autoridade_id', '');
            formData.append('credenciado', '');
            formData.append('credenciado_em', '');
            if (formData.has('partido_politico')) {
                formData.delete('partido_politico');
            }
            if (!formData.has('partido')) {
                formData.append('partido', '');
            }
            const response = await api.post('api/insereAutoridade', formData);
            // console.log('response autoridade salva =', response)
            if (response.status === 200) {
                showAlert("Autoridade cadastrada com sucesso", 'success');
                resetForm();
                // HandleSearch();
                setDesabilitarBotao(false);
                // setFuncionalidade('') // alterarar para retornar para detalhes
                vinculateAutoridades(response.data[0]);
            }
        } catch (error) {
            showAlert("Ocorreu um erro no cadastro da autoridade. Por favor, tente novamente mais tarde", 'error');
            setDesabilitarBotao(false);
            console.error('Erro ao cadastrar autoridade:', error);
        }
    };

    const handleVoltar = () => {
        resetForm();
        setFuncionalidade('')
    };

    const shouldRenderEmpresaInstituicaoField = () => {
        const tipoInscricao = formValuesInside?.tipo_inscricao;
        const tipoCargo = formValuesInside?.tipo_cargo;
        const cargo = formValuesInside?.cargo;

        return (
            ([26].includes(tipoInscricao) && [27, 39, 44, 49, 55].includes(tipoCargo) && [32, 38, 43, 48, 54, 60].includes(cargo))
            || ([26].includes(tipoInscricao) && [61].includes(tipoCargo))
            || ([26].includes(tipoInscricao) && [27].includes(tipoCargo))
            || ([68].includes(tipoInscricao) && [69, 80, 91, 97].includes(tipoCargo) && [74, 79, 85, 92, 93, 94, 95, 96, 101].includes(cargo))
            || ([102].includes(tipoInscricao) && [165].includes(tipoCargo))
        );
    };

    // useEffect(() => {
    //     // console.log('formValuesInside -> ', formValuesInside);
    // }, [formValuesInside]);

    return (
        <Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <Button style={{ marginTop: '10px' }} variant="contained" onClick={handleVoltar}>Voltar</Button>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                    <Typography variant="h6" gutterBottom>
                        Cadastro de Autoridades
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }} >Tipo de inscrição</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.tipo_inscricao}
                            value={formValues?.tipo_inscricao}
                            onChange={(e) => {
                                const tipoInscricaoSelecionado = e.target.value;
                                setFormValues({
                                    ...formValues,
                                    tipo_inscricao: tipoInscricaoSelecionado,
                                    tipo_cargo: '',
                                    cargo: '',
                                    area_atuacao: '',
                                });
                                setFormValuesInside({
                                    ...formValuesInside,
                                    cargo_id: tipoInscricaoSelecionado,
                                    tipo_inscricao: tipoInscricaoSelecionado,
                                    tipo_cargo: '',
                                    cargo: '',
                                });
                            }}
                            IconComponent={naturezas.length === 1 ? () => null : undefined}
                            disabled={naturezas.length === 1}
                        >
                            {naturezas?.map((natureza) => (
                                <MenuItem key={natureza.id} value={natureza.id}>
                                    {natureza.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }} >Tipo cargo</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.tipo_cargo}
                            disabled={!formValues.tipo_inscricao}
                            value={formValues?.tipo_cargo}
                            onChange={(e) => {
                                const tipoCargoSelecionado = e.target.value;
                                setFormValues({
                                    ...formValues,
                                    tipo_cargo: tipoCargoSelecionado,
                                    cargo: '',
                                    area_atuacao: '',
                                });
                                setFormValuesInside({
                                    ...formValuesInside,
                                    tipo_cargo: tipoCargoSelecionado,
                                    cargo: '',
                                });
                            }}
                        >
                            {tipoCargo.map((tipo) => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                    {tipo.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginLeft: '13px' }}>Cargo</InputLabel>
                        <Select
                            variant="outlined"
                            // error={!!formErrors.cargo}
                            value={formValuesInside?.cargo}
                            disabled={!formValues.tipo_cargo}
                            onChange={(e) => {
                                const cargoSelecionado = e.target.value;
                                setFormValuesInside({
                                    ...formValuesInside,
                                    cargo: cargoSelecionado,
                                    area_atuacao: '',
                                });
                            }}
                        >
                            {cargo.map((cargo) => (
                                <MenuItem key={cargo.id} value={cargo.id}>
                                    {cargo.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {(([26, 68].includes(formValuesInside?.tipo_inscricao) && [49, 55, 97].includes(formValuesInside?.tipo_cargo)) && (formValuesInside?.cargo && [50, 51, 52, 56, 57, 58, 98, 99].includes(formValuesInside?.cargo))) &&
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={partidoPolitico || []}
                                getOptionLabel={(partidoPolitico) => partidoPolitico.NOME + ' - ' + partidoPolitico.SIGLA || ''}
                                value={partidoPolitico?.find((p) => p.SIGLA === formValuesInside?.partido) || null}
                                onChange={(e, selectedPartidoPolitico) => {
                                    HandleChangeFormInside('partido', selectedPartidoPolitico?.SIGLA);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Partido Político"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                }

                {shouldRenderEmpresaInstituicaoField() && (
                    <Grid item xs={12} sm={3}>
                        <TextField
                            variant="outlined"
                            onChange={(e) => HandleChangeFormInside('ministerio_orgao', e.target.value)}
                            value={formValuesInside?.ministerio_orgao}
                            fullWidth
                            label="Empresa/Instituição"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                )}

                {formValues?.tipo_inscricao === 26 && formValues?.tipo_cargo === 160 &&
                    <Grid item xs={12} sm={3}>
                        <TextField
                            variant="outlined"
                            onChange={(e) => HandleChangeFormInside('ministerio_orgao', e.target.value)}
                            value={formValuesInside?.ministerio_orgao}
                            fullWidth
                            label="Ministério"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                }

                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        onChange={(e) => HandleChangeFormInside('nome', e.target.value)}
                        value={formValuesInside?.nome}
                        fullWidth
                        label="Nome"
                        autoComplete="off"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="cpf"
                        label="CPF"
                        name="cpf"
                        autoComplete="off"
                        value={FormatCPF(formValuesInside?.cpf)}
                        onChange={(e) => HandleChangeFormInside('cpf', e.target.value)}
                        inputProps={{ maxLength: 14 }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.email}
                        onChange={(e) => HandleChangeFormInside('email', e.target.value)}
                        value={formValuesInside?.email}
                        fullWidth
                        label="E-mail"
                        inputProps={{ maxLength: 255 }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="outlined"
                        // error={!!formErrors.celular}
                        onChange={(e) => HandleChangeFormInside('celular', FormatCelular(e.target.value))}
                        value={FormatCelular(formValuesInside?.celular)}
                        fullWidth
                        label="Celular"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <WhatsAppIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            options={ufs}
                            getOptionLabel={(uf) => uf.SIGLA_UF}
                            value={ufs.find((uf) => uf.SIGLA_UF === formValuesInside?.uf) || null}
                            onChange={(e, selectedUfObject) => {
                                setCodigoUf(selectedUfObject?.CD_UF || null);
                                setMunicipio(selectedUfObject?.CD_MUNICIPIO || null);
                                HandleChangeFormInside('uf', selectedUfObject?.SIGLA_UF || '');
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    // error={!!formErrors.uf}
                                    label="UF"
                                />
                            )}
                        />
                    </FormControl>
                </Grid>

            </Grid>

            <Divider style={{ margin: '10px' }} />

            <Grid item xs={12} sm={12}>
                <Grid container justifyContent="center" mt={4} style={{ marginTop: '20px' }}>
                    <Button type="submit" disabled={desabilitarBotao} variant="contained" style={{ backgroundColor: '#47944a', color: '#FFFFFF' }} onClick={handleSaveAutoridade}>
                        <SaveIcon /> Salvar
                    </Button>
                </Grid>
            </Grid>

        </Container >
    );
}

export default CadastroAutoridades;