import AuthApiContatos from '../service/AuthApiContatos';

const LoginHandler = () => {

    const authApiContatos = new AuthApiContatos({
        systemId: process.env.REACT_APP_SYSTEM_ID_CONTATOS,
        serverUrl: process.env.REACT_APP_SERVER_URL,
        tokenKey: process.env.REACT_APP_TOKEN_KEY,
        userKey: process.env.REACT_APP_USER_KEY,
        loggedHome: process.env.REACT_APP_LOGGED_HOME,
    });

    authApiContatos.init();

    const handleLogin = () => {
        authApiContatos.login();
    };

    const handleLogout = () => {
        authApiContatos.logout();
    };

    return { handleLogin, handleLogout };
};

export default LoginHandler;
