import React, { useEffect, useState } from "react";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Box } from '@material-ui/core';
import api from "../service/Api";

const Meucaixa = () => {
    const [meuCaixa, setMeuCaixa] = useState(null);
    const userCred = JSON.parse(localStorage.getItem('user_credmarcha'));

    useEffect(() => {
        const fetchAutoridades = async () => {
            try {
                const response = await api.get(`api/getCaixasEspecifico?perfil=${userCred.name}`);
                setMeuCaixa(response?.data?.getCaixasEspecifico[0]);
            } catch (error) {
                console.error('Erro ao buscar as autoridades:', error);
            }
        };
        fetchAutoridades();
        // eslint-disable-next-line
    }, []);

    const formatCurrency = (value) => {
        return parseFloat(value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        });
    };

    return (
        <Container>
            <Box display="flex" justifyContent="center" my={4}>
                <Typography variant="h4" gutterBottom>
                    {userCred.name}
                </Typography>
            </Box>
            {meuCaixa && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Tipo</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align="right">Valor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {['dinheiro', 'cheque', 'transferencia', 'cartao', 'pix', 'filiacao'].map((tipo) => (
                                <TableRow key={tipo}>
                                    <TableCell>{tipo.charAt(0).toUpperCase() + tipo.slice(1)}</TableCell>
                                    <TableCell align="right">{formatCurrency(meuCaixa[tipo])}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Total Caixa</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }} align="right">{formatCurrency(meuCaixa.total_fluxo_caixa)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default Meucaixa;
