import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Container,
    Typography,
} from '@material-ui/core';
import bankLogo from '../images/Banco_do_Brasil_logo.svg.png'
import pix from '../images/pix.png'

const ImpressaoBoleto = () => {
    const location = useLocation();

    const formatRazaoSocial = (razao_social) => {
        if (!razao_social) {
            return '';
        }
        return razao_social.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
    };

    const searchParams = new URLSearchParams(location.search);
    const linhaDigitavel = searchParams.get('linhaDigitavel');
    const dataVencimento = searchParams.get('dataVencimento');
    const dataEmissao = searchParams.get('dataEmissao');
    const numeroTituloCliente = searchParams.get('numeroTituloCliente');
    const valor = searchParams.get('valor');
    const razao_social = formatRazaoSocial(searchParams.get('razao_social'));
    const cnpj = searchParams.get('cnpj');
    const endereco = searchParams.get('endereco');
    const bairro = searchParams.get('bairro');
    // const municipio = searchParams.get('municipio');
    const nome_municipio = searchParams.get('nome_municipio');
    const uf = searchParams.get('uf');
    const cep = searchParams.get('cep');
    const nome = searchParams.get('nome');
    const cpf = searchParams.get('cpf');
    const codigoBarraNumerico = searchParams.get('codigoBarraNumerico');
    const emv = searchParams.get('emv');
    const camposNecessariosPresentes = linhaDigitavel && dataVencimento && dataEmissao && numeroTituloCliente && valor && codigoBarraNumerico;

    useEffect(() => {
        if (!camposNecessariosPresentes) {
            console.log('Boleto não encontrado. Verifique os parâmetros na URL.');
            return;
        }
        setTimeout(() => {
            window.print();
        }, 750);
    }, [camposNecessariosPresentes]);

    if (!camposNecessariosPresentes) {
        return (
            <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Typography variant="h5">
                    Boleto vencido ou não encontrado. Em caso de dúvidas, favor, entre em contato com a CNM.
                </Typography>
            </Container>
        );
    }

    return (
        <>
            <div className="document">
                <br />
                <div className="documentPix">
                    <div className="linha">
                        <img src={pix} width="280px" height="100px" style={{ marginBottom: '50px' }} className="d-inline-block align-top" alt="" />
                        <img alt="Qries" style={{ paddingLeft: '200px' }} src={`https://api.invertexto.com/v1/qrcode?token=7336|jJY34KH5a0H4dMpo6h4XQ7k6FMANMuC6&text=${emv}&scale=4`} />
                    </div>
                </div>
                <br />
                <br />
                <table cellSpacing="0" cellPadding="0">
                    <tr className="topLine">
                        <td className="bankLogo">
                            <img style={{ width: '98%', height: '98%' }} alt="foto" src={bankLogo} className="image-container" />
                        </td>
                        <td className="sideBorders center"><span style={{ fontSize: '24px', fontWeight: 'bold' }} >001-9</span></td>
                        <td className="boletoNumber center"><span>{linhaDigitavel}</span></td>
                    </tr>
                </table>
                <table cellSpacing="0" cellPadding="0" border="1">
                    <tr>
                        <td width="70%" colSpan="6">
                            <span className="title">Local de Pagamento</span>
                            <br />
                            <span className="text">ATÉ O VENCIMENTO EM QUALQUER BANCO, NÃO ACEITAR APÓS O VENCIMENTO</span>
                        </td>
                        <td width="30%">
                            <span className="title">Data de Vencimento</span>
                            <br />
                            <br />
                            <p className="content right text" style={{ fontWeight: 'bold' }} >{dataVencimento}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="70%" colSpan="6">
                            <span className="title">Nome do Beneficiário / CNPJ / CPF / Endereço:</span>
                            <br />
                            <table border="0" style={{ border: 'none' }} >
                                <tr>
                                    <td><span className="text">CONFEDERACAO NACIONAL DE MUNICIPIOS-CNM CPF/CNPJ:
                                        00.703.157/0001-83</span></td>
                                </tr>
                            </table>
                            <br />
                            <span className="text">Q SGAN 601 MD N PL PILOTO - ASA NORTE - BRASILIA - DF - 70.830-010</span>
                        </td>
                        <td width="30%">
                            <span className="title">Agência/Código Beneficiário</span>
                            <br />
                            <br />
                            <p className="content right">4200-5 / 15969-7</p>
                        </td>
                    </tr>

                    <tr>
                        <td width="15%">
                            <span className="title">Data do Documento</span>
                            <br />
                            <p className="content center">{dataEmissao}</p>
                        </td>
                        <td width="17%" colSpan="2">
                            <span className="title">Num. do Documento</span>
                            <br />
                            <p className="content center">{numeroTituloCliente}</p>
                        </td>
                        <td width="10%">
                            <span className="title">Espécie doc</span>
                            <br />
                            <p className="content center"></p>
                        </td>
                        <td width="8%">
                            <span className="title">Aceite</span>
                            <br />
                            <p className="content center">N</p>
                        </td>
                        <td>
                            <span className="title">Data Processamento</span>
                            <br />
                            <p className="content center">{dataEmissao}</p>
                        </td>
                        <td width="30%">
                            <span className="title">Carteira/Nosso Número</span>
                            <br />
                            <br />
                            <p className="content right">17/{numeroTituloCliente}</p>
                        </td>
                    </tr>

                    <tr>
                        <td width="15%">
                            <span className="title">Uso do Banco</span>
                            <br />
                            <p className="content center">&nbsp;</p>
                        </td>
                        <td width="10%">
                            <span className="title">Carteira</span>
                            <br />
                            <p className="content center">17</p>
                        </td>
                        <td width="10%">
                            <span className="title">Espécie</span>
                            <br />
                            <p className="content center">R$</p>
                        </td>
                        <td width="8%" colSpan="2">
                            <span className="title">Quantidade</span>
                            <br />
                            <p className="content center">N</p>
                        </td>
                        <td>
                            <span className="title">Valor</span>
                            <br />
                            <p className="content center">{valor}</p>
                        </td>
                        <td width="30%">
                            <span className="title">(=) Valor do Documento</span>
                            <br />
                            <br />
                            <p className="content right">{valor}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="6" rowSpan="4">
                            <span className="title">Instruções de responsabilidade do BENEFICIÁRIO. Qualquer dúvida sobre
                                este
                                boleto contate o beneficiário.</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="title">(-) Descontos/Abatimento</span>
                            <br />
                            <p className="content right">&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="title">(+) Juros/Multa</span>
                            <br />
                            <p className="content right">&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="title">(=) Valor Pago</span>
                            <br />
                            <p className="content right">&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7">
                            <table border="0" style={{ border: 'none' }}>
                                {razao_social && (
                                    <tr>
                                        <td width="60%">
                                            <span className="text">
                                                <b>Fonte pagadora: {
                                                    razao_social === 'Municipio' ? razao_social + ' de ' + nome_municipio + ' - ' + uf : razao_social ?? ''
                                                }</b>
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td width="60%">
                                        <span className="text">
                                            <b>Nome participante: {nome ?? ''}
                                            </b>
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text">
                                            <b>CNPJ/CPF: {cnpj ? cnpj : cpf ? cpf : 'Não disponível'}
                                            </b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="text"><b>Endereço: </b> {endereco} - {bairro} - {cep}</span></td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><span className="text"><b>Sacador/Avalista: </b> &nbsp;</span></td>
                                    <td><span className="text"><b>CNPJ/CPF: </b> &nbsp;</span></td>
                                </tr>
                            </table>

                        </td>

                    </tr>
                </table>
            </div>

            <div className="document" style={{ marginTop: '230px' }}>
                <hr />
                <table cellSpacing="0" cellPadding="0">
                    <tr className="topLine">
                        <td className="bankLogo">
                            <img style={{ width: '98%', height: '98%' }} alt="foto" src={bankLogo} className="image-container" />
                        </td>
                        <td className="sideBorders center"><span style={{ fontSize: '24px', fontWeight: 'bold' }}>001-9</span></td>
                        <td className="boletoNumber center"><span>{linhaDigitavel}</span></td>
                    </tr>
                </table>
                <table cellSpacing="0" cellPadding="0" border="1">
                    <tr>
                        <td width="70%" colSpan="6">
                            <span className="title">Local de Pagamento</span>
                            <br />
                            <span className="text">ATÉ O VENCIMENTO EM QUALQUER BANCO, NÃO ACEITAR APÓS O VENCIMENTO</span>
                        </td>
                        <td width="30%">
                            <span className="title">Data de Vencimento</span>
                            <br />
                            <br />
                            <p className="content right text" style={{ fontWeight: 'bold' }}>{dataVencimento}</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="70%" colSpan="6">
                            <span className="title">Nome do Beneficiário: / CNPJ / CPF / Endereço:</span>
                            <br />
                            <table border="0" style={{ border: 'none' }}>
                                <tr>
                                    <td><span className="text">CONFEDERACAO NACIONAL DE MUNICIPIOS-CNM CPF/CNPJ:
                                        00.703.157/0001-83</span></td>
                                </tr>
                            </table>
                            <br />
                            <span className="text">Q SGAN 601 MD N PL PILOTO - ASA NORTE - BRASILIA - DF - 70.830-010</span>
                        </td>
                        <td width="30%">
                            <span className="title">Agência/Código Beneficiário</span>
                            <br />
                            <br />
                            <p className="content right">4200-5 / 15969-7</p>
                        </td>
                    </tr>

                    <tr>
                        <td width="15%">
                            <span className="title">Data do Documento</span>
                            <br />
                            <p className="content center">{dataEmissao}</p>
                        </td>
                        <td width="17%" colSpan="2">
                            <span className="title">Num. do Documento</span>
                            <br />
                            <p className="content center">{numeroTituloCliente}</p>
                        </td>
                        <td width="10%">
                            <span className="title">Espécie doc</span>
                            <br />
                            <p className="content center"></p>
                        </td>
                        <td width="8%">
                            <span className="title">Aceite</span>
                            <br />
                            <p className="content center">N</p>
                        </td>
                        <td>
                            <span className="title">Data Processamento</span>
                            <br />
                            <p className="content center">{dataEmissao}</p>
                        </td>
                        <td width="30%">
                            <span className="title">Carteira/Nosso Número</span>
                            <br />
                            <br />
                            <p className="content right">17/{numeroTituloCliente}</p>
                        </td>
                    </tr>

                    <tr>
                        <td width="15%">
                            <span className="title">Uso do Banco</span>
                            <br />
                            <p className="content center">&nbsp;</p>
                        </td>
                        <td width="10%">
                            <span className="title">Carteira</span>
                            <br />
                            <p className="content center">17</p>
                        </td>
                        <td width="10%">
                            <span className="title">Espécie</span>
                            <br />
                            <p className="content center">R$</p>
                        </td>
                        <td width="8%" colSpan="2">
                            <span className="title">Quantidade</span>
                            <br />
                            <p className="content center">N</p>
                        </td>
                        <td>
                            <span className="title">Valor</span>
                            <br />
                            <p className="content center">{valor}</p>
                        </td>
                        <td width="30%">
                            <span className="title">(=) Valor do Documento</span>
                            <br />
                            <br />
                            <p className="content right">{valor}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="6" rowSpan="4">
                            <span className="title">Instruções de responsabilidade do BENEFICIÁRIO. Qualquer dúvida sobre
                                este
                                boleto contate o beneficiário.</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="title">(-) Descontos/Abatimento</span>
                            <br />
                            <p className="content right">&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="title">(+) Juros/Multa</span>
                            <br />
                            <p className="content right">&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="title">(=) Valor Pago</span>
                            <br />
                            <p className="content right">&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="7">
                            <table border="0" style={{ border: 'none' }} >
                                {razao_social && (
                                    <tr>
                                        <td width="60%">
                                            <span className="text">
                                                <b>Fonte pagadora: {
                                                    razao_social === 'Municipio' ? razao_social + ' de ' + nome_municipio + ' - ' + uf : razao_social ?? ''
                                                }</b>
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                <td width="60%">
                                    <span className="text">
                                        <b>Nome participante: {nome ?? ''}
                                        </b>
                                    </span>
                                </td>
                                <td>
                                    <span className="text">
                                        <b>CNPJ/CPF: {cnpj ? cnpj : cpf ? cpf : 'Não disponível'}
                                        </b>
                                    </span>
                                </td>
                                <tr>
                                    <td><span className="text"><b>Endereço: </b> {endereco} - {bairro} - {cep}</span></td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><span className="text"><b>Sacador/Avalista: </b> &nbsp;</span></td>
                                    <td><span className="text"><b>CNPJ/CPF: </b> &nbsp;</span></td>
                                </tr>
                            </table>

                        </td>

                    </tr>
                </table>
                <br />
                <div className="card-body" style={{ textAlign: 'center' }}>
                    <img alt="Qries"
                        src={`https://api.invertexto.com/v1/barcode?token=1923%7C0SB8TzYXMXUxJOXiOTOccPETsie2Nl1U&text=${codigoBarraNumerico}&type=code128&scale=2&font=0`}
                    />
                </div>
                <br />
                <br />
                <br />
            </div>
        </>

    );
};

export default ImpressaoBoleto;
