import React, { useState, useCallback } from "react";
import Navbar from "../../components/AuthenticatedNavbar";
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import ConstantInscricoes from "../../constants/ConstantInscricoes";
import {
    Accordion, Paper, AccordionSummary, Typography, Container, Grid, AccordionDetails, TextField, Button, Box, Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination, TableSortLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link,
    Card, CardMedia
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import tutorial_filtro from '../../images/tutorial_filtros.gif';
import api from "../../service/Api";

const Index = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [eventos, setEventos] = useState([]);
    const [nomeEvento, setNomeEvento] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);

    const { token } = ConstantInscricoes({ dontLoadAll: true });

    const [filtrosAbertos, setFiltrosAbertos] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedEventoId, setSelectedEventoId] = useState(null);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);

    const handleSearch = useCallback(async () => {
        try {
            const response = await api.get('api/eventos/cadastroEvento');
            let filteredEventos = response.data;

            if (nomeEvento) {
                filteredEventos = filteredEventos.filter(evento =>
                    evento.evento.toLowerCase().includes(nomeEvento.toLowerCase())
                );
            }

            if (startDate) {
                filteredEventos = filteredEventos.filter(evento =>
                    new Date(evento.data_inicio) >= new Date(startDate)
                );
            }

            if (endDate) {
                filteredEventos = filteredEventos.filter(evento =>
                    new Date(evento.data_fim) <= new Date(endDate)
                );
            }

            setEventos(filteredEventos);
        } catch (error) {
            console.error(error);
        }
    }, [nomeEvento, startDate, endDate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const resetForm = () => {
        setStartDate(null);
        setEndDate(null);
        setNomeEvento('');
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const handleInfoClick = async (id) => {
        try {
            const response = await api.get(`api/eventos/cadastroEvento/${id}`);
            setSelectedEvent(response.data);
            setInfoDialogOpen(true);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async () => {
        try {
            await api.delete(`api/eventos/cadastroEvento/${selectedEventoId}`);
            handleSearch();
            setOpenDialog(false);
        } catch (error) {
            console.error(error);
        }
    };

    const openDeleteDialog = (id) => {
        setSelectedEventoId(id);
        setOpenDialog(true);
    };

    const closeDeleteDialog = () => {
        setOpenDialog(false);
        setSelectedEventoId(null);
    };

    const handleEditClick = (id) => {
        navigate(`/eventos/editar/${id}`);
    };

    const handleFormClick = (id) => {
        navigate(`/eventos/credenciamento/${id}`);
    };

    const closeInfoDialog = () => {
        setInfoDialogOpen(false);
        setSelectedEvent(null);
    };

    return (
        <>
            {token && (
                <React.Fragment>
                    <Navbar />
                    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                    <CssBaseline />
                    <Container style={{ marginTop: '20px' }}>
                        <Paper>
                            <Accordion expanded={filtrosAbertos} onChange={() => setFiltrosAbertos(!filtrosAbertos)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography>Filtros</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                label="Data Inicial"
                                                type="date"
                                                value={startDate === null ? '' : startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                label="Data Final"
                                                type="date"
                                                value={endDate === null ? '' : endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Nome evento"
                                                variant="outlined"
                                                fullWidth
                                                value={nomeEvento}
                                                onChange={(e) => setNomeEvento(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Box display="flex" alignItems="center">
                                                <Box mr={2} display="inline">
                                                    <Button variant="contained" color="inherit" onClick={resetForm}>
                                                        Limpar Campos
                                                    </Button>
                                                </Box>
                                                <Box display="inline">
                                                    <Button variant="contained" color="primary" onClick={handleSearch}>
                                                        Pesquisar
                                                    </Button>
                                                </Box>
                                                <Box display="inline" ml="auto">
                                                    <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Card sx={{ width: 300 }}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        inputProps={{ height: 2000 }}
                                                                        image={tutorial_filtro}
                                                                        alt="tutorial de filtro"
                                                                    />
                                                                </Card>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Button endIcon={<QuestionMarkIcon />}>
                                                            Dúvidas
                                                        </Button>
                                                    </HtmlTooltip>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                        <Grid item xs={12} sm={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/eventos/criacao')}
                                style={{ marginTop: '20px', marginLeft: '10px' }}
                            >
                                Novo Evento
                            </Button>
                        </Grid>
                        <Paper>
                            <Grid mt={4}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <TableSortLabel>
                                                    Eventos
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <TableSortLabel>
                                                    Edições
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                Ações
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(rowsPerPage > 0
                                            ? eventos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : eventos
                                        ).map((evento, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ textAlign: 'center' }}>{evento.evento}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>{evento.edicao_evento}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    <Button onClick={() => handleInfoClick(evento.id)} style={{ color: '#007bff' }}>
                                                        <InfoIcon />
                                                    </Button>
                                                    <Button onClick={() => handleEditClick(evento.id)} style={{ color: 'green' }}>
                                                        <EditIcon />
                                                    </Button>
                                                    <Button onClick={() => openDeleteDialog(evento.id)} style={{ color: 'red' }}>
                                                        <DeleteIcon />
                                                    </Button>
                                                    <Button onClick={() => handleFormClick(evento.id)} style={{ color: 'purple' }}>
                                                        <ContentPasteIcon/>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={eventos.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Registros por página:"
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                />
                            </Grid>
                        </Paper>
                    </Container>
                    <Dialog
                        open={openDialog}
                        onClose={closeDeleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirmação de Exclusão"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Tem certeza que deseja excluir este evento?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDeleteDialog} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={handleDelete} color="primary" autoFocus>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {selectedEvent && (
                        <Dialog
                            open={infoDialogOpen}
                            onClose={closeInfoDialog}
                            aria-labelledby="event-info-title"
                            aria-describedby="event-info-description"
                        >
                            <DialogTitle id="event-info-title">{selectedEvent.evento}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="event-info-description">
                                    <strong>Data:</strong> {selectedEvent.data_inicio} - {selectedEvent.data_fim}<br />
                                    <strong>Horário:</strong> {selectedEvent.hora_inicio} - {selectedEvent.hora_fim}<br />
                                    <strong>Local:</strong> {selectedEvent.endereco}, {selectedEvent.municipio}, {selectedEvent.uf}<br />
                                    <strong>Descrição:</strong> {selectedEvent.descricao}<br />
                                    <strong>Link do Convite:</strong> <Link href={selectedEvent.link_do_convite} target="_blank">{selectedEvent.link_do_convite}</Link><br />
                                    <strong>Edição:</strong> {selectedEvent.edicao_evento}<br />
                                    <strong>Entidade Parceira:</strong> {selectedEvent.entidade_parceria}<br />
                                    <strong>UF:</strong> {selectedEvent.uf}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeInfoDialog} color="primary">
                                    Fechar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </React.Fragment>
            )}
        </>
    );
}

export default Index;
