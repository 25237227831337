import React, { useEffect, useState } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import api from '../service/Api';
import {
    Container,
    Grid,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FiltroTabelaAutoridades from "./FiltrosTabelaAutoridades";
import ConstantInscricoes from "../constants/ConstantInscricoes";
import CadastroAutoridades from "./CadastroAutoridades";
import CadastroAssessores from './CadastroAssessores';
import GerenAssessores from "./GerenAssessores";
import CadastroInterno from "./CadastroInterno";

const AutoridadesList = () => {

    const {
        HandleSnackbarClose,
        showAlert,
        FormatCPF,
        OcultarCPF,
        FormatCNPJ,
        FormatCelular,
        FormatTelefone,
        HandleChangeForm,
        HandleChangeCheckbox,
        HandleCheckboxChange,
        setFormValues,
        setCodigoUf,
        setMunicipio,
        setOrder,
        setOrderBy,
        setInscricoes,
        setInscricaoSelecionadaId,
        setSearchTerm,
        resetForm,
        handleMenuOpen,
        handleMenuClose,
        handleMenuItemClick,
        removerAcentos,
        hasPermission,
        isAdmin,
        permissoes,
        anchorEl,
        ufs,
        tipoCargo,
        cargo,
        municipio,
        formValues,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        parlamentar,
        inscricoes,
        orderBy,
        order,
        searchTerm,
        inscricaoSelecionadaId,
        partidoPolitico,
        initialState,
    } = ConstantInscricoes();

    const [openModalExcluirCadastro, setOpenModalExcluirCadastro] = useState(false);
    const [filtrosAbertos, setFiltrosAbertos] = useState(true);
    const [funcionalidade, setFuncionalidade] = useState('');
    const [inscricaoSelecionada, setInscricaoSelecionada] = useState(null);
    const [naturezas, setNaturezas] = useState([]);

    const handleSort = (columnName) => {
        if (orderBy === columnName) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(columnName);
            setOrder('asc');
        }
    };

    const createAutoridades = (id) => {
        resetForm();
        setInscricaoSelecionadaId(id);
        setFuncionalidade('1');
    };

    const vinculateAutoridades = (inscricao) => {
        setInscricaoSelecionada(inscricao);
        setFuncionalidade('2');
    };

    const createAssessor = (id) => {
        setInscricaoSelecionadaId(id);
        setFuncionalidade('3');
    };

    const createInscricao = () => {
        setFuncionalidade('4');
    };

    const sortedAndFilteredUsers = inscricoes
        .filter((inscricao) => {
            return (
                removerAcentos(inscricao?.nome).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.partido).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.uf).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.cargo_descricao).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase())) ||
                removerAcentos(inscricao?.parlamentar).toLowerCase().includes(removerAcentos(searchTerm.toLowerCase()))
            );
        })
        .sort((a, b) => {
            const isAsc = order === 'asc';
            if (orderBy === 'nome') {
                return removerAcentos(a.nome).localeCompare(removerAcentos(b.nome)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'partidoPolitico') {
                return removerAcentos(a.partido).localeCompare(removerAcentos(b.partido)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'Uf') {
                return removerAcentos(a.uf).localeCompare(removerAcentos(b.uf)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'cargo') {
                return removerAcentos(a.cargo).localeCompare(removerAcentos(b.cargo)) * (isAsc ? 1 : -1);
            } else if (orderBy === 'parlamentar') {
                return removerAcentos(a.parlamentar).localeCompare(removerAcentos(b.parlamentar)) * (isAsc ? 1 : -1);
            } else {
                return 0;
            }
        });

    const HandleSearch = async () => {
        try {

            const nonEmptyValues = Object.fromEntries(
                Object.entries({
                    ...formValues,
                }).filter(([key, value]) => value !== '')
            );

            delete nonEmptyValues.PCD;
            delete nonEmptyValues.reenviarEmail;
            delete nonEmptyValues.convidado;

            const response = await api.get('api/getAutoridadeAssessorFiltro', { params: nonEmptyValues });
            setInscricoes(response.data.getAutoridadeAssessor);
            setFiltrosAbertos(false);
        } catch (error) {
            const errors = error?.response?.data?.errors;
            console.log(errors);
            showAlert('Ocorreu um erro ao realizar a consulta', 'error');
        }
    };

    const handleOpenModalExcluirUsuario = (idExclusao) => {
        setInscricaoSelecionadaId(idExclusao);
        setOpenModalExcluirCadastro(true);
    };

    const handleCloseModalExcluirUsuario = () => {
        setOpenModalExcluirCadastro(false);
    };

    useEffect(() => {
        console.log('formValuesGerenAutoridades -> ', formValues);
    }, [formValues]);

    useEffect(() => {
        const fetchNatureza = async () => {
            try {
                const response = await api.get('api/getNatureza');
                const todasNaturezas = [
                    ...response.data?.natureza?.pagamento,
                    ...response.data?.natureza?.isento_autoridade,
                    ...response.data?.natureza?.demais_isento,
                ];
                setNaturezas(todasNaturezas);
            } catch (error) {
                console.error('Erro ao buscar tipos de inscrições:', error);
            }
        };

        fetchNatureza();
    }, [setNaturezas]);

    return (
        <React.Fragment>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={HandleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={HandleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Grid item xs={12} sm={12}>
                {funcionalidade === '1' && (
                    <CadastroAutoridades
                        HandleSnackbarClose={HandleSnackbarClose}
                        FormatCelular={FormatCelular}
                        setFormValues={setFormValues}
                        setCodigoUf={setCodigoUf}
                        setMunicipio={setMunicipio}
                        FormatCPF={FormatCPF}
                        showAlert={showAlert}
                        ufs={ufs}
                        naturezas={naturezas}
                        tipoCargo={tipoCargo}
                        cargo={cargo}
                        formValues={formValues}
                        snackbarOpen={snackbarOpen}
                        snackbarMessage={snackbarMessage}
                        snackbarSeverity={snackbarSeverity}
                        partidoPolitico={partidoPolitico}
                        setFuncionalidade={setFuncionalidade}
                        HandleSearch={HandleSearch}
                        vinculateAutoridades={vinculateAutoridades}
                    />
                )}

                {funcionalidade === '2' && (
                    <GerenAssessores
                        order={order}
                        naturezas={naturezas}
                        tipoCargo={tipoCargo}
                        cargo={cargo}
                        orderBy={orderBy}
                        snackbarOpen={snackbarOpen}
                        snackbarMessage={snackbarMessage}
                        snackbarSeverity={snackbarSeverity}
                        isAdmin={isAdmin}
                        inscricaoSelecionadaId={inscricaoSelecionadaId}
                        ufs={ufs}
                        inscricaoSelecionada={inscricaoSelecionada}
                        HandleSnackbarClose={HandleSnackbarClose}
                        hasPermission={hasPermission}
                        FormatCPF={FormatCPF}
                        removerAcentos={removerAcentos}
                        showAlert={showAlert}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        searchTerm={''}
                        setInscricaoSelecionadaId={setInscricaoSelecionadaId}
                        setFuncionalidade={setFuncionalidade}
                        HandleSearch={HandleSearch}
                        FormatCelular={FormatCelular}
                        HandleChangeForm={HandleChangeForm}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        resetForm={resetForm}
                    />
                )}

                {funcionalidade === '3' && (
                    <CadastroAssessores
                        naturezas={naturezas}
                        formValues={formValues}
                        snackbarOpen={snackbarOpen}
                        snackbarMessage={snackbarMessage}
                        snackbarSeverity={snackbarSeverity}
                        initialState={initialState}
                        showFieldParlamentar={true}
                        ufs={ufs}
                        setFuncionalidade={setFuncionalidade}
                        HandleSearch={HandleSearch}
                        showAlert={showAlert}
                        HandleSnackbarClose={HandleSnackbarClose}
                        FormatCelular={FormatCelular}
                        setFormValues={setFormValues}
                        FormatCPF={FormatCPF}
                        HandleChangeForm={HandleChangeForm}
                        resetForm={resetForm}
                    />
                )}

                {funcionalidade === '4' && (
                    <CadastroInterno
                        setFuncionalidade={setFuncionalidade}
                        showAlert={showAlert}
                        HandleSearch={HandleSearch}
                    />
                )}

                {funcionalidade === '' && (
                    <Container style={{ marginTop: '20px' }}>
                        <FiltroTabelaAutoridades
                            filtrosAbertos={filtrosAbertos}
                            formValues={formValues}
                            tipoCargo={tipoCargo}
                            cargo={cargo}
                            parlamentar={parlamentar}
                            ufs={ufs}
                            municipio={municipio}
                            searchTerm={searchTerm}
                            orderBy={orderBy}
                            order={order}
                            sortedAndFilteredUsers={sortedAndFilteredUsers}
                            anchorEl={anchorEl}
                            isAdmin={isAdmin}
                            permissoes={permissoes}
                            openModalExcluirCadastro={openModalExcluirCadastro}
                            naturezas={naturezas}
                            hasPermission={hasPermission}
                            resetForm={resetForm}
                            setFiltrosAbertos={setFiltrosAbertos}
                            setFormValues={setFormValues}
                            HandleChangeForm={HandleChangeForm}
                            HandleCheckboxChange={HandleCheckboxChange}
                            FormatCPF={FormatCPF}
                            FormatCelular={FormatCelular}
                            FormatTelefone={FormatTelefone}
                            OcultarCPF={OcultarCPF}
                            setCodigoUf={setCodigoUf}
                            setMunicipio={setMunicipio}
                            FormatCNPJ={FormatCNPJ}
                            HandleChangeCheckbox={HandleChangeCheckbox}
                            HandleSearch={HandleSearch}
                            setSearchTerm={setSearchTerm}
                            handleSort={handleSort}
                            handleMenuOpen={handleMenuOpen}
                            handleMenuClose={handleMenuClose}
                            handleMenuItemClick={handleMenuItemClick}
                            handleOpenModalExcluirUsuario={handleOpenModalExcluirUsuario}
                            handleCloseModalExcluirUsuario={handleCloseModalExcluirUsuario}
                            createAutoridades={createAutoridades}
                            createAssessor={createAssessor}
                            vinculateAutoridades={vinculateAutoridades}
                            createInscricao={createInscricao}
                        />
                    </Container>
                )}

            </Grid>
        </React.Fragment>
    );
};

export default AutoridadesList;
