import React from 'react';
import { Box, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridCsvExportMenuItem } from '@mui/x-data-grid';
import GetAppIcon from '@mui/icons-material/GetApp';

const csvOptions = { delimiter: ';', fileName: 'InscricoesPorUF' };

function BarraDeFerramentas() {
    return (
        <GridToolbarContainer>
            <Button variant='outlined' size='small'>
                <GetAppIcon />
                <GridCsvExportMenuItem options={csvOptions} />
            </Button>
        </GridToolbarContainer>
    );
}

const paginacaoNula = () => null;

const TabelaEstado = ({ contagensInfo }) => {

    const calcularSomaTotal = (data) => {
        return data.reduce((total, row) => {
            total.prefeito += row.prefeito;
            total.vice += row.vice;
            total.vereador += row.vereador;
            total.demaisParticipantes += row.demaisParticipantes;
            return total;
        }, { prefeito: 0, vice: 0, vereador: 0, demaisParticipantes: 0 });
    };

    const rows = Object.keys(contagensInfo).map((uf, index) => {
        const row = {
            id: `${uf}-${index}`,
            uf,
            prefeito: parseInt(contagensInfo[uf]['Prefeito']) || 0,
            vice: parseInt(contagensInfo[uf]['Vice']) || 0,
            vereador: parseInt(contagensInfo[uf]['Vereador']) || 0,
            demaisParticipantes: parseInt(contagensInfo[uf]['Demais Participantes']) || 0,
        };

        row.total = row.prefeito + row.vice + row.vereador + row.demaisParticipantes;

        return row;
    });

    const somaTotal = calcularSomaTotal(rows);

    const totalRow = {
        id: 'total',
        uf: 'Total',
        prefeito: somaTotal.prefeito,
        vice: somaTotal.vice,
        vereador: somaTotal.vereador,
        demaisParticipantes: somaTotal.demaisParticipantes,
        total: somaTotal.prefeito + somaTotal.vice + somaTotal.vereador + somaTotal.demaisParticipantes,
    };

    rows.push(totalRow);

    const columns = [
        { field: 'uf', headerName: 'UF', width: 100, headerClassName: 'cabecalho-tabela' },
        { field: 'prefeito', headerName: 'Prefeito', type: 'number', width: 90, headerClassName: 'cabecalho-tabela' },
        { field: 'vice', headerName: 'Vice', type: 'number', width: 100, headerClassName: 'cabecalho-tabela' },
        { field: 'vereador', headerName: 'Vereador', type: 'number', width: 100, headerClassName: 'cabecalho-tabela' },
        { field: 'demaisParticipantes', headerName: 'Outros', type: 'number', width: 100, headerClassName: 'cabecalho-tabela' },
        { field: 'total', headerName: 'Total', type: 'number', width: 100, headerClassName: 'cabecalho-tabela' },
    ];

    const handleExportCSV = (params) => {
        const { fields, data } = params;
        const header = fields.map((field) => field.headerName || field.field).join(';');
        const csv = [header, ...data.map((row) => fields.map((field) => row[field.field]).join(';'))].join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{ height: 'auto', width: '100%' }}>
            <Box
                sx={{
                    height: '748px',
                    width: '100%',
                    overflowY: 'auto',
                    '& .cabecalho-tabela': {
                        color: '#005BAB'
                    },
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    sortModel={[{ field: 'uf', sort: 'asc' }]}
                    rowHeight={25}
                    pagination
                    slots={{
                        toolbar: BarraDeFerramentas,
                        pagination: paginacaoNula,
                    }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    disableColumnSelector
                    disableColumnReorder
                    disableSelectionOnClick
                    onExportCsv={handleExportCSV}
                />
            </Box>
        </div>
    );
};

export default TabelaEstado;


