import React from 'react';
import './App.css';
import RoutesApp from './routesApp';

function App() {
  return (
    <RoutesApp />
  );
}

export default App;
