export const Countries = [
    { label: 'Afeganistão', value: 'AF' },
    { label: 'Albânia', value: 'AL' },
    { label: 'Argélia', value: 'DZ' },
    { label: 'Andorra', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Antígua e Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armênia', value: 'AM' },
    { label: 'Austrália', value: 'AU' },
    { label: 'Áustria', value: 'AT' },
    { label: 'Azerbaijão', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrein', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Bielorrússia', value: 'BY' },
    { label: 'Bélgica', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Butão', value: 'BT' },
    { label: 'Bolívia', value: 'BO' },
    { label: 'Bósnia e Herzegovina', value: 'BA' },
    { label: 'Botsuana', value: 'BW' },
    { label: 'Brasil', value: 'BR' },
    { label: 'Brunei', value: 'BN' },
    { label: 'Bulgária', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cabo Verde', value: 'CV' },
    { label: 'Camboja', value: 'KH' },
    { label: 'Camarões', value: 'CM' },
    { label: 'Canadá', value: 'CA' },
    { label: 'República Centro-Africana', value: 'CF' },
    { label: 'Chade', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Colômbia', value: 'CO' },
    { label: 'Comores', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Croácia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Chipre', value: 'CY' },
    { label: 'República Tcheca', value: 'CZ' },
    { label: 'Dinamarca', value: 'DK' },
    { label: 'Djibuti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'República Dominicana', value: 'DO' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Equador', value: 'EC' },
    { label: 'Egito', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Guiné Equatorial', value: 'GQ' },
    { label: 'Eritreia', value: 'ER' },
    { label: 'Estônia', value: 'EE' },
    { label: 'Eswatini', value: 'SZ' },
    { label: 'Etiópia', value: 'ET' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finlândia', value: 'FI' },
    { label: 'França', value: 'FR' },
    { label: 'Gabão', value: 'GA' },
    { label: 'Gâmbia', value: 'GM' },
    { label: 'Geórgia', value: 'GE' },
    { label: 'Alemanha', value: 'DE' },
    { label: 'Gana', value: 'GH' },
    { label: 'Grécia', value: 'GR' },
    { label: 'Granada', value: 'GD' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guiné', value: 'GN' },
    { label: 'Guiné-Bissau', value: 'GW' },
    { label: 'Guiana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hungria', value: 'HU' },
    { label: 'Islândia', value: 'IS' },
    { label: 'Índia', value: 'IN' },
    { label: 'Indonésia', value: 'ID' },
    { label: 'Irã', value: 'IR' },
    { label: 'Iraque', value: 'IQ' },
    { label: 'Irlanda', value: 'IE' },
    { label: 'Israel', value: 'IL' },
    { label: 'Itália', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japão', value: 'JP' },
    { label: 'Jordânia', value: 'JO' },
    { label: 'Cazaquistão', value: 'KZ' },
    { label: 'Quênia', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: 'Coreia do Norte', value: 'KP' },
    { label: 'Coreia do Sul', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Quirguistão', value: 'KG' },
    { label: 'Laos', value: 'LA' },
    { label: 'Letônia', value: 'LV' },
    { label: 'Líbano', value: 'LB' },
    { label: 'Lesoto', value: 'LS' },
    { label: 'Libéria', value: 'LR' },
    { label: 'Líbia', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lituânia', value: 'LT' },
    { label: 'Luxemburgo', value: 'LU' },
    { label: 'Madagáscar', value: 'MG' },
    { label: 'Malauí', value: 'MW' },
    { label: 'Malásia', value: 'MY' },
    { label: 'Maldivas', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Ilhas Marshall', value: 'MH' },
    { label: 'Mauritânia', value: 'MR' },
    { label: 'Maurícia', value: 'MU' },
    { label: 'México', value: 'MX' },
    { label: 'Micronésia', value: 'FM' },
    { label: 'Moldávia', value: 'MD' },
    { label: 'Mônaco', value: 'MC' },
    { label: 'Mongólia', value: 'MN' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Marrocos', value: 'MA' },
    { label: 'Moçambique', value: 'MZ' },
    { label: 'Mianmar', value: 'MM' },
    { label: 'Namíbia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Países Baixos', value: 'NL' },
    { label: 'Nova Zelândia', value: 'NZ' },
    { label: 'Nicarágua', value: 'NI' },
    { label: 'Níger', value: 'NE' },
    { label: 'Nigéria', value: 'NG' },
    { label: 'Macedônia do Norte', value: 'MK' },
    { label: 'Noruega', value: 'NO' },
    { label: 'Omã', value: 'OM' },
    { label: 'Paquistão', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Panamá', value: 'PA' },
    { label: 'Papua Nova Guiné', value: 'PG' },
    { label: 'Paraguai', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Filipinas', value: 'PH' },
    { label: 'Polônia', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Catar', value: 'QA' },
    { label: 'Romênia', value: 'RO' },
    { label: 'Rússia', value: 'RU' },
    { label: 'Ruanda', value: 'RW' },
    { label: 'São Cristóvão e Nevis', value: 'KN' },
    { label: 'Santa Lúcia', value: 'LC' },
    { label: 'São Vicente e Granadinas', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'São Tomé e Príncipe', value: 'ST' },
    { label: 'Arábia Saudita', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Sérvia', value: 'RS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Serra Leoa', value: 'SL' },
    { label: 'Cingapura', value: 'SG' },
    { label: 'Eslováquia', value: 'SK' },
    { label: 'Eslovênia', value: 'SI' },
    { label: 'Ilhas Salomão', value: 'SB' },
    { label: 'Somália', value: 'SO' },
    { label: 'África do Sul', value: 'ZA' },
    { label: 'Sudão do Sul', value: 'SS' },
    { label: 'Espanha', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudão', value: 'SD' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Suécia', value: 'SE' },
    { label: 'Suíça', value: 'CH' },
    { label: 'Síria', value: 'SY' },
    { label: 'Taiwan', value: 'TW' },
    { label: 'Tajiquistão', value: 'TJ' },
    { label: 'Tanzânia', value: 'TZ' },
    { label: 'Tailândia', value: 'TH' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trindade e Tobago', value: 'TT' },
    { label: 'Tunísia', value: 'TN' },
    { label: 'Turquia', value: 'TR' },
    { label: 'Turcomenistão', value: 'TM' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ucrânia', value: 'UA' },
    { label: 'Emirados Árabes Unidos', value: 'AE' },
    { label: 'Reino Unido', value: 'GB' },
    { label: 'Estados Unidos', value: 'US' },
    { label: 'Uruguai', value: 'UY' },
    { label: 'Uzbequistão', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Cidade do Vaticano', value: 'VA' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Vietnã', value: 'VN' },
    { label: 'Iêmen', value: 'YE' },
    { label: 'Zâmbia', value: 'ZM' },
    { label: 'Zimbábue', value: 'ZW' },
  ];
  