import { useState, useEffect, useCallback } from "react";
import api from '../service/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useConstantsInscricoes = (dontLoadAll = false) => {
    const [inscricoes, setInscricoes] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    const [order, setOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [naturezas, setNaturezas] = useState([]);
    const [tipoCargo, setTipoCargo] = useState([]);
    const [cargo, setCargo] = useState([]);
    const [parlamentar, setParlamentar] = useState([]);
    const [partidoPolitico, setPartidoPolitico] = useState([]);
    const [entidadeNacional, setEntidadeNacional] = useState([]);
    const [entidadeEstadual, setEntidadeEstadual] = useState([]);
    const [entidadeMicro, setEntidadeMicro] = useState([]);
    const [consorcio, setConsorcio] = useState([]);
    const [areaDeAtuacao, setAreaDeAtuacao] = useState([]);
    const [ufs, setUfs] = useState([]);
    const [codigoUf, setCodigoUf] = useState(null);
    const [municipio, setMunicipio] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [filtrosAbertos, setFiltrosAbertos] = useState(true);
    const [mostrarEdicao, setMostrarEdicao] = useState('');
    const [inscricaoSelecionadaId, setInscricaoSelecionadaId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isCaixa, setIsCaixa] = useState(false);
    const [dadosMunicipio, setDadosMunicipio] = useState('');
    const navigate = useNavigate();
    const initialState = {
        tipo_inscricao: '',
        tipo_cargo: '',
        cargo: '',
        parlamentar: '',
        nome_parlamentar: '',
        entidade_estadual: '',
        entidade_micro: '',
        entidade_nacionais: '',
        consorcio: '',
        partido_politico: '',
        nome_cargo: '',
        pais: '',
        area_atuacao: '',
        empresa_instituicao: '',
        ministerio_orgao: '',
        nome: '',
        genero: '',
        PCD: {
            necessita_atendimento_especializado: '',
            tipo_deficiencias: [],
            outras_deficiencia: '',
            dificuldade_locomocao: '',
            acompanhamento_acessibilidade: '',
            descricao_acompanhamento_acessibilidade: ''
        },
        nome_cracha: '',
        cpf: '',
        passaporte: '',
        cnpj: '',
        uf: '',
        municipio: '',
        nome_municipio: '',
        celular: '',
        email: '',
        telefone: '',
        cep: '',
        endereco: '',
        bairro: '',
        razao_social: '',
        opc_pagamento: '',
        status_contribuicao: '',
        politica_privacidade: '',
        tipo_pagamento: '',
        // convidado: {
        //     cargo: '',
        //     convidado_de: ''
        // },
        obs: '',
        aprovado: '',
        // file:'',
        cortesia: '',
        perfil: '',
        valorBoleto: '',
        statusBoleto: '',
        boletoBaixado: '',
        numeroTituloCliente: '',
        dataVencimentoTituloCobranca: '',
        dataPagamentoBoleto: '',
        reenviarEmail: {
            id: '',
            recibo: false,
            confirmacao_participacao: false,
            confirmacao_pagamento: false,
            convidados_isentos: false,
            pix: false,
            boleto: false,
            prefeito_isento: false,
            empenho: false

        },
    };
    const [formValues, setFormValues] = useState(initialState);
    const [isAdmin, setIsAdmin] = useState(['']);
    const [permissoes, setPermissoes] = useState(['']);
    const token = localStorage.getItem('token_credmarcha');

    const hasPermission = (funcionalidade, tipo) => {
        if (isAdmin) {
            return true;
        }
        const permissao = permissoes.find(perm => perm.includes(`${funcionalidade}:${tipo}`));
        return permissao && permissao.split(':')[1] !== 'semPermissao';
    };

    const hasPermissionFuncionalidades = (permissoes, funcionalidade) => {
        const permissao = permissoes.find(perm => perm.includes(`${funcionalidade}:`));
        return permissao && permissao.split(':')[1] !== 'semPermissao';
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (link) => {
        window.open(link, '_blank');
        handleMenuClose();
    };

    const HandleChangeForm = useCallback((atributo, valor, nome_municipio = null, nome_parlamentar = null) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [atributo]: valor,
            ...(nome_municipio && { nome_municipio }),
            ...(nome_parlamentar && { nome_parlamentar }),
        }));
    }, [setFormValues]);

    const resetForm = () => {
        setFormValues({ ...initialState });
        setSearchTerm('');
    };

    const HandleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const showAlert = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const FormatCPF = (value) => {
        // Remove tudo o que não é dígito
        if (value) {
            value = value.replace(/\D/g, '');
            // Adiciona os pontos e o hífen no CPF
            value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
            return value;
        }

        return '';
    };

    const FormatCNPJ = (value) => {
        // Remove tudo o que não é dígito
        if (value) {
            value = value.replace(/\D/g, '');
            // Adiciona os pontos, a barra e o hífen no CNPJ
            value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
            return value;
        }

        return '';
    };

    const FormatCEP = (value) => {
        // Remove tudo o que não é dígito
        if (value) {
            value = value.replace(/\D/g, '');

            // Garante que o CEP tenha exatamente 8 dígitos
            if (value.length === 8) {
                // Adiciona o ponto e o hífen no CEP
                value = value.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3');
            }

            return value;
        }

        return '';
    };

    const OcultarCPF = (value) => {
        if (value) {
            value = value.replace(/\D/g, '');
            // Adiciona os pontos e o hífen no CPF
            value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.XXX.XXX-$4');
            return value;
        }

        return '';
    };

    const FormatCelular = (value) => {
        const celular = value.replace(/\D/g, '');

        if (celular.length === 0) {
            return '';
        } else if (celular.length <= 2) {
            return `(${celular}`;
        } else if (celular.length <= 7) {
            return `(${celular.slice(0, 2)}) ${celular.slice(2)}`;
        } else {
            return `(${celular.slice(0, 2)}) ${celular.slice(2, 7)}-${celular.slice(7, 11)}`;
        }
    };

    const FormatTelefone = (value) => {
        const telefone = value.replace(/\D/g, '');

        if (telefone.length === 0) {
            return '';
        } else if (telefone.length <= 2) {
            return `(${telefone}`;
        } else if (telefone.length <= 6) {
            return `(${telefone.slice(0, 2)}) ${telefone.slice(2)}`;
        } else {
            return `(${telefone.slice(0, 2)}) ${telefone.slice(2, 6)}-${telefone.slice(6, 10)}`;
        }
    };

    const HandleChangeCheckbox = (tipoDeficiencia, isChecked) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            PCD: {
                ...prevFormValues.PCD,
                tipo_deficiencias: isChecked
                    ? [...prevFormValues.PCD.tipo_deficiencias, tipoDeficiencia]
                    : prevFormValues.PCD.tipo_deficiencias.filter((deficiencia) => deficiencia !== tipoDeficiencia),
            },
        }));
    };

    const ajustaNomes = (str) => {
        return str.replace(/(^|\s)\S/g, (match) => match.toLocaleUpperCase());
    };

    const HandleCheckboxChange = (tipoEnvio, isChecked) => {
        setFormValues((prevFormValues) => {
            return {
                ...prevFormValues,
                reenviarEmail: {
                    ...prevFormValues.reenviarEmail,
                    [tipoEnvio]: isChecked
                },
            };
        });
    };

    const getEntidadeOpcoesPorTipoCargo = (tipoCargo) => {
        switch (tipoCargo) {
            case 138:
                return entidadeNacional || [];
            case 146:
                return entidadeEstadual || [];
            case 153:
                return entidadeMicro || [];
            default:
                return [];
        }
    };

    const getEntidadeValorPorTipoCargo = (formValues, tipoCargo) => {
        switch (tipoCargo) {
            case 138:
                return entidadeNacional?.find((e) => e.id === formValues?.entidade_nacionais);
            case 146:
                return entidadeEstadual?.find((e) => e.id === formValues?.entidade_estadual);
            case 153:
                return entidadeMicro?.find((e) => e.id === formValues?.entidade_micro);
            default:
                return null;
        }
    };

    const getEntidadeLabelPorTipoCargo = (tipoCargo) => {
        switch (tipoCargo) {
            case 138:
                return 'Entidade Nacional';
            case 146:
                return 'Entidade Estadual';
            case 153:
                return 'Entidade Microrregional';
            default:
                return '';
        }
    };

    const getEntidadeNomeFormulario = (tipoCargo) => {
        switch (tipoCargo) {
            case 138:
                return 'entidade_nacionais';
            case 146:
                return 'entidade_estadual';
            case 153:
                return 'entidade_micro';
            default:
                return '';
        }
    };

    const TransformFormData = (data) => {
        const pcdJson = JSON.stringify(data?.PCD);
        const convidadoJson = JSON.stringify(data?.convidado);
        const cleanTelefone = data?.telefone?.replace(/[\s()-]/g, '');
        const cleanCelular = data?.celular?.replace(/[\s()-]/g, '');
        const cleanCpf = data?.cpf?.replace(/[^\d]/g, '');
        const cleanCnpj = data?.cnpj?.replace(/[^\d]/g, '') || '';

        const transformedData = {
            ...data,
            PCD: pcdJson,
            convidado: convidadoJson,
            telefone: cleanTelefone,
            celular: cleanCelular,
            cpf: cleanCpf,
            cnpj: cleanCnpj,
        };

        delete transformedData?.statusBoleto;
        delete transformedData?.valorBoleto;
        delete transformedData?.numeroTituloCliente;
        delete transformedData?.dataRecebimentoTitulo;
        delete transformedData?.file;

        return transformedData;
    };

    const processarPagamentos = (formValues) => {
        let pagamentosUnicos = {};

        if (formValues.pagamentos && formValues.pagamentos.length > 0) {
            const groupedPagamentos = {};

            formValues.pagamentos.forEach(async (pagamento) => {
                const tipoPgto = pagamento.tipo_pgto.toLowerCase();
                if (!groupedPagamentos[tipoPgto]) {
                    groupedPagamentos[tipoPgto] = 0;
                }
                groupedPagamentos[tipoPgto] += pagamento.valor;

                if (tipoPgto === 'filiacao' && dadosMunicipio?.situacao === 'Não contribuinte') {
                    const fetchContribuinte = await fetchContribuicao();
                    if (fetchContribuinte === 200) {
                        formValues.status_contribuicao = 'true';
                        showAlert('Municipio filiado com sucesso', 'success');
                    } else {
                        showAlert('Ocorreu um erro durante o processo de filiação', 'error');
                    }
                } else if (tipoPgto === 'filiacao' && dadosMunicipio?.situacao === 'Inadimplente') {
                    const fetchContribuinte = await fetchRegularizacao();
                    if (fetchContribuinte === 200) {
                        formValues.status_contribuicao = 'true';
                        showAlert('Municipio regularizado com sucesso', 'success');
                    } else {
                        return showAlert('Ocorreu um erro durante o processo de filiação', 'error');
                    }
                }
            });

            const perfil = formValues.pagamentos[0]?.perfil || '';

            pagamentosUnicos = {
                ...groupedPagamentos,
                obs: '',
                banco: null,
                agencia: null,
                conta: null,
                titular: null,
                perfil,
            };

            formValues.pagamentos.forEach((pagamento) => {
                if (pagamento.obs) {
                    const obsComTipoPagamento = `${pagamento.tipo_pgto}: ${pagamento.obs}`;
                    if (pagamentosUnicos.obs) {
                        pagamentosUnicos.obs += `, ${obsComTipoPagamento}`;
                    } else {
                        pagamentosUnicos.obs = obsComTipoPagamento;
                    }
                }

                pagamentosUnicos.banco = pagamento.banco || pagamentosUnicos.banco;
                pagamentosUnicos.agencia = pagamento.agencia || pagamentosUnicos.agencia;
                pagamentosUnicos.conta = pagamento.conta || pagamentosUnicos.conta;
                pagamentosUnicos.titular = pagamento.titular || pagamentosUnicos.titular;
                pagamentosUnicos.perfil = pagamento.perfil || pagamentosUnicos.perfil;
            });

            if (Object.keys(pagamentosUnicos).length > 0) {
                pagamentosUnicos.pagamentos = JSON.stringify(pagamentosUnicos);
            }
        }

        return pagamentosUnicos;
    };

    const SalvarPagamentos = async (id, formValues) => {
        if (formValues?.pagamentos) {
            try {
                const pagamentosProcessados = processarPagamentos(formValues);
                pagamentosProcessados.id = id;
                const response = await api.post(`api/fluxoCaixa`, pagamentosProcessados, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    return 200;
                } else {
                    return 500;
                }
            } catch (error) {
                console.error('Erro ao salvar os pagamentos:', error);
            }
        } else {
            return
        }
    };

    const fetchContribuicao = async () => {
        try {

            const apiFatura = axios.create({
                baseURL: process.env.REACT_APP_SERVER_FATURA_URL,
            });

            const body = {
                "id_municipio": dadosMunicipio?.cod_cnm?.toString(),
                "cnpj": dadosMunicipio?.cnpj,
                "municipio": dadosMunicipio?.descricao,
                "uf": dadosMunicipio?.estado?.sigla,
                "servico": "Marcha 2024",
                "formaDePgto": "Cobrança Bancária",
                "valor": dadosMunicipio?.contribuicao.toString()
            };
            const response = await apiFatura.post(`api/contribuicao`, body, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                return 200;
            } else {
                return 500;
            }
        } catch (error) {
            console.error('Ocorreu o seguinte erro: ', error);
        }
    };

    const fetchRegularizacao = async () => {
        try {
            const apiFatura = axios.create({
                baseURL: process.env.REACT_APP_SERVER_FATURA_URL,
            });

            const faturasPagas = dadosMunicipio?.apagar.map(fatura => ({
                ...fatura,
                servico: 'Regularização marcha 2024',
                situacao: 'Pago'
            }));

            const body = {
                faturas: faturasPagas
            };
            const response = await apiFatura.post(`api/contribuicao/regularizar`, body, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                return 200;
            } else {
                return 500;
            }
        } catch (error) {
            console.error('Ocorreu o seguinte erro: ', error);
        }
    };

    const CreateFormData = (data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (typeof value === 'object') {
                formData.append(key, JSON.stringify(value));
            } else {
                formData.append(key, value);
            }
        });
        return formData;
    };

    const removerAcentos = (texto) => {
        if (!texto) return '';

        if (typeof texto.normalize === 'function') {
            return texto
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
        }
        return texto;
    };

    const deletarInscricao = async () => {
        try {
            const response = await api.delete(`api/cadastroUser/${inscricaoSelecionadaId}`);
            return response;
        } catch (error) {
            console.error('Ocorreu um erro ao excluir o usuário selecionado: ', error);
        }
    };

    function verificaSituacao(inscricao) {
        let situacao = '';
        if (inscricao?.status && inscricao?.tipo_pagamento !== 'caixa') {
            situacao = ajustaNomes(inscricao?.status?.toLowerCase());
        } else if ([3, 16].includes(inscricao.cargo)) {
            if (inscricao.status_contribuicao === "true" && !inscricao.obs) {
                situacao = 'Isento';
            } else if (inscricao.cortesia === 1) {
                situacao = 'Cortesia';
            } else if (inscricao?.tipo_pagamento === 'caixa' && (inscricao?.aprovado === 'Homologado' || inscricao?.aprovado === true)) {
                situacao = 'Pago';
            } else if (inscricao?.tipo_pagamento === 'caixa' && (inscricao?.aprovado === 'Não homologado' || inscricao?.aprovado === false)) {
                situacao = 'Aberto';
            } else {
                situacao = 'Cortesia';
            }
        } else if (inscricao?.cortesia === 1) {
            situacao = 'Cortesia';
        } else if (inscricao?.tipo_pagamento === 'caixa' && (inscricao?.aprovado === 'Homologado' || inscricao?.aprovado === true)) {
            situacao = 'Pago';
        } else if (inscricao?.tipo_pagamento === 'caixa' && (inscricao?.aprovado === 'Não homologado' || inscricao?.aprovado === false)) {
            situacao = 'Aberto';
        } else {
            situacao = 'Cortesia';
        }

        return situacao;
    };

    const handlePagamentosChange = (novosPagamentos) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            pagamentos: novosPagamentos,
        }));
    };

    const CalculoDeValores = (cargo, statusContribuicao, uf) => {
        let valorSubstituicao;

        if ([3, 16].includes(cargo) && (statusContribuicao === "false" || statusContribuicao === false)) {
            valorSubstituicao = process.env.REACT_APP_VALOR_PREFEITO_NAO_CONTRIBUINTE;
        } else if (statusContribuicao === true || statusContribuicao === "true") {
            valorSubstituicao = process.env.REACT_APP_VALOR_MUNICIPIO_CONTRIBUINTE;
        } else if (uf === 'DF') {
            valorSubstituicao = process.env.REACT_APP_VALOR_DF;
        } else {
            valorSubstituicao = process.env.REACT_APP_VALOR_DEMAIS;
        }

        return valorSubstituicao;
    };

    const getStatusLabel = () => {
        if (formValues?.statusBoleto) {
            return ajustaNomes(formValues?.statusBoleto?.toLowerCase());
        } else if ([3, 16].includes(formValues?.cargo)) {
            if ((formValues?.status_contribuicao === true || formValues?.status_contribuicao === 'true') && !formValues?.obs) {
                return "Isento";
            } else if ((formValues?.status_contribuicao === true || formValues?.status_contribuicao === 'true') && formValues?.obs) {
                return "Cortesia";
            } else {
                return "Não há boleto registrado"
            }
        } else {
            return "Cortesia";
        }
    };

    useEffect(() => {
        const userCred = JSON.parse(localStorage.getItem('user_credmarcha'));

        if (userCred && userCred.perfil) {
            const temPermissaoCaixas = userCred?.perfil?.permissoes?.includes('caixas:leitura') || userCred?.perfil?.permissoes?.includes('caixas:escrita');

            if (temPermissaoCaixas) {
                setIsCaixa(true);
            }
        } else {
            console.log('Não foi possível encontrar as credenciais do usuário.');
        }
    }, []);

    useEffect(() => {
        if (!token) {
            localStorage.clear();
            navigate('/');
        } else {
            setIsAdmin(JSON.parse(localStorage.getItem('user_credmarcha')).perfil.is_admin);
            setPermissoes(JSON.parse(localStorage.getItem('user_credmarcha')).perfil.permissoes);
        }
    }, [isAdmin, navigate, token]);

    useEffect(() => {
        if (!dontLoadAll) {
            const fetchNatureza = async () => {
                try {
                    // setIsLoading(true);
                    const response = await api.get('api/getNatureza');
                    const todasNaturezas = [
                        ...response.data?.natureza?.pagamento,
                        ...response.data?.natureza?.isento_autoridade,
                        ...response.data?.natureza?.demais_isento,
                    ];
                    setNaturezas(todasNaturezas);
                    // setIsLoading(false);
                } catch (error) {
                    console.error('Erro ao buscar tipos de inscrições:', error);
                }
            };

            fetchNatureza();
        }
    }, [setNaturezas, dontLoadAll]);

    useEffect(() => {
        if (!formValues.cep.trim()) {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                endereco: '',
                bairro: '',
            }));
        }
    }, [formValues.cep]);

    useEffect(() => {
        const fetchTipoCargo = async () => {
            try {
                // setIsLoading(true);
                const response = await api.get(`api/getTipoInscricao?id_tipo_inscricao=${formValues.tipo_inscricao}`);
                setTipoCargo(response.data.tipoInscricao);
                // setIsLoading(false);
            } catch (error) {
                console.error('Erro ao buscar tipos de cargos:', error);
            }
        };

        if (formValues.tipo_inscricao) {
            fetchTipoCargo();
        }
    }, [formValues.tipo_inscricao]);

    useEffect(() => {
        if (!dontLoadAll) {
            const fetchAutoridades = async () => {
                try {
                    const response = await api.get(`api/getAutoridade`);
                    setParlamentar(response.data.autoridade)
                } catch (error) {
                    console.error('Erro ao buscar as autoridades:', error);
                }
            };
            fetchAutoridades();
        }
    }, [dontLoadAll]);

    useEffect(() => {
        if (!dontLoadAll) {
            const fetchPartidoPolitico = async () => {
                try {
                    const response = await api.get(`api/getPartidoPolitico`);
                    setPartidoPolitico(response.data.partidoPolitico)
                } catch (error) {
                    console.error('Erro ao buscar os partidos políticos:', error);
                }
            };
            fetchPartidoPolitico();
        }
    }, [formValues?.tipo_inscricao, dontLoadAll]);

    useEffect(() => {
        const fetchCargos = async () => {
            try {
                // setIsLoading(true);
                const response = await api.get(`api/getCargo?id_tipo_cargo=${formValues.tipo_cargo}`);
                setCargo(response.data.id_tipo_cargo);
                // setIsLoading(false);
            } catch (error) {
                console.error('Erro ao buscar cargos:', error);
            }
        };

        if (formValues.tipo_cargo) {
            fetchCargos();
        }
    }, [formValues.tipo_cargo]);

    useEffect(() => {
        if (!dontLoadAll) {
            const fetchAreasAtuacao = async () => {
                try {
                    // setIsLoading(true);
                    const response = await api.get(`api/getAreaAtuacao`);
                    setAreaDeAtuacao(response.data.AreaAtuacao);
                    // setIsLoading(false);
                } catch (error) {
                    console.error('Erro ao buscar áreas de atuação:', error);
                }
            };

            // if (formValues.tipo_cargo) {
            fetchAreasAtuacao();
            // }
        }
    }, [dontLoadAll]);

    useEffect(() => {
        if (!dontLoadAll) {
            const fetchUfs = async () => {
                try {
                    // setIsLoading(true);
                    const response = await api.get('api/getUf');
                    setUfs(response.data.ufs);
                    // setIsLoading(false);
                } catch (error) {
                    console.error('Erro ao buscar UFs:', error);
                }
            };

            fetchUfs();
        }
    }, [dontLoadAll]);

    useEffect(() => {
        const fetchMunicipios = async () => {
            try {
                // setIsLoading(true);
                if (codigoUf) {
                    const response = await api.get(`api/getMunicipio?uf_id=${codigoUf}`);
                    setMunicipio(response.data.municipios);
                    // setIsLoading(false);
                }
            } catch (error) {
                console.error('Erro ao buscar município:', error);
            }
        };

        fetchMunicipios();
    }, [codigoUf]);

    useEffect(() => {
        const fetchEntidade = async () => {
            try {
                let response;
                if (formValues.tipo_cargo === 15) {
                    response = await api.get(`api/getConsorcios`);
                    setConsorcio(response.data.consorcios)
                }
                else if (formValues.tipo_cargo === 138) {
                    response = await api.get(`api/getEntidadesNacionais`);
                    setEntidadeNacional(response.data.EntidadesNacionais);
                } else if (formValues.tipo_cargo === 146) {
                    response = await api.get(`api/getEntidadesEstaduais`);
                    setEntidadeEstadual(response.data.EntidadesEstaduais);
                } else if (formValues.tipo_cargo === 153) {
                    response = await api.get(`api/getEntidadesMicros`);
                    setEntidadeMicro(response.data.EntidadesMicros);
                } else {
                    return;
                }
            } catch (error) {
                console.error('Erro ao buscar entidade:', error);
            }
        };

        fetchEntidade();
    }, [formValues.tipo_cargo]);

    useEffect(() => {
        // Verifica se há UFs disponíveis e se a sigla da UF está selecionada no formulário.
        if (ufs && ufs.length > 0 && formValues.uf) {
            // Encontra o objeto da UF correspondente à sigla selecionada.
            const selectedUfObject = ufs.find((uf) => uf.SIGLA_UF === formValues.uf);

            // Se a UF correspondente for encontrada, atualiza o estado 'codigoUf'.
            if (selectedUfObject) {
                setCodigoUf(selectedUfObject.CD_UF || null);
            }
        }
    }, [ufs, formValues.uf, setCodigoUf]);

    useEffect(() => {
        const fetchContribuinte = async () => {
            try {
                if (formValues?.municipio) {
                    const apiContatos = axios.create({
                        baseURL: process.env.REACT_APP_SERVER_URL,
                    });
                    const apiFatura = axios.create({
                        baseURL: process.env.REACT_APP_SERVER_FATURA_URL,
                    });
                    const response = await apiContatos.get(`api/list/municipio/${formValues?.municipio}`);
                    const response2 = await apiFatura.get(`api/contribuicao/${formValues?.municipio}`);
                    const isContribuinte = response?.data?.data?.situacao === "Em dia" ? true : false;
                    HandleChangeForm('status_contribuicao', isContribuinte);
                    setDadosMunicipio(response?.data?.data);
                    setDadosMunicipio({
                        ...response?.data?.data,
                        apagar: response2?.data?.apagar
                    });
                }
            } catch (error) {
                if (error?.response.status === 404) {
                    HandleChangeForm('status_contribuicao', false);
                } else {
                    console.error('Erro ao buscar município:', error);
                }
            }
        };
        fetchContribuinte();
    }, [formValues?.municipio, HandleChangeForm]);

    useEffect(() => {
        if (formValues.tipo_cargo === 122) {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                cpf: '',
                uf: '',
                municipio: ''
            }));
        }
    }, [formValues.tipo_cargo, setFormValues]);

    useEffect(() => {
        if (formValues?.PCD?.necessita_atendimento_especializado === 'Nao') {
            const updatedPCD = {
                tipo_deficiencias: [],
                outras_deficiencia: '',
                dificuldade_locomocao: '',
                acompanhamento_acessibilidade: '',
                descricao_acompanhamento_acessibilidade: '',
            };
            if (
                formValues.PCD.tipo_deficiencias.length !== 0 ||
                formValues.PCD.outras_deficiencia ||
                formValues.PCD.dificuldade_locomocao ||
                formValues.PCD.acompanhamento_acessibilidade ||
                formValues.PCD.descricao_acompanhamento_acessibilidade
            ) {
                HandleChangeForm('PCD', { ...formValues.PCD, ...updatedPCD });
            }
        }
    }, [formValues?.PCD?.necessita_atendimento_especializado, formValues.PCD, HandleChangeForm]);


    useEffect(() => {
        if (formValues?.PCD?.tipo_deficiencias && !formValues.PCD.tipo_deficiencias.includes('Outros')) {
            if (formValues.PCD.outras_deficiencia) {
                HandleChangeForm('PCD', { ...formValues.PCD, outras_deficiencia: '' });
            }
        }
    }, [formValues?.PCD?.tipo_deficiencias, formValues.PCD, HandleChangeForm]);

    useEffect(() => {
        if (
            formValues?.PCD?.dificuldade_locomocao &&
            formValues.PCD.dificuldade_locomocao !== 'Sim' &&
            formValues.PCD.dificuldade_locomocao !== 'Cadeirante'
        ) {
            const updatedPCD = {
                acompanhamento_acessibilidade: '',
                descricao_acompanhamento_acessibilidade: '',
            };
            if (
                formValues.PCD.acompanhamento_acessibilidade !== updatedPCD.acompanhamento_acessibilidade ||
                formValues.PCD.descricao_acompanhamento_acessibilidade !== updatedPCD.descricao_acompanhamento_acessibilidade
            ) {
                HandleChangeForm('PCD', { ...formValues.PCD, ...updatedPCD });
            }
        } else if (formValues?.PCD?.acompanhamento_acessibilidade === 'Nao') {
            const updatedPCD = {
                descricao_acompanhamento_acessibilidade: '',
            };
            if (formValues.PCD.descricao_acompanhamento_acessibilidade !== updatedPCD.descricao_acompanhamento_acessibilidade) {
                HandleChangeForm('PCD', { ...formValues.PCD, ...updatedPCD });
            }
        }
    }, [formValues?.PCD?.dificuldade_locomocao, formValues.PCD, HandleChangeForm]);

    useEffect(() => {
        if (formValues.opc_pagamento === '1') {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                cnpj: '',
                razao_social: '',
            }));
        }
    }, [formValues.opc_pagamento]);

    useEffect(() => {
        if (!dontLoadAll) {
            console.log('formValues -> ', formValues);
        }
    }, [formValues, dontLoadAll]);

    return {
        HandleSnackbarClose,
        showAlert,
        FormatCPF,
        OcultarCPF,
        FormatCNPJ,
        FormatCEP,
        FormatCelular,
        FormatTelefone,
        HandleChangeForm,
        HandleChangeCheckbox,
        HandleCheckboxChange,
        setFormValues,
        setCodigoUf,
        setMunicipio,
        setNaturezas,
        setTipoCargo,
        setParlamentar,
        setCargo,
        setUfs,
        setAreaDeAtuacao,
        setOrder,
        setOrderBy,
        setInscricoes,
        setInscricaoSelecionadaId,
        setMostrarEdicao,
        setFiltrosAbertos,
        setSearchTerm,
        resetForm,
        getEntidadeOpcoesPorTipoCargo,
        getEntidadeValorPorTipoCargo,
        getEntidadeNomeFormulario,
        getEntidadeLabelPorTipoCargo,
        setConsorcio,
        ajustaNomes,
        handleMenuOpen,
        handleMenuClose,
        handleMenuItemClick,
        TransformFormData,
        CreateFormData,
        removerAcentos,
        setIsAdmin,
        setPermissoes,
        hasPermission,
        hasPermissionFuncionalidades,
        deletarInscricao,
        CalculoDeValores,
        getStatusLabel,
        setIsCaixa,
        handlePagamentosChange,
        verificaSituacao,
        SalvarPagamentos,
        fetchContribuicao,
        fetchRegularizacao,
        dadosMunicipio,
        isCaixa,
        token,
        isAdmin,
        permissoes,
        anchorEl,
        ufs,
        areaDeAtuacao,
        naturezas,
        tipoCargo,
        cargo,
        consorcio,
        codigoUf,
        municipio,
        formValues,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        parlamentar,
        partidoPolitico,
        inscricoes,
        orderBy,
        order,
        searchTerm,
        filtrosAbertos,
        mostrarEdicao,
        inscricaoSelecionadaId
    };

}

export default useConstantsInscricoes;