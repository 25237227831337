import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingInit from "highcharts/modules/exporting";
import exportDataInit from "highcharts/modules/export-data";
import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";
import Mapa from "./Mapa";
import TabelaEstado from "./TabelaEstado";
import api from '../service/Api';
import * as XLSX from 'xlsx';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ConstantInscricoes from "../constants/ConstantInscricoes";
import LoadingOverlay from '../components/LoadingOverlay';

exportingInit(Highcharts);
exportDataInit(Highcharts);

const Graficos = () => {
    const [naturezas, setNaturezas] = useState({});
    const [govMunicipalCargos, setGovMunicipalCargos] = useState({});
    const [cargosGeral, setCargosGeral] = useState({});
    const [genero, setGenero] = useState({});
    const [status, setStatus] = useState({});
    const [situacao, setSituacao] = useState({});
    const [totalInscricos, setTotalInscricos] = useState(0);
    const [cadastroUserGlobal, setCadastroUserGlobal] = useState([]);
    const [contagensInfo, setContagensInfo] = useState([]);
    const [inscricoesEsteAno, setInscricoesEsteAno] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {
        isAdmin,
        verificaSituacao,
    } = ConstantInscricoes({ dontLoadAll: true });

    const inscricoesAnoPassado = [
        { mes: 'Janeiro', quantidade: 2010 },
        { mes: 'Fevereiro', quantidade: 5501 },
        { mes: 'Março', quantidade: 12897 },
    ];

    const estadosData = [
        { uf: 'AC', estado: 'Acre', total_municipios: 22 },
        { uf: 'AL', estado: 'Alagoas', total_municipios: 102 },
        { uf: 'AM', estado: 'Amazonas', total_municipios: 62 },
        { uf: 'AP', estado: 'Amapá', total_municipios: 16 },
        { uf: 'BA', estado: 'Bahia', total_municipios: 417 },
        { uf: 'CE', estado: 'Ceará', total_municipios: 184 },
        // { uf: 'DF', estado: 'Brasília', total_municipios: 1 },
        { uf: 'ES', estado: 'Espírito Santo', total_municipios: 78 },
        { uf: 'GO', estado: 'Goiás', total_municipios: 246 },
        { uf: 'MA', estado: 'Maranhão', total_municipios: 217 },
        { uf: 'MG', estado: 'Minas Gerais', total_municipios: 853 },
        { uf: 'MS', estado: 'Mato Grosso do Sul', total_municipios: 79 },
        { uf: 'MT', estado: 'Mato Grosso', total_municipios: 142 },
        { uf: 'PA', estado: 'Pará', total_municipios: 144 },
        { uf: 'PB', estado: 'Paraíba', total_municipios: 223 },
        { uf: 'PE', estado: 'Pernambuco', total_municipios: 184 },
        { uf: 'PI', estado: 'Piauí', total_municipios: 224 },
        { uf: 'PR', estado: 'Paraná', total_municipios: 399 },
        { uf: 'RJ', estado: 'Rio de Janeiro', total_municipios: 92 },
        { uf: 'RN', estado: 'Rio Grande do Norte', total_municipios: 167 },
        { uf: 'RO', estado: 'Rondônia', total_municipios: 52 },
        { uf: 'RR', estado: 'Roraima', total_municipios: 15 },
        { uf: 'RS', estado: 'Rio Grande do Sul', total_municipios: 497 },
        { uf: 'SC', estado: 'Santa Catarina', total_municipios: 295 },
        { uf: 'SE', estado: 'Sergipe', total_municipios: 75 },
        { uf: 'SP', estado: 'São Paulo', total_municipios: 645 },
        { uf: 'TO', estado: 'Tocantins', total_municipios: 139 },
    ];

    const ajustaNomes = (str) => {
        return str.replace(/(^|\s)\S/g, (match) => match.toLocaleUpperCase());
    };

    const graficoInscricoesPorNaturezas = {
        chart: {
            type: "column",
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25,
            },
        },
        title: {
            text: "Naturezas",
        },
        xAxis: {
            crosshair: true,
            title: {
                text: "Naturezas",
                enabled: false,
            },
            categories: Object.keys(naturezas),
        },
        yAxis: {
            title: {
                text: "Quantidade",
            },
        },
        tooltip: {
            shared: true
        },
        series: [
            {
                name: "Quantidade",
                data: Object.values(naturezas),
                colorByPoint: true,
            },
        ],
        legend: {
            enabled: false,
        },
    };

    const entradasOrdenadas = Object.entries(govMunicipalCargos).sort((a, b) => b[1] - a[1]);
    const categoriasOrdenadas = entradasOrdenadas.map(entrada => entrada[0]);
    const dadosOrdenados = entradasOrdenadas.map(entrada => entrada[1]);

    const graficoInscricoesPorCargosMunicipal = {
        chart: {
            type: "column",
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25,
            },
        },
        title: {
            text: "Gov. Municipal - Cargos",
        },
        xAxis: {
            crosshair: true,
            title: {
                text: "Cargo",
                enabled: false,
            },
            categories: categoriasOrdenadas,
        },
        yAxis: {
            title: {
                text: "Quantidade",
            },
        },
        tooltip: {
            shared: true,
        },
        series: [
            {
                name: "Quantidade",
                data: dadosOrdenados,
                colorByPoint: true,
            },
        ],
        legend: {
            enabled: false,
        },
    };

    const graficoInscricoesPorCargosGeral = {
        chart: {
            type: "pie",
        },
        title: {
            text: "Cargos - Geral",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                tooltip: {
                    pointFormat: '<b>{point.name}:</b> {point.y} inscritos',
                },
            },
        },
        series: [
            {
                name: "Quantidade",
                data: Object.entries(cargosGeral).map(([cargosGeral, quantidade]) => ({
                    name: cargosGeral,
                    y: quantidade,
                })),
            },
        ],
        exporting: {
            csv: {
                columnHeaderFormatter: function (item, key) {
                    if (key === undefined) {
                        return 'Cargos';
                    } else if (key === 'y') {
                        return 'Quantidade';
                    }
                    return item;
                },
            },
        },
    };

    const graficoInscricoesPorGenero = {
        chart: {
            type: "bar",
        },
        title: {
            text: "Gêneros",
        },
        xAxis: {
            crosshair: true,
            categories: Object.keys(genero).map(genero => genero.charAt(0).toUpperCase() + genero.slice(1)),
            title: {
                text: "Gêneros",
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Quantidade",
                align: "high",
            },
            labels: {
                overflow: "justify",
            },
            allowDecimals: false,
        },
        tooltip: {
            valueSuffix: " inscritos",
            shared: true
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                },
            },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: "Quantidade",
                data: Object.entries(genero).map(([genero, quantidade]) => ({ name: genero, y: quantidade })),
                colorByPoint: true,
            },
        ],
        legend: {
            enabled: false,
        },
    };

    const graficoInscricoesStatus = {
        chart: {
            type: "pie",
        },
        title: {
            text: "Status",
        },
        series: [
            {
                name: 'Quantidade',
                data: Object.entries(status).map(([status, quantidade]) => ({
                    name: status,
                    y: quantidade,
                })),
            },
        ],
        exporting: {
            csv: {
                columnHeaderFormatter: function (item, key) {
                    if (key === undefined) {
                        return 'Status';
                    } else if (key === 'y') {
                        return 'Quantidade';
                    }
                    return item;
                },
            },
        },
    };    

    const graficoInscricoesSituacao = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45,
                beta: 0,
            },
        },
        title: {
            text: 'Situação',
        },
        xAxis: {
            title: {
                text: "Status",
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Quantidade",
                align: "high",
            },
            labels: {
                overflow: "justify",
            },
            allowDecimals: false,
        },
        plotOptions: {
            pie: {
                innerSize: window.innerWidth <= 850 ? 50 : 100,
                depth: 45,
            },
        },
        series: [
            {
                name: 'Quantidade',
                data: Object.entries(situacao).map(([situacao, quantidade]) => ({
                    name: ajustaNomes(situacao),
                    y: quantidade,
                })),
            },
        ],
        exporting: {
            csv: {
                columnHeaderFormatter: function (item, key) {
                    if (key === undefined) {
                        return 'Status';
                    } else if (key === 'y') {
                        return 'Quantidade';
                    }
                    return item;
                },
            },
        },
    };

    const graficoInscricoesPorEstado = {
        chart: {
            type: 'column',
            alignTicks: false,
        },
        title: {
            text: 'Inscrições de Prefeitos(as) por UFs'
        },
        xAxis: {
            categories: estadosData.map((estadoData) => estadoData.estado),
            crosshair: true,
            title: {
                text: "Estado",
            },
            accessibility: {
                description: 'Estados'
            },
        },
        yAxis: [
            {
                title: {
                    text: 'Quantidade de Municípios'
                },
                min: 0,
                max: 1000,
                startOnTick: false,
                endOnTick: false,
            },
            {
                title: {
                    text: 'Porcentagem de Prefeitos Inscritos'
                },
                opposite: true,
                labels: {
                    format: '{value}%'
                },
                min: 0,
                max: 100
            }
        ],
        tooltip: {
            shared: true
        },
        series: [
            {
                name: 'Municípios',
                data: estadosData.map((estadoData) => estadoData.total_municipios),
                color: Highcharts.getOptions().colors[0],
            },
            {
                name: 'Porcentagem',
                data: estadosData.map((estadoData) => {
                    const porcentagem = (cadastroUserGlobal?.filter(
                        (user) => [3].includes(user.cargo) && user.uf === estadoData.uf
                    )?.length / estadoData.total_municipios) * 100;
                    return parseFloat(porcentagem.toFixed(2));
                }),
                color: Highcharts.getOptions().colors[1],
                yAxis: 1,
                tooltip: {
                    valueSuffix: '%'
                }
            }
        ]
    };

    const graficoComparativoInscricoes = {
        chart: {
            type: 'line',
        },
        title: {
            text: 'Evolução das inscrições 2023/2024',
        },
        xAxis: {
            categories: inscricoesAnoPassado.map((item) => item.mes),
            crosshair: true,
            title: {
                text: 'Mês',
            },
        },
        yAxis: {
            title: {
                text: 'Quantidade de Inscrições',
            },
        },
        tooltip: {
            shared: true,
        },
        series: [
            {
                name: '2023',
                data: inscricoesAnoPassado.map((item) => item.quantidade),
                color: 'blue',
            },
            {
                name: '2024',
                data: inscricoesEsteAno,
                color: 'green',
            },
        ],
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
        },
    };

    const fetchDadosFromAPI = async () => {
        try {
            const response = await api.get('api/getGeralCadastroUser');
            if (response.data && response.data.getGeralCadastroUser) {
                return response.data.getGeralCadastroUser;
            } else {
                throw new Error('Resposta da API não contém os dados esperados.');
            }
        } catch (error) {
            throw new Error(`Erro ao obter dados da API: ${error.message}`);
        }
    };

    const formatarDadosParaExcel = (dados) => {
        return dados.map(item => {
            const campos = [
                'id', 'tipo_inscricao', 'tipo_cargo', 'cargo', 'pais', 'area_atuacao',
                'empresa_instituicao', 'ministerio_orgao', 'nome', 'genero', 'PCD',
                'obs', 'nome_cracha', 'cpf', 'cnpj', 'uf', 'municipio', 'id_municipio', 'celular',
                'email', 'telefone', 'cep', 'endereco', 'bairro', 'razao_social',
                'tipo_pagamento', 'aprovado', 'parlamentar', 'partido_politico',
                'entidade_estadual', 'entidade_micro', 'entidade_nacionais', 'consorcio',
                'file', 'created_at', 'updated_at', 'status_boleto', 'dataEmissao',
                'dataVencimento', 'qtd_etiquetas', 'DataPagamento', 'valor', 'situacao_boleto'
            ];

            const campoFormatado = {};
            campos.forEach(campo => {
                campoFormatado[campo] = item[campo] || '';
            });

            return campoFormatado;
        });
    };

    const exportarDadosParaExcel = async () => {
        try {
            const dados = await fetchDadosFromAPI();
            const dadosFormatados = formatarDadosParaExcel(dados);

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(dadosFormatados);
            XLSX.utils.book_append_sheet(wb, ws, "Dados Gerais");

            const dataAtual = new Date();
            const dia = dataAtual.getDate().toString().padStart(2, '0');
            const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataAtual.getFullYear();

            const formattedDate = `${dia}-${mes}-${ano}`;

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `cadastros-credmarcha-${formattedDate}.xlsx`;

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        const fetchAllGraphs = async () => {
            try {
                const response = await api.get(`api/cadastroUser?limit=${process.env.REACT_APP_LIMIT_SEARCH}`);
                const cadastroUser = response.data.getCadastroUser;
                if (cadastroUser) {
                    setIsLoading(false);
                }
                setCadastroUserGlobal(cadastroUser);

                const inscricoesNatureza = cadastroUser.reduce((acc, user) => {
                    let cargosPorNatureza = user.tipo_cargo_descricao;

                    if (!["Prefeitura", "Câmara", "Consórcio"].includes(cargosPorNatureza)) {
                        cargosPorNatureza = "Convidados";
                    }

                    if (!acc[cargosPorNatureza]) {
                        acc[cargosPorNatureza] = 1;
                    } else {
                        acc[cargosPorNatureza]++;
                    }

                    return acc;
                }, {});

                const inscricoesCargosMunicipais = cadastroUser
                    .filter(user => user.tipo_inscricao === 1)
                    .reduce((acc, user) => {
                        const inscricoesMunicipais = user.cargo_descricao;
                        if (!acc[inscricoesMunicipais]) {
                            acc[inscricoesMunicipais] = 1;
                        } else {
                            acc[inscricoesMunicipais]++;
                        }
                        return acc;
                    }, {});

                const cargosGeral = cadastroUser
                    .reduce((acc, user) => {
                        const inscricoesGerais = user.cargo_descricao;

                        if (!acc[inscricoesGerais]) {
                            acc[inscricoesGerais] = 1;
                        } else {
                            acc[inscricoesGerais]++;
                        }

                        return acc;
                    }, {});

                const generoCount = cadastroUser.reduce((acc, user) => {
                    const genero = user.genero;

                    if (!acc[genero]) {
                        acc[genero] = 1;
                    } else {
                        acc[genero]++;
                    }

                    return acc;
                }, {});

                const status = cadastroUser.reduce((acc, user) => {
                    const homologado = user.aprovado === true || user.cortesia === 1 ? 'Homologado' : 'Não Homologado';

                    if (!acc[homologado]) {
                        acc[homologado] = 1;
                    } else {
                        acc[homologado]++;
                    }

                    return acc;
                }, {});

                const situacao = cadastroUser.reduce((acc, user) => {
                    const situacao = verificaSituacao(user);

                    if (!acc[situacao]) {
                        acc[situacao] = 1;
                    } else {
                        acc[situacao]++;
                    }

                    return acc;
                }, {});

                const tableInscricoesPorEstado = cadastroUser.reduce((acc, user) => {
                    const { cargo_descricao, uf, tipo_cargo_descricao } = user;

                    let cargo;
                    if (cargo_descricao === 'Prefeito(a)' && tipo_cargo_descricao === 'Prefeitura') {
                        cargo = 'Prefeito';
                    } else if (cargo_descricao === 'Vice' && tipo_cargo_descricao === 'Prefeitura') {
                        cargo = 'Vice';
                    } else if (cargo_descricao === 'Vereador(a)' && tipo_cargo_descricao === 'Câmara') {
                        cargo = 'Vereador';
                    } else {
                        cargo = 'Demais Participantes';
                    }

                    if (!acc[uf]) {
                        acc[uf] = {};
                    }

                    if (!acc[uf][cargo]) {
                        acc[uf][cargo] = 1;
                    } else {
                        acc[uf][cargo]++;
                    }

                    return acc;
                }, {});

                const inscricoesEsteAno = cadastroUser.reduce((acc, user) => {
                    const dataCriacao = new Date(user.created_at);
                    const mesCriacao = dataCriacao.getMonth();

                    if (!acc[mesCriacao]) {
                        acc[mesCriacao] = 1;
                    } else {
                        acc[mesCriacao]++;
                    }

                    return acc;
                }, {});

                let somaAcumulada = 0;

                const inscricoesDezembroAnoAnterior = inscricoesEsteAno[11] || 0;
                somaAcumulada += inscricoesDezembroAnoAnterior;

                const mesesDesejados = [0, 1, 2];
                Object.keys(inscricoesEsteAno).forEach((mes, index) => {

                    somaAcumulada += inscricoesEsteAno[mes];
                    inscricoesEsteAno[mes] = somaAcumulada;

                    if (!mesesDesejados.includes(Number(mes))) {
                        delete inscricoesEsteAno[mes];
                    }
                });

                const totalInscritosGeral = Object.values(cargosGeral).reduce((acc, quantidade) => acc + quantidade, 0);
                const inscricoesEsteAnoArray = Object.values(inscricoesEsteAno);
                setInscricoesEsteAno(inscricoesEsteAnoArray);
                setGovMunicipalCargos(inscricoesCargosMunicipais);
                setCargosGeral(cargosGeral);
                setNaturezas(inscricoesNatureza);
                setSituacao(situacao);
                setStatus(status);
                setGenero(generoCount);
                setTotalInscricos(totalInscritosGeral);
                setContagensInfo(tableInscricoesPorEstado);
            } catch (error) {
                console.error('Erro ao buscar as inscrições:', error);
            }
        };

        fetchAllGraphs();
        const intervalId = setInterval(fetchAllGraphs, 300000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, []);

    return (
        isLoading ? (
            <>
                <LoadingOverlay />
            </>
        ) : (
            <>
                <Grid container spacing={2} style={{ maxWidth: '99%', overflow: 'hidden', marginLeft: '10px' }}>
                    {isAdmin && (
                        <Grid item xs={12} sm={12}>
                            <Grid container justifyContent="flex-end" mt={4} style={{ marginTop: '20px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CloudDownloadIcon />}
                                    onClick={exportarDadosParaExcel}
                                >
                                    Exportar Dados
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardContent style={{ marginTop: '30px' }}>
                                        <HighchartsReact highcharts={Highcharts} options={graficoInscricoesPorNaturezas} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardContent style={{ marginTop: '30px' }}>
                                        <HighchartsReact highcharts={Highcharts} options={graficoInscricoesPorCargosMunicipal} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardContent >
                                        <HighchartsReact highcharts={Highcharts} options={graficoInscricoesPorCargosGeral} />
                                        <Grid style={{ textAlign: 'center', marginTop: '10px' }}>
                                            <strong>Total: {totalInscricos}</strong>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardContent style={{ marginTop: '30px' }}>
                                        <HighchartsReact highcharts={Highcharts} options={graficoInscricoesPorGenero} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardContent style={{ marginTop: '30px' }}>
                                        <HighchartsReact highcharts={Highcharts} options={graficoInscricoesStatus} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardContent style={{ marginTop: '30px' }}>
                                        <HighchartsReact highcharts={Highcharts} options={graficoInscricoesSituacao} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardContent style={{ marginTop: '30px' }}>
                                        <HighchartsReact highcharts={Highcharts} options={graficoComparativoInscricoes} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Card>
                                    <CardContent style={{ marginTop: '30px' }}>
                                        <HighchartsReact highcharts={Highcharts} options={graficoInscricoesPorEstado} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Card>
                                    <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.3em' }}> Inscrições por Estado </Typography>
                                    <CardContent style={{ marginTop: '30px', justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                                        <Mapa cadastroUserGlobal={cadastroUserGlobal} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TabelaEstado contagensInfo={contagensInfo} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    );
};

export default Graficos;
